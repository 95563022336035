import React from 'react'
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from 'react-device-detect';

export function Presentation(props) {
  const [t, i18n] = useTranslation('common');
  return (

    <div>
      <BrowserView>
        {/*"Esp"*/}
        <div className={`container-nosotros-banner ${t("clase.es")}`} style={{ backgroundImage: `url(${props.image})` }}>
          <div className='container'>
            <div className='row' hidden>
              <div className='col'>{props.text}</div>
              <div className='col'>{props.text2}</div>
            </div>
          </div>
        </div>
        {/*"Engish"*/}
        <div className={`container-nosotros-banner image-en ${t("clase.en")}`} style={{ backgroundImage: `url(${props.image})` }}>
          <div className='container'>
            <div className='row' hidden>
              <div className='col'>{props.text}</div>
              <div className='col'>{props.text2}</div>
            </div>
          </div>
        </div>
        {/*"Portu"*/}
        <div className={`container-nosotros-banner image-por ${t("clase.pt")}`} style={{ backgroundImage: `url(${props.image})` }}>
          <div className='container'>
            <div className='row' hidden>
              <div className='col'>{props.text}</div>
              <div className='col'>{props.text2}</div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        {/*"Esp"*/}
        <div className={`container-nosotros-banner-mobile image-esp-mobile ${t("clase.es")}`} style={{ backgroundImage: `url(${props.image2})` }}>
          <div className='container'>
            <div className='row' hidden>
              <div className='col'>{props.text}</div>
              <div className='col'>{props.text2}</div>
            </div>
          </div>
        </div>
        {/*"Engish"*/}
        <div className={`container-nosotros-banner-mobile image-en-mobile ${t("clase.en")}`} style={{ backgroundImage: `url(${props.image2})` }}>
          <div className='container'>
            <div className='row' hidden>
              <div className='col'>{props.text}</div>
              <div className='col'>{props.text2}</div>
            </div>
          </div>
        </div>
        {/*"Portu"*/}
        <div className={`container-nosotros-banner-mobile image-por-mobile ${t("clase.pt")}`} style={{ backgroundImage: `url(${props.image2})` }}>
          <div className='container'>
            <div className='row' hidden>
              <div className='col'>{props.text}</div>
              <div className='col'>{props.text2}</div>
            </div>
          </div>
        </div>
      </MobileView>
    </div>
  );
}


