import React from 'react'
import {useTranslation} from "react-i18next";

export const RecursosChild2 = (props) => {
  const [t, i18n] = useTranslation('common');
  const domainName = window.location.hostname;
  return (
    <div className='row m-0 p-0 justify-content-center '>
      <div className='row m-0 p-0 justify-content-center col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10'>
        <div>

        </div>
      </div>
      <div className={`row m-0 p-0 col-12 div-imgRecursos}`}>
        <img src={(domainName.includes('iwsservices.com') || domainName.includes('redinet.cr') || domainName.includes('redinet.pe')  || domainName.includes('redinet.com.mx')) ? props.image2 : props.image1} alt="" className='img-fluid m-0 p-0 img-PruebaL' />
      </div>
     
      <div className='row mbR p-0 justify-content-center col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-12 text-unnamed text-25'>
        <div className='row m-0 p-0 justify-content-between col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4 p-0 pe-sm-4 divRecursos-Text'>
          <p className='text-center px-5 text-download'>
          {domainName.includes('redinet.us') ? 'Discover all the solutions that Redinet has your company by downloading our ' :  
          domainName.includes('redinet.cr') || domainName.includes('redinet.pe')  || domainName.includes('redinet.com.mx') ? 'Descubre todas las soluciones que Redinet tiene para tu empresa descargando nuestra ' : 
          domainName.includes('iwsservices.com') ? 'Descubre todas las soluciones que Redinet tiene para tu empresa descargando nuestra ' :
          'Discover all the solutions that Redinet has your company by downloading our '
          }
            
            <span className='fw-bold'> {t('recursos.presentacion')}</span>
          </p>
        </div>
        <div className='row m-0 p-0 justify-content-center col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4 p-0 ps-sm-4 divRecursos-Text'>
          <p className='text-center px-5 text-download'>{t('recursos.obsequio')}<span className='fw-bold'> {t('recursos.book')}</span> {t('recursos.compartir')}
          </p>
        </div>
      </div>
    </div>
  )
}
