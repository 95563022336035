import React from 'react';
import fb from '../Images/Home/Facebook.png';
import ig from '../Images/Home/Instagram-2.png';
import tw from '../Images/Home/Twitter.png';
import yt from '../Images/Home/YouTube.png';
import lk from '../Images/Home/icon-linkedin.png';
import banderas from '../Images/Banderas.png'
import bandera1 from '../Images/banderas2024.png'
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from 'react-device-detect';

export function Footer() {

  const [t, i18n] = useTranslation('common');
  const domainName = window.location.hostname;
  return (
    <div>
      <BrowserView>
        <div className='card-footer-new'>
          <div className='container-footer-new'>
            <div className='row'>
              <div className='col-lg-4 col-sm-12 col-md-12'>
                <h4><a className='a-white'>{t('footer.contacto')}</a></h4>
                <div className='footer-info lh-1 mt-3'>
                  <p>
                    {domainName.includes('redinet.cr')
                      ? <a href="mailto:sales@redinet.cr" className='a-white'>sales@redinet.cr</a> : 
                      domainName.includes('iwsservices.ca') ?
                      <a className='a-white' href="mailto:sales@iwsservices.ca">sales@iwsservices.ca</a> 
                      : <a href="mailto:sales@redinet.us" className='a-white'>sales@redinet.us</a>
                    }
                  </p>
                  {domainName.includes('redinet.cr') == false &&
                    <>
                      <p className='text-bold footer-direction m-0 p-0 pb-1'> {t('contact.oficinasMex')} </p>
                      <p className='p-0 mb-2'>
                        <a href="tel:+525589776500" className='footer-direction a-white'>Llámanos: +52 55 8977 6500</a>
                      </p>
                      <p className='footer-direction'>
                        Mario Pani No.400 Piso1, Lomas de Santa Fe 05300 Cuajimalpa, Mexico City
                      </p>
                    </>
                  }
                  <p className='text-bold footer-direction m-0 p-0 pb-1'> {t('contact.oficinasLATAM')} </p>
                  <p>
                    <a href="tel:+52 973 321 4062" className='footer-direction a-white'>{t('footer.llamanos')} {domainName.includes('redinet.cr') ? '800 052 1713' : '+1 973 321 4062'} </a>
                  </p>
                  <p className='footer-direction'>
                      {/* San José-Santa Ana Río oro, Condominio Avalon, Country Club, Penthouse 305 */}
                    {domainName.includes('redinet.cr') ? '' : '1390 Market Street, Suite 200, San Francisco, California, 94102, United States'}
                  </p>
                </div>
              </div>
              {/* <div className='col-lg-3 col-sm-12 col-md-12'>
                <h5><a className='a-white'>Servicios</a></h5>
              </div> */}
              <div className='col-lg-4 col-sm-12 col-md-12'>
                <h4><a className='a-white'>{t('footer.politicas')}</a></h4>
                <div className='footer-info lh-1 mt-3'>
                  <p>
                    <a className="a-white">
                      {t('footer.aviso')}
                    </a>
                  </p>
                  <p>
                    <a className="a-white">
                      {t('footer.terminos')}
                    </a>
                  </p>
                  <p>
                    <a className="a-white">
                      {t('footer.preguntas')}
                    </a>
                  </p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-md-12'>
                <div className='text-center'>
                  <h5><a className='a-white'>{t('footer.siguenos')}</a></h5>
                </div>
                <div className='row col-12 p-0 m-0 mt-3 d-flex justify-content-start'>
                  <div className='col-2'>
                    <a href={domainName.includes('redinet.cr') ? 'https://www.facebook.com/RedinetCostaRica' : 'https://www.facebook.com/IWSServicesMexico/'} target="_blank" rel="noopener noreferrer">
                      <img src={fb} alt="Facebook" height="30px" width="30px" />
                    </a>
                  </div>
                  <div className={domainName.includes('redinet.cr') ? 'col-2 d-none' : 'col-2'}>
                    <a href='https://www.instagram.com/iws_services_latam' target="_blank" rel="noopener noreferrer">
                      <img src={ig} alt="Instagram" height="30px" width="30px" />
                    </a>              </div>
                  <div className={domainName.includes('redinet.cr') ? 'col-2 d-none' : 'col-2'}>
                    <a href='https://twitter.com/iws_services' target="_blank" rel="noopener noreferrer">
                      <img src={tw} alt="Twitter" height="30px" width="30px" />
                    </a>              </div>
                  <div className={domainName.includes('redinet.cr') ? 'col-2 d-none' : 'col-2'}>
                    <a href='https://www.youtube.com/channel/UC5N-VKX6WnQBxwn6Awfq_-A/featured' target="_blank" rel="noopener noreferrer">
                      <img src={yt} alt="YouTube" height="30px" width="30px" />
                    </a>
                  </div>
                  <div className={domainName.includes('redinet.cr') ? 'col-2 d-none' : 'col-2'}>
                    <a href='https://www.linkedin.com/company/iws-services/' target="_blank" rel="noopener noreferrer">
                      <img src={lk} alt="YouTube" height="30px" width="30px" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container-footer-new-bottom'>
            <div className='row'>

              <div className='col-12 text-center'>
                <h5>{t('footer.presencia')}</h5>
                
                <img src={bandera1} alt="Banderas" className="row mb-0 bandera-footer" width="100%"></img>
                
                
                <div className='footer-italic text-center mb-3 mb-md-1'>
                  Redinet Rights Reserved 2023
                </div>
              </div>
            </div>
          </div>
        </div>

      </BrowserView>

      <MobileView>
        <div className='card-footer-new mt-4'>
          <div className='container-footer-new'>
            <div className='row'>
              <div className='d-flex '>
                <div className='col-6'>
                  <div className=''>
                    <h4><a className='a-white'>{t('footer.contacto')}</a></h4>
                    <div className='footer-info lh-1'>
                      <p>
                        { domainName.includes('redinet.cr')
                          ? <a className='a-white' href="mailto:sales@redinet.cr">sales@redinet.cr</a>
                          : domainName.includes('iwsservices.ca') ?
                           <a className='a-white' href="mailto:sales@iwsservices.ca">sales@iwsservices.ca</a> :
                           <a className='a-white' href="mailto:sales@redinet.us">sales@redinet.us</a>
                        }
                      </p>
                      {domainName.includes('redinet.cr') == false &&
                        <>
                          <p className='text-bold footer-direction m-0 p-0 pb-1'> {t('contact.oficinasMex')} </p>
                          <p className=''>
                            <a href="tel:+525589776500" className='footer-direction a-white'>{t('contact.llamanos')}+52 55 8977 6500</a>
                          </p>
                          <p className='footer-direction'>
                            Mario Pani No.400 Piso1, Lomas de Santa Fe 05300 Cuajimalpa, Mexico City
                          </p>
                        </>
                      }
                      <p className='text-bold footer-direction m-0 p-0 pb-1'> {t('contact.oficinasLATAM')} </p>
                      <p>
                        <a href="tel:+52 973 321 4062" className='footer-direction '>{t('footer.llamanos')} {domainName.includes('redinet.cr') ? '800 052 1713' : '+1 973 321 4062'} </a>
                      </p>
                      <p className='footer-direction'>
                        {domainName.includes('redinet.cr') ? 'San José-Santa Ana Río oro, Condominio Avalon, Country Club, Penthouse 305' : '1390 Market Street, Suite 200, San Francisco, California, 94102, United States'}
                      </p>
                    </div>
                  </div>

                </div>

                <div className='col-6'>
                  <h4><a className='a-white'>{t('footer.politicas')}</a></h4>
                  <div className='footer-info lh-1 mt-3'>
                    <p>
                      <a >
                        {t('footer.aviso')}
                      </a>
                    </p>
                    <p>
                      <a >
                        {t('footer.terminos')}
                      </a>
                    </p>
                    <p>
                      <a >
                        {t('footer.preguntas')}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className=''>
                <h4><a className='a-white'>{t('footer.servicios')}</a></h4>
              </div> */}

              <div className=''>
                <div className='text-center'>
                  {/* <h5><a className='a-white'>{t('footer.siguenos')}</a></h5> */}
                </div>
                <div className='row col-12 p-0 m-0 mt-4 d-flex justify-content-center'>
                  <div className='col-2'>
                    <a href={domainName.includes('redinet.cr') ? 'https://www.facebook.com/RedinetCostaRica' : 'https://www.facebook.com/IWSServicesMexico/'} target="_blank" rel="noopener noreferrer">
                      <img src={fb} alt="Facebook" height="30px" width="30px" />
                    </a>
                  </div>
                  <div className={domainName.includes('redinet.cr') ? 'col-2 d-none' : 'col-2'}>
                    <a href='https://www.instagram.com/iws_services_latam' target="_blank" rel="noopener noreferrer">
                      <img src={ig} alt="Instagram" height="30px" width="30px" />
                    </a>              </div>
                  <div className={domainName.includes('redinet.cr') ? 'col-2 d-none' : 'col-2'}>
                    <a href='https://twitter.com/iws_services' target="_blank" rel="noopener noreferrer">
                      <img src={tw} alt="Twitter" height="30px" width="30px" />
                    </a>              </div>
                  <div className={domainName.includes('redinet.cr') ? 'col-2 d-none' : 'col-2'}>
                    <a href='https://www.youtube.com/channel/UC5N-VKX6WnQBxwn6Awfq_-A/featured' target="_blank" rel="noopener noreferrer">
                      <img src={yt} alt="YouTube" height="30px" width="30px" />
                    </a>
                  </div>
                  <div className={domainName.includes('redinet.cr') ? 'col-2 d-none' : 'col-2'}>
                    <a href='https://www.linkedin.com/company/iws-services/' target="_blank" rel="noopener noreferrer">
                      <img src={lk} alt="YouTube" height="30px" width="30px" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container-footer-new-bottom m'>
            <div className='row'>

              <div className='col-12 text-center mt-4 px-4'>
                <h5>{t('footer.presencia')}</h5>
                <img src={bandera1} alt="Banderas" className={domainName.includes('redinet.cr') ? 'row mb-0 bandera-footer d-none' : 'row mb-0 bandera-footer d-none'} width='100%' />
                <div className={domainName.includes('redinet.cr') ? 'mb-3 d-none' : 'flags-movil mb-3 '} >
                  <img src={bandera1} alt="Banderas" className='mt-3 mb-0 d-none' />
                </div>

                <div className={domainName.includes('redinet.cr') ? 'mb-3 d-none' : 'flags-movil mb-3 '} >
                  <img src={bandera1} alt="Banderas" className='mt-3 mb-0' />
                </div>

                <div className='footer-italic text-center mb-3 mb-md-1'>
                  Redinet Rights Reserved 2023
                </div>
              </div>
            </div>
          </div>
         
        </div>

      </MobileView>
    </div>

  )
}