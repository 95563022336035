import React from 'react'
import { useTranslation } from "react-i18next";
import { CardProblems } from './CardProblems'
export function ProposalsContainer(props) {
    const [t, i18n] = useTranslation('common');
    const domainName =  window.location.hostname;
    const cards = props.images.map((data, index) =>
        <div className='col-sm-12 col-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 align-self-center pb-5 text-center' key={index}>
            <img src={data} className="w-75" />
        </div>
    );
    return (

        <div className='mb-4'>
            <div className='row justify-content-center'>
                <div className={domainName.includes('iwsservices.com.br') ? 'd-none' : 'col-8 text-center mb-5'}>
                    <span className={props.classnameindustry}>PROPUESTAS DE PROYECTOS DE TECNOLOGÍA:</span>
                </div>
                <div className={domainName.includes('iwsservices.com.br') ? 'col-8 text-center mb-5' : 'd-none'}>
                    <span className={props.classnameindustry}>PROPOSTAS DE PROJETOS DE TECNOLOGIA:</span>
                </div>
            </div>
            <div className='row justify-content-center'>{cards}</div>
        </div>
    )
}