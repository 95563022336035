import React from 'react'

export function SVG(props) {
  return (
    <svg xmlns={props.xmlns} width={props.ancho} height={props.alto}
      fill={props.currentColor} className={props.clase} viewBox={props.viewCaja}>
      <path fillRule="evenodd"
        d={props.d} />
    </svg>
  )
}
