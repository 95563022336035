import React from 'react';
import {useTranslation} from "react-i18next";

export function SecondInfo(props) {
  const [t, i18n] = useTranslation('common');

  const images = props.images.map((data, index) =>
    <div key={index} className='col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 d-flex justify-content-center'>
     <div className='containerImg'>
     <div className='d-flex justify-content-center'>
        <img src={data.src} alt="1" width='120px' className='img-fluid imgInfo'/>
      </div>
      <div className='container-imgHover'>
      {<img src={data.hover} className='imgContainer'/>}
      </div>
     </div>
    </div>
  );
  return (
    <div className='mb-3'>
      <div className='row p-0 m-0'>
        <div className='col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 col-xxl-7 d-flex justify-content-center text-end flex-column our-Job'>
          <div className='align-self-end px-3 divTrabajo'>
            <p className='p-second-info text-start'>{t('help.manera')}</p>
            {t('help.satisfaccion')}
          </div>
        </div>
        <div className='block-info2'>
        </div>
      </div>
      <div className='row p-0 m-0 mt-4 div-italyc'>
        <p className='font-italic'>
        {t('help.informacion')}
        </p>
      </div>
      <div className='row p-0 m-0 mt-5 d-flex justify-content-center align-items-center'>
        {images}
      </div>

    </div>

  )
}
