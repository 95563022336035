import React, { Fragment, useEffect } from 'react'
import { Presentation } from './Components/Presentation'
import { Child1 } from './Components/Services/Child1'
import { ButtonForm } from './Components/ButtonForm'
import { BagChild2 } from './Components/Services/BagChild2'
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";
import styles from './CSS/bagHour.css';
import { useTranslation } from "react-i18next";


const condiciones = 'https://cdn.iwsservices.com/Bolsa de Horas/Condiciones.png'
const condicionesEn = 'https://cdn.iwsservices.com/Bolsa de Horas/CondicionesEn.png'
const condicionesPt = 'https://cdn.iwsservices.com/Bolsa de Horas/Condiciones - Portugués.png'
const condiciones2 = 'https://cdn.iwsservices.com/Bolsa de Horas/Condiciones2.png'
const condiciones2En = 'https://cdn.iwsservices.com/Bolsa de Horas/Condiciones2En.png'
const img1DesktopEs = "https://cdn.iwsservices.com/Secciones/Desktop/Es/Bolsadehoras.png";
const img1DesktopEn = "https://cdn.iwsservices.com/Secciones/Desktop/En/Bagofhours.png";
const img1DesktopPr = "https://cdn.iwsservices.com/Secciones/Desktop/Pr/Bancodehoras.png";
const img1MobileEs = "https://cdn.iwsservices.com/Secciones/Mobile/Es/Bolsadehoras.png";
const img1MobileEn = "https://cdn.iwsservices.com/Secciones/Mobile/En/Bagofhours.png";
const img1MobilePr = "https://cdn.iwsservices.com/Secciones/Mobile/Pr/Bancodehoras.png";


export const Bag_Hours = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const domainName = window.location.hostname;
  const [t, i18n] = useTranslation('common');

  const child1 = {
    text1: `${t('bag.child1-text1')}`,
    span: `${t('bag.child1-span')}`,
    text2: `${t('bag.child1-text2')}`,
    span2: `${t('bag.child1-span2')}`,
    text3: `${t('bag.child1-text3')}`,
  }
  const inputs = [
    { label: "Nombre", type: "text", placeholder: "Jhon Doe" },
    { label: "E-mail", type: "email", placeholder: "nuevo@gmail.com" },
    { label: "Comentarios", type: "textarea", placeholder: "Type something…" },
  ]
  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresMigrationsFather" className='bagFather'>
          <Presentation
            image={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1DesktopEn : domainName.includes('iwsservices.com.br') ? img1DesktopPr : img1DesktopEs}
            image2={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1MobileEn : domainName.includes('iwsservices.com.br') ? img1MobilePr : img1MobileEs}
            text={t("bag.text")}
          />
        </div>
        <div id="MigraChild1" className='row m-0 p-0 justify-content-center pb-5 pt-7'>
          <div className=' divBag-migra-child1'>
            <Child1 text1={child1.text1} span={child1.span} text2={child1.text2} span2={child1.span2} text3={child1.text3} />
          </div>
          <div className={`row m-0 p-0 justify-content-center margin-topBag`}>
            <BagChild2
              image1={domainName.includes('iwsservices.com.br') ? condicionesPt : domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') || domainName.includes('redinet.us') ? condicionesEn : condiciones}
              image2={domainName.includes('iwsservices.com.br') ? condiciones2 : domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca')  || domainName.includes('redinet.us') ? condiciones2En : condiciones2}
            />
          </div>
          {/* <div className={`row m-0 p-0 justify-content-center margin-topBag ${t("clase.en")}`}>
          <BagChild2 image1={condicionesEn} image2={condiciones2En}/>
        </div> */}
        </div>
        <ButtonForm inputs={inputs} />

      </Fragment>
      <Footer />
      <ToggleComp />
    </div>
  )
}
