import React, { Fragment, useEffect } from 'react'
import { Soluciones } from './Components/Soluciones'
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";
import { SolutionsChild1 } from './Components/Solutions/SolutionsChild1'
import { SolutionsChild2 } from './Components/Solutions/SolutionsChild2'
import { useTranslation } from "react-i18next";

const Kasper = 'https://cdn.iwsservices.com/Kaspersky.png'
const Kasper2 = 'https://cdn.iwsservices.com/Kaspersky2Movil.png'
const virus = 'https://cdn.iwsservices.com/Soluciones/Kaspersky/Anti-virus.png'
const hackeo = 'https://cdn.iwsservices.com/Soluciones/Kaspersky/Antihackeo.png'
const cloud = 'https://cdn.iwsservices.com/Soluciones/Kaspersky/Cloud.png'
const proteccion = 'https://cdn.iwsservices.com/Soluciones/Kaspersky/Protección.png'
const image01 = 'https://cdn.iwsservices.com/Soluciones/Kaspersky/Internet Security.png'
const image02 = 'https://cdn.iwsservices.com/Soluciones/Kaspersky/Total Security.png'
const image03 = 'https://cdn.iwsservices.com/Soluciones/Kaspersky/Security Cloud.png'


export function Kaspersky() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const [t, i18n] = useTranslation('common');

  const body = {
    seguridad: [
      { src: virus, height: "145px", width: "145px", text: `${t('kaspersky.virus')}` },
      { src: hackeo, height: "145px", width: "145px", text: `${t('kaspersky.hackeo')}` },
      { src: cloud, height: "145px", width: "145px", text: `${t('kaspersky.cloud')}` },
      { src: proteccion, height: "145px", width: "145px", text: `${t('kaspersky.proteccion')}` }
    ]
  }
  const child1 = {
    text1: `${t('kaspersky.child1-text1')}`,
    span: `${t('kaspersky.child1-span')}`,
    text2: `${t('kaspersky.child1-text2')}`,
    span2: `${t('kaspersky.child1-span2')}`,
    text3: `${t('kaspersky.child1-text3')}`,
  }
  const child2 = [
    {
      src: image01,
      text1: `${t('kaspersky.img1-text1')}`,
      span: `${t('kaspersky.img1-span')}`,
      text2: `${t('kaspersky.img1-text2')}`,
      value: 1
    },
    {
      src: image02,
      text1: `${t('kaspersky.img2-text1')}`,
      span: `${t('kaspersky.img2-span')}`,
      text2: `${t('kaspersky.img2-text2')}`,
      value: 0
    },
    {
      src: image03,
      text1: `${t('kaspersky.img3-text1')}`,
      span: `${t('kaspersky.img3-span')}`,
      text2: `${t('kaspersky.img3-text2')}`,
      value: 1
    },
  ]
  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresKasFather" className='kasperky'>
          <Soluciones image={Kasper} image2={Kasper2} />
        </div>
        <div id="KasperChild1" className='mb-4 soluciones'>
          <SolutionsChild1 images={body.seguridad} text1={child1.text1} span={child1.span} text2={child1.text2} span2={child1.span2} text3={child1.text3} />
        </div>
        <div id="KasperChild2" className='my-4 margin-top'>
          <SolutionsChild2 image={child2} button={{ text: `${t('AWS.button')}`, rute: "/" }} />
        </div>
      </Fragment>
      <ToggleComp />
      <Footer />
    </div>

  )
}
