import React, { Fragment } from 'react'
import { Presentation } from './Components/Presentation'
import { ProblemsContainer } from './Components/Industry/ProblemsContainer'
import { ProposalsContainer } from './Components/Industry/ProposalsContainer'
import { ProposalsContainerHorizontal } from './Components/Industry/ProposalsContainerHorizontal'
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from 'react-device-detect'

const goverment = 'https://cdn.iwsservices.com/Industrias/Gobierno/Gobierno.png'
const goverment_mov = 'https://cdn.iwsservices.com/Industrias/Gobierno/Gobierno_Mobile.png'
const headerImg = 'https://cdn.iwsservices.com/Industrias/Gobierno/header_image.png'
const firstCard = 'https://cdn.iwsservices.com/Industrias/Gobierno/cards/1.3.png'
const secondCard = 'https://cdn.iwsservices.com/Industrias/Gobierno/cards/2.3.png'
const thirdCard = 'https://cdn.iwsservices.com/Industrias/Gobierno/cards/3.3.png'
const fourhCard = 'https://cdn.iwsservices.com/Industrias/Gobierno/cards/4.3.png'
const proposal1 = 'https://cdn.iwsservices.com/Industrias/Gobierno/proposals/Grupo 1.png'
const proposal2 = 'https://cdn.iwsservices.com/Industrias/Gobierno/proposals/Grupo 2.png'
const proposal3 = 'https://cdn.iwsservices.com/Industrias/Gobierno/proposals/Grupo 3.png'
const proposal4 = 'https://cdn.iwsservices.com/Industrias/Gobierno/proposals/Grupo 4.png'
const proposal1Pt = 'https://cdn.iwsservices.com/Industrias/Gobierno/proposals/Grupo 1 portugues.png'
const proposal2Pt = 'https://cdn.iwsservices.com/Industrias/Gobierno/proposals/Grupo 2 portugues.png'
const proposal3Pt = 'https://cdn.iwsservices.com/Industrias/Gobierno/proposals/Grupo 3 portugues.png'
const proposal4Pt = 'https://cdn.iwsservices.com/Industrias/Gobierno/proposals/Grupo 4 portugues.png'

const proposal1Hori = 'https://cdn.iwsservices.com/Industrias/Gobierno/proposals/Grupo 1 hori.png'
const proposal2Hori = 'https://cdn.iwsservices.com/Industrias/Gobierno/proposals/Grupo 2 hori.png'
const proposal3Hori = 'https://cdn.iwsservices.com/Industrias/Gobierno/proposals/Grupo 3 hori.png'
const proposal4Hori = 'https://cdn.iwsservices.com/Industrias/Gobierno/proposals/Grupo 4 hori.png'

//inglés
const govermentEN = 'https://cdn.iwsservices.com/Industrias/Educacion/ingles/Goverment.png';
//portugués
const govermentPT = 'https://cdn.iwsservices.com/Industrias/Educacion/portugues/Goverment.png';
//inglés
const govermentENMov = 'https://cdn.iwsservices.com/Industrias/Educacion/ingles/Goverment_mov.png';
//portugués
const govermentPTMov = 'https://cdn.iwsservices.com/Industrias/Educacion/portugues/Goverment_mov.png';


export function Government() {
  const domainName = window.location.hostname;
  const [t, i18n] = useTranslation("common");
  const proposalsImages = [proposal1, proposal2, proposal3, proposal4];
  const proposalsImagesPt = [proposal1Pt, proposal2Pt, proposal3Pt, proposal4Pt];
  const proposalsImagesHorizontal = [proposal1Hori, proposal2Hori, proposal3Hori, proposal4Hori];
  const cardData = [
    {
      img: firstCard,
      title: "1. Confusion",
      body: "Tanto ciudadanos como empleados de las organizaciones no entienden los procedimientos que deben llevar a cabo, con quién dirigirse y qué camino seguir para concluir con su trámite."
    },
    {
      img: secondCard,
      title: "2. Burocracia",
      body: "Hay una administración ineficiente debido a papaleo excesivo, mal manejo de información y a que no se cuentan con procesos estandarizados."
    },
    {
      img: thirdCard,
      title: "3. Ineficiencia",
      body: "Demasiados procesos manuales que impactan en la productividad de las organizaciones."
    },
    {
      img: fourhCard,
      title: "4. Insatisfacción",
      body: "Los ciudadanos esperan demasiado para realizar trámites y de manera frecuente no pueden resolver sus asuntos con las distintas instancias de gobierno."
    },
  ]

  const cardDataPt = [
    {
      img: firstCard,
      title: "1. Confusão",
      body: "Tanto os cidadãos como os funcionários das organizações não entendem os procedimentos que devem ser realizados, quem contatar e qual caminho seguir para concluir o processo."
    },
    {
      img: secondCard,
      title: "2. Burocracia",
      body: "Há uma administração ineficiente devido ao excesso de papelada, má gestão de informações e falta de padronização dos processos."
    },
    {
      img: thirdCard,
      title: "3. Ineficiência",
      body: "Muitos processos manuais que impactam a produtividade das organizações."
    },
    {
      img: fourhCard,
      title: "4. Insatisfação",
      body: "Os cidadãos demoram muito para realizar os procedimentos e muitas vezes não conseguem resolver seus problemas com as diferentes instâncias de governo."
    },
  ]

  let imgBanner = goverment;
  if (domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca')) {
    imgBanner = govermentEN;
  } else if (domainName.includes('iwsservices.com.br')) {
    imgBanner = govermentPT;
  }

  let imgBannerMov = goverment_mov;
  if (domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca')) {
    imgBannerMov = govermentENMov;
  } else if (domainName.includes('iwsservices.com.br')) {
    imgBannerMov = govermentPTMov;
  }

  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresRecursosFather" className="recursosFather">
          <Presentation
            image={imgBanner}
            image2={imgBannerMov}
            text={t("goverment.title")}
          />
          <div className="pleca-2"></div>
        </div>
        <div id="RecursosChild1" className="pb-0 control-header-industry">
          <BrowserView>
            <div className='box-problems-container'>
              <ProblemsContainer widthImg={'w-60'} imagebotom={headerImg} cardsData={domainName.includes('iwsservices.com.br') ? cardDataPt : cardData} />

            </div>

          </BrowserView>

          <MobileView>
            <div className='box-problems-container-mb'>
              <ProblemsContainer widthImg={'w-60'} imagebotom={headerImg} cardsData={domainName.includes('iwsservices.com.br') ? cardDataPt : cardData} />
            </div>

          </MobileView>
        </div>
        <div className='row justify-content-center'>
          <div className='col-sm-12 col-12 col-md-12 col-lg-8 col-xl-9 col-xxl-8 text-center'>
            <span className={domainName.includes('iwsservices.com.br') ? "d-none color-gobierno" : "span-our-alianza color-gobierno"}>VISIÓN</span>
            <span className={domainName.includes('iwsservices.com.br') ? "span-our-alianza color-gobierno" : "d-none"}>VISÃO</span>
            <p className={domainName.includes('iwsservices.com.br') ? "d-none" : "align-items-center justify-content-center"}>Bienestar y salud preventiva, industria, innovación e infraestructura, educación de calidad, acción climática, equidad de género, cuidades y comunidades sustentables,<strong> trabajo digno, crecimiento económico e instituciones de paz y justicia.</strong></p>
            <p className={domainName.includes('iwsservices.com.br') ? "align-items-center justify-content-center" : "d-none"}>Bem-estar e saúde preventiva, indústria, inovação e infraestrutura, educação de qualidade, ação climática, igualdade de gênero, cidades e comunidades sustentáveis, <strong>trabalho decente, crescimento econômico e instituições de paz e justiça.</strong></p>
          </div>
        </div>
        <div className='row justify-content-center mt-5 ProposalsContainer'>
          <div className='col-10 mt-5'>
            <ProposalsContainer images={domainName.includes('iwsservices.com.br') ? proposalsImagesPt : proposalsImages} classnameindustry="span-our-alianza color-gobierno" />
          </div>
        </div>

        <div className='row justify-content-center mt-5 ProposalsContainerHorizontal'>
          <div className='col-10 mt-5'>
            <ProposalsContainerHorizontal images={domainName.includes('iwsservices.com.br') ? proposalsImagesHorizontal : proposalsImagesHorizontal} classnameindustry="span-our-alianza color-gobierno" />
          </div>
        </div>

      </Fragment>
      <ToggleComp />
      <Footer />
    </div>
  )
}