import { CopyAllTwoTone } from '@mui/icons-material';
import React from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { useTranslation } from 'react-i18next';

const copaImg =  'https://cdn.iwsservices.com/Nosotros/Copa.png';

const Commitment = props => {

  const [t, i18n] = useTranslation('common');
  const domainName = window.location.hostname;

  return (
    <div>
      <BrowserView className='bg-gray'>
        <div className='bg-white container-snake-left-rr'>
          <div className='container-rr-nosotros container pb-4'>
            <div className='row'>
              <div className='col'>
                <img src={copaImg} alt="" width="400px" height="300px" className='img-flud copa-img' />
              </div>
              <div className='col'>
                <div className='container text-center mt--6'>

                  <h2 className="span-our-alianza span-info-Aliado">{t('aboutUs.compromiso')}</h2>
                  <h2 className='title-a-section-home our-alianza"'>{t('aboutUs.clientes')}</h2>

                  <div className='container'>
                    <p className='p-compromisos'>
                      {domainName.includes('redinet.us') ? 'Redinet is a Mexican company, which was founded more than 17 years ago. We have the purpose of providing an excellent service to our clients and accompanying them step by step in their digital transformation.' :

                        domainName.includes('redinet.cr') || domainName.includes('redinet.pe') || domainName.includes('redinet.com.mx') ? 'Redinet se fundó hace más de 17 años con el propósito de brindar un excelente servicio a nuestros clientes y acompañándolos paso a paso en su transformación digital.' :

                          domainName.includes('redinet.br') ? 'A Redinet se fundou há mais de 17 anos com o propósito de oferecer um excelente serviço a nossos clientes e acompanhá-los passo a passo em sua transformação digital.' :

                            domainName.includes('iwsservices.com') ? 'Redinet se fundó hace más de 17 años con el propósito de brindar un excelente servicio a nuestros clientes y acompañándolos paso a paso en su transformación digital.' :

                              'Redinet is a Mexican company, which was founded more than 17 years ago. We have the purpose of providing an excellent service to our clients and accompanying them step by step in their digital transformation.'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>

      <MobileView className='pb-4 bg-gray'>
        <div className='bg-white container-snake-left-rr'>
          <div className='container text-center'>
            <img src={copaImg} alt="Copa" height={150} width={200} />
          </div>
          <div className='box-title-rr'>
            <p id='' className='title-rr'>{t('aboutUs.compromiso')}</p>
            <p id='' className='subtitle-rr'>{t('aboutUs.clientes')}</p>
          </div>
          <div id='' className='text-info-aboutus pb-6 mg-2'>
            Redinet {t('aboutUs.historia')}
          </div>
        </div>
      </MobileView>
    </div>
  )
}


export default Commitment