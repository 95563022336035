import React from 'react'

export const HelpChild3 = (props) => {
  const images = props.images.map((data, index) =>
    <div key={index} className='col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 px-2 mx-2 img-box-help height-420 my-2 my-sm-1'>
      <img src={data.src} alt="Ejemplo"  className='img-fluid '/>
      <p className='toolHelp'>
        {data.text}
      </p>
    </div>
  );
  return (
    <div className='row m-0 p-0 col-12 col-sm-12 col-md-12 col-lg-9 col-xl-10 col-xxl-9 justify-content-center'>
      {images}
    </div>
  )
}
