import React, { Fragment, useEffect } from 'react'
import { Presentation } from './Components/Presentation'
import { Child1 } from './Components/Services/Child1'
import { MigraChild2 } from './Components/Services/MigraChild2'
import { MigraChild3 } from './Components/Services/MigraChild3'
import { ButtonForm } from './Components/ButtonForm'
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";
import { useTranslation } from "react-i18next";


const img01 = 'https://cdn.iwsservices.com/Migraciones/Costos.png'
const img02 = 'https://cdn.iwsservices.com/Migraciones/Escalabilidad.png'
const img03 = 'https://cdn.iwsservices.com/Migraciones/Elasticidad.png'
const img04 = 'https://cdn.iwsservices.com/Migraciones/Confiabilidad.png'
const img05 = 'https://cdn.iwsservices.com/Migraciones/Seguridad.png'
const img01Pt = 'https://cdn.iwsservices.com/Migraciones/Costos portugues.png'
const img02Pt = 'https://cdn.iwsservices.com/Migraciones/Escalabilidad portugues.png'
const img03Pt = 'https://cdn.iwsservices.com/Migraciones/Elasticidad portugues.png'
const img04Pt = 'https://cdn.iwsservices.com/Migraciones/Confiabilidad portugues.png'
const img05Pt = 'https://cdn.iwsservices.com/Migraciones/Seguridad portugues.png'
const beneficios = 'https://cdn.iwsservices.com/Migraciones/Beneficios.png'
const beneficiosEn = 'https://cdn.iwsservices.com/Migraciones/BeneficiosEn.png'
const mapaEn = 'https://cdn.iwsservices.com/Migraciones/MapaEn.png'
const imagen01 = 'https://cdn.iwsservices.com/Migraciones/Necesidades.png'
const imagen02 = 'https://cdn.iwsservices.com/Migraciones/Modelo.png'
const imagen03 = 'https://cdn.iwsservices.com/Migraciones/Sistemas.png'
const img1DesktopEs = "https://cdn.iwsservices.com/Secciones/Desktop/Es/Migraciones.png";
const img1DesktopEn = "https://cdn.iwsservices.com/Secciones/Desktop/En/Migrations.png";
const img1DesktopPr = "https://cdn.iwsservices.com/Secciones/Desktop/Pr/Migrações.png";
const img1MobileEs = "https://cdn.iwsservices.com/Secciones/Mobile/Es/Migraciones.png";
const img1MobileEn = "https://cdn.iwsservices.com/Secciones/Mobile/En/Migrations.png";
const img1MobilePr = "https://cdn.iwsservices.com/Secciones/Mobile/Pr/Migrações.png";



export const Migrations = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const [t, i18n] = useTranslation('common');
  const domainName = window.location.hostname;

  const child1 = {
    text1: `${t('migraciones.child1-text1')}`,
    span: `${t('migraciones.child1-span')}`,
    text2: `${t('migraciones.child1-text2')}`,
    span2: `${t('migraciones.child1-span2')}`,
    text3: `${t('migraciones.child1-text3')}`,
  }
  const images = [
    { src: img01, value: 1 },
    { src: img02, value: 0 },
    { src: img03, value: 1 },
    { src: img04, value: 0 },
    { src: img05, value: 1 },
  ]

  const imagesPt = [
    { src: img01Pt, value: 1 },
    { src: img02Pt, value: 0 },
    { src: img03Pt, value: 1 },
    { src: img04Pt, value: 0 },
    { src: img05Pt, value: 1 },
  ]


  const images2 = [
    {
      src: imagen01,
      Text1: `${t('migraciones.img1-text1')}`,
      hover: `${t('migraciones.img1-hover')}`
    },
    {
      src: imagen02,
      Text1: `${t('migraciones.img2-text1')}`,
      hover: `${t('migraciones.img2-hover')}`
    },
    {
      src: imagen03,
      Text1: `${t('migraciones.img3-text1')}`,
      hover: `${t('migraciones.img3-hover')}`
    },
  ]
  const inputs = [
    { label: "Nombre", type: "text", placeholder: "Jhon Doe" },
    { label: "E-mail", type: "email", placeholder: "nuevo@gmail.com" },
    { label: "Comentarios", type: "textarea", placeholder: "Type something…" },
  ]
  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresMigrationsFather" className='migrationFather'>
          <Presentation
            image={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1DesktopEn : domainName.includes('iwsservices.com.br') ? img1DesktopPr : img1DesktopEs}
            image2={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1MobileEn : domainName.includes('iwsservices.com.br') ? img1MobilePr : img1MobileEs}
            text={t("migraciones.text")}
          />

        </div>
        <div id="MigraChild1" className='row m-0 p-0 justify-content-center mb-5 pb-5 pb-7 migTop'>
          <div className='my-5 div-MigraChild1 p-4'>
            <Child1 text1={child1.text1} span={child1.span} text2={child1.text2} span2={child1.span2} text3={child1.text3} />
          </div>
          <div className='migrationBenefi m-0 p-0 col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 col-xxl-9 text-center text-47-blue mt-5'>
            <p>
              {t('migraciones.beneficios')}
            </p>
            <p className='text-27-light p-MigrationDecision'>
              {t('migraciones.decision')}
            </p>
          </div>
        </div>
        <div id="MigraChild2" className='row m-0 p-0 pb-4 justify-content-center position-relative'>
          <div className="gris-derecha"></div>
          <div className={domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') || domainName.includes('redinet.us') ? 'd-none m-0 p-0' : 'd-block m-0 p-0'}>
            <MigraChild2 img={domainName.includes('iwsservices.com.br') ? imagesPt : images} img01={domainName.includes('iwsservices.com.br') ? beneficios : beneficios} />
          </div>

          <div className={domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') || domainName.includes('redinet.us') ? 'd-block m-0 p-0' : 'd-none m-0 p-0'}>
            <div className={`m-0 p-0 d-flex justify-content-center`}>
              <img className='d-none d-md-block m-0 p-0 py-5 mb-5 imgBenEn' src={mapaEn} alt="map1" />
              <img className="d-block d-md-none img-fluid m-0 p-0 pt-4" src={beneficiosEn} alt="map1" />
            </div>
          </div>

        </div>
        <div id="ContactChild2" className='row m-0 p-0 pb-4 justify-content-center pt-5 migrationBenefi'>
          <p className='m-0 p-0 px-1 col-12 col-sm-12 col-md-11 col-lg-10 col-xl-10 col-xxl-8 text-47-blue text-center mt-5'>
            {t('migraciones.migrar')}
          </p>
          <MigraChild3 images={images2} />
        </div>
        <ButtonForm inputs={inputs} />
      </Fragment>
      <Footer />
      <ToggleComp />
    </div>
  )
}
