import React from 'react'
import { useTranslation } from "react-i18next";
import { CardProblems } from './CardProblems'
import { Carousel as CarouselReact } from 'react-responsive-carousel';
import { BrowserView, MobileView } from 'react-device-detect';
import { CardContent } from '@mui/material';

export function ProblemsContainer(props) {
    const domainName = window.location.hostname;
    const [t, i18n] = useTranslation('common');
    const cards = props.cardsData.map((data, index) =>
        <div className='col-6 align-self-center pb-5' key={index}>
            <CardProblems img={data.img} title={data.title} body={data.body} />
        </div>
    );
    const cardsMobile = props.cardsData.map((data, index) =>

        <div className='card-content text-center' key={index}>
            <CardProblems img={data.img} title={data.title} body={data.body} />
        </div>
    );
    const onChange = () => {
    }
    const onClickItem = () => {
    }

    const onClickThumb = () => {

    }
    return (
        <div>

            <div className='my-4'>
                <div className='row justify-content-end'>
                    <div className='col-sm-4 col-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                        <img className={`pt-xl-10 d-none d-sm-none d-md-block d-lg-block ${props.widthImg}`} src={props.imagebotom}></img>
                    </div>
                </div>
                <BrowserView>
                    <div className='row justify-content-center padding-top-class-title'>
                        <div className='col-sm-11 col-11 col-md-10 col-lg-10 col-xl-8 col-xxl-6 text-center'>
                            <h1 className={props.classvar}>PROBLEMAS</h1>
                            <span className={domainName.includes('iwsservices.com.br') ? "d-none" : "s-Solu-child1 text-subtitle-financial"}>A LOS QUE SE ENFRENTA EL SECTOR:</span>
                            <span className={domainName.includes('iwsservices.com.br') ? "s-Solu-child1 text-subtitle-financial" : "d-none"}>ENFRENTADOS PELO SETOR:</span>
                        </div>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className='container'>
                        <img className='image-style-2 f-r' src={props.imagebotom}></img>
                    </div>

                    
                    <div className='container text-center'>
                                <h1 className={props.classvar}>PROBLEMAS</h1>
                                <span className={domainName.includes('iwsservices.com.br') ? "d-none" : "s-Solu-child1 text-subtitle-financial"}>A LOS QUE SE ENFRENTA EL SECTOR:</span>
                                <span className={domainName.includes('iwsservices.com.br') ? "s-Solu-child1 text-subtitle-financial" : "d-none"}>ENFRENTADOS PELO SETOR:</span>
                            </div>
                </MobileView>
                <div className='row justify-content-center'>
                    <div className='col-sm-12 col-11 col-md-11 col-lg-10 col-xl-8 col-xxl-8'>
                        <BrowserView>
                            <div className='row mt-5 justify-content-center text-center'>
                                {cards}
                            </div>
                        </BrowserView>
                        <MobileView>
                            

                            <CardContent>
                                <CarouselReact interval={7000} showStatus={false} showArrows={true} onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb} autoPlay={true} infiniteLoop={true} showThumbs={false}>
                                    {cardsMobile}
                                </CarouselReact>
                            </CardContent>
                        </MobileView>
                    </div>
                </div>
            </div>
        </div>
    )
}