import React, { Fragment } from 'react'
import useScript from '../UseScript'
export const Form = (props) => {
  const component = useScript('Plumsail.js',"");
  return (
    <Fragment>
      <div id="plumsail-form-0mln"><div className="fd-form-loading"></div></div>
      {component}
    </Fragment>
  )
}
