import React, { useContext } from 'react';
import './ReportsChatBot.css';
import Axios from "axios";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';
import { Pagination, Tooltip, OverlayTrigger, Modal, Button } from 'react-bootstrap';
import { ContextDataChatIWS } from '../..';
import Skeleton from '@mui/material/Skeleton';
import Moment from 'moment';

const _actionViews = {
    RESPONSABLES: 'RESPONSABLES',
    CLIENTES: 'CLIENTES',
}
const ReportsChatBot = () => {
    const { ObjChat } = useContext(ContextDataChatIWS);
    const [actionViews] = React.useState(_actionViews);
    const [search, setSearch] = React.useState('');
    const [ShowView, setShowView] = React.useState(_actionViews.RESPONSABLES);
    const [listResponsables, setListResponsables] = React.useState([]);
    const [listResponsablesAll, setListResponsablesAll] = React.useState([]);
    const [listResponsables_Skeleton, setListResponsables_Skeleton] = React.useState(true);
    const [listClientes, setListClientes] = React.useState([]);
    const [listClientes_Skeleton, setlistClientes_Skeleton] = React.useState(true);

    const [showModal, setshowModal] = React.useState(false)
    const [listFechas, setListFechas] = React.useState([]);
    const [listFechas_Skeleton, setListFechas_Skeleton] = React.useState(true);
    const [dataCliente, setDataCliente] = React.useState('');
    const [filteredData, setFilteredData] = React.useState([]);

    const [rango, setRango] = React.useState(10);
    const [listPaginado, setListPaginado] = React.useState([]);
    const [objetoPaginado, setObjetoPaginado] = React.useState({
        paginaInicial: 1,
        paginaActiva: 1,
        totalPaginas: 1,
        arrayPaginas: [],
    });



    const handleInputChange = (e) => {
        const newValue = e.target.value.toLowerCase();
        setSearch(newValue);
        filterData(newValue);
    }
    const handleRefreshHistory = () => {
        setSearch('')
        setFilteredData(listResponsablesAll);
    }

    React.useEffect(() => {
        getDataResponsables();
    }, []);
    
    React.useEffect(() => {
        filterData(search);
    }, [listResponsablesAll]); // Agrega este useEffect para actualizar filteredData cuando listResponsablesAll cambie
    
    const filterData = (value) => {
        const filtered = listResponsablesAll.filter((item) =>
            item.nombre.toLowerCase().includes(value) || item.correo.toLowerCase().includes(value)
        );
        setFilteredData(filtered);
    };
    
    // En getDataResponsables después de setListResponsables_Skeleton(false);
    React.useEffect(() => {
        getDataResponsables();
    }, []);

    const changeView = (view) => {
        let vista = actionViews[view]
        setShowView(vista)
        if (vista === actionViews.RESPONSABLES) {
            let array = Fn_paginado(listResponsablesAll);
            setListResponsables(array);
        }
    }

    const getDataResponsables = async () => {
        const listUsers = await Axios.get(`${ObjChat.api}/api/Services/Responsables`);
        const { data, code, error } = listUsers.data;
        if (code === 0) {
            setListResponsablesAll(data);
            console.log('RESPOSABLESALL',data)
            let array = Fn_paginado(data);
            setListResponsables(array);


            setListResponsables_Skeleton(false)
        } else {
            console.log(error);
            setListResponsables_Skeleton(false)
        }
    }

    const showClientes = async (view, idResponsable) => {
        setlistClientes_Skeleton(true)
        setListClientes([]);
        changeView(view)

        const listUsers = await Axios.get(`${ObjChat.api}/api/Services/ReportByResponsable?idResponsable=${idResponsable}`);
        const { data, code, error } = listUsers.data;
        if (code === 0) {

            let array = Fn_paginado(data);
            setListClientes(array);

            setlistClientes_Skeleton(false)
        } else {
            console.log(error);
            setlistClientes_Skeleton(false)
        }
    }


    const Fn_paginado = (arreglo) => {
        let newArray = []
        let _paginado = []
        let cont = 0

        let objetoGral = {
            paginaInicial: 1,
            paginaActiva: 1,
            totalPaginas: 1,
            arrayPaginas: [],
        };

        if (arreglo.length > rango) {

            for (let item of arreglo) {
                if (cont < rango) {
                    _paginado.push(item)
                    if (cont === (rango - 1)) {
                        newArray.push(_paginado)
                        _paginado = [];
                        cont = 0
                    } else {
                        cont++
                    }
                }
            }
            if (_paginado.length !== 0) {
                newArray.push(_paginado)
            }


            objetoGral = {
                paginaInicial: 1,
                paginaActiva: 1,
                totalPaginas: newArray.length,
                arrayPaginas: [],
            };


            let page1 = 1;
            let pageTotal = objetoGral.totalPaginas;
            for (let i = 0; i < objetoGral.totalPaginas; i++) {
                if ((i + 1) !== page1 && (i + 1) !== pageTotal) {
                    objetoGral.arrayPaginas.push(i + 1)
                }
            }
            setListPaginado(newArray)
            setObjetoPaginado(objetoGral);
            return newArray[0];
        } else {
            setObjetoPaginado(objetoGral);
            return arreglo;
        }
    }

    const handleChangePage = (page) => {

        let ArrayPagina = listPaginado[page - 1]
        objetoPaginado.paginaActiva = page
        setListResponsables(ArrayPagina)
    }
    const handleChangePageCliente = (page) => {

        let ArrayPagina = listPaginado[page - 1]
        objetoPaginado.paginaActiva = page
        setListClientes(ArrayPagina)
    }


    const handleHide = () => {
        setshowModal(false)
    }
    const handleShow = (item) => {
        setListFechas([])
        setDataCliente(item)
        getReportByResponsable(item)
        setshowModal(true)
    }

    const getReportByResponsable = async (item) => {
        setListFechas_Skeleton(true)
        const list = await Axios.get(`${ObjChat.api}/api/Services/ReportByResponsable&Client?idResponsable=${item.idResponsable}&clientEmail=${item.correoCliente}`);
        const { data, code, error } = list.data;
        if (code === 0) {
            setListFechas(data)
            setListFechas_Skeleton(false)
        } else {
            setListFechas_Skeleton(false)
            console.log(error);
        }
    }



    React.useEffect(() => {
        getDataResponsables();
    }, [])

    return (
        <>
            {
                ShowView === actionViews.RESPONSABLES &&
                <section className='ReportsChatBot'>
                    <div className='container p-t-1'>
                        <div className='row'>
                            <div className='col-lg-5 col-md-5 col-sm-12'>
                                <div className="search-box-chat">
                                    <Card className="search-box--input">
                                        <Form className="p-0">
                                            <Col className="p-0">
                                                <div className='row'>
                                                    <div className='col-11'>
                                                        <Form.Control
                                                            type="text"
                                                            value={search}
                                                            placeholder="Buscar por correo o nombre..."
                                                            className=" mr-sm-2"
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <div className='col-1 d-flex align-items-center'>
                                                        <div>
                                                            <IconButton onClick={handleRefreshHistory}>
                                                                <RefreshIcon />
                                                            </IconButton>

                                                        </div>

                                                    </div>
                                                </div>

                                            </Col>
                                        </Form>
                                    </Card>
                                </div>
                            </div>
                        </div>

                        <div className=''>
                            <div className='style-table scrollTable'>
                                <table className='table hover-tr table--striped trPointer'>
                                    <thead>
                                        <tr>
                                            <th>Nombre Responsable</th>
                                            <th>Correo</th>
                                            <th>Servicio</th>
                                            <th>Pais</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            listResponsables_Skeleton ?
                                                <>
                                                    {
                                                        [1, 2, 3, 4, 5, 6].map((item) =>
                                                            <tr key={item}>
                                                                <td><Skeleton variant="rectangular" width={200} height={20} /></td>
                                                                <td><Skeleton variant="rectangular" width={200} height={20} /></td>
                                                                <td><Skeleton variant="rectangular" width={200} height={20} /></td>
                                                                <td><Skeleton variant="rectangular" width={200} height={20} /></td>
                                                            </tr>
                                                        )
                                                    }
                                                </>
                                                :
                                                filteredData.map((item) =>
                                                    <tr key={item.idResponsable} onClick={() => { showClientes(actionViews.CLIENTES, item.idResponsable) }}>
                                                        <td>{item.nombre}</td>
                                                        <td>{item.correo}</td>
                                                        <td>{item.servicio}</td>
                                                        <td>{item.paises}</td>
                                                    </tr>
                                                )
                                        }

                                    </tbody>
                                </table>
                                {
                                    listResponsables.length === 0 && !listResponsables_Skeleton &&
                                    <div className='sin-datos'>
                                        No se tiene datos
                                    </div>
                                }
                            </div>
                            <div className='content--agination'>
                                {
                                    listResponsables.length !== 0 && objetoPaginado.totalPaginas !== 1 &&
                                    <Pagination>
                                        <Pagination.First disabled={objetoPaginado.paginaActiva === 1} onClick={() => handleChangePage(objetoPaginado.paginaInicial)} />
                                        <Pagination.Prev disabled={objetoPaginado.paginaActiva === 1} onClick={() => handleChangePage(objetoPaginado.paginaActiva - 1)} />
                                        <Pagination.Item active={objetoPaginado.paginaActiva === objetoPaginado.paginaInicial} onClick={() => handleChangePage(objetoPaginado.paginaInicial)}>{objetoPaginado.paginaInicial}</Pagination.Item>

                                        {
                                            (objetoPaginado.arrayPaginas).map((page, i) =>
                                                <Pagination.Item
                                                    key={i}
                                                    active={objetoPaginado.paginaActiva === page}
                                                    onClick={() => handleChangePage(page)}
                                                >
                                                    {page}
                                                </Pagination.Item>
                                            )
                                        }

                                        {/* <Pagination.Ellipsis /> */}
                                        <Pagination.Item active={objetoPaginado.paginaActiva === objetoPaginado.totalPaginas} onClick={() => handleChangePage(objetoPaginado.totalPaginas)}>{objetoPaginado.totalPaginas}</Pagination.Item>
                                        <Pagination.Next disabled={objetoPaginado.paginaActiva === objetoPaginado.totalPaginas} onClick={() => handleChangePage(objetoPaginado.paginaActiva + 1)} />
                                        <Pagination.Last disabled={objetoPaginado.paginaActiva === objetoPaginado.totalPaginas} onClick={() => handleChangePage(objetoPaginado.totalPaginas)} />
                                    </Pagination>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            }

            {
                ShowView === actionViews.CLIENTES &&
                <section className='ReportsChatBot'>
                    <div className='container p-t-1'>
                        <div className='row'>
                            <div className='col-lg-5 col-md-5 col-sm-12'>
                                <div className="search-box-chat">
                                    <Card className="search-box--input">
                                        <Form className="p-0">
                                            <Col className="p-0">
                                                <div className='row'>
                                                    <div className='col-11'>
                                                        <Form.Control
                                                            type="text"
                                                            value={search}
                                                            placeholder="Buscar por correo o nombre..."
                                                            className=" mr-sm-2"
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <div className='col-1 d-flex align-items-center'>
                                                        <div>
                                                            <IconButton onClick={handleRefreshHistory}>
                                                                <RefreshIcon />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Form>
                                    </Card>
                                    <div className='return-view__container'>
                                        <samp className='cPointer return-view' onClick={() => { changeView(actionViews.RESPONSABLES) }}>Regresar</samp>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className=''>
                            <div className='style-table scrollTable'>
                                <table className='table hover-tr table--striped'>
                                    <thead>
                                        <tr>
                                            <th>Cliente</th>
                                            <th>Correo</th>
                                            <th>Celular</th>
                                            <th>Servicio</th>
                                            <th>Pais</th>
                                            <th>Ultima fecha de envió de reporte</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listClientes_Skeleton ?
                                                <>
                                                    {
                                                        [1, 2, 3, 4, 5, 6].map((item) =>
                                                            <tr key={item}>
                                                                <td><Skeleton variant="rectangular" width={130} height={20} /></td>
                                                                <td><Skeleton variant="rectangular" width={130} height={20} /></td>
                                                                <td><Skeleton variant="rectangular" width={130} height={20} /></td>
                                                                <td><Skeleton variant="rectangular" width={130} height={20} /></td>
                                                                <td><Skeleton variant="rectangular" width={130} height={20} /></td>
                                                                <td><Skeleton variant="rectangular" width={180} height={20} /></td>
                                                            </tr>
                                                        )
                                                    }
                                                </>
                                                :
                                                listClientes.map((item) =>
                                                    <tr key={item.idCliente}>
                                                        <td>{item.nombre}</td>
                                                        <td>{item.correoCliente}</td>
                                                        <td>{item.celular}</td>
                                                        <td>{item.servicioDescripcion}</td>
                                                        <div>{item.paisDescripcion}</div>
                                                        <td>
                                                            {Moment(item.fechaRegistro).format('DD/MM/YYYY HH:mm')}
                                                            <span className='ms-3' onClick={() => { handleShow(item) }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                                                                </svg>
                                                            </span>
                                                            {/* <OverlayTrigger placement="left" overlay={
                                                                <Tooltip placement="right" className="in" id="tooltip-right">
                                                                    Click para ver todas las fechas.
                                                                </Tooltip>
                                                            }>
                                                            </OverlayTrigger> */}
                                                        </td>
                                                    </tr>
                                                )
                                        }
                                    </tbody>
                                </table>
                                {
                                    listClientes.length === 0 && !listClientes_Skeleton &&
                                    <div className='sin-datos'>
                                        No se tiene datos
                                    </div>
                                }
                            </div>
                            <div className='content--agination'>
                                {
                                    listResponsables.length !== 0 && objetoPaginado.totalPaginas !== 1 &&
                                    <Pagination>
                                        <Pagination.First disabled={objetoPaginado.paginaActiva === 1} onClick={() => handleChangePageCliente(objetoPaginado.paginaInicial)} />
                                        <Pagination.Prev disabled={objetoPaginado.paginaActiva === 1} onClick={() => handleChangePageCliente(objetoPaginado.paginaActiva - 1)} />
                                        <Pagination.Item active={objetoPaginado.paginaActiva === objetoPaginado.paginaInicial} onClick={() => handleChangePageCliente(objetoPaginado.paginaInicial)}>{objetoPaginado.paginaInicial}</Pagination.Item>

                                        {
                                            (objetoPaginado.arrayPaginas).map((page, i) =>
                                                <Pagination.Item
                                                    key={i}
                                                    active={objetoPaginado.paginaActiva === page}
                                                    onClick={() => handleChangePageCliente(page)}
                                                >
                                                    {page}
                                                </Pagination.Item>
                                            )
                                        }

                                        {/* <Pagination.Ellipsis /> */}
                                        <Pagination.Item active={objetoPaginado.paginaActiva === objetoPaginado.totalPaginas} onClick={() => handleChangePageCliente(objetoPaginado.totalPaginas)}>{objetoPaginado.totalPaginas}</Pagination.Item>
                                        <Pagination.Next disabled={objetoPaginado.paginaActiva === objetoPaginado.totalPaginas} onClick={() => handleChangePageCliente(objetoPaginado.paginaActiva + 1)} />
                                        <Pagination.Last disabled={objetoPaginado.paginaActiva === objetoPaginado.totalPaginas} onClick={() => handleChangePageCliente(objetoPaginado.totalPaginas)} />
                                    </Pagination>
                                }
                            </div>
                        </div>
                    </div>

                    <Modal
                        show={showModal}
                        onHide={handleHide}
                        aria-labelledby="contained-modal-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title">
                                Envío de reporte
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className=''>
                                <div>

                                </div>
                                <div className='style-table scrollTable'>
                                    <table className='table hover-tr table--striped'>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Correo</th>
                                                <th>Fecha de registro</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listFechas_Skeleton ?
                                                    <>
                                                        {
                                                            [1, 2, 3, 4].map((item) =>
                                                                <tr key={item}>
                                                                    <td><Skeleton variant="rectangular" width={130} height={20} /></td>
                                                                    <td><Skeleton variant="rectangular" width={130} height={20} /></td>
                                                                    <td><Skeleton variant="rectangular" width={130} height={20} /></td>
                                                                </tr>
                                                            )
                                                        }
                                                    </>
                                                    :
                                                    listFechas.map((item) =>
                                                        <tr>
                                                            <td>
                                                                {dataCliente.nombre}
                                                            </td>
                                                            <td>
                                                                {dataCliente.correoCliente}
                                                            </td>
                                                            <td>
                                                                {Moment(item.fechaRegistro).format('DD/MM/YYYY HH:mm')}
                                                            </td>
                                                        </tr>
                                                    )
                                            }
                                        </tbody>

                                    </table>
                                    {
                                        listFechas.length === 0 && !listFechas_Skeleton &&
                                        <div className='sin-datos'>
                                            No se tiene datos
                                        </div>
                                    }
                                </div>
                            </div>
                        </Modal.Body>



                        <Modal.Footer>
                            <Button onClick={handleHide}>Close</Button>
                        </Modal.Footer>
                    </Modal>




                </section>
            }

        </>


    );
};

export { ReportsChatBot };
