import React, { Fragment } from 'react'

export const AssessmentChild2 = (props) => {
  const images = props.images.map((data, index) =>
    <div key={index} className="row p-0 m-0 justify-content-center col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-8 my-3">
      <img src={data} alt="" className='img-fluid' />
    </div>
    
  );
  return (
    <Fragment>
      {images}
    </Fragment>
  )
}
