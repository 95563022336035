import React, { Fragment, useEffect } from 'react'
import { Presentation } from './Components/Presentation'
import { Child1 } from './Components/Services/Child1'
import { ImplementationChild1 } from './Components/Services/ImplementationChild1'
import { ButtonForm } from './Components/ButtonForm'
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";
import styles from './CSS/implementaciones.css';
import { useTranslation } from "react-i18next";


const img01 = 'https://cdn.iwsservices.com/Implementaciones/Etapa 1.png'
const img02 = 'https://cdn.iwsservices.com/Implementaciones/Etapa 2.png'
const img03 = 'https://cdn.iwsservices.com/Implementaciones/Etapa 3.png'
const img01En = 'https://cdn.iwsservices.com/Implementaciones/Etapa 1En.png'
const img02En = 'https://cdn.iwsservices.com/Implementaciones/Etapa 2En.png'
const img03En = 'https://cdn.iwsservices.com/Implementaciones/Etapa 3En.png'
const img01Pt = 'https://cdn.iwsservices.com/Implementaciones/Etapa 1 portugues.png'
const img02Pt = 'https://cdn.iwsservices.com/Implementaciones/Etapa 2 portugues.png'
const img03Pt = 'https://cdn.iwsservices.com/Implementaciones/Etapa 3 portugues.png'
const img1DesktopEs =  "https://cdn.iwsservices.com/Secciones/Desktop/Es/Implementaciones.png";
const img1DesktopEn =  "https://cdn.iwsservices.com/Secciones/Desktop/En/Implementations.png";
const img1DesktopPr =  "https://cdn.iwsservices.com/Secciones/Desktop/Pr/Implementações.png";
const img1MobileEs  = "https://cdn.iwsservices.com/Secciones/Mobile/Es/Implementaciones.png";
const img1MobileEn  = "https://cdn.iwsservices.com/Secciones/Mobile/En/Implementations.png";
const img1MobilePr  = "https://cdn.iwsservices.com/Secciones/Mobile/Pr/Implementações.png";

export const Implementaciones = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const [t, i18n] = useTranslation('common');
  const domainName = window.location.hostname;

  const child1 = {
    text1: `${t('implementaciones.child1-text1')}`,
    span: `${t('implementaciones.child1-span')}`,
    text2: `${t('implementaciones.child1-text2')}`,
    span2: `${t('implementaciones.child1-span2')}`,
    text3: `${t('implementaciones.child1-text3')}`,
  }
  const inputs = [
    { label: "Nombre", type: "text", placeholder: "Jhon Doe" },
    { label: "E-mail", type: "email", placeholder: "nuevo@gmail.com" },
    { label: "Comentarios", type: "textarea", placeholder: "Type something…" },
  ]
  const images = [
    { src: img01, value: 0 },
    { src: img02, value: 1 },
    { src: img03, value: 0 },
  ]

  const imagesEn = [
    { src: img01En, value: 0 },
    { src: img02En, value: 1 },
    { src: img03En, value: 0 },
  ]

  const imagesPt = [
    { src: img01Pt, value: 0 },
    { src: img02Pt, value: 1 },
    { src: img03Pt, value: 0 },
  ]

  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresMigrationsFather" className='implementacionesFather'>
          <Presentation
            image={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1DesktopEn : domainName.includes('iwsservices.com.br') ? img1DesktopPr : img1DesktopEs}
            image2={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1MobileEn : domainName.includes('iwsservices.com.br') ? img1MobilePr : img1MobileEs}
            text={t("implementaciones.text")}
          />
        </div>
        <div id="MigraChild1" className='row m-0 p-0 justify-content-center mb-5 pb-5 pt-7'>
          <div className='mb-5 divImplenetaciones-migra'>
            <Child1 text1={child1.text1} span={child1.span} text2={child1.text2} span2={child1.span2} text3={child1.text3} />
          </div>
          <ImplementationChild1 images={domainName.includes('iwsservices.com.br') ? imagesPt : domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') || domainName.includes('redinet.us') ? imagesEn : images} />
        </div>
        <ButtonForm inputs={inputs} />
      </Fragment>
      <Footer />
      <ToggleComp />
    </div>
  )
}
