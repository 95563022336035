import React, { Fragment, useEffect } from 'react'
import { Presentation } from './Components/Presentation'
import { Child1 } from './Components/Services/Child1'
import { HelpChild3 } from './Components/Services/HelpChild3'
import { ButtonForm } from './Components/ButtonForm'
import { SecondInfo } from './Components/Home/SecondInfo'
import { useTranslation } from "react-i18next";
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";
import styles from './CSS/helpDesk.css'

const objetives = 'https://cdn.iwsservices.com/Help Desk/Objetivos.png'
const objetivesEn = 'https://cdn.iwsservices.com/Help Desk/ObjetivosEn.png'
const objetivesPt = 'https://cdn.iwsservices.com/Help Desk/Objetivos  Portugués.png'
const objetives2 = 'https://cdn.iwsservices.com/Help Desk/Objetivos2.png'
const objetives2En = 'https://cdn.iwsservices.com/Help Desk/Objetivos2En.png'
const img01  ='https://cdn.iwsservices.com/Help Desk/Ampliar.png'
const img02  ='https://cdn.iwsservices.com/Help Desk/Brindar.png'
const img03  ='https://cdn.iwsservices.com/Help Desk/Estar disponible.png'
const img04  ='https://cdn.iwsservices.com/Help Desk/Evitar.png'
const img05  ='https://cdn.iwsservices.com/Help Desk/Estimular.png'
const img1In = 'https://cdn.iwsservices.com/Home/1-individual.png'
const img2In = 'https://cdn.iwsservices.com/Home/2-individual.png'
const img3In = 'https://cdn.iwsservices.com/Home/3-individual.png'
const img4In = 'https://cdn.iwsservices.com/Home/4-individual.png'
const img5In = 'https://cdn.iwsservices.com/Home/5-individual.png'
const img6In = 'https://cdn.iwsservices.com/Home/6-individual.png'
const img1En = 'https://cdn.iwsservices.com/Home/11.png'
const img2En = 'https://cdn.iwsservices.com/Home/2En.png'
const img3En = 'https://cdn.iwsservices.com/Home/3En.png'
const img4En = 'https://cdn.iwsservices.com/Home/4En.png'
const img5En = 'https://cdn.iwsservices.com/Home/5En.png'
const img6En = 'https://cdn.iwsservices.com/Home/6En.png'
const img1Pt = 'https://cdn.iwsservices.com/Home/1 portugues.png'
const img2Pt = 'https://cdn.iwsservices.com/Home/2 portugues.png'
const img3Pt = 'https://cdn.iwsservices.com/Home/3 portugues.png'
const img4Pt = 'https://cdn.iwsservices.com/Home/4 portugues.png'
const img5Pt = 'https://cdn.iwsservices.com/Home/5 portugues.png'
const img6Pt = 'https://cdn.iwsservices.com/Home/6 portugues.png'
const img1DesktopEs =  "https://cdn.iwsservices.com/Secciones/Desktop/Es/Helpdesk.png";
const img1DesktopEn =  "https://cdn.iwsservices.com/Secciones/Desktop/En/Helpdesk.png";
const img1DesktopPr =  "https://cdn.iwsservices.com/Secciones/Desktop/Pr/Helpdesk.png";
const img1MobileEs  = "https://cdn.iwsservices.com/Secciones/Mobile/Es/Helpdesk.png";
const img1MobileEn  = "https://cdn.iwsservices.com/Secciones/Mobile/En/Helpdesk.png";
const img1MobilePr  = "https://cdn.iwsservices.com/Secciones/Mobile/Pr/Helpdesk.png";


// Img con Texto
const img11 = "https://cdn.iwsservices.com/Home/1.png";
const img22 = "https://cdn.iwsservices.com/Home/2.png";
const img3 = "https://cdn.iwsservices.com/Home/3.png";
const img4 = "https://cdn.iwsservices.com/Home/4.png";
const img5 = "https://cdn.iwsservices.com/Home/5.png";
const img6 = "https://cdn.iwsservices.com/Home/6.png";


export const Help_Desk = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const [t, i18n] = useTranslation('common');
  const domainName = window.location.hostname;

  const images1 = [
    { src: img1In, hover: img11 },
    { src: img2In, hover: img22 },
    { src: img3In, hover: img3 },
    { src: img4In, hover: img4 },
    { src: img5In, hover: img5 },
    { src: img6In, hover: img6 },
  ]

  const images1En = [
    { src: img1In, hover: img1En },
    { src: img2In, hover: img2En },
    { src: img3In, hover: img3En },
    { src: img4In, hover: img4En },
    { src: img5In, hover: img5En },
    { src: img6In, hover: img6En },
  ]

  const images1Pt = [
    { src: img1In, hover: img1Pt },
    { src: img2In, hover: img2Pt },
    { src: img3In, hover: img3Pt },
    { src: img4In, hover: img4Pt },
    { src: img5In, hover: img5Pt },
    { src: img6In, hover: img6Pt },
  ]


  const child1 = {
    text1: `${t('help.child1-text1')}`,
    span: `${t('help.child1-span')}`,
    text2: `${t('help.child1-text2')}`,
    span2: `${t('help.child1-span2')}`,
    text3: `${t('help.child1-text3')}`,
  }
  const images = [
    { src: img01, text: `${t('help.img1-text')}` },
    { src: img02, text: `${t('help.img2-text')}` },
    { src: img03, text: `${t('help.img3-text')}` },
    { src: img04, text: `${t('help.img4-text')}` },
    { src: img05, text: `${t('help.img5-text')}` },
  ]
  const inputs = [
    { label: "Nombre", type: "text", placeholder: "Jhon Doe" },
    { label: "E-mail", type: "email", placeholder: "nuevo@gmail.com" },
    { label: "Comentarios", type: "textarea", placeholder: "Type something…" },
  ]

  const hola = "hola";
  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresMigrationsFather" className='helpFather'>

          <Presentation
            image={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1DesktopEn : domainName.includes('iwsservices.com.br') ? img1DesktopPr : img1DesktopEs}
            image2={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1MobileEn : domainName.includes('iwsservices.com.br') ? img1MobilePr : img1MobileEs}
            text={t("help.text")}
          />
        </div>
        <div id="MigraChild1" className='row m-0 p-0 justify-content-center mb-5 pt-7'>
          <div className='div-help'>
            <Child1 text1={child1.text1} span={child1.span} text2={child1.text2} span2={child1.span2} text3={child1.text3} />
          </div>
          <div className='px-2 col-12 col-sm-12 col-md-11 col-lg-10 col-xl-10 col-xxl-9 text-center text-47-blue div-objetivosHelp'>
            <p>
              {t('help.objetivos')}
            </p>
          </div>
        </div>
        <div id="HelpDeskChild2" className='d-flex m-0 p-0 pb-4 flex-column align-items-center'>
          <div id="imgEs" className='col-12 col-sm-12 col-md-10 col-lg-9 col-xl-8 col-xxl-8'>
            <div>
              <img src={domainName.includes('iwsservices.com.br') ? objetivesPt : domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') || domainName.includes('redinet.us') ? objetivesEn : objetives} className="img-fluid d-none d-sm-block" alt='' />
              <img src={domainName.includes('iwsservices.com.br') ? objetives2 : domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') || domainName.includes('redinet.us') ? objetives2En : objetives2} className="img-fluid d-block d-sm-none" alt='' />
            </div>
          </div>
          {/* <div id="imgEn" className='col-12 col-sm-12 col-md-10 col-lg-9 col-xl-8 col-xxl-8'>
          <div className={t("clase.en")}>
          <img src={objetivesEn} className="img-fluid d-none d-sm-block" alt=''/>
          <img src={objetives2En} className="img-fluid d-block d-sm-none" alt=''/>
          </div>
        </div> */}
        </div>
        <div id="" className='row m-0 p-0 pb-4 justify-content-center'>
          <div className='row justify-content-center m-0 p-0 col-12 col-sm-12 col-md-11 col-lg-10 col-xl-10 col-xxl-9 text-center text-47-blue mt-5 div-SolicitudHD'>
            <p>
              {t('help.solicitud')}
            </p>
            <HelpChild3 images={images} />
          </div>
        </div>

        <div>
          <div> <SecondInfo images={domainName.includes('iwsservices.com.br') ? images1Pt : domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') || domainName.includes('redinet.us') ? images1En : images1} /> </div>
          {/* <div className={t("clase.en")}> <SecondInfo images={images1En}/> </div> */}
        </div>

        <ButtonForm inputs={inputs} />
      </Fragment><Footer />
      <ToggleComp />
    </div>
  )
}
