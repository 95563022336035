import React from 'react'

export const Child1 = props => {

  return (
    <div className='row col-12 m-0 p-0 justify-content-end text-unnamed'>
      <div className='div-container row col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-9 p-0 m-0'>
        <div className='divParrafo row col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-4 text-azul text-center align-content-end'>
          <p className='p-Solu-child1 m-0 p-0'>
            {props.text1}
          </p>
          <span className='s-Solu-child1 text-unnamed'>{props.span}</span>
        </div>
        <div className='row col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-6 div-Solu-child1 text-center p-0'>
          <p>
            {props.text2} <span className="s-Solu-Child2">{props.span2}</span>{props.text3}
          </p>
        </div>
        <hr></hr>
      </div>
    </div>
  )
}