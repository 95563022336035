import React from 'react'
import {useTranslation} from "react-i18next";

export const ContactChild3 = (props) => {

  const [t, i18n] = useTranslation('common');
  const images1 = props.images.map((data, index) =>
    <div key={index} className="d-flex col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 justify-content-center py-2">
      <a href={data.href} target="_blank" rel="noopener noreferrer">
        <img src={data.src} alt="" height="80px" width="80px" />
      </a>
    </div>
  );
  const images2 = props.images2.map((data, index) =>
    <div key={index} className="d-flex col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 justify-content-center py-4">
      <a href="https://instagram.com/iws_services_latam?igshid=YmMyMTA2M2Y=" target='_blank'><img src={data.src} alt="" height="415px" width="415px" className='img-fluid img-contac' /></a>
    </div>
  );

//   const images3 = props.images3.map((data, index) =>
//   <div key={index} className="d-flex col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 justify-content-center py-4">
//     <a href="https://instagram.com/iws_services_latam?igshid=YmMyMTA2M2Y=" target='_blank'><img src={data.src} alt="" height="415px" width="415px" className='img-fluid img-contac' /></a>
//   </div>
// );
  const domainName = window.location.hostname;
  return (
    <div className='row p-0 m-0 justify-content-center'>
      <div className='row p-0 m-0'>
        <div className='col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 col-xxl-7 d-flex justify-content-center text-end flex-column our-Job'>
          <div className='align-self-end px-3 divRedesContact'>
            <p className='p-second-info text-start'>{t('contact.siguenos')}</p>
            {t('contact.participa')}
          </div>
        </div>
        <div className='block-info2'>
        </div>
      </div>
      <div className='row m-0 p-0 justify-content-center my-3'>
        <div className='row col-12 col-sm-12 col-md-10 col-lg-8 col-xl-5 col-xxl-4'>
          { domainName.includes('redinet.cr') === false &&
             images1
          }
        </div>
      </div>
      <div className={`row m-0 p-0 mb-5 justify-content-center mt-5 pt-4 col-12 col-sm-12 col-md-10 col-lg-12 col-xl-12 col-xxl-10`}>
        {images2} 
      </div>
      {/* <div className={`row m-0 p-0 mb-5 justify-content-center mt-5 pt-4 col-12 col-sm-12 col-md-10 col-lg-12 col-xl-12 col-xxl-10 ${t("clase.en")}`}>
        {images3} 
      </div> */}
    </div>
  )
}