import React from 'react'
import {useTranslation} from "react-i18next";

export const BagChild2 = (props) => {
  const [t, i18n] = useTranslation('common');
  return (
    <div className='row justify-content-center m-0 p-0'>
      <div className='Bag-child2-text col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-8 mb-5'>
        <p>
          {t('bag.agiliza')}
          <span className='span-Bag'> {t('bag.control')} </span>
          {t('bag.herramientas')}
        </p>
      </div>
      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-8 Bag-child2-text-2 mb-5'>
        <p>
        {t('bag.bolsa')}
        </p>
      </div>
      <div className='row m-0 p-0 pb-4 justify-content-center position-relative col-12'>
        <div id="MigraChild2" className='row m-0 p-0 pb-4 justify-content-center col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-8 mt-3 pt-1'>
          <div className="gris-derecha"></div>
          <img src={props.image1} alt="" className='d-none d-sm-block img-fluid ' />
          <img src={props.image2} alt="" className='p-0 m-0 d-block d-sm-none img-fluid ' />
        </div>
      </div>

      <div>

      </div>
    </div>
  )
}
