import React from 'react'
import { ButtonForm } from '../ButtonForm';
import {useTranslation} from "react-i18next";

export function SolutionsChild2(props) {
  const [t, i18n] = useTranslation('common');
  const inputs = [
    { label: "Nombre", type: "text", placeholder: "Jhon Doe" },
    { label: "E-mail", type: "email", placeholder: "nuevo@gmail.com" },
    { label: "Comentarios", type: "textarea", placeholder: "Type something…" },
  ]
  const Divs = props.image.map((data, index) =>
    data.value === 1 ?
      <div key={index} className='row justify-content-end p-0 m-0 py-5'>
        <div className='col-12 col-sm-12 col-md-4 col-lg-5 col-xl-4 col-xxl-3 align-self-center mb-2'>
          <div className='col-10 col-sm-7 col-md-12 col-lg-10 col-xl-10 col-xxl-9 '>
            <img src={data.src} className='img-fluid' alt=''/>

          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-8 col-lg-7 col-xl-7 col-xxl-7 gradient-right-child2 p-3'>
          <div className='col-xxl-8 text-end text-unnamed div-Solu-Child2  lh-1 div-rigth'>
            {data.text1} <span className="fw-bold s-Solu-Child2">{data.span}</span>
            {data.text2}
          </div>
        </div>
      </div>
      :
      <div key={index} className='row justify-content-start p-0 m-0 py-5'>
        <div className='col-12 col-sm-12 col-md-8 col-lg-7 col-xl-7 col-xxl-7 d-flex gradient-left-child2 justify-content-end p-3'>
          <div className='col-xxl-8 text-start text-unnamed div-Solu-Child2 lh-1 div-left'>
            {data.text1} <span className="fw-bold s-Solu-Child2">{data.span}</span>
            {data.text2}
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-4 col-lg-5 col-xl-4 col-xxl-3 d-flex justify-content-start justify-content-sm-end order-first order-md-last'>
          <div className='col-10 col-sm-7 col-md-12 col-lg-10 col-xl-10 col-xxl-9 align-self-center'>
            <img src={data.src} className='img-fluid' alt=''/>
          </div>
        </div>
      </div>
  );
  const buttons =
    props.value === 1 ?
      <div className='row justify-content-center pt-3'>
        <div className='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6 mb-3'>
          <img src={props.image2} className={`img-fluid ${t("clase.es")}`} alt=''></img>
          <img src={props.image3} className={`img-fluid ${t("clase.en")}`}  alt=''></img>
        </div>
        <ButtonForm inputs={inputs} />
      </div>
      :
      <ButtonForm inputs={inputs} />
  return (
    <div className='row p-0 col-xxl-12 m-0'>
      {Divs}
      {buttons}

    </div>
  )
}
