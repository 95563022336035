import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import Slider from "react-slick";
import { BrowserView, MobileView } from 'react-device-detect';

const Planificacion_recursos = 'https://cdn.iwsservices.com/Home/Planificacion_recursos.png';
const Soluciones_analiticas = 'https://cdn.iwsservices.com/Home/Soluciones_analiticas.png';
const IA_machine_learning = 'https://cdn.iwsservices.com/Home/IA_machine_learning.png';
const Seguridad_disponibilidad = 'https://cdn.iwsservices.com/Home/Seguridad_disponibilidad.png';
const Migracion_modernizacion = 'https://cdn.iwsservices.com/Home/Migracion_modernizacion.png';
const planificacion_icon = 'https://cdn.iwsservices.com/Home/1. Planificación.png';
const soluciones_icon = 'https://cdn.iwsservices.com/Home/2. Soluciones.png';
const ai_icon = 'https://cdn.iwsservices.com/Home/3. AI.png';
const seguridad_icon = 'https://cdn.iwsservices.com/Home/4. Seguridad.png';
const migracion_icon = 'https://cdn.iwsservices.com/Home/5. Migración.png';



const SolutionsComp = props => {
    const [t, i18n] = useTranslation('common');
    const [ObjSolution, setObj] = useState({ title: "Planificación de recursos", src2: Planificacion_recursos, text: `${t('oursolutions.Descrp1')}`, css: "mt-2 fade-in" });

    const Solutions = [
        { src: planificacion_icon, alt: "Solucion1", src2: Planificacion_recursos, Id: 1, title: `${t('oursolutions.Solution1')}`, descrp: `${t('oursolutions.Descrp1')}`, css: "mt-2 fade-in" },
        { src: soluciones_icon, alt: "Solucion2", src2: Soluciones_analiticas, Id: 2, title: `${t('oursolutions.Solution2')}`, descrp: `${t('oursolutions.Descrp2')}`, css: "mt-2 fade-in" },
        { src: ai_icon, alt: "Solucion3", src2: IA_machine_learning, Id: 3, title: `${t('oursolutions.Solution3')}`, descrp: `${t('oursolutions.Descrp3')}`, css: "mt-2 fade-in" },
        { src: seguridad_icon, alt: "Solucion4", src2: Seguridad_disponibilidad, Id: 4, title: `${t('oursolutions.Solution4')}`, descrp: `${t('oursolutions.Descrp4')}`, css: "mt-2 fade-in" },
        { src: migracion_icon, alt: "Solucion5", src2: Migracion_modernizacion, Id: 5, title: `${t('oursolutions.Solution5')}`, descrp: `${t('oursolutions.Descrp5')}`, css: "mt-2 fade-in" }
    ];

    const ListSolutions = Solutions.map((item) =>
        <div class="p-2 flex-fill" key={item.Id} onClick={e => {
            setObj({
                title: item.title,
                text: item.descrp,
                css: item.css,
                src2: item.src2
            });
        }}>
            <div className='card p-1 cursor-p'>
                <div className='container text-center mt-3' onMouseEnter={(e)=>{handleEnterGiro(e)}} onMouseLeave={(e)=>{handleLeaveGiro(e)}}>
                    <img src={item.src} className='img-giro w-50'></img>
                </div>
                <div className='container text-center mt-3'>
                    <h2 className='title-a-section-home our-alianza fz-20'>{item.title}</h2>
                </div>
            </div>
        </div>
    )

    const ListSolutionsMobile = Solutions.map((item) =>
        <div class="container" key={item.Id} onClick={e => {
            setObj({
                title: item.title,
                text: item.descrp,
                css: item.css,
                src2: item.src2
            });
        }}>
            <div className='card'>
                <div className='container text-center mt-3'>
                    <h2 className='title-a-section-home fz-15'>{item.title}</h2>
                </div>
            </div>
        </div>
    )

    const handleEnterGiro = (e)=>{
        
        if(e.target.children.length > 0){
            e.target.children[0].classList.add('anima');
            e.target.children[0].classList.remove('anima-return');
        }else{
            e.target.classList.add('anima');
            e.target.classList.remove('anima-return');

        }
    }
    const handleLeaveGiro = (e)=>{
        
        if(e.target.children.length > 0){
            e.target.children[0].classList.add('anima-return');
            e.target.children[0].classList.remove('anima');
        }else{
            e.target.classList.add('anima-return');
            e.target.classList.remove('anima');

        }
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 0,
        slidesToShow: 2,
        slidesToScroll: 2
    };
    return (
        <div>
            <BrowserView>
                <div className='bg-gray'>
                    <div className="bg-white container-snake-left-rr_6">
                        <div className="container text-center padding-" >
                            <h2 className='title-a-section-home our-alianza'>{t('oursolutions.title')}</h2>
                            <p className='card-service-desc'>{t('oursolutions.descrp')}</p>
                        </div>
                        <div className=''>
                            <div class="d-flex">
                                {ListSolutions}
                            </div>
                        </div>
                        <div className={ObjSolution.css}>
                            <div className='d-flex pb-6'>
                                <div className='p-2 flex-fill image-cover' style={{ backgroundImage: `url(${ObjSolution.src2})` }}></div>
                                <div className='p-2 flex-fill p-3 section-text-solutions'>
                                    <h2 className='title-a-section-home our-alianza'>{ObjSolution.title}</h2>
                                    <br />
                                    <p className=''>{ObjSolution.text}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <div className='bg-gray'>
                    <div className="bg-white container-snake-left-rr_">
                        <div className="text-center padding-" >
                            <Slider {...settings}>
                                {ListSolutionsMobile}
                            </Slider>
                            <div className={ObjSolution.css}>
                            <div className='d-flex flex-column'>
                                <div className='p-2 flex-fill image-cover-mobile' style={{ backgroundImage: `url(${ObjSolution.src2})` }}></div>
                                <div className='p-2 flex-fill p-3'>
                                    <h2 className='title-a-section-home our-alianza fz-28'>{ObjSolution.title}</h2>
                                    <br />
                                    <p className=''>{ObjSolution.text}</p>
                                </div>
                            </div>
                        </div>

                        </div>
                    </div>
                </div>
            </MobileView>


        </div>

    )
}


export default SolutionsComp