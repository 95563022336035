import React, { Fragment } from 'react'
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";
import { BrowserView, MobileView } from 'react-device-detect';
import { Carousel as CarouselReact } from 'react-responsive-carousel';
import Card from 'react-bootstrap/Card';
import "./CSS/teams.css"

const portada_teams = "https://cdn.iwsservices.com/p_teams.png"
const body_teams = "https://cdn.iwsservices.com/body_teams.png"
const logo_teams = "https://cdn.iwsservices.com/logo_teams.png"
const teams_carries = "https://cdn.iwsservices.com/teams_carries.PNG"
const teams_funcionalidad = "https://cdn.iwsservices.com/teams_funcionalidad.PNG"
const telefonia_nube = "https://cdn.iwsservices.com/telefonia_nube.png"
const teams_check = "https://cdn.iwsservices.com/teams_check.png"

export function Teams() {

    return (
        <div>
            <Header />
            <Fragment>
                <div className='portada'>
                    <img className='logo_teams' src={logo_teams}></img>
                    <img className='p_teams' src={portada_teams}></img>
                </div>

                <div className='container pt-teams'>

                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='container center-teams'>
                                <img className='image-1-teams' src={body_teams}></img>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>

                            <div className='container center-teams r-text-teams'>
                                <span className='title-teams f-right-teams'>Soluciones Telefónicas</span>
                                <label className='title-teams-2 f-right-teams'>Realice y reciba llamadas telefónicas</label>

                                <div className=''>
                                    <label className='title-teams-3 f-right-teams'>PBX de Microsoft Teams <br /><span className='title-teams-2 fs-teams-20 f-weight-semibold'>(Direct Routing)</span></label>
                                    <div className='p-top-teams-1'>
                                        <label className='txt-teams fs-teams-20  bg-color-teams-1 f-weight-regular'>Microsoft admite varias opciones al comenzar su viaje hacia equipos en la nube. </label>
                                        <label className='p-top-teams-1 txt-teams  fs-teams-20  bg-color-teams-1 f-weight-regular'>Direct Routing es una <strong>solución para interconectar a los usuarios de su organización</strong> que utilicen Microsoft Teams con los usuarios de la Red de Telefonía Pública</label>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className=' bg-color-2-teams'>
                    <div className='container pt-teams'>

                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12'>

                                <div className='container center-teams l-text-teams'>
                                    <label className='title-teams'>Funcionalidades de la PBX</label>
                                    <label className='title-teams-2'>Lo que puede hacer con este servicio</label>

                                </div>

                                <BrowserView>
                                    <div className='container p-top-teams-5 top-mobile-1-teams'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 top-mobile-3-teams '>
                                                <div className='list_cuadro'>
                                                    <img className='team_pbx_img' src={teams_check}></img>
                                                    <span className='f-weight-bold bg-color-teams-3 p-top-teams-1'>Operadora automática</span>
                                                    <span className='f-weight-light bg-color-teams-4'>en la nube.</span>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 top-mobile-3-teams '>
                                                <div className='list_cuadro'>
                                                    <img className='team_pbx_img' src={teams_check}></img>
                                                    <span className='f-weight-bold bg-color-teams-3 p-top-teams-1'>Grabación Interactiva</span>
                                                    <span className='f-weight-light bg-color-teams-4'>de voz inteligente (IVR).</span>
                                                </div>
                                            </div>


                                            <div className='col-lg-6 col-md-6 col-sm-12 p-top-teams-3'>
                                                <div className='list_cuadro'>
                                                    <img className='team_pbx_img' src={teams_check}></img>
                                                    <span className='f-weight-bold bg-color-teams-3 p-top-teams-1'>Correo de voz en la nube</span>
                                                    <span className='f-weight-light bg-color-teams-4'>con transcripción.</span>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 p-top-teams-3'>
                                                <div className='list_cuadro'>
                                                    <img className='team_pbx_img' src={teams_check}></img>
                                                    <span className='f-weight-bold bg-color-teams-3 p-top-teams-1'>Captura de llamadas</span>
                                                    <span className='f-weight-light bg-color-teams-4'>grupales.</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </BrowserView>
                                <MobileView>
                                    <div className='container'>
                                        <div className=''>
                                            <CarouselReact className='' showThumbs={false} showArrows={false} autoPlay={true} infiniteLoop={true} showStatus={false}>

                                                <div>
                                                    <div className='card card-mobile-teams-1'>
                                                        <div className='container p-teams-2'>
                                                            <img className='team_pbx_img-mobile' src={teams_check}></img>
                                                            <div className='container'>
                                                                <span className='f-weight-bold bg-color-teams-3'>Operadora automática</span>
                                                            </div>
                                                            <div className='container'>
                                                                <span className='f-weight-light bg-color-teams-4 mr-boton-mobile-teams'>en la nube.</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>

                                                    <div className='card card-mobile-teams-1'>
                                                        <div className='container p-teams-2'>
                                                            <img className='team_pbx_img-mobile' src={teams_check}></img>
                                                            <div className='container'>
                                                                <span className='f-weight-bold bg-color-teams-3'>Grabación Interactiva</span>
                                                            </div>
                                                            <div className='container'>
                                                                <span className='f-weight-light bg-color-teams-4'>de voz inteligente (IVR).</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>

                                                    <div className='card card-mobile-teams-1'>
                                                        <div className='container p-teams-2'>
                                                            <img className='team_pbx_img-mobile' src={teams_check}></img>
                                                            <div className='container'>
                                                                <span className='f-weight-bold bg-color-teams-3'>Correo de voz en la nube</span>
                                                            </div>
                                                            <div className='container'>
                                                                <span className='f-weight-light bg-color-teams-4'>Correo de voz en la nube</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>

                                                    <div className='card card-mobile-teams-1'>
                                                        <div className='container p-teams-2'>
                                                            <img className='team_pbx_img-mobile' src={teams_check}></img>
                                                            <div className='container'>
                                                                <span className='f-weight-bold bg-color-teams-3'>Captura de llamadas</span>
                                                            </div>
                                                            <div className='container'>
                                                                <span className='f-weight-light bg-color-teams-4'>grupales.</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CarouselReact>
                                        </div>
                                    </div>
                                </MobileView>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='container center-teams'>
                                    <img className='image-1-teams' src={teams_funcionalidad}></img>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='container pt-teams'>
                    <div className='container center-teams r-text-teams p-teams-2'>
                        <h5 className='title-teams f-right-teams'>Ventajas de la Telefónia en la nube</h5>
                        <h5 className='title-teams-2 f-right-teams'>Flexibilidad</h5>

                    </div>
                    <BrowserView>
                        <div className='container p-top-teams-2 top-mobile-1-teams'>

                            <div className='row'>
                                <div className='col-lg-7 col-md-7 col-sm-12'>

                                    <div className='container center-teams r-text-teams top-mobile-1-teams'>
                                        <div className='teams_telefonia_left'>
                                            <div className='telefonia_left_column'>
                                                <h1>Permite dedicar menos tiempo en gestionar y <strong>destinar su personal de TI a otras tareas de alto nivel</strong> </h1>
                                            </div>
                                            <div className='telefonia_left_column'>
                                                <h1><span className='f-weight-bold'>Agiliza el proceso</span> de administracion y soporte de la PBX</h1>
                                            </div>
                                            <div className='telefonia_left_column_opacity'>
                                                <h1><span className='f-weight-bold'>Cualquier usuario puede hacer y recibir llamadas</span> desde oficina, casa o cualquier dispositivo con conexión a internet.</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-5 col-md-5 col-sm-12'>
                                    <div className='container center-teams'>
                                        <img className='image-1-teams' src={telefonia_nube}></img>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </BrowserView>

                    <MobileView>
                        <div className='container'>
                            <div className=''>
                                <CarouselReact className='' showThumbs={false} showArrows={false} autoPlay={true} infiniteLoop={true} showStatus={false}>

                                    <div>
                                        <div className='container center-teams r-text-teams top-mobile-1-teams'>
                                            <div className='teams_telefonia_left'>
                                                <div className='telefonia_left_column-teams  pt-teams'>
                                                    <h1>Permite dedicar menos tiempo en gestionar y <strong>destinar su personal de TI a otras tareas de alto nivel</strong> </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='container center-teams r-text-teams top-mobile-1-teams'>
                                            <div className='teams_telefonia_left'>
                                                <div className='telefonia_left_column-teams  pt-teams'>
                                                    <h1><span className='f-weight-bold'>Agiliza el proceso</span> de administracion y soporte de la PBX</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='container center-teams r-text-teams top-mobile-1-teams'>
                                            <div className='teams_telefonia_left'>
                                                <div className='telefonia_left_column-teams  pt-teams'>
                                                    <h1><span className='f-weight-bold'>Cualquier usuario puede hacer y recibir llamadas</span> desde oficina, casa o cualquier dispositivo con conexión a internet.</h1>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </CarouselReact>
                            </div>
                        </div>
                    </MobileView>

                </div>

                <div className='container pt-teams'>
                    <div className='container center-teams-2 p-teams-2'>
                        <h5 className='title-teams'>Carriers de telefonía SIP</h5>
                        <h5 className='title-teams-2 '>Beneficios y ventajas de la telefonia SIP</h5>
                    </div>

                    <img className='team_carries_img' src={teams_carries}></img>

                </div>



            </Fragment>

            <Footer />
            <ToggleComp />
        </div>
    )

}