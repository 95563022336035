import React from 'react'

export const MigraChild3 = (props) => {
  const images = props.images.map((data, index) =>
    <div key={index} className="d-flex flex-column col-11 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-3 migra-child3-img position-relative">
      <div className='text-center mt-3'>
        <p className='text-unnamed p-child3-migra d-flex align-items-center justify-content-center'>
          {data.Text1}
        </p>
      </div>
      <div className='d-flex flex-column align-items-center m-0 p-0 justify-content-center my-1 img-hover-migra'>
        <img src={data.src} alt="" width="305px" className='img-fluid' />
        <p className='text-migra d-block d-md-none'>
          {data.hover}
        </p>
      </div>
    </div>
  );
  return (
    <div id='Migrations01' className='row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-10 justify-content-center m-0 p-0 mt-5'>
      {images}
    </div>
  )
}
