import React from 'react'
import { Form } from '../Form'
import {useTranslation} from "react-i18next";

export const ContactChild1 = (props) => {

  const [t, i18n] = useTranslation('common');
  const inputs = [
    { label: "Nombre", type: "text", placeholder: "Jhon Doe" },
    { label: "E-mail", type: "email", placeholder: "nuevo@gmail.com" },
    { label: "Comentarios", type: "textarea", placeholder: "Type something…" },
  ]
  const domainName =  window.location.hostname;
  return (
    <div className='row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-10 justify-content-center'>
      <div className='row col-12 justify-content-end'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-10 col-xl-6 col-xxl-6 text-center p-divContacto'>
          <p className='text-azul'>
            {t('contact.datos')}
          </p>
          <span className='text-azul-marino'>{t('contact.asesor')}</span>
        </div>
      </div>
      <div className='row justify-content-center p-0 m-0 col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 pt-3 text-unnamed'>
        <div className='row p-0 m-0 col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 col-xxl-6 justify-content-center '>
          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-11 h-100'>
            <Form campos={inputs} text="Enviar" />
          </div>
        </div>
        <div className='row p-0 m-0 col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 col-xxl-6 py-4 text-start '>
          <div className={ domainName.includes('redinet.cr') ? 'p-0 m-0 pb-4 d-none' : 'p-0 m-0 pb-4' }>
            <p className='lh-1 m-0 p-0 title-Contact'>{t('contact.email')}</p>
            <a href={ domainName.includes('redinet') ? 'mailto:sales@redinet.us' :  domainName.includes('iwsservices.ca') ? 'mailto:sales@iwsservices.ca' : 'mailto:sales@redinet.us' } className='mailto'>
              {domainName.includes('redinet') ? 'sales@redinet.us' :  domainName.includes('iwsservices.ca') ? 'sales@iwsservices.ca' : 'mailto:sales@redinet.us'}
            </a>
          </div>
          <div className={ domainName.includes('redinet.cr') ? 'p-0 m-0 pb-4 d-none' : 'p-0 m-0 pb-4' }>
            <p className='lh-1 m-0 p-0 title-Contact'>{t('contact.emailQuejas')}</p>
            <a href={domainName.includes('iwsservices.ca') ? 'mailto:quejasysugerencias@iwsservices.ca' : 'mailto:quejasysugerencias@redinet.us' } className='mailto'>
              { domainName.includes('iwsservices.ca') ? 'quejasysugerencias@iwsservices.ca' : 'quejasysugerencias@redinet.us' } 
            </a>
          </div>
          {/* 
          <div className='p-0 m-0 pb-4'>
            <p className='lh-1 m-0 p-0 title-Contact' style={{fontSize: "20px"}}>{t('contact.emailRH')}</p>
            <a href={domainName.includes('iwsservices.ca') ? 'mailto:iwsrecursoshumanos@iwsservices.ca' : 'mailto:iwsrecursoshumanos@redinet.us' } className='mailto'>
              {domainName.includes('iwsservices.ca') ? 'iwsrecursoshumanos@iwsservices.ca' : 'iwsrecursoshumanos@redinet.us'} 
            </a>
          </div>*/}
          
          <div className={ domainName.includes('redinet.cr') ? 'p-0 m-0 lh-1 py-4 d-none' : 'p-0 m-0 lh-1 py-4' }>
            <p className='title-Contact'>{t('contact.oficinasLATAM')}</p>
            <div className='d-flex'> 
              <img src={props.image2} alt="" width='50px' height='28px' className='me-2'/>
              <div className='pb-4'>
                <p className='span-Contact1'>1390 Market Street, Suite 200, San Francisco, California, 94102, United States</p>
                <span className='span-Contact1'>{t('contact.llamanos')}</span>
                <a onclick="return gtag_report_conversion('tel:+19733214062');" href="tel:+19733214062" className='span-Contact1'>(+1) 973 321 4062</a>
              
              </div>
            </div>
            <p className='title-Contact'> {t('contact.oficinasMex')} </p>
            <div className='d-flex mt-4'>
              <img src={props.image} alt="" width='50px' height='28px' className=' me-2'/>
              <div>
                <p className='span-Contact1'>Mario Pani No.400 Piso1, Lomas de Santa Fe 05300 Cuajimalpa, Mexico City</p>
                <span className='span-Contact1'>{t('contact.llamanos')}</span>
                <a onclick="return gtag_report_conversion('tel:+525589776500');" href="tel:+525589776500" className='span-Contact1'>(+52) 55 8977-6500</a><br/>
                <br/>
                <a onclick="return gtag_report_conversion('tel:+525589776528');" href="tel:+525589776528" className='span-Contact2'>(+52) 55 8977-6528</a>
              </div>
            </div>
          </div>
          
          <div className={!domainName.includes('redinet.cr') ? 'p-0 m-0 lh-1 py-4 d-none' : 'p-0 m-0 lh-1 py-4' }>
            <div className='mb-3'>
              <p className='m-0 p-0 title-Contact pb-2'>{t('contact.direccion')}</p>
              <div className='d-flex align-items-center'>
                <img src={props.image3} alt="" width='35px' height='35px' className='me-2'/>
                <p className='span-Contact1 p-0 m-0'>San José-Santa Ana Río oro, Condominio Avalon, Country Club, Penthouse 305</p>
              </div>
            </div>
            <p className='title-Contact'>{t('contact.oficina')}</p>
            <div className='d-flex align-items-center mb-3'> 
              {/* <img src={props.image3} alt="" width='35px' height='35px' className='me-2'/> */}
              <div className=''>
                <span className='span-Contact1'>{t('contact.llamanos')}</span><a href="tel:+50641032166" className='span-Contact1'>(+506) 4103 2166</a>
              </div>
            </div>
            <p className='title-Contact'> {t('contact.gratuito')} </p>
            <div className='d-flex align-items-center mb-3'>
              {/* <img src={props.image3} alt="" width='35px' height='35px' className=' me-2'/> */}
              <div>
                <span className='span-Contact1'>{t('contact.llamanos')}</span><a href="tel:8000521713" className='span-Contact1'>800 052 1713</a>
              </div>
            </div>
          </div>
          <div className=' p-0 m-0 lh-1 pt-4'>
            <p className='title-Contact'>{t('contact.horas')}</p>
            <span className='span-Contact1'>{t('contact.dias')}</span>
          </div>
        </div>
      </div>

    </div>
  )
}
