import React from 'react'

export const MigraChild2 = props => {
  const images = props.img.map((data, index) =>
    data.value === 1 ?
      <div className='d-flex align-items-start justify-content-center col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 px-0 d-none d-md-block' key={index}>
        <img src={data.src} alt="" width="242px" className='img-fluid '/>
      </div>
      :
      <div className='d-flex align-items-end justify-content-center col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 col-xxl-2 px-0 d-none d-md-block' key={index}>
        <img src={data.src} alt="" width="242px" className='img-fluid'/>
      </div>
  );
  return (
    <div className='row p-0 m-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-11 mt-4 justify-content-center height-510'>
      {images}
      <div className='d-flex align-items-start justify-content-center col-12'>
        <img src={props.img01} className="d-block d-md-none img-fluid" alt=''/>
      </div>
    </div>
  )
}