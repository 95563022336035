import React, { useCallback, useEffect, useState } from 'react'
import { BrowserView, isTablet, MobileView } from 'react-device-detect'
import { Carousel as CarouselReact } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next';
import CarouselOurTeam from './CarouselOurTeam';

const conjunto_1_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo Comercial.png';
const conjunto_1_2 = 'https://cdn.iwsservices.com/Nosotros/Ventas.png';

const conjunto_2_1 =  'https://cdn.iwsservices.com/Nosotros/Equipo de Preventa.png';
const conjunto_2_2 = 'https://cdn.iwsservices.com/Nosotros/Preventa.png';
const conjunto_3_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo de Ingeniería.png';
const conjunto_3_2 = 'https://cdn.iwsservices.com/Nosotros/Ingeniería.png';
const conjunto_4_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo Help Desk.png';
const conjunto_4_2 = 'https://cdn.iwsservices.com/Nosotros/Help desk.png';
const conjunto_5_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo Diseño y Marketing.png'
const conjunto_5_2 = 'https://cdn.iwsservices.com/Nosotros/Diseño.png'
const conjunto_6_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo Administración.png'
const conjunto_6_2 = 'https://cdn.iwsservices.com/Nosotros/Administración.png'
const conjunto_7_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo RRHH.png'
const conjunto_7_2 = 'https://cdn.iwsservices.com/Nosotros/RRHH.png'
const conjunto_8_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo Legal.png'
const conjunto_8_2 = 'https://cdn.iwsservices.com/Nosotros/Legal.png'


const conjunto_1_1En = 'https://cdn.iwsservices.com/Nosotros/ComercialEn.png';
const conjunto_2_1En = 'https://cdn.iwsservices.com/Nosotros/PreventaEn.png';
const conjunto_3_1En = 'https://cdn.iwsservices.com/Nosotros/IngenieríaEn.png';
const conjunto_4_1En = 'https://cdn.iwsservices.com/Nosotros/HelpEn.png';
const conjunto_5_1En = 'https://cdn.iwsservices.com/Nosotros/DiseñoEn.png';
const conjunto_6_1En = 'https://cdn.iwsservices.com/Nosotros/AdmEn.png';
const conjunto_7_1En = 'https://cdn.iwsservices.com/Nosotros/HREn.png';
const conjunto_8_1En = 'https://cdn.iwsservices.com/Nosotros/LegalEn.png';


const comercial = 'https://cdn.iwsservices.com/Nosotros/Mobile/Comercial.png';
const comercial2 = 'https://cdn.iwsservices.com/Nosotros/Mobile/Ventas.png';
const preventa = 'https://cdn.iwsservices.com/Nosotros/Mobile/Preventa2.png';
const preventa2 = 'https://cdn.iwsservices.com/Nosotros/Mobile/Preventa.png';
const ingenieria = 'https://cdn.iwsservices.com/Nosotros/Mobile/Ingeniería.png';
const ingenieria2 = 'https://cdn.iwsservices.com/Nosotros/Mobile/Ingeniería2.png';
const helpDesk = 'https://cdn.iwsservices.com/Nosotros/Mobile/HelpDesk.png';
const helpDesk2 = 'https://cdn.iwsservices.com/Nosotros/Mobile/Help Desk2.png';
const diseño = 'https://cdn.iwsservices.com/Nosotros/Mobile/Diseño y marketing.png';
const diseño2 = 'https://cdn.iwsservices.com/Nosotros/Mobile/Diseño.png';
const rrhh = 'https://cdn.iwsservices.com/Nosotros/Mobile/RRHH2.png';
const rrhh2 = 'https://cdn.iwsservices.com/Nosotros/Mobile/RRHH.png';
const administracion = 'https://cdn.iwsservices.com/Nosotros/Mobile/Administración.png';
const administracion2 = 'https://cdn.iwsservices.com/Nosotros/Mobile/Administración2.png';
const legal = 'https://cdn.iwsservices.com/Nosotros/Mobile/Legal.png';
const legal2 = 'https://cdn.iwsservices.com/Nosotros/Mobile/Legal2.png';


let textHelp = "";
let textIng = "";
const OurTeam = props => {
  const [t, i18n] = useTranslation('common');

  const domainName = window.location.hostname;


  if (domainName.includes('redinet.us')) {
    textHelp = "This centralized team within Redinet employees or customers in a personalized and efficient manner. It all comes down to the core principle of helping and providing excellent care to serve as a place where they can seek help.";
    textIng = "At Redinet, we have a deft certified engineering team with broad experience in technology tools, infrastructure, process design, among other things. Our team knows how to optimize your performance.";
  }
  else if (domainName.includes('redinet.pe') || domainName.includes('redinet.cr') || domainName.includes('redinet.com.mx')) {
    textHelp = "Este equipo centralizado dentro de Redinet, atiende a empleados y clientes de forma personalizada y eficaz. Todo se reduce al principio central de ayudar y brindar una excelente atención para servir como lugar en el que puedan buscar ayuda.";
    textIng = "En Redinet tenemos un increíble equipo conformado por ingenieros certificados y con amplia experiencia en herramientas tecnológicas, infraestructura, diseño de procesos, entre otras cosas. Contamos con un equipo especializado y que sabe optimizar su rendimiento.";
  }
  else if (domainName.includes('iwsservices.com')) {
    textHelp = "Este equipo centralizado dentro de Redinet, atiende a empleados y clientes de forma personalizada y eficaz. Todo se reduce al principio central de ayudar y brindar una excelente atención para servir como lugar en el que puedan buscar ayuda.";
    textIng = "En Redinet tenemos un increíble equipo conformado por ingenieros certificados y con amplia experiencia en herramientas tecnológicas, infraestructura, diseño de procesos, entre otras cosas. Contamos con un equipo especializado y que sabe optimizar su rendimiento.";
  }
  else {
    textHelp = "This centralized team within Redinet employees or customers in a personalized and efficient manner. It all comes down to the core principle of helping and providing excellent care to serve as a place where they can seek help.";
    textIng = "At Redinet, we have a deft certified engineering team with broad experience in technology tools, infrastructure, process design, among other things. Our team knows how to optimize your performance.";
  }

  const slider = {
    settings: {
      className: "",
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 2,
      speed: 500,
      rows: 2,
      slidesPerRow: 1,
      // autoplay:true,
      // autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 993,
          settings: {
            // centerPadding: "10px",
            slidesToShow: 1,
            speed: 500,
            rows: 2,
            slidesPerRow: 1,
            // autoplay:true,
            // autoplaySpeed: 3000,
          }
        },
        {
          breakpoint: 769,
          settings: {
            // centerPadding: "10px",
            slidesToShow: 1,
            speed: 500,
            rows: 1,
            slidesPerRow: 1,
            // autoplay:true,
            // autoplaySpeed: 3000,
          }
        },
      ]
    },
    body: [
      {
        src1: conjunto_1_1, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.comercial')}`, height: "230px", widht: "100%", classimg1: "imagen-slick",
        src2: conjunto_1_2, classFather: "father-slick p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.comercial-text')}`
      },
      {
        src1: conjunto_3_1, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.ingenieria')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_3_2, classFather: "father-slick p-0", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: textIng
      },
      {
        src1: conjunto_2_1, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.preventa')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_2_2, classFather: "father-slick p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.preventa-text')}`
      },
      {
        src1: conjunto_4_1, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.help')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_4_2, classFather: "father-slick p-0", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: textHelp
      },
      {
        src1: conjunto_5_1, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.diseño')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_5_2, classFather: "father-slick p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.diseño-text')}`
      },
      {
        src1: conjunto_6_1, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.administracion')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_6_2, classFather: "father-slick p-0", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: `${t('aboutUsEquipos.administracion-text')}`
      },

      {
        src1: conjunto_7_1, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.RRHH')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_7_2, classFather: "father-slick p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.RRHH-text')}`
      },
      {
        src1: conjunto_8_1, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.legal')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_8_2, classFather: "father-slick p-0", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: `${t('aboutUsEquipos.legal-text')}`
      }
    ],
    body2: [
      {
        src1: conjunto_1_1En, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.comercial')}`, height: "230px", widht: "100%", classimg1: "imagen-slick",
        src2: conjunto_1_2, classFather: "father-slick p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.comercial-text')}`
      },
      {
        src1: conjunto_3_1En, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.ingenieria')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_3_2, classFather: "father-slick p-0", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: textIng
      },
      {
        src1: conjunto_2_1En, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.preventa')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_2_2, classFather: "father-slick p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.preventa-text')}`
      },
      {
        src1: conjunto_4_1En, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.help')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_4_2, classFather: "father-slick p-0", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: textHelp
      },
      {
        src1: conjunto_5_1En, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.diseño')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_5_2, classFather: "father-slick  p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.diseño-text')}`
      },
      {
        src1: conjunto_6_1En, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.administracion')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_6_2, classFather: "father-slick p-0", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: `${t('aboutUsEquipos.administracion-text')}`
      },

      {
        src1: conjunto_7_1En, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.RRHH')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_7_2, classFather: "father-slick p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.RRHH-text')}`
      },
      {
        src1: conjunto_8_1En, class1: "row col-6 p-0 d-flex ", alt1: `${t('aboutUsEquipos.legal')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_8_2, classFather: "father-slick p-0", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: `${t('aboutUsEquipos.legal-text')}`
      }
    ]
  }

  return (
    <div>
      <BrowserView className='bg-gray'>
        <div className='bg-white container-snake-left-rr_'>
          <div id="InfoUsChild3" className=' mg-0 row m-0 p-0 my-5 py-4 col-12 justify-content-center align-items-center text-unnamed'>
            <div className='col-12 d-flex justify-content-center'>
              <div className='title-Child3 text-center text-azul-marino p-2'>
                <p>{t('aboutUs.conoce')}</p>
              </div>
            </div>
            <div className='title-Child3-absolute position-relative text-azul-marino '>
              {t('aboutUs.equipo')}
            </div>
          </div>
          <div id="SlickCarrousel" className='row m-0 p-0 align-self-center justify-content-center'>
            <div className='col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10 p-0'>
              <div className={t("clase.es")}> <CarouselOurTeam body={slider.body} /></div>
            </div>
            <div className='col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10 p-0'>
              <div className={t("clase.en")}> <CarouselOurTeam body={slider.body2} /></div>
            </div>
          </div>
        </div>

      </BrowserView>

      <MobileView className='bg-gray'>
        <div className='bg-white container-snake-left-rr_'>
          <div className='container pt-4'>
            
          <div id="InfoUsChild3" className='row m-0 p-0 mb-4 col-12 justify-content-center align-items-center text-unnamed box-title'>
            <div className='col-12 d-flex justify-content-center'>
              <div className='subtitle-rr text-center txt-azul p-2'>
                <p>{t('aboutUs.conoce')}</p>
              </div>
            </div>

            <div className='subtitle-rr txt-azul'>
              {t('aboutUs.equipo')}
            </div>
          </div>

          <CarouselReact className='carousel-mb-container' interval={10000} showThumbs={false} showArrows={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
            <div className='row'>
              <div className='col-12'>
                <div className='box-img-ourteam'>
                  <div className=' mx-auto'>
                    <img src={comercial} alt="Equipo comercial" className='over' />
                    <div className='position-relative'>
                      <img src={comercial2} alt="Equipo comercial 2" className='height-189 to-top' />
                      <div className='box-text-ourteam animate-faded' id='boxTransition'>
                        <p className='pt-4 pb-3'>{t('aboutUsEquipos.comercial-text')}</p>
                      </div>

                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='box-img-ourteam'>
                  <div className=' mx-auto'>
                    <img src={preventa} alt="Equipo preventa" className='over' />
                    <div className='position-relative'>
                      <img src={preventa2} alt="Equipo preventa 2" className='height-189 to-top' />
                      <div className='box-text-ourteam animate-faded'>
                        <p className='pt-4 pb-3'>{t('aboutUsEquipos.preventa-text')}</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='box-img-ourteam'>
                  <div className=' mx-auto'>
                    <div className='position-relative'>
                      <img src={ingenieria} alt="Equipo ingenieria" className='height-189' />
                      <div className='box-text-ourteam animate-faded'>
                        <p>{t('aboutUsEquipos.ingenieria-text')}</p>
                      </div>
                    </div>
                    <img src={ingenieria2} alt="Equipo ingenieria 2" className='over to-top' />
                  </div>

                </div>
              </div>

            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='box-img-ourteam'>
                  <div className=' mx-auto'>
                    <div className='position-relative'>
                      <img src={helpDesk} alt="Equipo help desk" className='height-189' />
                      <div className='box-text-ourteam animate-faded'>
                        <p>{t('aboutUsEquipos.help-text')}</p>
                      </div>
                    </div>
                    <img src={helpDesk2} alt="Equipo help desk 2" className='over to-top' />
                  </div>

                </div>
              </div>

            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='box-img-ourteam'>
                  <div className=' mx-auto'>
                    <img src={diseño} alt="Equipo diseño" className='over' />
                    <div className='position-relative'>
                      <img src={diseño2} alt="Equipo diseño 2" className='height-189 to-top' />
                      <div className='box-text-ourteam animate-faded'>
                        <p className='pt-4 pb-3'>{t('aboutUsEquipos.diseño-text')}</p>
                      </div>

                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='box-img-ourteam'>
                  <div className='col-6 mx-auto'>
                    <img src={rrhh} alt="Equipo RH" className='over' />
                    <div className='position-relative'>
                      <img src={rrhh2} alt="Equipo RH 2" className='height-189 to-top' />
                      <div className='box-text-ourteam animate-faded'>
                        <p className='pt-4 pb-3'>{t('aboutUsEquipos.RRHH-text')}</p>
                      </div>

                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='box-img-ourteam'>
                  <div className='col-6 mx-auto'>
                    <div className='position-relative'>
                      <img src={administracion} alt="Equipo administración" className='height-189' />
                      <div className='box-text-ourteam animate-faded'>
                        <p>{t('aboutUsEquipos.administracion-text')}</p>
                      </div>
                    </div>
                    <img src={administracion2} alt="Equipo administración 2" className='over to-top' />
                  </div>

                </div>
              </div>

            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='box-img-ourteam'>
                  <div className='col-6 mx-auto'>
                    <div className='position-relative'>
                      <img src={legal} alt="Equipo legal" className='height-189' />
                      <div className='box-text-ourteam animate-faded'>
                        <p>{t('aboutUsEquipos.legal-text')}</p>
                      </div>
                    </div>
                    <img src={legal2} alt="Equipo legal 2" className='over to-top' />
                  </div>

                </div>
              </div>

            </div>
          </CarouselReact>
          
          </div>
        </div>
      </MobileView>
    </div>
  )
}


export default OurTeam