import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../CSS/Design2023/OurServices.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import { CardContent } from '@mui/material';
import { Carousel as CarouselReact } from 'react-responsive-carousel';
import { useTranslation } from "react-i18next";
import CountUp from 'react-countup';

const TotalPaises = 'https://cdn.iwsservices.com/Home/Conteo/Paises.png';
const TotalClientes =  'https://cdn.iwsservices.com/Home/Conteo/Clientes.png';
const TotalEmpleados =  'https://cdn.iwsservices.com/Home/Conteo/Empleados.png';
const TotalCertificaciones =  'https://cdn.iwsservices.com/Home/Conteo/Certificaciones.png';
const TotalPaisesColores =  'https://cdn.iwsservices.com/Home/Conteo/Paises-blanco.png';
const TotalClientesColores =  'https://cdn.iwsservices.com/Home/Conteo/Clientes-blanco.png';
const TotalEmpleadosColores =  'https://cdn.iwsservices.com/Home/Conteo/Empleados-blanco.png';
const TotalCertificacionesColores =  'https://cdn.iwsservices.com/Home/Conteo/Certificaciones-blanco.png';


const UsComp = props => {
    const [t, i18n] = useTranslation('common');
    const [esVisible, setEsVisible] = useState(false);
    const [esVisibleMobile, setEsVisibleMobile] = useState(false);
    const [isMounted, setIsMounted] = useState(true);
    const duration = 6;

    const Countrys = new CountUp(7000);
    const Clients = new CountUp(15300);

    const handleScrollUs2 = useCallback(() => {

        if (isMounted) {

            if (window.pageYOffset >= 3700) {
                setEsVisible(true);

            } else {
                setEsVisible(false);
            }

            if (window.pageYOffset >= 3200) {
                setEsVisibleMobile(true);
            } else {
                setEsVisibleMobile(false);
            }
        }
    }, [isMounted]);

    React.useEffect(() => {
        //window.onscroll = () => handleScrollUs();

        window.addEventListener('scroll', handleScrollUs2);

        return (
            () => {
                setIsMounted(false);
            }
        )
    }, [handleScrollUs2]); // IMPORTANT, This will cause react to update depending on change of this value

    const handleEnterGiro = (e)=>{
        
        if(e.target.children.length > 0){
            e.target.children[0].classList.add('anima');
            e.target.children[0].classList.remove('anima-return');
        }else{
            e.target.classList.add('anima');
            e.target.classList.remove('anima-return');

        }
    }
    const handleLeaveGiro = (e)=>{
        
        if(e.target.children.length > 0){
            e.target.children[0].classList.add('anima-return');
            e.target.children[0].classList.remove('anima');
        }else{
            e.target.classList.add('anima-return');
            e.target.classList.remove('anima');

        }
    }
    
    return (
        <>
            <BrowserView className="container-father card-service bg-white">
                <div className="bg-gray container-snake-left-rr_5 pb-6">
                    <div className="container text-center padding-" hidden>
                        <h2 className='title-a-section-home our-alianza'>{t('us.title')}</h2>
                        <p className='card-service-desc'>{t('us.descrp')}</p>
                    </div>
                    <div className='container padding-'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-6'>
                                <div className="">
                                    <div className='container text-center mt-3' onMouseEnter={(e)=>{handleEnterGiro(e)}} onMouseLeave={(e)=>{handleLeaveGiro(e)}}>
                                        <img src={TotalPaises} className='img-giro img-50'></img>
                                    </div>
                                    <div className='text-center'>
                                        <h2 className='fz-50 l-h-1 f-w efecto-espejo color-blue'>+
                                            {
                                                esVisible &&  <CountUp start={0} end={t('us.number1')} duration={3.00} />
                                            }
                                           
                                        </h2>
                                        <br />
                                        <p className='fz-25 f-w'>{t('us.us1')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6'>
                                <div className="">
                                    <div className='container text-center mt-3' onMouseEnter={(e)=>{handleEnterGiro(e)}} onMouseLeave={(e)=>{handleLeaveGiro(e)}}>
                                        <img src={TotalClientes} className='img-giro img-50'></img>
                                    </div>
                                    <div className='text-center'>
                                        <h2 className='fz-50 l-h-1 f-w efecto-espejo color-blue'>+
                                            {
                                                esVisible && <CountUp start={0} end={t('us.number2')} decimal="," duration={3.00} />
                                            }
                                            </h2>
                                        <br />
                                        <p className='fz-25 f-w'>{t('us.us2')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6'>
                                <div className="">

                                    <div className='container text-center mt-3' onMouseEnter={(e)=>{handleEnterGiro(e)}} onMouseLeave={(e)=>{handleLeaveGiro(e)}}>
                                        <img src={TotalEmpleados} className='img-giro img-50'></img>
                                    </div>
                                    <div className='text-center'>
                                        <h2 className='fz-50 l-h-1 f-w efecto-espejo color-blue'>+
                                            {
                                                esVisible && <CountUp start={0} end={t('us.number3')} decimal="," duration={3.00} />
                                            }
                                        </h2>
                                        <br />
                                        <p className='fz-25 f-w'>{t('us.us3')}</p>
                                    </div>
                                </div>

                            </div>
                            <div className='col-lg-3 col-md-6'>
                                <div className="">
                                    <div className='container text-center mt-3' onMouseEnter={(e)=>{handleEnterGiro(e)}} onMouseLeave={(e)=>{handleLeaveGiro(e)}}>
                                        <img src={TotalCertificaciones} className='img-giro img-50'></img>
                                    </div>
                                    <div className='text-center'>
                                        <h2 className='fz-50 l-h-1 f-w efecto-espejo color-blue'>+
                                            {
                                                esVisible && <CountUp start={0} end={t('us.number4')} decimal="," duration={3.00} />
                                            }
                                        </h2>
                                        <br />
                                        <p className='fz-25 f-w'>{t('us.us4')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BrowserView>
            <MobileView className="bg-white">
                <div className='bg-white image-cover-countupmobile'>
                    <div className=''>
                        <div className="row">
                            <div className="col-6">
                                <div className="">
                                    <div className='container text-center mt-3'>
                                        <img src={TotalPaisesColores} className='img-giro img-50'></img>
                                    </div>
                                    <br />
                                    <div className='text-center'>
                                        <h2 className='fz-50 l-h-1 f-w color-white transbox'>+
                                            {
                                                esVisibleMobile && <CountUp start={0} end={t('us.number1')} duration={3.00} />
                                            }

                                        </h2>
                                        <br />
                                        <div className='container'>
                                            <p className='fz-15 f-w color-white transbox'>{t('us.us1')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 bg-color-blue opacity">
                                <div className="">
                                    <div className='container text-center mt-3'>
                                        <img src={TotalClientesColores} className='img-giro img-50'></img>
                                    </div>
                                    <br />
                                    <div className='text-center'>
                                        <h2 className='fz-50 l-h-1 f-w color-white transbox'>+
                                            {
                                                esVisibleMobile && <CountUp start={0} end={t('us.number2')} duration={3.00} />
                                            }

                                        </h2>
                                        <br />
                                        <div className='container'>
                                            <p className='fz-15 f-w color-white transbox'>{t('us.us2')}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 bg-color-blue opacity">
                                <div className="">
                                    <div className='container text-center mt-3'>
                                        <img src={TotalEmpleadosColores} className='img-giro img-50'></img>
                                    </div>
                                    <br />
                                    <div className='text-center'>
                                        <h2 className='fz-50 l-h-1 f-w color-white transbox'>+
                                            {
                                                esVisibleMobile && <CountUp start={0} end={t('us.number3')} duration={3.00} />
                                            }

                                        </h2>
                                        <br />

                                        <div className='container'>
                                            <p className='fz-15 f-w color-white transbox'>{t('us.us3')}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="">
                                    <div className='container text-center mt-3'>
                                        <img src={TotalCertificacionesColores} className='img-giro img-50'></img>
                                    </div>
                                    <br />
                                    <div className='text-center'>
                                        <h2 className='fz-50 l-h-1 f-w color-white'>+
                                            {
                                                esVisibleMobile && <CountUp start={0} end={t('us.number4')} duration={3.00} />
                                            }

                                        </h2>
                                        <br />
                                        <br />
                                        <div className='container'>
                                            <p className='fz-15 f-w color-white'>{t('us.us4')}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </MobileView>
        </>
    )
}

export default UsComp