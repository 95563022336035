import React from 'react'
import { useTranslation } from "react-i18next";

export function CardProblems(props) {
  const [t, i18n] = useTranslation('common');

  return (
    <div>
      <div className='card no-border bg-white pb-2'>
        <div className='card-header no-border bg-white'>
        <img src={props.img} className="w-25" />
        </div>
        <div className='card-section no-border bg-white'>
        <h5 className="card-title pt-3">{props.title}</h5>
              <p className="card-text">{props.body}</p>
        </div>
      </div>
    </div>

  )
}
