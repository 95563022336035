import React, { Fragment, useEffect } from 'react'
import { Soluciones } from './Components/Soluciones'
import styles from './CSS/microsoft.css'
import { useTranslation } from "react-i18next";
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";
import { SolutionsChild1 } from './Components/Solutions/SolutionsChild1'
import { SolutionsChild2 } from './Components/Solutions/SolutionsChild2'

const Micro = 'https://cdn.iwsservices.com/Microsoft.png'
const Micro2 = 'https://cdn.iwsservices.com/MicrosoftMovil.png'
const cloud = 'https://cdn.iwsservices.com/Soluciones/Microsoft/Cloud.png'
const aplicaciones = 'https://cdn.iwsservices.com/Soluciones/Microsoft/Aplicaciones.png'
const migracion = 'https://cdn.iwsservices.com/Soluciones/Microsoft/Migración.png'
const seguridad = 'https://cdn.iwsservices.com/Soluciones/Microsoft/Seguridad.png'
const image01 = 'https://cdn.iwsservices.com/Soluciones/Microsoft/Microsoft.png'
const image02 = 'https://cdn.iwsservices.com/Soluciones/Microsoft/Azure.png'
const image03 = 'https://cdn.iwsservices.com/Soluciones/Microsoft/Dynamics.png'
const image04 = 'https://cdn.iwsservices.com/Soluciones/Microsoft/Teams.png'


export function Microsoft() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);

  const [t, i18n] = useTranslation('common');


  const body = {
    operaciones: [
      { src: cloud, height: "145px", width: "145px", text: `${t('microsoft.cloud')}` },
      { src: aplicaciones, height: "145px", width: "145px", text: `${t('microsoft.aplicaciones')}` },
      { src: migracion, height: "145px", width: "145px", text: `${t('microsoft.migracion')}` },
      { src: seguridad, height: "145px", width: "145px", text: `${t('microsoft.seguridad')}` }
    ]
  }
  const child1 = {
    text1: `${t('microsoft.child1-text1')}`,
    span: `${t('microsoft.child1-span')}`,
    text2: `${t('microsoft.child1-text2')}`,
    span2: `${t('microsoft.child1-span2')}`,
    text3: `${t('microsoft.child1-text3')}`,
  }
  const child2 = [
    {
      src: image01,
      text1: `${t('microsoft.img1-text1')}`,
      span: `${t('microsoft.img1-span')}`,
      text2: `${t('microsoft.img1-text2')}`,
      value: 1
    },
    {
      src: image02, text1: `${t('microsoft.img2-text1')}`, span: `${t('microsoft.img2-span')}`,
      text2: `${t('microsoft.img2-text2')}`, value: 0
    },
    {
      src: image03, text1: `${t('microsoft.img3-text1')}`, span: `${t('microsoft.img3-span')}`,
      text2: `${t('microsoft.img3-text2')}`, value: 1
    },
    {
      src: image04, text1: `${t('microsoft.img4-text1')}`, span: `${t('microsoft.img4-span')}`,
      text2: `${t('microsoft.img4-text2')}`, value: 0
    },
  ]
  return (
    <div>

      <Header />
      <Fragment>
        <div id="PresMicroFather" className='MicroFather'>
          <Soluciones image={Micro} image2={Micro2} />
        </div>
        <div id="MicroChild1" className='solucionesMicrosoft'>
          <SolutionsChild1 images={body.operaciones} text1={child1.text1} span={child1.span} text2={child1.text2} span2={child1.span2} text3={child1.text3} />
        </div>
        <div id="KasperChild2" className='my-4 margin-top'>
          <SolutionsChild2 image={child2} button={{ text: `${t('AWS.button')}`, rute: "/Nosotros" }} />
        </div>
      </Fragment>

      <ToggleComp />
      <Footer />
    </div>
  )
}
