import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { ContextDataChatIWS } from "../../../index"
import { useContext } from "react";


export const BotTakename = ({ steps }) => {
    axios.defaults.headers.common['Api_Key'] = 'J7rTm2LpH9qA6wZ8xN3cE1bD4';
    axios.defaults.headers.post['Api_Key'] = `J7rTm2LpH9qA6wZ8xN3cE1bD4`;
    const { ObjChat, UpdateObjHub } = useContext(ContextDataChatIWS);

    const [list, setList] = useState();
    useEffect(() => {
        const { names } = steps;
        const { value } = names;
        const nombre = value;
        setList(nombre)
        UpdateObjHub("nombreUser", nombre)


        const fetchData = async () => {
            try {
                console.log("Nombre", nombre)
                const response = await axios.post(`${ObjChat.api}/api/Services/BotTakeName?text=${nombre}`);
                //setList(response.data.data);
                console.log("API Nombre", response)
                setList(nombre);


            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>Hola {list} ,¿Cual es tu correo? </div>
    );
}
export default BotTakename;
