import React, { Fragment } from 'react';
import {useTranslation} from "react-i18next";

export const ImplementationChild1 = (props) => {

  const [t, i18n] = useTranslation('common');

  const images = props.images.map((data, index) =>
    data.value === 0 ?
      <div key={index} className='d-flex justify-content-start col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10 my-3'>
        <div className='d-flex justify-content-start col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-8'>
          <img src={data.src} className="img-fluid" alt=''/>
        </div>

      </div>
      :
      <div key={index} className='d-flex justify-content-end col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10 my-3'>
        <div className='d-flex justify-content-start col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-8'>
          <img src={data.src} className="img-fluid" alt=''/>
        </div>

      </div>
  );
  // const imagesEn = props.imagesEn.map((data, index) =>
  //   data.value === 0 ?
  //     <div key={index} className='d-flex justify-content-start col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10 my-3'>
  //       <div className='d-flex justify-content-start col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-8'>
  //         <img src={data.src} className="img-fluid" alt=''/>
  //       </div>

  //     </div>
  //     :
  //     <div key={index} className='d-flex justify-content-end col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10 my-3'>
  //       <div className='d-flex justify-content-start col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-8'>
  //         <img src={data.src} className="img-fluid" alt=''/>
  //       </div>

  //     </div>
  // );
  return (
    <Fragment>
      <div className="text-center our-alianza margin-topImplement">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="father-our-alianza2 m-0 align-items-center">
            <p className="d-flex flex-row m-0 align-items-center p-line justify-content-between ">
              {t('implementaciones.etapas')}
            </p>
          </div>
          <span className="span-our-alianza">{t('implementaciones.implementacion')}</span>
        </div>
      </div>
      <div className={`row m-0 p-0 justify-content-center col-12 col-sm-12 col-md-11 col-lg-10 col-xl-10 col-xxl-8`}>
        {images}
      </div>
      {/* <div className={`row m-0 p-0 justify-content-center col-12 col-sm-12 col-md-11 col-lg-10 col-xl-10 col-xxl-8 ${t("clase.en")}`}>
        {imagesEn}
      </div> */}
    </Fragment>
  )
}
