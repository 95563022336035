import React,{Component} from 'react';
import { ItemHeader } from './ItemHeader'
import logo from '../Images/LogoRedinet.png';
import logoRDCR from '../Images/Redinet_CR.png';
import logoRDMX from '../Images/Redinet_MX.png';
import logoRDPE from '../Images/Redinet_PE.png';
import logoSolo from '../Images/Redinet_Solo.png';
import logoIWS from '../Images/Logo IWS2.png';
import { Link } from 'react-router-dom';
// METODO ORIGINAL
// export function ListHeader() {
//   return (
//     <div className="collapse navbar-collapse" id="navbarSupportedContent">
//       <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//         <ItemHeader />
//       </ul>
//     </div>
//   )
// }
const domainName =  window.location.hostname;


export class ListHeader extends Component {

  constructor(props){
    super(props)

    // Establecer un estado
    this.state = { messageClass: 'justify-content-center collapse navbar-collapse' }

    this.handler = this.handler.bind(this);

    this.cont = 0;
  }

  // Este método se enviará al componente secundario
  handler() {
   this.cont ++;
   this.setState({ messageClass: 'justify-content-center collapse navbar-collapse nav' + this.cont })
}

  render() {
    return (
      <div>
        <div className={this.state.messageClass} id="navbarSupportedContent" hidden>
          <Link className="navbar-brand m-0" to="/">
            <img src={ domainName.includes('redinet.com.mx') ? logoRDMX : domainName.includes('redinet.us') ? logoSolo : domainName.includes('redinet.cr') ? logoRDCR : domainName.includes('redinet.pe') ? logoRDPE : domainName.includes('iwsservices.ca') ? logoIWS : logo } alt="LogoIWS"  className="d-inline-block align-text-top d-none d-md-block logo-desktop" />
            <img src={ domainName.includes('redinet.com.mx') ? logoRDMX : domainName.includes('redinet.us') ? logoSolo : domainName.includes('redinet.cr') ? logoRDCR : domainName.includes('redinet.pe') ? logoRDPE : domainName.includes('iwsservices.ca') ? logoIWS : logo } alt="LogoIWS"  className="d-inline-block align-text-top d-block d-md-none logo-desktop" />
          </Link>
          <ul className="navbar-nav justify-content-center mb-lg-0">
            <ItemHeader action={this.handler}/>
          </ul>
        </div>
        
      </div>
      );
  }
}




