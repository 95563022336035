import React from 'react'
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faGraduationCap, faMagnifyingGlass, faBook, faBookOpen, faDownload } from '@fortawesome/free-solid-svg-icons'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Carousel as CarouselReact } from 'react-responsive-carousel';
import { useTranslation } from "react-i18next";
import { Info } from "./Info";
import pdfEbook from "../../../src/Icons/Ebook_InteligenciaArtificial.pdf";


const Google = 'https://cdn.iwsservices.com/Home/Google color.png';
const Azure = 'https://cdn.iwsservices.com/Home/Logos_multinubes-01.png';
const AWS = 'https://cdn.iwsservices.com/Home/AWS color.png'


const CardBanner = props => {
  const [t, i18n] = useTranslation('common');
  return (
    <div>
      <div className={isMobile ? "pb-1" : "pb-1 background-blue-desktop"}>
        <BrowserView>
          <div className='container cards-bannet-rr'>
            <div className='insights-cards'>
              <div className='row'>
                <div className='col-lg-3 col-md-6 pb-2'>
                  <Card className='card-service-borders '>
                    <Card.Body className="text-cent">
                      <div className='text-center'>
                        <Card.Title><span className='size-rm2 bg-blue'><FontAwesomeIcon icon={faGraduationCap} /></span></Card.Title>
                      </div>
                      <Card.Title>{t('home.universidadv2')}</Card.Title>
                      {/* <a className='arrow-right-icon'>Leer más    <FontAwesomeIcon icon={faArrowRight} /></a> */}
                      <a className='arrow-right-icon' href='https://cursos.iwsservices.com/' target="_blank">{t('aboutUs.vermas')}  <FontAwesomeIcon icon={faArrowRight} /></a>
                    </Card.Body>
                  </Card>
                </div>
                <div className='col-lg-3 col-md-6 pb-2'>
                  <Card className='card-service-borders'>
                    <Card.Body className="text-cent">
                      <div className='text-center'>
                        <Card.Title><span className='size-rm2 bg-blue'><FontAwesomeIcon icon={faMagnifyingGlass} /></span></Card.Title>
                      </div>
                      <Card.Title>{t('home.descubrev2')}</Card.Title>
                      {/* <a className='arrow-right-icon'>Leer más    <FontAwesomeIcon icon={faArrowRight} /></a> */}
                      <Link className='arrow-right-icon' to="/AWS">{t('aboutUs.vermas')} <FontAwesomeIcon icon={faArrowRight} /></Link>
                    </Card.Body>
                  </Card>
                </div>
                <div className='col-lg-3 col-md-6 pb-2'>
                  <Card className='card-service-borders'>
                    <Card.Body className="text-cent">
                      <div className='text-center'>
                        <Card.Title><span className='size-rm2 bg-blue'><FontAwesomeIcon icon={faBook} /></span></Card.Title>
                      </div>
                      <Card.Title>{t('home.revista')}</Card.Title>
                      {/* <a className='arrow-right-icon'>Leer más    <FontAwesomeIcon icon={faArrowRight} /></a> */}
                      
                      <Link className='arrow-right-icon' to="/Recursos">{t('aboutUs.vermas')} <FontAwesomeIcon icon={faArrowRight} /></Link>
                    </Card.Body>
                  </Card>
                </div>
                <div className='col-lg-3 col-md-6 pb-2'>
                  <Card className='card-service-borders'>
                    <Card.Body className="text-cent">
                      <div className='text-center'>
                        <Card.Title><span className='size-rm2 bg-blue'> <FontAwesomeIcon icon={faBookOpen} /></span></Card.Title>
                      </div>
                      <Card.Title>{t('home.ebook')}</Card.Title>
                      {/* <a className='arrow-right-icon'>Leer más    <FontAwesomeIcon icon={faArrowRight} /></a> */}
                      <a className='arrow-right-icon' target="_blank" href={pdfEbook} download>{t('home.descargar')}  <FontAwesomeIcon icon={faDownload}/></a>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
          {/*"Section Multinubes"*/}
          <Info imgGoogle={Google} imgAWS={AWS} imgAzure={Azure} />
        </BrowserView>
      </div>
      <MobileView>
        <div className='background-blue-mobile'>
          <div className='section-banner'>
            <CarouselReact className='carousel-mb-container' showThumbs={false} showArrows={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
              <div>
                <Card className='card-cloud card-chicle'>
                  <div className='card card-cloud'>
                    <Card.Body>
                      <Card.Title><span className='size-rm3 bg-blue'><FontAwesomeIcon icon={faGraduationCap} /></span></Card.Title>
                      <Card.Title>{t('home.universidadv2')}</Card.Title>
                      <Card.Text>
                      <a className='arrow-right-icon' href='https://cursos.iwsservices.com/' target="_blank">{t('aboutUs.vermas')}  <FontAwesomeIcon icon={faArrowRight} /></a>
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
              </div>
              <div>
                <Card className='card-cloud card-chicle'>
                  <div className='card card-cloud '>
                    <Card.Body>
                      <Card.Title><span className='size-rm3 bg-blue'><FontAwesomeIcon icon={faMagnifyingGlass} /></span></Card.Title>
                      <Card.Title>{t('home.descubrev2')}</Card.Title>
                      <Card.Text>
                      <Link className='arrow-right-icon' to="/AWS">{t('aboutUs.vermas')} <FontAwesomeIcon icon={faArrowRight} /></Link>
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
              </div>
              <div>
                <Card className='card-cloud card-chicle'>
                  <div className='card card-cloud '>
                    <Card.Body>
                      <Card.Title><span className='size-rm3 bg-blue'><FontAwesomeIcon icon={faBook} /></span></Card.Title>
                      <Card.Title>{t('home.revista')}</Card.Title>
                      <Card.Text>
                      <Link className='arrow-right-icon' to="/Recursos">{t('aboutUs.vermas')} <FontAwesomeIcon icon={faArrowRight} /></Link>
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
              </div>
              <div>
                <Card className='card-cloud card-chicle'>
                  <div className='card card-cloud '>
                    <Card.Body>
                      <Card.Title><span className='size-rm3 bg-blue'><FontAwesomeIcon icon={faBookOpen} /></span></Card.Title>
                      <Card.Title>{t('home.ebook')}</Card.Title>
                      <Card.Text>
                      <a className='arrow-right-icon' href={pdfEbook} download>{t('home.descargar')}  <FontAwesomeIcon icon={faDownload}/></a>
                      </Card.Text>
                    </Card.Body>
                  </div>
                </Card>
              </div>
            </CarouselReact>
          </div>
        </div>     
          {/*"Section Multinubes"*/}
          <Info imgGoogle={Google} imgAWS={AWS} imgAzure={Azure} />
      </MobileView>
    </div>
  )
}

export default CardBanner