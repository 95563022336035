import React from 'react';

function ChatNoHistory() {

    return (
        <div>
            <div className='container text-center p-t-3'>
                <h3>Aún no tienes ninguna conversación disponible</h3>
            </div>
        </div>
    );
}


export default ChatNoHistory;