import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import logo from "./Images/IWS_Lat-01.png";
import admi from "./Components/ChatBotInterfaz/Image/Avatar.png";
import email from "../src/Images/correo.png";
import chatAvatar from "./Components/ChatBotInterfaz/Image/Chatavatar.png"
import "bootstrap/dist/css/bootstrap.css";
import * as signalR from '@microsoft/signalr'
import ChatNoHistory from "./Components/ChatBotInterfaz/ChatNoHistory";
import ChatDashboard from "./Components/ChatBotInterfaz/ChatDashboard";
import "react-bootstrap"
import "./Components/ChatBotInterfaz/css/CharResponsable.css";
import Axios from "axios";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { ReportsChatBot } from './Page/Reports/ReportsChatBot';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Col from "react-bootstrap/Col";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { ContextDataChat } from "./index"
import { ContextDataChatIWS } from "../src/index"
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { ActivateConversation, addUSerSelected, SaveNameUser, UserNoRead, HistoryRead } from "./redux/dataSlice.js";
import { useDispatch, useSelector } from "react-redux";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, IconButton } from '@mui/material';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs'; import Miequipo from './Miequipo';
import Alert from 'react-bootstrap/Alert';
import alertAudio from './Notification/alert.mp3';

// function nav({ active, onClick }) {
//     return (
//         <div className={`nav ${active ? 'active' : ''}`} onClick={onClick}></div>
//     );
// }
// function CustomToggle({ children, eventKey }) {
//     const decoratedOnClick = useAccordionButton(eventKey, () =>
//         ////console.log('totally custom!')
//     );

//     return (
//         <button
//             type="button"
//             onClick={decoratedOnClick}
//         >
//             {children}
//         </button>
//     );
// }

const InterfazChatBoot = React.memo((props) => {





  axios.defaults.headers.common['Api_Key'] = 'J7rTm2LpH9qA6wZ8xN3cE1bD4';
  //const token = "J7rTm2LpH9qA6wZ8xN3cE1bD4";
  // const headers = useMemo(() => {
  //     return {Api_Key: `${token}`}
  // }, [token])
  //axios.defaults.headers.post['Api_Key'] = `J7rTm2LpH9qA6wZ8xN3cE1bD4`;

  //Estado donde se guarda datos del usuario
  const [arrayUserConected, setarrayUserConected] = useState({
    "Nombre": "",
    "email": "",
    "conexionID": "",
    "Rol": "",
    "idUser": "",
    "urlpath": admi
  });
  const { accounts, instance } = useMsal();

  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }
  //Estado donde se guarda el historico de conversaciones
  const [arrayObj, setarrayObj] = useState([]);
  const filteredConversations = useRef([])
  //Estado donde se guarda el item a buscar en objeto de historico
  const [query, setQuery] = useState('');
  //Estado donde se guarda un bool que mostrara mensaje de no historico
  const [boolHistoricoSkeleton, setboolHistoricoSkeleton] = useState(true);
  //Estado donde se guarda el conexionID del HUB
  const [conexionIDHUB, setconexionIDHUB] = useState("");
  //Estado donde se guarda la info del usuario actual que busca una conversación
  const [dataCliente, setdataCliente] = useState({})
  //Estado donde se guarda la conversación del cliente seleccionado
  const [Convesation, setConvesation] = useState([]);
  //Estado que cambia la vista de los componentes
  const [Module, setModule] = useState(1)
  //Estado donde se guarda la primera letra del Usuario
  const [latter, setlatter] = useState("");
  //Estado donde se guarda el nombre del usuario
  const { ObjChat, UpdateObjHub } = useContext(ContextDataChatIWS);
  //Estado donde hace la funcion del scroll
  const chatContainerRef = useRef(null);
  //Estado donde se guarda el correo del usuario
  // const [emailClient_, setemailClient__] = useState("")
  //Contexto con toda la información necesaria del admin
  const { ObjChatIWS, updateIWS } = useContext(ContextDataChat);
  //Estado donde se guardara bool de errores
  const [errordata, seterrodata] = useState({ bool: false, label: "" })
  //Estado donde se guardara bool de errores
  const [clientConected, setclientConected] = useState(false)
  //Estado donde se guarda el index
  const [shownav, setShownav] = useState(1);
  //Estado donde se guarda los mensajes no leidos
  const [sumNoReadTrue, setSumNoReadTrue] = useState(0);
  //Estado donde se guarda el value del mensaje
  // const [inputValue, setinputValue] = useState('');
  //Estado donde se guarda el menssage leido
  // const [read, setread] = useState('');
  //Estadp donde se guarda cuando el mensaje no se ah leido
  const [Emailbutton, setEmailbutton] = useState(false)
  const [SeEnvio, setSeEnvio] = useState(true)

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('tab1'); // Estado para almacenar la pestaña seleccionada

  const [audio] = useState(new Audio(alertAudio));


  const isMounted = useRef(true);

  const inputElement = useRef()
  const [ShowMobileChat, setShowMobileChat] = useState(false);
  const [TabModule, setTabModule] = useState(true);
  // const [bool, setbool] = useState(true)
  const [InputValue, setInputValue] = useState('');
  const [ObjChatSelected, setNameChatSelected] = useState({
    "name": "",
    "online": true,
  });
  // const [Show, setShow] = useState(true)
  // const [message, setMessage] = useState('');
  // const [boolvisibility, setboolvisibility] = useState(bool);
  // const [Loading, setLoading] = useState(ObjChatIWS.LoadingHistorico);
  // const [emailTo_, setemailTo_] = useState("")
  // const [ConnectionId_, setConnectionId_] = useState("")
  // const [UserHistory, setUserHistory] = useState("");
  const [allHistory, setAllHistory] = useState([])
  async function FechaActual() {

    // Obtener la fecha y hora actual
    const fechaHoraActual = new Date();

    // Obtener las partes individuales de la fecha y hora
    const dia = String(fechaHoraActual.getDate()).padStart(2, '0');
    const mes = String(fechaHoraActual.getMonth() + 1).padStart(2, '0');
    const anio = fechaHoraActual.getFullYear();
    const horas = String(fechaHoraActual.getHours() % 12).padStart(2, '0'); // Ajuste para mostrar en formato 12 horas
    const minutos = String(fechaHoraActual.getMinutes()).padStart(2, '0');
    const segundos = String(fechaHoraActual.getSeconds()).padStart(2, '0');
    const ampm = fechaHoraActual.getHours() >= 12 ? 'PM' : 'AM'; // Obtener AM o PM

    // Crear la cadena en el formato deseado
    const fechaHoraPersonalizada = `${dia}/${mes}/${anio} ${horas}:${minutos}:${segundos} ${ampm}`;

    return fechaHoraPersonalizada;
  }
  //evento cambio en input que toma el valor a buscar en historico
  const handleInputChange = (event) => {
    scrollToBottom();
    // let _backup = null;
    // _backup = arrayObj
    // setQuery(event.target.value);
    // if (query == '') {
    //     setarrayObj(_backup);
    // }
    // else {
    //     let list = _backup.filter(x => x.name.toLowerCase().includes(query.toLowerCase()));
    //     setarrayObj(list);
    // }
    let valueSearch = event.target.value;
    const filterHistory = filterConversationHistory(valueSearch);
    setarrayObj(filterHistory);
    filteredConversations.current = filterHistory;
    //setQuery(valueSearch);
  };
  const filterConversationHistory = useCallback((querySearch) => {
    try {
      const filterArray = allHistory.filter(e => e.name.toLowerCase().includes(querySearch.toLowerCase())
        || e.email.toLowerCase().includes(querySearch.toLowerCase()));
      setQuery(querySearch);
      return filterArray;
    } catch (e) {
      return [];
    }
  }, [allHistory, setQuery]);
  const handleClickReturn = () => {
    setShowMobileChat(!ShowMobileChat);
    dispatch(ActivateConversation(false))
  }
  const handleClick = async User => {

    async function AsignchatSelected(user) {

      updateIWS("ClientEmail", User.email);
      const oClient = {
        email: User.email,
        name: User.name,
        connectionId: User.connectionId,
        connected: false
      }
      setNameChatSelected(oClient)
      return oClient;
    }
    async function activeConversation() {

      //Activar conversación, objeto donde activara: arrayObj, atributo a cambiar: activeConversation:true
      ////console.log("Asignamos activeConversation")
      let _historia = allHistory;
      const index = allHistory.findIndex(item => item.email === oClient.email);
      _historia[index].css = "item-1";
      _historia[index].noRead = true;
      const oFound = allHistory.find(el => el.email === oClient.email);
      oFound.activeConversation = true;
      ////console.log("Retorna", oFound)
      ////console.log("Nuevo historico", _historia)
      setAllHistory(_historia);

      return oFound
    }




    //Mostrar Skeleton de carga  user.activateConversation = true
    dispatch(ActivateConversation(true))
    scrollToBottom();
    calcularSumaNoReadFalse()
    ////console.log("Contexto responsable", arrayUserConected)E
    ////console.log("Contexto usuario seleccionado", dataCliente)
    ////console.log("Asignar informacion de usaurio", User)
    const oClient = await AsignchatSelected(User);
    ////console.log("oClient", oClient)
    const oFound = await activeConversation();
    ////console.log("Datos de cliente encontrados en historico", oFound)

    //Buscar usuario en listado de conectados para ver si se encuentra en linea, si si, cambiar atributo connected a true
    ////console.log("Obtenemos usuarios conectados")
    const pathGetlistUsersConnected = `${ObjChat.api}/api/Chat/GetListUsersConnected`;
    const listUsers = await Axios.get(pathGetlistUsersConnected);
    ////console.log("Usuarios conectados", listUsers)
    const isConnected = listUsers.data.listUsers.some(x => x.email === oClient.email);
    oFound.connected = isConnected;
    oClient.connected = isConnected;
    if (isConnected) {
      const oFound__ = listUsers.data.listUsers.find(el => el.email === oClient.email);
      oClient.connectionId = oFound__.connectionID[0];
      setdataCliente(oClient);
    } else {
      //No, definir el estado del cliente, guardar nombre,correo,conexionID, connected = false
      setdataCliente({
        email: null,
        name: null,
        connectionId: null,
        connected: false
      });
    }
    //Obtener conversación de back end Servicio a consumir: GetConversationHistory, tomar del objeto el correo e igualar Convesations

    const emailResp = arrayUserConected.email;
    const emailClient = oClient.email;
    const pathGetChat = `${ObjChat.api}/api/Chat/GetChat?emailUser=${emailResp}&emailClient=${emailClient}`;
    const { data } = await Axios.post(pathGetChat);
    ////console.log(data)

    const mailResp = arrayUserConected.email;
    ////Obtener si la conversacion ya ah sido leida
    const Messagesen = `${ObjChat.api}/api/Chat/MessageSeen?emailClient=${emailClient}&mailResp=${mailResp}`;
    ////console.log(Messagesen)
    const { res } = await Axios.put(Messagesen)

    //setread(res.data)

    setConvesation(data.data);
    if (!isMobile) {
      dispatch(ActivateConversation(false))
    }
    setShowMobileChat(true);
    setclientConected(User.connected)
    scrollToBottom();
    calcularSumaNoReadFalse();
  };
  const handleClickSend = async () => {
    scrollToBottom()
    const emailFrom = arrayUserConected.email;
    const emailTo = dataCliente.email;
    let ConnectionId = dataCliente.connectionId;
    const Mesage = InputValue;
    const UserType = 'C';

    async function GetConnResponsable(emailR) {
      //////SendNotification

      let resp;
      ////console.log(emailR);
      const url = `${ObjChat.api}/api/Chat/GetConnResponsable?`;
      await axios
        .get(`${url}emailRes=${emailR}`)
        .then((res) => {
          //console.log("GetConnResponsable: ", res);
          resp = res;
        })
        .catch(function (error) {
          //console.log(error);
        });

      return resp;
    }

    let _ConnResponsable = await GetConnResponsable(emailTo);
    ////console.log("_ConnResponsable", _ConnResponsable)
    if (_ConnResponsable.data.code === 0) {
      ConnectionId = _ConnResponsable.data.data.data.connectionID[0];
      const fechaHoraPersonalizada = await FechaActual();
      let item = {
        "time": fechaHoraPersonalizada,
        "text": Mesage,
        "From": true,
        "className": "p-2 text-right to-msg",
        "timeClass": ""
      }

      var Message = { ConnectionId, emailFrom, emailTo, Mesage, Date: fechaHoraPersonalizada, UserType };
      var itemJson = JSON.stringify(Message);
      scrollToBottom();
      try {
        await ObjChatIWS.connection.invoke("SendAllMessages", itemJson);

      } catch (err) {
        console.error(err);
      }

      setConvesation(prevArray => [...prevArray, item])
      setInputValue("")
      scrollToBottom();
    }
    else {
      seterrodata({
        bool: true, label: "Lo sentimos, el cliente se acaba de desconectar"
      })
      dataCliente.connected = false;
      setclientConected(false)
      setInputValue("")
      scrollToBottom();
      setTimeout(() => {
        seterrodata({
          bool: false, label: ""
        })
      }, 5000);
    }



  }
  const handleRefreshHistory = async () => {
    calcularSumaNoReadFalse();
    setboolHistoricoSkeleton(true)
    const _historico = await FetchHistorico(props.accounts[0].username);
    if (isMounted.current) {

      setAllHistory(_historico)
      setarrayObj(_historico)
      filteredConversations.current = _historico;
      // setnoRead(_historico[0].noRead)
      setboolHistoricoSkeleton(false)
    }
  }
  const fetchRole_ = useCallback(
    (_email) => {
      async function _fetchRole_(_email) {

        let email = _email;
        let _resp;
        try {
          const TakeIdResponsable = `${ObjChat.api}/api/Services/GetRoleByEmail?`;
          await Axios
            .get(`${TakeIdResponsable}`, {
              params: {
                email
              }
            })
            .then((res) => {
              if (res.data.code === 0) {
                _resp = res.data.data

              }
              else {

                _resp = -1
              }
            })
            .catch(function (error) {
              //console.log(error);
            });
        }
        catch (err) {
          //console.log(err);

        }

        return _resp

      }

      return _fetchRole_(_email);
    },
    [ObjChat.api],
  )

  const Login = useCallback(
    async (accessToken) => {

      const Rol = await fetchRole_(ObjChatIWS.UserEmail)
      const imgsrc = await fetchImageRole_(accessToken)
      let srcimg;
      if (imgsrc === undefined || imgsrc === null) {

        srcimg = admi
      }
      else {
        srcimg = imgsrc;
      }

      let _obj =
      {
        "Nombre": ObjChatIWS.UserName,
        "email": ObjChatIWS.UserEmail,
        "conexionID": "",
        "Rol": Rol,
        "idUser": "",
        "urlpath": srcimg
      }
      return _obj
    },
    [ObjChatIWS, fetchRole_],
  )
  const FetchHistorico = useCallback(
    async (_email) => {
      let _arrayConver = []
      try {
        const GetConversationHistory = `${ObjChat.api}/api/Chat/GetConversationHistory?`;
        await Axios.post(`${GetConversationHistory}emailUser=${_email}`)
          .then((res) => {

            let leatter = res.data
            if (isMounted.current)
              setlatter(leatter)

            if (res.data.code === 0) {
              _arrayConver = res.data.data
              if (_arrayConver.length !== 0) {
                //setarrayObj(_arrayConver)
                updateIWS("Historico", _arrayConver);
                if (isMounted.current)
                  setboolHistoricoSkeleton(false)
              }
              else {
                if (isMounted.current)
                  setboolHistoricoSkeleton(true)
              }
              //Si trae hay conversacion renderizamos el arreglo
            }
          })
          .catch(function (error) {
            //console.log(error);
          });

      }
      catch (err) {
        //console.log(err);

      }

      return _arrayConver

    },
    [ObjChat.api, updateIWS],
  )
  const fetchData_ = useCallback(
    (_email) => {
      ////console.log('useCallback', _email);
      async function _fetchData_(_email) {

        let email = _email;
        let _resp;
        try {
          const TakeIdResponsable = `${ObjChat.api}/api/Services/TakeIdResponsable?`;
          await Axios
            .get(`${TakeIdResponsable}`, {
              params: {
                email
              }
            })
            .then((res) => {
              if (res.data.code === 0) {
                _resp = res.data.data
              }
              else {

                _resp = -1
              }
            })
            .catch(function (error) {
              //console.log(error);
            });
        }
        catch (err) {
          //console.log(err);

        }

        return _resp

      }
      return _fetchData_(_email);
    },
    [ObjChat.api],
  )
  const fetchImageRole_ = async (accessToken) => {

    let _resp;
    try {
      await Axios
        .get('https://graph.microsoft.com/v1.0/me/photo/$value', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          const imageBlob = new Blob([response.data], { type: response.headers['content-type'] });
          const imageUrl = URL.createObjectURL(imageBlob);
          _resp = imageUrl
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
    catch (err) {
      //console.log(err);

    }

    return _resp

  }
  const fetchToke = useCallback(
    async () => {

      const tokenRequest = {
        account: accounts[0],
        scopes: ['User.Read'],
      };
      const accessTokenResponse = await instance.acquireTokenSilent(tokenRequest);
      return accessTokenResponse.accessToken;
    },
    [accounts, instance],
  )

  const fetchConnect = useCallback(
    async (data, con) => {
      let conId = con;
      let email = data.correo;
      let idUser = data.idResponsable;
      let isClient = false;
      let response;

      try {
        const ConnectUser = `${ObjChat.api}/api/Chat/ConnectUser?`;
        await Axios
          .post(`${ConnectUser}conId=${conId}&email=${email}&idUser=${idUser}&isClient=${isClient}`)
          .then((res) => {
            //console.log("ConnectUser: ", res);
            response = res.status
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
      catch (err) {
        //console.log(err);

      }

      return response

    },
    [ObjChat.api],
  )
  const NewMessage = useCallback(

    (obj) => {
      async function _NewMessage(obj) {
        console.log("Se recibio un nuevo mensaje", obj)
        console.log("Este es el historico", arrayObj);
        console.log("Este es el context", ObjChatIWS);
        console.log("Este es el historico context", ObjChatIWS.Historico);
        console.log("dataCliente", dataCliente)
        console.log("allHistory", allHistory)
        scrollToBottom();
        async function findemail(email_) {
          //console.log("findemail")
          //console.log(email_)
          let his_ = ObjChatIWS.Historico;
          const position_ = his_.findIndex(item => item.email === email_)

          return position_
        }
        const x = await findemail(obj.de);
        const fechaHoraPersonalizada = await FechaActual();
        let item = {
          "time": fechaHoraPersonalizada,
          "text": obj.mensaje,
          "From": true,
          "className": "p-2 from-msg",
          "timeClass": ""
        }
        //Aqui revisamos si la conversacion esta activa hago push del nuevo mensaje si es que 
        //es del usuario que se tiene la conversacion
        if (ObjChatIWS.ClientEmail === obj.de) {
          setConvesation(prevArray => [...prevArray, item])
        }
        //Si existe en el historico, y hacer un update a props
        console.log("Evaluamos si ya existe o no en el historica")
        if (x === 0 || x > 0) {
          console.log("Si existe")
          let historico_temp = ObjChatIWS.Historico;
          if (ObjChatIWS.ClientEmail !== obj.de) {
            historico_temp[x].css = "Conversation-Active"

          }
          historico_temp[x].connected = obj.activo
          historico_temp[x].connectionId = obj.deConnId
          historico_temp[x].lastConversation = obj.fecha
          historico_temp[x].lastMessage = obj.mensaje

          const foundHistory = filteredConversations.current.find(e => e.email === obj.de);
          if (foundHistory) {
            // const arrHistory = filteredConversations.current;
            if (ObjChatIWS.ClientEmail !== obj.de) {
              foundHistory.css = "Conversation-Active"

            }
            // arrHistory[foundHistory].connected = obj.activo
            // arrHistory[foundHistory].connectionId = obj.deConnId
            // arrHistory[foundHistory].lastConversation = obj.fecha
            // arrHistory[foundHistory].lastMessage = obj.mensaje
            foundHistory.connected = obj.activo;
            foundHistory.connectionId = obj.deConnId;
            foundHistory.lastConversation = obj.fecha;
            foundHistory.lastMessage = obj.mensaje;

            setarrayObj([...filteredConversations.current]);
          } else {
            let item_historico = {
              "activeConversation": false,
              "connected": obj.activo,
              "connectionId": obj.deConnId,
              "country": null,
              "css": "Conversation-Active",
              "email": obj.de,
              "esMiMensaje": false,
              "lastConversation": obj.fecha,
              "lastMessage": obj.mensaje,
              "letter": "C", //aqui
              "name": obj.name, //aqui
              "noRead": "",
            }
            setarrayObj(arr => [...arr, item_historico]);
            filteredConversations.current = [item_historico, ...filteredConversations.current];
          }
        }
        else {

          console.log("No existe")
          let item_historico = {
            "activeConversation": false,
            "connected": obj.activo,
            "connectionId": obj.deConnId,
            "country": null,
            "css": "Conversation-Active",
            "email": obj.de,
            "esMiMensaje": false,
            "lastConversation": obj.fecha,
            "lastMessage": obj.mensaje,
            "letter": "C", //aqui
            "name": obj.name, //aqui
            "noRead": "",
          }
          if (arrayObj.length <= 0) {
            //const _historico = await FetchHistorico(props.accounts[0].username);
            setAllHistory(prevArray => [item_historico, ...prevArray]);
            //const arrHistory = [item_historico, ..._historico];
            filteredConversations.current = [item_historico, ...filteredConversations.current]
            setarrayObj([...filteredConversations.current]);
            updateIWS("Historico", filteredConversations.current);
          }
          else {
            setAllHistory(prevArray => [item_historico, ...prevArray]);
            const arrHistory = [item_historico, ...filteredConversations.current];
            setarrayObj([...arrHistory]);
            filteredConversations.current = [item_historico, ...filteredConversations.current]
            updateIWS("Historico", filteredConversations.current);
          }

        }
        scrollToBottom();
        reproducirAudio();
        calcularSumaNoReadFalse();
      }
      _NewMessage(obj);
      reproducirAudio();


    },

    [ObjChatIWS, updateIWS],
    // reproducirAudio()

  )
  const connectToSignalR = useCallback(
    () => {
      async function _connectToSignalR() {
        let conexionID;
        try {
          const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${ObjChat.api}/message`)
            .build();
          updateIWS("connection", newConnection)
          //setConnection(newConnection);
          await newConnection.on("NewUserConected", async (obje) => {
            //console.log("Un usuario acaba de conectarse", obje);
            ////console.log("Usuario seleccionado", dataCliente)

            // const _historico = await FetchHistorico(ObjChatIWS.UserEmail);
            // //console.log("Historico Actual", _historico);
            // setarrayObj(_historico);
            // setQuery('x');
            // setQuery('');
          });
          await newConnection.on("sendMessage", async (obj) => {
            scrollToBottom();
            calcularSumaNoReadFalse();
            if (filteredConversations.current.length === 0) {
              const _historico = await FetchHistorico(props.accounts[0].username);
              setarrayObj(_historico);
              filteredConversations.current = _historico;
              setAllHistory(_historico);
            }
            await NewMessage(obj)
          });
          await newConnection.start()
            .then(() => {
              conexionID = newConnection.connection.connectionId
            })
            .catch(error => {
              console.error(error);
            });
        } catch (error) {
          //console.log(error)
        }
        return conexionID;
      };

      return _connectToSignalR();
    },
    [ObjChat.api, NewMessage, updateIWS],
  )
  const fetchData = useCallback(
    () => {
      async function _GetData() {
        try {
          //console.log("PROPS", props)
          updateIWS("UserEmail", props.accounts[0].username)
          updateIWS("UserName", props.accounts[0].name)
          //console.log("¿Es responsable?")

          const _iduser = await fetchData_(props.accounts[0].username);
          //console.log("TakeIdResponsable", _iduser)
          if (_iduser !== -1) {
            //console.log("Si es responsable")
            //console.log("Obtenemos token para consumir graph y usar avatar teams")
            try {
              const accessToken = await fetchToke();
              //console.log("Aignamos imagen y Rol")
              const _objdatauser = await Login(accessToken);
              //console.log("_objdatauser", _objdatauser)
              if (isMounted.current)
                setarrayUserConected(_objdatauser);
              //console.log("Obtenemos Historico")
              const _historico = await FetchHistorico(props.accounts[0].username);
              //console.log(isMounted.current)
              if (isMounted.current) {

                setAllHistory(_historico)
                setarrayObj(_historico)
                filteredConversations.current = _historico;
                // setnoRead(_historico[0].noRead)
              }
              //console.log("Obtenemos ConexionID")
              if (!conexionIDHUB) {
                const _conexionID = await connectToSignalR();
                if (isMounted.current)
                  setconexionIDHUB(_conexionID);
                //console.log("Conectamos a HUB")
                const _Connectawait = await fetchConnect(_iduser, _conexionID);
                //console.log(_Connectawait)
              }

            }
            catch (e) {
              //console.log("Error en token graph")
              seterrodata({
                bool: true, label: "Error en el sistema, favor de contactar a su admninistrador"
              })
            }
          }
          else {
            //console.log("No es responsable")
            instance.logout();
          }
        }
        catch (error) {
          //console.log(error)
        }
      }

      return _GetData();
    }
    ,
    [props, fetchToke, Login, conexionIDHUB, fetchConnect, FetchHistorico, connectToSignalR, instance, fetchData_, updateIWS],
  )

  const CreatePdf = async () => {
    let resp
    let _email = ObjChatIWS.UserEmail
    let _emailclient = ObjChatIWS.ClientEmail
    let _service = user.OneService

    try {
      const CreatePdf = `${ObjChat.api}/api/Services/CreatePdf?`;
      await Axios.post(`${CreatePdf}`, { emailUser: _email, emailClient: _emailclient, service: `${_service}` })
        .then((res) => {
          resp = res.data.data.code;
          if (resp == 0) {
            setSeEnvio(true)
          } else {
            setSeEnvio(false)
          }

        })
        .catch(function (error) {
          //console.log(error);
        });

    }
    catch (err) {
      //console.log(err);

    }

    return resp
  }
  document.title = `(${user.totalNoread}) IWS Services`;
  useEffect(() => {
    calcularSumaNoReadFalse();

    //console.log('UseEffect');
    isMounted.current = true;
    async function _init() {
      scrollToBottom();


      await fetchData();
      // setQuery('x');
      // setQuery('');
      if (isMounted.current)
        setboolHistoricoSkeleton(false)
      scrollToBottom();
    }
    _init();

    return () => {
      isMounted.current = false;
    }

  }, [updateIWS]);


  // useEffect(() => {
  //     //console.log("arraObj", arrayObj);
  //     updateIWS("Historico", arrayObj);
  // }, [arrayObj]);

  const HandleChange = (event) => {
    scrollToBottom();
    setInputValue(event.target.value);
  }
  const handleEnter = (event) => {
    event.preventDefault();
    scrollToBottom();
    if (dataCliente.connected === true) {
      if (InputValue.length == 0) { } else {
        handleClickSend()
      }
    }
  }
  const onClickHandler = (e) => {

    setShownav(e);
  };
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current;
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };
  const valueMessage = () => {
    if (InputValue.length == 0) {

    } else {

      handleClickSend();
    }

  }


  const handleTabSelect = (selectedKey) => {
    setSelectedTab(selectedKey); // Actualizar el estado al seleccionar una pestaña
    console.log(selectedKey)
    dispatch(ActivateConversation(false))
    setConvesation([])
    dispatch(addUSerSelected([]))
    dispatch(SaveNameUser([]))
    //dispatch(ActivateConversation(false))
  }


  const EnviarEmail = () => {
    CreatePdf()
    setEmailbutton(true)
    setTimeout(() => {
      setEmailbutton(false)
    }, "1000");
  }

  const reproducirAudio = () => {
    audio.currentTime = 0; // Reinicia el audio a la posición inicial
    audio.play(); // Reproduce el audio
    let sum = 0;
  };

  const calcularSumaNoReadFalse = () => {
    let sumaNoReadFalse = 0;

    // Iterar sobre cada objeto en el arreglo
    allHistory.forEach(item => {
      // Verificar si la clave 'noRead' es false
      if (item.noRead === false) {
        // Incrementar la suma en 1
        sumaNoReadFalse++;
      }
    });

    dispatch(UserNoRead(sumaNoReadFalse));
    return sumaNoReadFalse;
  };

  // calcularSumaNoReadFalse()

  return (
    <div>
      <div>
        <BrowserView>
          <div className='row'>
            <div className='col-2 aside-menu bg-blue-Chat ajust-height'>
              <div>
                <div className="d-flex">
                  <div className="p-2 flex-grow-1">
                    <nav className="navbar navbar-expand-sm bg-blue-Chat tx-center">
                      <ul className="navbar-nav">
                        <li className="nav-item active">
                          <a className="nav-link" href="/">
                            <img className="logoiws" src={logo} />
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>

              </div>

              <div>
                <div className="d-flex flex-column">
                  <div className="p-2 tx-center">
                    <img className="admi" src={arrayUserConected.urlpath} />
                  </div>
                  <div className="tx-center leading-normal font-medium white-color">{ObjChatIWS.UserName}</div>
                  <div className="tx-center leading-normal font-medium gray-color text-md">{ObjChatIWS.UserEmail}</div>
                </div>

              </div>

              <div className='container-menu-left'>
                <div>
                  <div className='p-t-1'>
                    <div className="label-menu purple-color">Aplicaciones</div>
                  </div>

                  <nav className={shownav === 1 ? "nav:active" : "nav"} onClick={() => onClickHandler(1) + setModule(1)}>

                    <ul className="navbar-nav" >
                      <li className="nav-item">
                        <a onClick={() => setModule(1)} className="nav-link label-menu white-color" href="#">Chat</a>
                      </li>
                    </ul>

                  </nav>
                </div>
                {
                  arrayUserConected.Rol === "Admin" ? <div>
                    <div className='p-t-1'>
                      <div className="label-menu purple-color">Dashboards</div>
                    </div>

                    <nav className={shownav === 2 ? "nav:active" : "nav"} onClick={() => onClickHandler(2) + setModule(2)} >
                      <ul className="navbar-nav" >
                        <li className="nav-item">
                          <a onClick={() => setModule(2)} className="nav-link label-menu white-color" href="#">Metricas Principales</a>
                        </li>
                      </ul>
                    </nav>

                    <nav className={shownav === 3 ? "nav:active" : "nav"} onClick={() => onClickHandler(3) + setModule(3)} >
                      <ul className="navbar-nav" >
                        <li className="nav-item">
                          <a onClick={() => setModule(3)} className="nav-link label-menu white-color" href="#">Reportes</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                    : ""
                }
                <nav className="nav" onClick={() => instance.logout()}>
                  <ul className="navbar-nav" >
                    <li className="nav-item">
                      <a className="nav-link label-menu white-color" href="#">Log out</a>
                    </li>
                  </ul>
                </nav>
                <div className='card-footer'></div>
              </div>

            </div>
            <div className='col-10 nopadding-nomargin'>
              <div className='header-container'>

                {
                  errordata.bool ?
                    <div className="alert alert-warning">
                      <strong>Error: {errordata.label}</strong>
                    </div> : <div>
                      <div className={shownav === 1 ? "label-menu2:active" : "label-menu2"}>Chat</div>
                      <div className={shownav === 2 ? "label-menu2:active" : "label-menu2"}>METRICAS PRINCIPALES</div>
                      <div className={shownav === 3 ? "label-menu2:active" : "label-menu2"}>Reportes</div>
                    </div>
                }
                {
                  Emailbutton &&
                  <div className="alert alert-warning">
                    {
                      SeEnvio ?
                        <Alert className="alert alert-primary">
                          Se ah enviado la conversacion exitosamente.
                        </Alert> : <Alert className="alert alert-primary">
                          No se enviado la conversacion.
                        </Alert>
                    }

                  </div>
                }

              </div>
              {
                Module === 1 &&
                <div className='row'>
                  <div className='col-lg-5 col-md-5 col-sm-12'>
                    <div className='container p-t-1 '>

                      <Tabs
                        defaultActiveKey="MC"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        onSelect={handleTabSelect}
                      >
                        <Tab eventKey="MC" title={`Mis conversaciones (${user.totalNoread})`}>
                          <div >
                            <div className="search-chat">
                              <Card className="search-container">
                                <Form className="p-0">
                                  <Col className="p-0">
                                    <div className='row'>
                                      <div className='col-11'>
                                        <Form.Control
                                          type="text"
                                          placeholder="Buscar por correo o nombre..."
                                          className=" mr-sm-2"
                                          onChange={handleInputChange}
                                        />
                                      </div>
                                      <div className='col-1 d-flex align-items-center'>
                                        <div>
                                          <IconButton onClick={handleRefreshHistory}>
                                            <RefreshIcon />
                                          </IconButton>

                                        </div>

                                      </div>
                                    </div>

                                  </Col>
                                </Form>
                                <ul className="users-chat-msg mt-1">
                                  {
                                    !boolHistoricoSkeleton ?
                                      arrayObj.length > 0 ? arrayObj.map(
                                        (UserChats, index) =>
                                          <li ref={inputElement} key={index} className={UserChats.noRead ? UserChats.css : "Conversation-Active"} onClick={() =>
                                            handleClick(UserChats)
                                          }>
                                            <div className="Avatar-name">
                                              <span>{UserChats.letter.toUpperCase()}</span>
                                            </div>


                                            <div className="Name-User">
                                              <h1 className={UserChats.noRead ? "leading-normal font-medium text-md" :
                                                "leading-normal font-medium text-md font-bold-chat-admin"}>{UserChats.name}</h1>
                                              <span className='leading-normal text-smll'>{UserChats.email}</span>
                                              <span className="leading-normal text-smll">{UserChats.lastMessage}</span>
                                            </div>
                                            <span className='leading-normal text-smll'>{UserChats.lastConversation}</span>
                                          </li>) : <ChatNoHistory />
                                      :
                                      <Stack className='p-t-3' spacing={1}>
                                        <Skeleton variant="rounded" height={500} />
                                      </Stack>
                                  }
                                </ul>
                              </Card>
                            </div>
                          </div>
                        </Tab>
                        <Tab eventKey="MT" title="Mis equipos" >

                          <Miequipo />
                        </Tab>
                      </Tabs>

                    </div>
                  </div>
                  {
                    user.activateConversation ?
                      <div className='col-lg-7 col-md-7 col-sm-12'>
                        <div >
                          <div>
                            <div className="msg-admin-chat">
                              <div className="header-msg-admin">
                                <div className="d-flex p-3">
                                  <div className="p-2"><img className="chatAvatar" src={chatAvatar} /></div>
                                  <div className="p-2"><label className='font-medium black-chat'>{user.savenameuser}</label>
                                  </div>
                                </div>
                                {/* <div className='email' >
                                    <button type="button" onClick={EnviarEmail}>
                                      <img className='enviar_email' src={email} />
                                    </button>
                                  </div> */}
                              </div>

                              <div id="body-msg-admin" className="body-msg-admin autoscroll-content">
                                <div>
                                  <ul className="users-chat-msgi" >
                                    <div className="d-flex flex-column">
                                      {
                                        user.UserSelected.map(
                                          (Chat, index) =>
                                            <div key={index} className="row">
                                              <div className='col-12'>
                                                <div className={Chat.className}>
                                                  <div className="Name-User">
                                                    <span>{Chat.text}</span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className='col-12'>
                                                <label className="black-letter-chat small-text-chat">{Chat.time}</label>
                                              </div>
                                            </div>)
                                      }
                                    </div>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div> :
                      <div className='col-lg-7 col-md-7 col-sm-12'>
                        {
                          user.activateConversation === true ?
                            <Stack className='p-t-3' spacing={2}>
                              <Skeleton variant="rounded" height={500} />
                            </Stack>
                            :
                            Object.entries(Convesation).length !== 0 ?
                              <div>
                                <div className="msg-admin-chat">
                                  <div className="header-msg-admin">
                                    <div className="d-flex p-3">
                                      <div className="p-2"><img className="chatAvatar" src={chatAvatar} /></div>
                                      <div className="p-2"><label className='font-medium black-chat'>{ObjChatSelected.name}</label>
                                        {dataCliente.connected ?

                                          <div className="online-admi">
                                            <span>
                                              <h5 className='text-md gray-color'>Online</h5>
                                            </span>
                                          </div> :
                                          <div className="offline-admi" >
                                            <span>
                                              <h5 className='text-md gray-color'>Offline</h5>
                                            </span>
                                          </div>
                                        }</div>



                                    </div>
                                    <div className='email'>
                                      <button title='Enviar conversacion' type="button" className='enviar_mail' onClick={EnviarEmail}>
                                        <img className='enviar_email' src={email} />
                                      </button>
                                    </div>
                                  </div>

                                  <div id="body-msg-admin" className="body-msg-admin autoscroll-content">
                                    <div>
                                      <ul className="users-chat-msgi" ref={chatContainerRef}>
                                        <div className="d-flex flex-column">
                                          {
                                            Convesation.map(
                                              (Chat, index) =>
                                                <div key={index} className="row">
                                                  <div className='col-12'>
                                                    <div className={Chat.className}>
                                                      <div className="Name-User">
                                                        <span>{Chat.text}</span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className='col-12'>
                                                    <label className="black-letter-chat small-text-chat">{Chat.time}</label>
                                                  </div>
                                                </div>)
                                          }
                                        </div>
                                      </ul>
                                    </div>
                                  </div>


                                  <div className="footer-msg-admin">
                                    {
                                      clientConected ?

                                        <div className="d-flex input-msg-chat">
                                          <div className="p-2 flex-grow-1">
                                            <Form className="padre-input" onSubmit={handleEnter}>
                                              <Col className="input">
                                                <Form.Control
                                                  type="text"
                                                  placeholder=" Escribe aquí tu mensaje...."
                                                  className=" mr-sm-3"
                                                  value={InputValue}
                                                  onChange={HandleChange}
                                                />
                                              </Col>
                                            </Form>
                                          </div>
                                          <div className="p-2">
                                            <div className="button-input chat-input-btn">
                                              <button type="submit" onClick={() =>
                                                valueMessage()
                                              }>  <FontAwesomeIcon className="icon-paperplane" icon={faPaperPlane} /></button>
                                            </div></div>
                                        </div>

                                        : ""
                                    }
                                  </div>
                                </div>
                              </div>
                              :
                              <div className='Container'>
                                <div className='container container-middle'>
                                  <FontAwesomeIcon className="iconPersonBlue-chat" icon={faMessage} />
                                  <h3>Selecciona una conversación</h3>
                                </div>

                              </div>

                        }
                      </div>
                  }
                </div>

                // <div className='container'>
                //   <ChatDashboard />
                // </div>
              }
              {
                Module === 2 &&
                <div className='container'>
                  <ChatDashboard />
                </div>

              }
              {
                Module === 3 &&
                <div className='container'>
                  <ReportsChatBot />
                </div>
              }

            </div>
          </div>
        </BrowserView>
        <MobileView>
          <Navbar expand="lg" className="bg-body-tertiary bg-blue-Chat">
            <Container>
              <Navbar.Brand href="/"><img className="logoiws" src={logo} /></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link onClick={() => { setModule(1); setTabModule(true); }} >Chat</Nav.Link>
                  {arrayUserConected.Rol === "Admin" ? <Nav.Link onClick={() => { setModule(2); setTabModule(false); }}>Dashboard</Nav.Link> : ""}
                  {arrayUserConected.Rol === "Admin" ? <Nav.Link onClick={() => { setModule(3); setTabModule(false); }} >Reportes</Nav.Link> : ""}
                  <Nav.Link onClick={() => instance.logout()} >Logout</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          {
            Emailbutton ?
              <div className="alert alert-warning">
                {
                  SeEnvio ?
                    <Alert className="alert alert-primary">
                      Se ah enviado la conversacion exitosamente.
                    </Alert> : <Alert className="alert alert-primary">
                      No se enviado la conversacion.
                    </Alert>
                }

              </div> : null
          }

          <Container className='mobile'>

            {
              TabModule &&
              <Tabs
                defaultActiveKey="MC"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab title="Mis conversaciones" eventKey="MC">
                  <div className='container p-t-1 '>
                    <div>
                      {
                        user.activateConversation === true
                          ?

                          boolHistoricoSkeleton ?

                            <Stack className='p-t-3' spacing={2}>
                              <Skeleton variant="rounded" height={500} />
                            </Stack>
                            :
                            Object.entries(Convesation).length !== 0 ?
                              <div>
                                <div className="msg-admin-chat mobile-chat-admin">
                                  <div className="header-msg-admin">

                                    <div className='hader-mobile-left'>
                                      <h2 className='fs-20 f-bold'> <span onClick={() =>
                                        handleClickReturn()

                                      }><FontAwesomeIcon className="icon-faArrowCircleLeft" icon={faArrowCircleLeft} /> </span>{ObjChatSelected.name}</h2>
                                      <br></br>
                                      {dataCliente.connected ?

                                        <div className="online-admi">
                                          <span>
                                            <h5 className='text-md gray-color'>Online</h5>
                                          </span>
                                        </div> :
                                        <div className="offline-admi" >
                                          <span>
                                            <h5 className='text-md gray-color'>Offline</h5>
                                          </span>
                                        </div>
                                      }
                                    </div>
                                    <div className='email' >
                                      <button type="button" onClick={EnviarEmail}>
                                        <img className='enviar_email' src={email} />
                                      </button>
                                    </div>
                                  </div>
                                  <div id="body-msg-admin" className="body-msg-admin autoscroll-content">
                                    <div>
                                      <ul className="users-chat-msgi" ref={chatContainerRef}>
                                        <div className="d-flex flex-column">
                                          {

                                            Convesation.map(
                                              (Chat, index) =>
                                                <div key={index}>
                                                  <div className={Chat.className}>

                                                    <div className="Name-User">
                                                      <span>{Chat.text}</span>
                                                    </div>
                                                  </div>
                                                  <label className="black-letter-chat small-text-chat">{Chat.time}</label>
                                                </div>)
                                          }
                                        </div>
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="footer-msg-admin">
                                    {
                                      clientConected ?
                                        <div className="d-flex input-msg-chat">
                                          <div className="p-2 flex-grow-1">
                                            <Form className="padre-input" onSubmit={handleEnter}>
                                              <Col className="imput">
                                                <Form.Control
                                                  type="text"
                                                  placeholder=" Escribe aquí tu mensaje...."
                                                  className=" mr-sm-3"
                                                  value={InputValue}
                                                  onChange={HandleChange}
                                                />
                                              </Col>
                                            </Form>
                                          </div>
                                          <div className="p-2">
                                            <div className="button-input chat-input-btn">
                                              <button onClick={() =>
                                                valueMessage()
                                              }>  <FontAwesomeIcon className="icon-paperplane" icon={faPaperPlane} /></button>
                                            </div></div>
                                        </div> : ""
                                    }

                                  </div>
                                </div>
                              </div>
                              :
                              <div className='Container'>
                                <div className='container container-middle'>
                                  <FontAwesomeIcon className="iconPersonBlue-chat" icon={faMessage} />
                                  <h3>Selecciona una conversación</h3>
                                </div>

                              </div>

                          :

                          Module === 1 &&

                          <div className="search-chat">

                            <Card className="search-container">
                              <Form className="p-0">
                                <Col className="p-0">
                                  <div className='row'>
                                    <div className='col-10'>
                                      <Form.Control
                                        type="text"
                                        placeholder="Buscar por correo o nombre..."
                                        className=" mr-sm-2"
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                    <div className='col-2 d-flex align-items-center'>
                                      <div>
                                        <IconButton onClick={handleRefreshHistory}>
                                          <RefreshIcon />
                                        </IconButton>

                                      </div>

                                    </div>
                                  </div>
                                </Col>
                              </Form>
                              <ul className="users-chat-msg">
                                {
                                  !boolHistoricoSkeleton ?
                                    arrayObj.length > 0 ? arrayObj.map(
                                      (UserChats, index) =>
                                        <li ref={inputElement} key={index}
                                          className={UserChats.noRead ? UserChats.css : "Conversation-Active"} onClick={() =>
                                            handleClick(UserChats)
                                          }>
                                          <div className="Avatar-name">
                                            <span>{UserChats.letter.toUpperCase()}</span>
                                          </div>
                                          <div className="Name-User">
                                            <h1 className={UserChats.noRead ? "leading-normal font-medium text-md" : "leading-normal font-medium text-md font-bold-chat-admin"}>{UserChats.name}</h1>
                                            <span className='leading-normal text-smll'>{UserChats.email}</span>
                                            <span className='leading-normal text-smll'>{UserChats.lastMessage}</span>
                                            <span className='leading-normal text-smll'>{UserChats.lastConversation}</span>
                                          </div>

                                        </li>) : <ChatNoHistory />
                                    :

                                    <Stack className='p-t-3' spacing={1}>
                                      <Skeleton variant="rounded" height={500} />
                                    </Stack>
                                }
                              </ul>
                            </Card>
                          </div>
                      }

                    </div>
                  </div>
                </Tab>
                <Tab eventKey="MT" title="Mis equipos"  >
                  <Miequipo />
                </Tab>
              </Tabs>
            }
            {
              Module === 2 &&
              <div className='container'>
                <ChatDashboard />
              </div>

            }
            {
              Module === 3 &&
              <div className='container'>
                <ReportsChatBot />
              </div>
            }

          </Container>
        </MobileView>
      </div >
    </div >
  );
});

export default InterfazChatBoot;