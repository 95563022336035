import React, { useContext, useEffect, useState } from 'react';
import Pie from '../ChatBotInterfaz/graphs/Pie'
import VerticalBarChart from '../ChatBotInterfaz/graphs/VerticalBarChart'
import axios from "axios";
import { ContextDataChatIWS } from "../../index"
import { Table, Form } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

function ChatDashboard() {
    const { ObjChat, setObjChat } = useContext(ContextDataChatIWS);
   
    const [dataServices, setDataServices] = useState({});
    const [dataServExist, setDataServExist] = useState(false);

    const [dataCountries, setDataCountries] = useState({});
    const [dataCountriesExist, setDataCountriesExist] = useState(false);

    const [paises, setPaises] = useState([]);
    const [servicios, setServicios] = useState([]);

    const [dataClients, setDataClients] = useState([]);

    const [dataInteraction, setDataInteraction] = useState([]);
    const [dataInteractionExist, setDataInteractionExist] = useState(false);

    const yearss = [];
    const dateNow = new Date();
    const currentYear = dateNow.getFullYear();

    yearss.push(currentYear - 3);
    yearss.push(currentYear - 2);
    yearss.push(currentYear - 1);
    yearss.push(currentYear);
    
    const [years, setYears] = useState(yearss);
    
    const [days, setDays] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState(0);

    const [selectedYear, setSelectedYear] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(0);

    axios.defaults.headers.common['Api_Key'] = 'J7rTm2LpH9qA6wZ8xN3cE1bD4';
    axios.defaults.headers.post['Api_Key'] = `J7rTm2LpH9qA6wZ8xN3cE1bD4`;

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Tráfico de Usuarios',
            font: {
                size: 20
            }
        }
          
        },
      };
    const monthsInitial = [{value: 1, name:"Enero"}, {value:2, name:"Febrero"},
    {value: 3, name:"Marzo"},{value: 4, name:"Abril"},
    {value:5, name:"Mayo"},{value:6,name:"Junio"},
    {value:7, name:"Julio"},{value:8, name:"Agosto"},
    {value:9, name:"Septiembre"},{value: 10, name:"Octubre"}
    ,{value: 11, name:"Noviembre"},{value:12, name:"Diciembre"}];

    const [months, setMonths] = useState(monthsInitial);
    
    const getDataServices = async()=>{
        const res = await axios.get(`${ObjChat.api}/api/Services/ServicesMoreRequested`);
        
        if(res.data.code === 0){
            const dataServices2 = res.data.data;
           
            let labels = dataServices2.map(e => e.descripcion);
            let totalVisitas = dataServices2.map(e=> e.totalVisitas);
           
            const data = {
                 labels: labels,
                 datasets: [
                    {
                        label: '#Servicios',
                        data: totalVisitas,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 2,
                    },
                ],
            };
            setDataServices(data);
            setDataServExist(true);
        }
    }

    const getServices = async()=>{
        const res = await axios.get(`${ObjChat.api}/api/Services/BotServices`);
        
        if(res.data.code === 0){
            setServicios(res.data.data.data);
        }
    }
    const getPaises = async()=>{
        const res = await axios.get(`${ObjChat.api}/api/Services/BotCountries`);
        
        if(res.data.code === 0){
            setPaises(res.data.data.data);
        }
    }
    const getDataCountries = async()=>{
        const res = await axios.get(`${ObjChat.api}/api/Services/InfoByCountryGraph`);
        
        if(res.data.code === 0){
            const dataServices2 = res.data.data.filter(e => e.totalVisitas > 0);
            
            let labels = dataServices2.map(e => e.pais);
            let totalVisitas = dataServices2.map(e=> e.totalVisitas);
           
            const data = {
                 labels: labels,
                 datasets: [
                    {
                        label: '#Países',
                        data: totalVisitas,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(223, 80, 102, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(255, 99, 132, 0.2)',

                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                            'rgba(255, 99, 132, 1)',
                        ],
                        borderWidth: 2,
                    },
                ],
            };
            setDataCountries(data);
            setDataCountriesExist(true);
        }else{
            console.error(res);
        }
    }

    const getDataTimesResponse = async()=>{
        const res = await axios.get(`${ObjChat.api}/api/Services/TimesResponse`);
        
        if(res.data.code === 0){
            const data = res.data.data;
            
            setDataClients(data);
           
        }
    }
    const getDataInteractions = async(Year, Month, day)=>{
        let KeyForSearch = 0;
        if(Year > 0){
            KeyForSearch = 1;
        }
        if(Month > 0){
            KeyForSearch = 2;
        }
        if(day > 0){
            KeyForSearch = 3;
        }
        const res = await axios.get(`${ObjChat.api}/api/Services/InteractionsByDate`, {params: {KeyForSearch, Year, Month,day}});
        
        if(res.data.code === 0){
            const data = res.data.data;
            
            const dataWithDate = data.map(e => {
                const dateString = e.ultimaInteraccion;
                const dateI = new Date(dateString);
                e.year = dateI.getFullYear();
                e.month = dateI.getMonth() + 1;
                e.day = dateI.getDate();
                return e;
            });

            const labels = [];
            const dataGraph = [];

           if(KeyForSearch === 0){
               const groupByYear = dataWithDate.reduce((acumulador, item) => {
               const { year } = item;
                 
                if (!acumulador[year]) {
                    acumulador[year] = []; // Inicializa un array si no existe 
                }
                
                acumulador[year].push(item); 
                return acumulador;
                }, {});
                 
                for (const propiedad in groupByYear) {
                    labels.push(propiedad);
                    dataGraph.push(groupByYear[propiedad].length);
                }
           }else if(KeyForSearch === 1){
              const groupByMonth = dataWithDate.reduce((acumulador, item) => {
                const { month } = item;
              
                if (!acumulador[month]) {
                  acumulador[month] = []; // Inicializa un array si no existe para esa edad
                }
              
                acumulador[month].push(item); // Agrega la persona al array correspondiente
                
                return acumulador;
              }, {});

              for (const propiedad in groupByMonth) {
                
                labels.push(months.find(e => e.value == propiedad).name);
                dataGraph.push(groupByMonth[propiedad].length);
              }
           }else if(KeyForSearch === 2){
            const groupByDay = dataWithDate.reduce((acumulador, item) => {
              const { day } = item;
            
              if (!acumulador[day]) {
                acumulador[day] = []; // Inicializa un array si no existe para esa edad
              }
            
              acumulador[day].push(item); // Agrega la persona al array correspondiente
              
              return acumulador;
            }, {});

            for (const propiedad in groupByDay) {
              
              labels.push(propiedad);
              dataGraph.push(groupByDay[propiedad].length);
            }
         }

              const oDate = {
                labels,
                dataGraph
              };
            setDataInteractionGraph(oDate)
        }
    }

    const setDataInteractionGraph = (oData)=>{
        const data = {
            labels: oData.labels,
            datasets: [
               {
                   label: '#Visitas',
                   data: oData.dataGraph,
                   backgroundColor: [
                       'rgba(255, 99, 132, 0.2)',
                       'rgba(54, 162, 235, 0.2)',
                       'rgba(255, 206, 86, 0.2)',
                       'rgba(75, 192, 192, 0.2)',
                       'rgba(153, 102, 255, 0.2)',
                       'rgba(255, 159, 64, 0.2)',
                       'rgba(223, 80, 102, 0.2)',
                       'rgba(54, 162, 235, 0.2)',
                       'rgba(255, 206, 86, 0.2)',
                       'rgba(75, 192, 192, 0.2)',
                       'rgba(153, 102, 255, 0.2)',
                       'rgba(255, 159, 64, 0.2)',
                       'rgba(255, 99, 132, 0.2)',
                       'rgba(54, 162, 235, 0.2)',
                       'rgba(255, 206, 86, 0.2)',
                       'rgba(75, 192, 192, 0.2)',
                       'rgba(153, 102, 255, 0.2)',
                       'rgba(255, 159, 64, 0.2)',
                       'rgba(255, 99, 132, 0.2)',

                   ],
                   borderColor: [
                       'rgba(255, 99, 132, 1)',
                       'rgba(54, 162, 235, 1)',
                       'rgba(255, 206, 86, 1)',
                       'rgba(75, 192, 192, 1)',
                       'rgba(153, 102, 255, 1)',
                       'rgba(255, 159, 64, 1)',
                       'rgba(255, 99, 132, 1)',
                       'rgba(54, 162, 235, 1)',
                       'rgba(255, 206, 86, 1)',
                       'rgba(75, 192, 192, 1)',
                       'rgba(153, 102, 255, 1)',
                       'rgba(255, 159, 64, 1)',
                       'rgba(255, 99, 132, 1)',
                       'rgba(54, 162, 235, 1)',
                       'rgba(255, 206, 86, 1)',
                       'rgba(75, 192, 192, 1)',
                       'rgba(153, 102, 255, 1)',
                       'rgba(255, 159, 64, 1)',
                       'rgba(255, 99, 132, 1)',
                   ],
                   borderWidth: 2,
               },
           ],
       };
         setDataInteraction(data);
         setDataInteractionExist(true);
    }

    
    
    const getDaysForSelect = ()=>{
        const date = new Date();
        const primerDia = 1;
        const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        
        const days = [];
        for (let index = primerDia; index <= ultimoDia; index++) {
           days.push(index);
        }

        return days;
    }

    const handleChangeCountries = (event)=>{
        const {value} = event.target;
        console.log(value);
        if(!isNaN(value)){
            const pais = paises.find(e => e.value === parseInt(value));
            if(pais){
                setSelectedCountry(pais);
            }
            console.log('Select países',pais.label);
        }else{
            setSelectedCountry(0);
        }
        
    }

    const handleChangeMonth = (event)=>{
        const {value} = event.target;
        console.log(value);
        if(!isNaN(value)){
            const valueInt = parseInt(value);
            setSelectedMonth(valueInt);
            getDataInteractions(selectedYear, valueInt,0);
        }else{
            setSelectedMonth(0);
            getDataInteractions(selectedYear, 0,0);
        }

        
        
    }

    const handleChangeYear = (event)=>{
        const {value} = event.target;
        const valueInt = parseInt(value);

        setSelectedYear(valueInt);
        getDataInteractions(valueInt, selectedMonth,0);

    }

    const filterDataClients = ()=>{
        let filtered = dataClients;
        if(selectedCountry != 0){
            filtered = dataClients.filter(e => e.pais === selectedCountry.label);
        }else{
            filtered = dataClients;

        }

        return filtered;
    }
    useEffect(async() =>{
        getDataTimesResponse();
        const dateNow = new Date();
        getDataInteractions(dateNow.getFullYear(), 0,0);
        setSelectedYear(dateNow.getFullYear());

        await getServices();
        await getPaises();
        await getDataServices();
        await getDataCountries();
    }, []);
    return (

        <div className='container'>
            <div className='row'>
               

               {
                dataServExist &&
                    <div className='col-6 d-flex justify-content-center'>
                        
                        <Pie data={dataServices} title={'Servicios más solicitados'}/>
                    </div>
               }

                {
                dataCountriesExist &&
                    <div className='col-6 d-flex justify-content-center'>
                        <Pie data={dataCountries} title={'Interacciones por país'}/>
                    </div>
               }
               
            </div>
            <div className='row mt-5 d-flex justify-content-center'>
            {
                dataInteractionExist &&
                    <div className='col-8'>
                        <div className='row'>
                            <div className='col-4'>
                                <Form.Select aria-label="Default select example" value={selectedYear} onChange={handleChangeYear}>
                                    
                                    {
                                        years.map(e => {
                                            return <option key={e} value={e}>{e}</option>
                                        })
                                    }
                                </Form.Select>
                            </div>
                            <div className='col-4'>
                                <Form.Select aria-label="Default select example" onChange={handleChangeMonth} value={selectedMonth}>
                                    <option key={0}>Mes</option>
                                    {
                                        months.map(e => {
                                            return <option key={e.value} value={e.value}>{e.name}</option>
                                        })
                                    }
                                </Form.Select>
                            </div>
                            
                        </div>
                        <div className='mt-3 d-flex justify-content-center'>
                            <Line options={options} data={dataInteraction} />

                        </div>
                    </div>
               }
            </div>

            <div className='row mb-3 mt-3'>
                <h2 className='fs-4 mt-5'>Tiempo de respuesta de Responsables</h2>
                <div className='col-4'>
                    <label htmlFor=""></label>
                   
                </div>
                <div className='col-4'>
                    <label htmlFor=""></label>
                    <Form.Select aria-label="Default select example" onChange={handleChangeCountries}>
                        <option key={0}>Países</option>
                        {
                            paises.map(e => {
                                return <option key={e.value} value={e.value}>{e.label}</option>
                            })
                        }
                    </Form.Select>
                </div>
                

            </div>
            <div className='row my-3'>
                    
                    <Table striped bordered hover responsive id='content-table'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Correo</th>
                                <th>País</th>
                                <th>Tiempo de respuesta</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filterDataClients().map((e, index) => {
                                return  <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{e.nombre}</td>
                                                <td>{e.client}</td>
                                                <td>{e.pais}</td>
                                                <td>{e.tiempoRespuesta}</td>
                                            </tr>
                                })
                            }
                            
                        
                        </tbody>
                    </Table>
               
            </div>
        </div>

    );
}


export default ChatDashboard;