import React from 'react'
import { useTranslation } from "react-i18next";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const argentina = 'https://cdn.iwsservices.com/Contacto/Argentina.png'
const brasil = 'https://cdn.iwsservices.com/Contacto/Brasil.png'
const canada = 'https://cdn.iwsservices.com/Contacto/Canadá.png'
const chile = 'https://cdn.iwsservices.com/Contacto/Chile.png'
const colombia = 'https://cdn.iwsservices.com/Contacto/Colombia.png'
const costaRica = 'https://cdn.iwsservices.com/Contacto/Costa Rica.png'
const ecuador ='https://cdn.iwsservices.com/Contacto/Ecuador.png'
const elSalvador = 'https://cdn.iwsservices.com/Contacto/El Salvador.png'
const guatemala = 'https://cdn.iwsservices.com/Contacto/Guatemala.png'
const honduras = 'https://cdn.iwsservices.com/Contacto/honduras.png'
const mexico = 'https://cdn.iwsservices.com/Contacto/México.png'
const panama = 'https://cdn.iwsservices.com/Contacto/Panamá.png'
const paraguay = 'https://cdn.iwsservices.com/Contacto/Paraguay.png'
const peru ='https://cdn.iwsservices.com/Contacto/Perú.png'
const repDominicana = 'https://cdn.iwsservices.com/Contacto/Rep. Dominicana.png'
const uruguay = 'https://cdn.iwsservices.com/Contacto/Uruguay.png'
const usa = 'https://cdn.iwsservices.com/Contacto/USA.png'
const nicaragua = 'https://cdn.iwsservices.com/Contacto/nicaragua.png'
const bolivia = 'https://cdn.iwsservices.com/Contacto/bolivia.png'



export const ContactChild2 = (props) => {

  const [t, i18n] = useTranslation('common');

  const areas =
    [
      { country: "USA:", cellphone: "+1 855 273 5975" },
      { country: "México:", cellphone: "(+52)55-8977-6528" },
      { country: "Guatemala:", cellphone: "(+502) 227-86336 " },
      { country: "El Salvador:", cellphone: "(+503) 2113-0242" },
      { country: "Costa Rica:", cellphone: "(+506) 4103-2166" },
      { country: "Panamá:", cellphone: "(+507) 833 6273" },
      { country: "Colombia:", cellphone: "(+57) 60-1580-0227" },
      { country: "Ecuador:", cellphone: "(+593) 9-6387-1028" },
      { country: "Perú:", cellphone: "(+51) 1706-8433" },
      { country: "Chile:", cellphone: "(+56) 2-2712-8087" },
      { country: "Argentina:", cellphone: "0 800 122 0928" },
      { country: "Praguay:", cellphone: "00 980 052 10114" },
      { country: "Uruguay:", cellphone: "000 4052 96720" },
    ]
  const domainName = window.location.hostname;
  const divs = areas.map((data, index) =>
    <a className='tooltip-area' key={index}>
      <div className='tooltip-box lh-1'>
        <p className='fw-bold'>{data.country}</p>
        <span>{data.cellphone}</span>
      </div>
    </a>
  );
  return (
    <div className='row justify-content-center position-relative heighTabs'>
      <div className='col-12 col-lg-6'>
        <div className='col-12 col-sm-12 col-md-10 col-lg-12 col-xl-12 col-xxl-12 position-relative' id="areas">
          <img src={props.image} alt="" className='img-fluid' />
        </div>
      </div>
      <div className='col-12 col-lg-6'>
        <div className='row'>
          <div className='col-6 pb-5 d-none d-lg-block'>
            <p className='text-27-light'>{t('contact.contacta')}</p>
            <span className='bold-title-contact'>{t('contact.oficinas2')}</span>
          </div>
          <div className='col-12 pb-5 d-block d-lg-none pt-5'>
            <p className='text-27-light mb-0 pb-0'>{t('contact.contacta')}</p>
            <span className='bold-title-contact mt-1 p-0'>{t('contact.oficinas2')}</span>
          </div>
          <div className='h-100'></div> 

          {/* {!domainName.includes('redinet.cr') === false &&
            <> */}
              <Tabs
                defaultActiveKey="norte"
                id="uncontrolled-tab-example"
                className="mb-3"
                fill
              >
                <Tab eventKey="norte" title={t('contact.norteAM')}>
                    <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center mb-2'>
                    <img src={mexico} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.mexico')} </span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a onclick="return gtag_report_conversion('tel:+525589776528');" href="tel:+525589776528" class="span-Contact1 m-0 p-0">{t('contact.oficina')} (+52) 55 8977-6528</a>
                    {/* <a onclick="return gtag_report_conversion('tel:8009676547');" href="tel:8009676547" class="span-Contact1 m-0 p-0">{t('contact.gratuito')} 800 9676 547</a> */}
                  </div>
                  <div className='h-100  mb-2'></div>
                  <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center mb-2'>
                    <img src={usa} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.eua')}</span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a onclick="return gtag_report_conversion('tel:+19733214062');" href="tel:+19733214062" class="span-Contact1">{t('contact.oficina')}  (+1) 973 321 4062</a>
                    {/* <a onclick="return gtag_report_conversion('tel:18552735975');" href="tel:18552735975" class="span-Contact1">{t('contact.gratuito')} 1 855 273 5975</a> */}
                  </div>
                  <div className='h-100 mb-2'></div>
                  <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center mb-2'>
                    <img src={canada} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.canada')}</span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a onclick="return gtag_report_conversion('tel:+16478460858');" href="tel:+16478460858" class="span-Contact1">{t('contact.oficina')}  (+1) 647 846 0858</a>
                  </div>
                  <div className='h-100 mb-2'></div>
                </Tab>
                <Tab eventKey="centro" title={t('contact.centroAM')}>
                    <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center'>
                    <img src={costaRica} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.costa')} </span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a onclick="return gtag_report_conversion('tel:+50641032166');" href="tel:+50641032166" class="span-Contact1">{t('contact.oficina')} (+506) 4103 2166</a>
                    {/* <a onclick="return gtag_report_conversion('tel:8000521713');" href="tel:8000521713" class="span-Contact1">{t('contact.gratuito')} 800 052 1713</a> */}
                  </div>

                  <div className='h-100  mb-2'></div>
                  <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center'>
                    <img src={panama} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.panama')} </span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a onclick="return gtag_report_conversion('tel:+5078336273');" href="tel:+5078336273" class="span-Contact1">{t('contact.oficina')} (+507) 833 6273</a>
                    {/* <a onclick="return gtag_report_conversion('tel:01100800052054');" href="tel:01100800052054" class="span-Contact1">{t('contact.gratuito')} 011 00 800 052 054</a> */}
                  </div>
                  <div className='h-100  mb-2'></div>
                  <div className='col-lg-12 col-md-4 text-end d-flex justify-content-start align-items-center'>
                    <img src={elSalvador} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.salvador')} </span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a onclick="return gtag_report_conversion('tel:+50321130242');" href="tel:+50321130242" class="span-Contact1">{t('contact.oficina')} (+503) 2113 0242</a>
                    {/* <a onclick="return gtag_report_conversion('tel:8006300');" href="tel:8006300" class="span-Contact1">{t('contact.gratuito')} 800 6300</a> */}
                  </div>
                  <div className='h-100 mb-2'></div>
                  <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center'>
                    <img src={guatemala} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.guatemala')} </span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a onclick="return gtag_report_conversion('tel:+50222786336');" href="tel:+50222786336" class="span-Contact1">{t('contact.oficina')} (+502) 227-86336</a>
                    {/* <a href="tel:+50222786336" class="span-Contact1">GRATUITO: (+502) 227-86336</a> */}
                  </div>
                  <div className='h-100 mb-2'></div>
                  <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center mb-2'>
                    <img src={nicaragua} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.nicaragua')}</span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a href="tel:+50575137655" class="span-Contact1">{t('contact.oficina')} (+505) 7513 - 7655</a>
                  </div>

              <div className='h-100  mb-2'></div>
              
             
              {/* Honduras */}
              <div className='h-100 mb-3'></div>
              <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center'>
                <img src={honduras} alt="" height="40px" width="40px" />
                <p className='m-0 p-0 px-2'><span className='bold-title-contact'>Honduras</span></p>
              </div>
              <div className='col-12 col-md-6 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                <a onclick="return gtag_report_conversion('tel:+50422620080');" href="tel:+50422620080" class="span-Contact1 m-0 p-0">{t('contact.oficina')} (+504) 22620080</a>
              </div>


            
                </Tab>
                <Tab eventKey="sur" title={t('contact.surAM')}>
                    <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center'>
                    <img src={peru} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.peru')} </span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a onclick="return gtag_report_conversion('tel:+5117068433');" href="tel:+5117068433" class="span-Contact1">{t('contact.oficina')} (+51) 1706 8433</a>
                    {/* <a onclick="return gtag_report_conversion('tel:080000643');" href="tel:080000643" class="span-Contact1">{t('contact.gratuito')} 0800 00 643</a> */}
                  </div>
                  <div className='h-100  mb-2'></div>

                  
                  <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center'>
                    <img src={colombia} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.colombia')} </span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a onclick="return gtag_report_conversion('tel:+576015800227');" href="tel:+576015800227" class="span-Contact1">{t('contact.oficina')} (+57) 601 580-0227</a>
                    {/* <a onclick="return gtag_report_conversion('tel:01800952088');" href="tel:01800952088" class="span-Contact1">{t('contact.gratuito')} 01 800 9520 88</a> */}
                  </div>
                  <div className='h-100  mb-2'></div>
                  
                  <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center '>
                    <img src={chile} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.chile')} </span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a onclick="return gtag_report_conversion('tel:+56227128087');" href="tel:+56227128087" class="span-Contact1">{t('contact.oficina')} (+56) 2 2712 8087</a>
                    {/* <a onclick="return gtag_report_conversion('tel:800000096');" href="tel:800000096" class="span-Contact1">{t('contact.gratuito')}  800 00 00 96</a> */}
                  </div>
                  <div className='h-100 mb-2'></div>
                  <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center'>
                    <img src={ecuador} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.ecuador')} </span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a onclick="return gtag_report_conversion('tel:+593963871028');" href="tel:+593963871028" class="span-Contact1">{t('contact.oficina')} (+593) 9 6387 1028</a>
                    {/* <a onclick="return gtag_report_conversion('tel:1800001011');" href="tel:1800001011" class="span-Contact1">{t('contact.gratuito')} 1 800 001 011</a> */}
                  </div>
                  <div className='h-100 mb-2'></div>
                  <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center'>
                    <img src={argentina} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.argentina')} </span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a onclick="return gtag_report_conversion('tel:+541177004092');" href="tel:+541177004092" class="span-Contact1">{t('contact.oficina')} (+54) 11 7700 4092</a>
                    {/* <a onclick="return gtag_report_conversion('tel:08001220928');" href="tel:08001220928" class="span-Contact1">{t('contact.gratuito')} 0 800 122 0928</a> */}
                  </div>
                  <div className='h-100 mb-2'></div>
                  <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center'>
                    <img src={paraguay} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.paraguay')} </span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>

                    <a onclick="return gtag_report_conversion('tel:+595217288624');" href="tel:+595217288624" class="span-Contact1">{t('contact.oficina')} (+59) 52 1728 8624</a>
                    {/* <a onclick="return gtag_report_conversion('tel:0098005210114');" href="tel:0098005210114" class="span-Contact1">{t('contact.gratuito')} 00 980 052 10114</a> */}
                  </div>
                  <div className='h-100 mb-2'></div>
                  <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center'>
                    <img src={uruguay} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.uruguay')} </span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    {/* <a href="tel:000405296720" class="span-Contact1">LOCAL: 000 4052 96720</a> */}
                    {/* <a onclick="return gtag_report_conversion('tel:000405296720');" href="tel:000405296720" class="span-Contact1">{t('contact.gratuito')} 000 4052 96720</a> */}
                  </div>                  
                  <div className='h-100 mb-2'></div>
                  <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center mb-2'>
                    <img src={brasil} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.brasil')}</span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a onclick="return gtag_report_conversion('tel:+551131957112');" href="tel:+551131957112" class="span-Contact1">{t('contact.oficina')} (+55) 11 3195 7112</a>
                  </div>

              <div className='h-100 mb-2'></div>
              <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center mb-2'>
                <img src={bolivia} alt="" height="40px" width="40px" />
                <p className='m-0 p-0 px-2'><span className='bold-title-contact'>{t('contact.bolivia')}</span></p>
              </div>
              <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                <a onclick="return gtag_report_conversion('tel:+59150152517');" href="tel:+59150152517" class="span-Contact1">{t('contact.oficina')} (+591) 5015-4129</a>
              </div>
                  
                </Tab>
                <Tab eventKey="caribe" title={t('contact.caribe')}>
                  <div className='h-100 mb-2'></div>
                  
                  <div className='col-12 col-md-4 text-end d-flex justify-content-start align-items-center mb-2'>
                    <img src={repDominicana} alt="" height="40px" width="40px" />
                    <p className='m-0 p-0 px-2 text-start'><span className='bold-title-contact'>{t('contact.repDominicana')}</span></p>
                  </div>
                  <div className='col-12 col-md-6 pb-3 d-flex flex-column justify-content-center align-items-start m-0 p-0'>
                    <a onclick="return gtag_report_conversion('tel:+18299477631');" href="tel:+18299477631" class="span-Contact1">{t('contact.oficina')} (+1) 829 947 7631</a>
                  </div>
                </Tab>
              </Tabs>
              
              
            {/* </>
          } */}
        </div>
      </div>
    </div>
  )
}