import React, { Fragment, useEffect } from 'react'
import { Presentation } from './Components/Presentation'
import { ProblemsContainer } from './Components/Industry/ProblemsContainer'
import { ProposalsContainer } from './Components/Industry/ProposalsContainer'
import { ProposalsContainerHorizontal } from './Components/Industry/ProposalsContainerHorizontal'
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from 'react-device-detect'

const financial = 'https://cdn.iwsservices.com/Industrias/Financial/Financiera.png'
const financial_mov = 'https://cdn.iwsservices.com/Industrias/Financial/Financiera_Mobile.png'
const headerImg = 'https://cdn.iwsservices.com/Industrias/Financial/Ilustraciones industrias-02.png'
const firstCard = 'https://cdn.iwsservices.com/Industrias/Financial/cards/1.2.png'
const secondCard = 'https://cdn.iwsservices.com/Industrias/Financial/cards/2.2.png'
const thirdCard = 'https://cdn.iwsservices.com/Industrias/Financial/cards/3.2.png'
const fourhCard = 'https://cdn.iwsservices.com/Industrias/Financial/cards/4.2.png'
const proposal1 = 'https://cdn.iwsservices.com/Industrias/Financial/proposals/Grupo 1.png'
const proposal2 = 'https://cdn.iwsservices.com/Industrias/Financial/proposals/Grupo 2.png'
const proposal3 = 'https://cdn.iwsservices.com/Industrias/Financial/proposals/Grupo 3.png'
const proposal4 = 'https://cdn.iwsservices.com/Industrias/Financial/proposals/Grupo 4.png'
const proposal1Pt = 'https://cdn.iwsservices.com/Industrias/Financial/proposals/Grupo 1 portugues.png'
const proposal2Pt = 'https://cdn.iwsservices.com/Industrias/Financial/proposals/Grupo 2 portugues.png'
const proposal3Pt = 'https://cdn.iwsservices.com/Industrias/Financial/proposals/Grupo 3 portugues.png'
const proposal4Pt = 'https://cdn.iwsservices.com/Industrias/Financial/proposals/Grupo 4 portugues.png'
const proposal1Hori = 'https://cdn.iwsservices.com/Industrias/Financial/proposals/Grupo 1 hori.png'
const proposal2Hori = 'https://cdn.iwsservices.com/Industrias/Financial/proposals/Grupo 2 hori.png'
const proposal3Hori = 'https://cdn.iwsservices.com/Industrias/Financial/proposals/Grupo 3 hori.png'
const proposal4Hori = 'https://cdn.iwsservices.com/Industrias/Financial/proposals/Grupo 4 hori.png'

//inglés
const financialEN = 'https://cdn.iwsservices.com/Industrias/Educacion/ingles/Financial.png';
//portugués
const financialPT = 'https://cdn.iwsservices.com/Industrias/Educacion/portugues/Financial.png';
//inglés
const financialENMov = 'https://cdn.iwsservices.com/Industrias/Educacion/ingles/Financial_mov.png';
//portugués
const financialPTMov = 'https://cdn.iwsservices.com/Industrias/Educacion/portugues/Financial_mov.png';

export function Financial() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const domainName = window.location.hostname;
  const [t, i18n] = useTranslation("common");
  const proposalsImages = [proposal1, proposal2, proposal3, proposal4];
  const proposalsImagesPt = [proposal1Pt, proposal2Pt, proposal3Pt, proposal4Pt];
  const proposalsImagesHorizontal = [proposal1Hori, proposal2Hori, proposal3Hori, proposal4Hori];
  const cardData = [
    {
      img: firstCard,
      title: "1. Procesos de ventas",
      body: "Quejas de los clientes, procesos no documentados, retrados en entradas de créditos o tarjetas, exceso en trámites y controles."
    },
    {
      img: secondCard,
      title: "2. Procesos de negocio",
      body: "Se siguen procesos al pie de la letra, descuidos de las áreas, demasiado tiempo de espera."
    },
    {
      img: thirdCard,
      title: "3. Inversión y fondos",
      body: "No hay control de riesgos y análisis de retorno, sin herramientas B2B para inversores, no hay confianza en países emergentes, sin acercamiento a prestatarios."
    },
    {
      img: fourhCard,
      title: "4. Proceso de rentabilidad",
      body: "Devaluación de tasas cambiarias, alta volatilidad del mercado, tasas de interés diferentes, caída de precios y préstamos mal otorgados."
    },
  ]

  const cardDataPt = [
    {
      img: firstCard,
      title: "1. Processos de Vendas",
      body: "Reclamações de clientes, processos não documentados, atrasos nas entregas de crédito ou cartão, excesso de burocracia e controles."
    },
    {
      img: secondCard,
      title: "2. Processos de Negócios",
      body: "Processos seguidos à risca, descaso com as áreas, muito tempo de espera."
    },
    {
      img: thirdCard,
      title: "3. Investimento e fundos",
      body: "Não há controle de risco e análise de retorno, ferramentas B2B para investidores, confiança em países emergentes, contato com tomadores de empréstimos."
    },
    {
      img: fourhCard,
      title: "4. Processo de rentabilidade",
      body: "Desvalorização das taxas de câmbio, alta volatilidade do mercado, taxas de juros diferentes, preços em queda e empréstimos mal concedidos."
    },
  ]

  let imgBanner = financial;
  if (domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca')) {
    imgBanner = financialEN;
  } else if (domainName.includes('iwsservices.com.br')) {
    imgBanner = financialPT;
  }
  let imgBannerMov = financial_mov;
  if (domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca')) {
    imgBannerMov = financialENMov;
  } else if (domainName.includes('iwsservices.com.br')) {
    imgBannerMov = financialPTMov;
  }

  return (
    <div><Header />
      <Fragment>
        <div id="PresRecursosFather" className="recursosFather">
          <Presentation
            image={imgBanner}
            image2={imgBannerMov}
            text={t("financial.title")}
          />
          <div className="pleca-2"></div>
        </div>
        <div id="RecursosChild1" className="pb-0 control-header-industry financial_modi">
          <BrowserView>
            <div className="box-problems-container">
              <ProblemsContainer widthImg={'w-50'} imagebotom={headerImg} cardsData={domainName.includes('iwsservices.com.br') ? cardDataPt : cardData} />

            </div>

          </BrowserView>

          <MobileView>
            <div className="box-problems-container-mb">
              <ProblemsContainer widthImg={'w-50'} imagebotom={headerImg} cardsData={domainName.includes('iwsservices.com.br') ? cardDataPt : cardData} />

            </div>

          </MobileView>
        </div>
        <div className='row justify-content-center'>
          <div className='col-sm-12 col-12 col-md-12 col-lg-8 col-xl-9 col-xxl-8 text-center'>
            <span className={domainName.includes('iwsservices.com.br') ? "d-none" : "span-our-alianza color-financiero"}>VISIÓN</span>
            <span className={domainName.includes('iwsservices.com.br') ? "span-our-alianza color-financiero" : "d-none"}>VISÃO</span>
            <p className={domainName.includes('iwsservices.com.br') ? "d-none" : "align-items-center justify-content-center"}>Productividad móvil, colaboración social, visión personalizada y <strong>productividad en ventas</strong></p>
            <p className={domainName.includes('iwsservices.com.br') ? "align-items-center justify-content-center" : "d-none"}>Produtividade móvel, colaboração social, visão personalizada e <strong>produtividade de vendas.</strong></p>
          </div>
        </div>
        <div className='row justify-content-center mt-5 ProposalsContainer'>
          <div className='col-10 mt-5'>
            <ProposalsContainer images={domainName.includes('iwsservices.com.br') ? proposalsImagesPt : proposalsImages} classnameindustry="span-our-alianza color-financiero" />
          </div>
        </div>

        <div className='row justify-content-center mt-5 ProposalsContainerHorizontal'>
          <div className='col-10 mt-5'>
            <ProposalsContainerHorizontal images={domainName.includes('iwsservices.com.br') ? proposalsImagesHorizontal : proposalsImagesHorizontal} classnameindustry="span-our-alianza color-financiero" />
          </div>
        </div>

      </Fragment> <ToggleComp />
      <Footer />
    </div>
  )
}