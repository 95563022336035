import React from 'react'
import { useTranslation } from "react-i18next";
import { CardProblems } from './CardProblems'
import { BrowserView, MobileView } from 'react-device-detect';

export function ProposalsContainerHorizontal(props) {
    const [t, i18n] = useTranslation('common');
    const cards = props.images.map((data, index) =>
        <div className='col-sm-12 col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-self-center pb-5 text-center' key={index}>
            <img src={data} className="w-75" />
        </div>
    );
    return (
        <div>
            <BrowserView>
                <div className='mb-4'>
                    <div className='row justify-content-center'>
                        <div className='col-8 text-center mb-5'>
                            <span className={props.classnameindustry}>PROPUESTAS DE PROYECTOS DE TECNOLOGÍA</span>
                        </div>
                    </div>
                    <div className='row justify-content-center'>{cards}</div>
                </div>
            </BrowserView>
            <MobileView>
                <div className='mb-4 pb-4'>
                    <div className='box-title pb-4'>
                        <span className={props.classnameindustry}>PROPUESTAS DE PROYECTOS DE TECNOLOGÍA</span>
                    </div>
                    <div className='row justify-content-center'>{cards}</div>
                </div>
            </MobileView>
        </div>

    )
}