import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { ContextDataChatIWS } from "../src/index"
import { ContextDataChat } from "./index"
import { addServices, ActivateConversation, addUSerSelected, SaveNameUser, UserService ,UserServices } from "./redux/dataSlice.js";
import { useDispatch, useSelector } from "react-redux";
import "./Components/ChatBotInterfaz/css/CharResponsable.css";
import axios, { Axios } from 'axios';
import Alert from 'react-bootstrap/Alert';
import { BrowserView, MobileView } from "react-device-detect";
import Accordion from 'react-bootstrap/Accordion';
import boton_regresar from "./Images/boton_regresar.png";
import './CSS/miEquipo.css'
import Historico from './Components/Historico';

function Miequipo() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const { ObjChat, UpdateObjHub } = useContext(ContextDataChatIWS);
    const { miequipoarray, setmiequipoarray } = useContext(ContextDataChatIWS);
    const { ObjChatIWS, updateIWS } = useContext(ContextDataChat);
    const [EquipoUsers, setEquipoUsers] = useState([]);
    const [UsuarioSeleccionado, setUsuarioSeleccionado] = useState("");
    const [UsuarioSeleccionadoName, setUsuarioSeleccionadoName] = useState("");
    const [ServicioEquipoUSer, setServicioEquipoUSer] = useState("");
    const [claseActiva, setClaseActiva] = useState("a");
    axios.defaults.headers.common['Api_Key'] = 'J7rTm2LpH9qA6wZ8xN3cE1bD4';


    useEffect(() => {
        const ServiceResponsable = async () => {
            let email = ObjChatIWS.UserEmail;
            let _resp;
            try {
                const GetResponsableDetail = `${ObjChat.api}/api/Chat/GetResponsableDetail?`;
                await axios
                    .get(`${GetResponsableDetail}emailResponsable=${email}`)
                    .then((res) => {
                        var serviceOne = res.data.data.services[0].clave
                        dispatch(UserService(serviceOne))
                        console.log('res', res.data)
                        let services = res.data.data.services;
                        const uniqueResponsables = services.filter(
                            (service, index, self) =>
                                index === self.findIndex((r) => r.clave === service.clave)
                        );
                        dispatch(addServices(uniqueResponsables))
                        console.log('reduxSave',res.data.data)
                        dispatch(UserServices(res.data.data))
                    })
                    .catch(function (error) {
                        // console.log(error);
                    });
            }
            catch (err) {
                console.log(err);
            }
            return _resp
        }


        let _apieresp = [];
        UpdateObjHub("MiequipoUsers", _apieresp)
        ServiceResponsable();
    }, [])

    const handleUsuarioClick = (usuario) => {
        let _email = usuario.email;
        let _name = usuario.nombre;
        // console.log('el usuario es', _email)

        setUsuarioSeleccionado(_email);
        updateIWS("UserSelected", usuario.email);
        setUsuarioSeleccionadoName(_name);
        setClaseActiva(null);

    };

    function onclickitem({ clave }) {
        setEquipoUsers([]);
        let _email = ObjChatIWS.UserEmail.toLowerCase();

        if (clave == 3) {
                  const response = user.UserServiceGroup;
                    let allResponsables = [];
                    let servicesData = [];

                    if (response.services) {
                        servicesData = response.services;

                        servicesData
                            .filter((service) => service.clave === 3) // Filtrar solo servicios con clave igual a 3
                            .forEach((service) => {
                                if (service.listaPaises && service.listaPaises.length > 0) {
                                    const responsablesNorteAmerica = service.listaPaises[0]?.norteAmerica?.responsables || [];
                                    const responsablesCentroAmerica = service.listaPaises[0]?.centroAmerica?.responsables || [];
                                    const responsablesSudAmerica = service.listaPaises[0]?.sudAmerica?.responsables || [];

                                    allResponsables = allResponsables.concat(
                                        responsablesNorteAmerica,
                                        responsablesCentroAmerica,
                                        responsablesSudAmerica
                                    );
                                }

                                if (service.responsables && service.responsables.length > 0) {
                                    const responsablesExtra = service.responsables[0] || [];
                                    allResponsables = allResponsables.concat(responsablesExtra);
                                }
                            });
                    }

                    const uniqueResponsables = allResponsables.filter(
                        (responsable, index, self) =>
                            index === self.findIndex((r) => r.email === responsable.email && r.nombre === responsable.nombre)
                    );

                    let _newArray;
                    _newArray = uniqueResponsables.filter(item => {
                        // console.log(item)
                        return item.email.toLowerCase() !== _email;
                    })
                    setEquipoUsers(_newArray)
                    return uniqueResponsables;
        } else {
            const response = user.UserServiceGroup;
            let allResponsables = [];
            let servicesData = [];
            if (response.services) {
                servicesData = response.services;

                servicesData
                    .filter((service) => service.clave === clave) // Filtrar solo servicios con clave diferente a 3
                    .forEach((service) => {
                        if (service.listaPaises && service.listaPaises.length > 0) {
                            const responsablesNorteAmerica = service.listaPaises[0]?.norteAmerica?.responsables || [];
                            const responsablesCentroAmerica = service.listaPaises[0]?.centroAmerica?.responsables || [];
                            const responsablesSudAmerica = service.listaPaises[0]?.sudAmerica?.responsables || [];

                            allResponsables = allResponsables.concat(
                                responsablesNorteAmerica,
                                responsablesCentroAmerica,
                                responsablesSudAmerica
                            );
                        }

                        if (service.responsables && service.responsables.length > 0) {
                            const responsablesExtra = service.responsables[0] || [];
                            allResponsables = allResponsables.concat(responsablesExtra);
                        }
                    });
            }
            const uniqueResponsables = allResponsables.filter(
                (responsable, index, self) =>
                    index === self.findIndex((r) => r.email === responsable.email && r.nombre === responsable.nombre)
            );

            let _newArray;
            _newArray = uniqueResponsables.filter(item => {
                // console.log(item)
                return item.email.toLowerCase() !== _email;
            })

            setEquipoUsers(_newArray)

        }


    }

    const resetearUsuario = () => {
        setUsuarioSeleccionado(null);
        setClaseActiva("a");
        dispatch(ActivateConversation(false))
        dispatch(addUSerSelected([]))
        dispatch(SaveNameUser([]))
    };

    return (
        <div className='Miequipo'>
            {claseActiva ? (
                <div className='container'>
                    <Alert variant='info'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                    </svg> Estos son los Servicios de los que eres parte.</Alert>
                    <Accordion>
                        {user.services.map((item, index) => (
                            <Accordion.Item eventKey={index} key={index} >
                                <Accordion.Header onClick={() => onclickitem(item)}><h1>{item.descripcion}</h1> </Accordion.Header>
                                <Accordion.Body>
                                    <ul className="users-chat-msg mt-1">
                                        <h1>Integrantes: </h1>
                                        {
                                            EquipoUsers.map((item, index) => (

                                                <li key={index} onClick={() => handleUsuarioClick(item)}>
                                                    <div className="Avatar-name">
                                                        T
                                                    </div>
                                                    <div className="Name-Responsable">
                                                        <h1 className="leading-normal font-medium text-md">
                                                            {item.nombre}:
                                                            {ServicioEquipoUSer} </h1>
                                                        <span className='leading-normal text-smll'>{item.email}</span>
                                                    </div>
                                                </li>
                                            ))}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>

                        ))}
                    </Accordion>

                    {/* <div>
                        <h1>Información de los países:</h1>
                        <ul>
                            {infoPaises.map((info, index) => (
                                <li key={index}>
                                    <strong>{paises[index].pais}:</strong> {JSON.stringify(info)}
                                </li>
                            ))}
                        </ul>
                    </div> */}
                </div>


            ) : ''}

            {UsuarioSeleccionado ? (
                <div className='Conversation-Responsable'>
                    {user.alertmobile ?
                        <Alert variant='primary'><button className='boton_regresar' onClick={resetearUsuario}><img className='bonton_regresar' src={boton_regresar} /></button>Conversaciones de:  {UsuarioSeleccionadoName}</Alert>
                        :
                        <div>

                        </div>
                    }

                    <Historico />
                </div>
            ) : null}

        </div>
    )
}

export default Miequipo;
