import React, { Fragment, useEffect } from 'react'
import { Presentation, Prueba } from './Components/Presentation'
import { ContactChild1 } from './Components/Contact/ContactChild1'
import { ContactChild2 } from './Components/Contact/ContactChild2'
import { ContactChild3 } from './Components/Contact/ContactChild3'
import { useTranslation } from "react-i18next";
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";

const img1 = 'https://cdn.iwsservices.com/Contacto_desktop.png'
const img1En =  'https://cdn.iwsservices.com/Contact_en.png'
const img2 =  'https://cdn.iwsservices.com/Contacto_mobile.png'
const img2En =  'https://cdn.iwsservices.com/Contact_mb_en.png'
const mapa =  'https://cdn.iwsservices.com/Contacto/Diseño_mapa.png'
const mapaBr =  'https://cdn.iwsservices.com/Contacto/MapaBr.png'
const link =  'https://cdn.iwsservices.com/Contacto/LinkedIn.png'
const insta =  'https://cdn.iwsservices.com/Contacto/Instagram.png'
const twitter =  'https://cdn.iwsservices.com/Contacto/Twitter.png'
const face =  'https://cdn.iwsservices.com/Contacto/Facebook.png'
const img01 =  'https://cdn.iwsservices.com/Contacto/Post 1.png'
const img02 =  'https://cdn.iwsservices.com/Contacto/Post 2.png'
const img03 =  'https://cdn.iwsservices.com/Contacto/Post 3.png'
const img01En =  'https://cdn.iwsservices.com/Contacto/Post 1 En.png'
const img02En = 'https://cdn.iwsservices.com/Contacto/Post 2 En.png'
const img03En = 'https://cdn.iwsservices.com/Contacto/Post 3 En.png'
const iconMex = 'https://cdn.iwsservices.com/Contacto/mx_flag.png'
const icoUSA ='https://cdn.iwsservices.com/Contacto/usaflag.png'
const iconCR ='https://cdn.iwsservices.com/Contacto/Costa Rica.png'
const mapaLineas = 'https://cdn.iwsservices.com/Contacto/Mapa.png'


export function Contact() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const [t, i18n] = useTranslation('common');
  const domainName = window.location.hostname;


  const imagenes = [
    { src: link, href: "https://www.linkedin.com/company/iws-services/" },
    { src: insta, href: "https://www.instagram.com/iws_services_latam/" },
    { src: twitter, href: "https://twitter.com/iws_services" },
    { src: face, href: "https://www.facebook.com/IWSServicesMexico/" },
  ]
  const imagenes2 = [
    { src: (domainName.includes('iwsservices.com') || domainName.includes('redinet.cr') || domainName.includes('redinet.pe')  || domainName.includes('redinet.com.mx')) ? img01 : img01En},
    { src: (domainName.includes('iwsservices.com') || domainName.includes('redinet.cr') || domainName.includes('redinet.pe')  || domainName.includes('redinet.com.mx')) ? img02 : img02En},
    { src: (domainName.includes('iwsservices.com') || domainName.includes('redinet.cr') || domainName.includes('redinet.pe')  || domainName.includes('redinet.com.mx')) ? img03 : img03En},
  ]

  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresContactFather" className='contactFather'>
          <Presentation image={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1En : img1} image2={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img2En : domainName.includes('iwsservices.com.br') ? img2En : img2} text={t('contact.text')} text2={t('contact.text2')} />
        </div>

        <div id="ContactChild1" className='row m-0 p-0 justify-content-center div-ContactChild1'>
          <ContactChild1 image={iconMex} image2={icoUSA} image3={iconCR} />
        </div>

        {/* <div>
        
      </div> */}
        <div id="ContactChild2" className={domainName.includes('redinet.cr') ? 'row m-0 p-0 py-4 justify-content-center d-none' : 'row m-0 p-0 py-4 justify-content-center'}>
          <div className='col-12 d-flex justify-content-center pt-5'>
            <div className='title-Child3 text-center text-azul-marino'>
              <p className='nuestras'>{t('contact.nuestras')}</p>
            </div>
          </div>

          <div className='title-relative-rr title-relative position-relative text-azul-marino '>
            {t('contact.oficinas')}
          </div>
          <ContactChild2 image={domainName.includes('iwsservices.com.br') ? mapaBr : mapa} image2={mapaLineas} />
        </div>

        <div className={domainName.includes('redinet.cr') ? 'row m-0 p-0 pt-4 mt-4 justify-content-center d-none' : 'row m-0 p-0 pt-4 mt-4 justify-content-center'}>
          <ContactChild3 images={imagenes} images2={imagenes2} />
        </div>
      </Fragment>
      <ToggleComp />
      <Footer />
    </div>
  )
}