import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux'; // Importa el Provider de React-Redux
import { store } from "./redux/store";
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CSS/Global.css';
import './CSS/Home.css';
import './CSS/Nosotros.css';
import './CSS/Solutions.css';
import './CSS/Services In a Box.css';
import './CSS/Services.css';
import './CSS/Migrations.css';
import './CSS/Recursos.css';
import './CSS/Contact.css';
import './CSS/basic.css';
import './CSS/bookshelf.css';
import './CSS/Style.css';
import './CSS/slick.css';
import './CSS/Financial.css';
// import './CSS/font-awesome.min.css';
import { MagazineStick } from './MagazineStick';
import { App } from './App';
import { About_US } from './About_Us'
import { Kaspersky } from './Kaspersky'
import { Microsoft } from "./Microsoft";
import { Services } from "./Services In a Box";
import { Help_Desk } from "./Help_Desk";
import { Migrations } from "./Migrations";
import { Trainings } from "./Trainings";
import { Financial } from "./Financial";
import { Government } from "./Government";
import { Education } from "./Education";
import { Retail } from "./Retail";
import { Transport } from "./Transport";
import { Assessment } from "./Assessment";
import { Bag_Hours } from "./Bag_Hours";
import { Implementaciones } from "./Implementaciones";
import { News } from "./News";
import { Recursos } from "./Recursos"
import { Contact } from "./Contact";
import { Google } from "./Google";
import { AWS } from "./AWS";
import { Teams } from "./Teams";
import { Veritas } from "./Veritas";
import { ChatAdmin } from "./ChatAdmin";
// import { Chat } from "./Components/Chat";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from "./translations/en/common.json";
import common_es from "./translations/es/common.json";
import common_pt from "./translations/pt/common.json"
import 'bootstrap/dist/css/bootstrap.min.css';

let idioma = '';

if (document.domain.includes('iwsservices.us') || document.domain.includes('redinet.us') || document.domain.includes('redinet.ca') || document.domain.includes('iwsservices.ca')) {
  idioma = 'en';
}
else if (document.domain.includes('iwsservices.com.br')) {
  idioma = 'pt';
}
else {
  idioma = 'es';
}

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: idioma,                              // language to use
  resources: {
    es: {
      common: common_es               // 'common' is our custom namespace
    },
    en: {
      common: common_en
    },
    pt: {
      common: common_pt
    },
  },
});

const favicon = document.getElementById("favicon");
const title = document.getElementById("title");

const domainName = window.location.hostname;
if (domainName.includes('redinet.cr')) {
  favicon.href = 'Images/Home/faviconRedinet.png';
}
else if (domainName.includes('redinet')) {
  title.innerText = "Redinet";
}
else {
  favicon.href = 'Images/Home/faviconIWS.png';
  console.log(favicon);
  title.innerText = "Redinet";
}



export const ContextDataChat = React.createContext();
export const ContextDataChatIWS = React.createContext();

function Apps() {
  const [ObjChat, setObjChat] = useState({
    "Live": false,
    "Conected": false,
    "Contador": 0,
    "Convesation": [],
    "ConvesationHub": [],
    "Email": "",
    "conexion": null,
    "conexionId": null,
    "idUserResponsable": 0,
    "nameResponsable": "",
    "emailResponsable": "",
    "conexionIdResponsable": null,
    "IsConectedResponsable": false,
    "ActiveClass": true,
    "idUSER": 0,
    "nombreUser": "",
    "emailUser": "",
    "pais": 0,
    "servicio": 0,
    "paises": [],
    "servicios": [],
    "conexionIDClienteTest": null,
    "arrayChat": [],
    "api": "https://chatbotiws.azurewebsites.net",
    "paises": [],
    "services": [],
    "ConversacionIniciada": false,
    "HorarioLaboral": false,
    "serviceId": 0,
    "countryId": 0,
    "ConversationstartHub": false,
    "phoneUser": 0,
    "listRespbuscadorporTeams": []

  })
  const [ObjChatIWS, setObjChatIWS] = useState({
    "connection": null,
    "ConexionID": "",
    "Conected": false,
    "Historico": [],
    "MiequipoUsers": [],
    "ConvesationSelected": {},
    "UserSelected":"",
    "ClientEmail": "",
    "UserEmail": "",
    "UserName": "",
    "UserService": "",
    "UserRole": "Admin",
    "idUser": "",
    "LoadingHistorico": false,
    "LoadingConversacion": false,
    "conexionIDClienteTest": null,
    "api": "https://chatbotiws.azurewebsites.net",
  })

  const updateConvesation = (newconversation) => {
    ObjChat.Convesation = newconversation
    setObjChat(ObjChat);
  };
  const updateEmail = (updateEmail) => {
    ObjChat.Email = updateEmail
    setObjChat(ObjChat);
  };

  const UpdateObjHub = (id, data) => {

    switch (id) {
      case 'Conected': ObjChat.Conected = data;
        break
      case 'ConvesationHub': ObjChat.ConvesationHub = data;
        break
      case 'conexion': ObjChat.conexion = data;
        break
      case 'conexionID': ObjChat.conexionId = data;
        break
      case "idUserResponsable": ObjChat.idUserResponsable = data;
        break
      case "nameResponsable": ObjChat.nameResponsable = data;
        break
      case "emailResponsable": ObjChat.emailResponsable = data;
        break
      case "IsConectedResponsable": ObjChat.IsConectedResponsable = data;
        break
      case "conexionIdResponsable": ObjChat.conexionIdResponsable = data;
        break
      case 'ActiveClass': ObjChat.ActiveClass = data;
        break
      case 'idUSER': ObjChat.idUSER = data;
        break
      case 'nombreUser': ObjChat.nombreUser = data;
        break
      case "emailUser": ObjChat.emailUser = data;
        break
      case "phoneUser": ObjChat.phoneUser = data;
        break
      case "pais": ObjChat.pais = data;
        break
      case 'servicio': ObjChat.servicio = data;
        break
      case "conexionIDClienteTest": ObjChat.conexionIDClienteTest = data;
        break
      case "paises": ObjChat.paises = data;
        break
      case "services": ObjChat.services = data;
        break
      case "Convesation": ObjChat.Convesation = data;
        break
      case "ConversacionIniciada": ObjChat.ConversacionIniciada = data;
        break
      case "ConversationstartHub": ObjChat.ConversationstartHub = data;
        break
      case "HorarioLaboral": ObjChat.HorarioLaboral = data;
        break
      case "serviceId": ObjChat.serviceId = data;
        break
      case "countryId": ObjChat.countryId = data;
        break
      case "listRespbuscadorporTeams": ObjChat.listRespbuscadorporTeams = data;
        break

    }

    setObjChat(ObjChat)

  }

  const updateIWS = (id, data) => {

    switch (id) {
      case "connection": ObjChatIWS.connection = data; break;
      case "ConexionID": ObjChatIWS.ConexionID = data; break;
      case "Conected": ObjChatIWS.Conected = data; break;
      case "Historico": ObjChatIWS.Historico = data; break;
      case "MiequipoUsers": ObjChatIWS.MiequipoUsers = data; break;
      case "ConvesationSelected": ObjChatIWS.ConvesationSelected = data; break;
      case "UserSelected": ObjChatIWS.UserSelected = data; break;
      case "UserEmail": ObjChatIWS.UserEmail = data; break;
      case "ClientEmail": ObjChatIWS.ClientEmail = data; break;
      case "UserName": ObjChatIWS.UserName = data; break;
      case "UserRole": ObjChatIWS.UserRole = data; break;
      case "UserService": ObjChatIWS.UserService = data; break;
      case "LoadingHistorico": ObjChatIWS.LoadingHistorico = data; break;
      case "LoadingConversacion": ObjChatIWS.LoadingConversacion = data; break;
      case "conexionIDClienteTest": ObjChatIWS.conexionIDClienteTest = data; break;
    }
    setObjChat(ObjChatIWS);
  };




  return (
    <BrowserRouter>
     <Provider store={store}> {/* Envuelve todo con el Provider de Redux */}
      <ContextDataChatIWS.Provider value={{ ObjChat, updateConvesation, updateEmail, UpdateObjHub }}>
        <ContextDataChat.Provider value={{ ObjChatIWS, updateIWS }}>
          <Routes>
            <Route exact path="/" element={<App />}></Route>
            {/* <Route exact path="/IWService/" element={<App />}></Route> */}
            <Route exact path="/Nosotros" element={<About_US />}></Route>
            <Route exact path="/Kaspersky" element={<Kaspersky />}></Route>
            <Route exact path="/Microsoft" element={<Microsoft />}></Route>
            <Route exact path="/Google" element={<Google />}></Route>
            <Route exact path="/AWS" element={<AWS />}></Route>
            <Route exact path="/Veritas" element={<Veritas />}></Route>
            <Route exact path="/Services%20In%20a%20Box" element={<Services />}></Route>
            <Route exact path="/Migrations" element={<Migrations />}></Route>
            <Route exact path="/Help%20Desk" element={<Help_Desk />}></Route>
            <Route exact path="/Trainings" element={<Trainings />}></Route>
            <Route exact path="/Assessment" element={<Assessment />}></Route>
            <Route exact path="/Bag%20of%20hours" element={<Bag_Hours />}></Route>
            <Route exact path="/Implementations" element={<Implementaciones />}></Route>
            <Route exact path="/News" element={<News />}></Route>
            <Route exact path="/Recursos" element={<Recursos />}></Route>
            <Route exact path="/Contact" element={<Contact />}></Route>
            <Route exact path="/Financial" element={<Financial />}></Route>
            <Route exact path="/Government" element={<Government />}></Route>
            <Route exact path="/Education" element={<Education />}></Route>
            <Route exact path="/Retail" element={<Retail />}></Route>
            <Route exact path="/Transport" element={<Transport />}></Route>
            <Route exact path="/Chat-Admin" element={<ChatAdmin />}></Route>
            <Route exact path="/Teams" element={<Teams />}></Route>
          </Routes>
        </ContextDataChat.Provider>
      </ContextDataChatIWS.Provider>
      </Provider>
    </BrowserRouter>
  );
}



ReactDOM.render(<I18nextProvider i18n={i18next}> <Apps /></I18nextProvider>, document.getElementById('root'));
