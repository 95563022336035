import React, { Fragment, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from "react-device-detect";
import pdfPresentacionEn from "../Icons/IWS Executive presentation 2023.pdf";
import pdfPresentacionEs from "../Icons/IWS Presentacion ejecutiva 2023.pdf";
import pdfPresentacionBr from "../Icons/Redinet_Presentação_Executiva2024.pdf";
import button from "../Components/Chatbot/Image/button.png";
import img_ppt from "../Components/Chatbot/Image/img-ppt.png";
import img_chat from "../Components/Chatbot/Image/img-chatbot.png";
import Chatbots from "../Components/Chatbot/Chatbot";
import { ContextDataChat } from "../../src/index";
import axios from "axios";

export function ToggleComp(props) {

  const [open, setOpen] = useState(false);
  const [ejecutado, setejecutado] = useState(false);
  const { ObjChat, setObjChat } = useContext(ContextDataChat);
  const handleClick = () => {
    setOpen(!open);
  };
  const [t, i18n] = useTranslation("common");

  function startChatbot(){
    if (ejecutado == false) {
        setOpen(!open);
        setejecutado(true)

    }
  }
  if (ejecutado == false) {
  setTimeout(startChatbot, 10000);
  setejecutado(true);
}
const domainName = window.location.hostname;

  return (
    <Fragment className="ToogleSection">
      <BrowserView>
        {open ? <Chatbots /> : null}
        {open ? (
          <button className="close-chat" onClick={handleClick}>
            X
          </button>
        ) : null}
        <div className="boton:option ToggleComp">
          <button className="botonF1">
            <div className="menu-option-f1">
              <div className="bontonf1:hover1">
                <button onClick={handleClick}>
                  <img src={img_chat} class="button-image2" />
                </button>
              </div>
              <div className="bontonf1:hover2">
                <button>
                  <a href={domainName.includes('iwsservices.com.br') ? pdfPresentacionBr : (domainName.includes('redinet.us')) || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? pdfPresentacionEn : pdfPresentacionEs } download>
                    <img src={img_ppt} class="button-image3" />
                  </a>
                </button>
              </div>
            </div>
          </button>
        </div>
      </BrowserView>

      <MobileView>
        {open ? <Chatbots /> : null}
        {open ? (
          <button className="close-chat" onClick={handleClick}>
            X
          </button>
        ) : null}
        <div className="boton:option">
          <button className="botonF1">
            <img src={button} class="button-image" />
            <div className="menu-option-f1">
              <div className="bontonf1:hover1">
                <button onClick={handleClick}>
                  <img src={img_chat} class="button-image2" />
                </button>
              </div>

              <div className="bontonf1:hover2">
                <button>
                  <a href={domainName.includes('iwsservices.com.br') ? pdfPresentacionBr : (domainName.includes('redinet.us')) || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? pdfPresentacionEn : pdfPresentacionEs } download>
                    <img src={img_ppt} class="button-image3" />
                  </a>
                </button>
              </div>
            </div>
          </button>
        </div>
      </MobileView>

    </Fragment>

  );

}



export default ToggleComp;