import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { ContextDataChatIWS } from "../../../index"


export const SaveUser = ({ steps, setbooluser }) => {

    const { ObjChat, UpdateObjHub } = useContext(ContextDataChatIWS);
    const { names, email, phoneinput, pais, services } = steps;
    const [_label, setlabel] = useState("");
    axios.defaults.headers.common['Api_Key'] = 'J7rTm2LpH9qA6wZ8xN3cE1bD4';
    axios.defaults.headers.post['Api_Key'] = `J7rTm2LpH9qA6wZ8xN3cE1bD4`;

    function quitarAcentos(cadena) {
        // Reemplazamos los caracteres acentuados por sus equivalentes sin acentos.
        cadena = cadena.replace(/[áÁ]/g, 'a');
        cadena = cadena.replace(/[éÉ]/g, 'e');
        cadena = cadena.replace(/[íÍ]/g, 'i');
        cadena = cadena.replace(/[óÓ]/g, 'o');
        cadena = cadena.replace(/[úÚ]/g, 'u');
        cadena = cadena.replace(/[ñÑ]/g, 'n');

        return cadena;
    }

    useEffect(async () => {
        const start = async () => {
            //////saveUser
            //console.log("Informacion que guarda bot", steps)
            //console.log("Contexto al momento", ObjChat)

            async function FechaActual() {

                // Obtener la fecha y hora actual
                const fechaHoraActual = new Date();

                // Obtener las partes individuales de la fecha y hora
                const dia = String(fechaHoraActual.getDate()).padStart(2, '0');
                const mes = String(fechaHoraActual.getMonth() + 1).padStart(2, '0');
                const anio = fechaHoraActual.getFullYear();
                const horas = String(fechaHoraActual.getHours() % 12).padStart(2, '0'); // Ajuste para mostrar en formato 12 horas
                const minutos = String(fechaHoraActual.getMinutes()).padStart(2, '0');
                const ampm = fechaHoraActual.getHours() >= 12 ? 'PM' : 'AM'; // Obtener AM o PM


                // Crear la cadena en el formato deseado
                const fechaHoraPersonalizada = `${dia}/${mes}/${anio} ${horas}:${minutos} ${ampm}`;

                return fechaHoraPersonalizada;
            }
            async function HorarioLaboral() {
                const currentDate = new Date();
                let resp;
                if (currentDate.getHours() >= 9 && currentDate.getHours() < 19) {
                    resp = true;
                }
                else {
                    resp = false;
                }
                UpdateObjHub("HorarioLaboral", resp)
                return resp;
            }
            async function saveuser() {
                let resp;
                const SaveUser = `${ObjChat.api}/api/Services/BotSaveUser?`;
                var nombre = names.value;
                var name = quitarAcentos(nombre);

                await axios
                    .put(`${SaveUser}email=${email.value}&name=${name}`)
                    .then((res) => {
                        //console.log("SaveUser: ", res);
                        UpdateObjHub("idUSER", res.data.data.client.idClient)
                        resp = true;
                    })
                    .catch(function (error) {
                        //console.log(error);
                        //console.log("Algo paso manda error")
                        resp = false;
                    });

                return resp

            }
            async function savePhone() {
                //////savecountry

                let resp;
                const SavePhone = `${ObjChat.api}/api/Services/BotSavePhoneNumber?`;
                await axios
                    .put(`${SavePhone}email=${email.value}&phoneNumber=${phoneinput.value}`)
                    .then((res) => {
                        //console.log("BotSavePhoneNumber: ", res.status);
                        resp = true;
                    })
                    .catch(function (error) {
                        //console.log(error);
                        resp = false;
                    });

                return resp;

            }
            async function saveCountry() {
                //////savecountry

                let resp;
                const SaveCountry = `${ObjChat.api}/api/Services/BotSaveCountry?`;
                await axios
                    .put(`${SaveCountry}email=${email.value}&idPais=${pais.value}`)
                    .then((res) => {
                        //console.log("SavePais: ", res.status);
                        resp = true;
                    })
                    .catch(function (error) {
                        //console.log(error);
                        resp = false;
                    });

                return resp;

            }
            async function saveServices() {
                //////saveServices
                let resp;
                const SaveService = `${ObjChat.api}/api/Services/BotSaveServices?`;
                await axios
                    .put(`${SaveService}email=${email.value}&idService=${services.value}`)
                    .then((res) => {
                        //console.log("SaveidService: ", res.status);
                        resp = true;
                    })
                    .catch(function (error) {
                        //console.log(error);
                        resp = false;
                    });

                return resp;
            }
            async function sendNotification() {

                //////SendNotification

                const fechaHoraPersonalizada = await FechaActual();
                let resp;
                const SendNotification = `${ObjChat.api}/api/Services/SendNotification?`;
                await axios
                    .post(`${SendNotification}clientEmail=${email.value}&serviceId=${services.value}&countryId=${pais.value}&date=${fechaHoraPersonalizada}`)
                    .then((res) => {
                        //console.log("sendNotification: ", res.status);
                        resp = true;
                    })
                    .catch(function (error) {
                        //console.log(error);
                        resp = false;
                    });
            }
            //await GetInfoResponsable();
            UpdateObjHub("serviceId", services.value)
            UpdateObjHub("countryId", pais.value)
            UpdateObjHub("phoneUser", phoneinput.value)
            setlabel("Espere un momento por favor, lo estamos conectando con un asesor")
            //console.log("Guardamos datos de cliente")
            const _saveuser = await saveuser();
            //console.log("¿Correcto", _saveuser)
            if (_saveuser) {
                //console.log("Guardamos Numero de cliente")
                const _savePhone = await savePhone()
                //console.log("¿Correcto", _savePhone)
                if (_savePhone) {
                    //console.log("Guardamos pais de cliente")
                    const _saveCountry = await saveCountry()
                    //console.log("¿Correcto", _saveCountry)
                    if (_saveCountry) {
                        //console.log("Guardamos servicio de cliente")
                        const _saveServices = await saveServices()
                        //console.log("¿Correcto", _saveServices)
                        if (_saveServices) {
                            //console.log("¿Dentro de Horario laboral?")
                            const _CurrentHour = await HorarioLaboral();
                            //console.log(_CurrentHour)
                            if (_CurrentHour) {
                                //console.log("Enviamos notificacion por Teams")
                                const _sendNotification = await sendNotification()
                                //console.log("¿Correcto", _sendNotification)
                                UpdateObjHub("serviceId", services.value)
                                setbooluser(true);
                            }
                            else {
                                //console.log("Error en mandar notificacion")
                                setlabel("Lo sentimos, por el momento el servicio no esta disponible")
                            }
                        }
                        else {
                            //console.log("Error en guardar el servicio")
                            setlabel("Lo sentimos, por el momento el servicio no esta disponible")
                        }
                    }
                    else {
                        //console.log("Error en guardar el paise")
                        setlabel("Lo sentimos, por el momento el servicio no esta disponible")
                    }
                }
                else {
                    //console.log("Error en guardar el paise")
                    setlabel("Lo sentimos, por el momento el servicio no esta disponible")
                }

            }
            else {
                //console.log("Error en guardar el usuario")
                setlabel("Lo sentimos, por el momento el servicio no esta disponible")
            }

        }
        await start()
    }, []);
    ;

    return (
        <div>
            {
                _label
            }
        </div>

    )
}


export default SaveUser;