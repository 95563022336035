import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ListHeader } from "./Components/ListHeader";
import MainMenu from './Components/MainMenu';
import { BrowserView, isTablet, MobileView } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons'

const logo = 'https://cdn.iwsservices.com/LogoRedinet.png';
const logoIWS = 'https://cdn.iwsservices.com/Logo IWS2.png';
const logoRDCR = 'https://cdn.iwsservices.com/Redinet_CR.png';
const logoRDMX = 'https://cdn.iwsservices.com/Redinet_MX.png';
const logoRDPE = 'https://cdn.iwsservices.com/Redinet_PE.png';
const logoSolo = 'https://cdn.iwsservices.com/Redinet_Solo.png';
const logoLatWhite = 'https://cdn.iwsservices.com/Redinet_Lat-01.png'
const plecaMenu = 'https://cdn.iwsservices.com/Plecas_sombra_desktop.png';
const plecaMobile = 'https://cdn.iwsservices.com/Pleca_sombra_mobile.png';

export function Header() {

  const [headerClassName, setHeaderClassName] = useState('');

  const handleScroll = (headerClassName) => {

    if (headerClassName !== 'menuscroll' && window.pageYOffset >= 100) {
      setHeaderClassName(window.pageYOffset);

    } else if (headerClassName === 'menuscroll' && window.pageYOffset < 100) {
      setHeaderClassName('');

    }
  }
  

  React.useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]); // IMPORTANT, This will cause react to update depending on change of this value


  const iconBar = {
    xmlns: "http://www.w3.org/2000/svg",
    ancho: "22",
    alto: "22",
    currentColor: "currentColor",
    clase: "bi bi-list",
    viewCaja: "0 0 16 16",
    d: "M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"

  };
  const domainName = window.location.hostname;

  return (
    <nav className="navbar navbar-expand-lg mb-5 Principal-header">

      <div className="container-fluid header-new-design">

        <BrowserView className="w-100">
          <ListHeader />
        </BrowserView>

        <MobileView className="menu-header-mb">

          {isTablet ? <ListHeader /> : headerClassName > 200 ?
            <Link className="nav-brand-mobile" to="/">
              <img src={domainName.includes('redinet.com.mx') ? logoRDMX : domainName.includes('redinet.us') ? logoSolo : domainName.includes('redinet.cr') ? logoRDCR : domainName.includes('redinet.pe') ? logoRDPE : domainName.includes('iwsservices.ca') ? logoIWS : logo} alt="LogoIWS" className="d-inline-block align-text-top d-none d-md-block logo-mobile" />
              <img src={domainName.includes('redinet.com.mx') ? logoRDMX : domainName.includes('redinet.us') ? logoSolo : domainName.includes('redinet.cr') ? logoRDCR : domainName.includes('redinet.pe') ? logoRDPE : domainName.includes('iwsservices.ca') ? logoIWS : logo} alt="LogoIWS" className="d-inline-block align-text-top d-block d-md-none logo-mobile" />
            </Link> :

            <Link className="navbar-brand" to="/">
              <img src={domainName.includes('redinet.com.mx') ? logoRDMX : domainName.includes('redinet.us') ? logoSolo : domainName.includes('redinet.cr') ? logoRDCR : domainName.includes('redinet.pe') ? logoRDPE : domainName.includes('iwsservices.ca') ? logoIWS : logoLatWhite} alt="LogoIWS" className="logowhite-mobile d-inline-block align-text-top d-none d-md-block logo-mobile" />
              <img src={domainName.includes('redinet.com.mx') ? logoRDMX : domainName.includes('redinet.us') ? logoSolo : domainName.includes('redinet.cr') ? logoRDCR : domainName.includes('redinet.pe') ? logoRDPE : domainName.includes('iwsservices.ca') ? logoIWS : logoLatWhite} alt="LogoIWS" className="logowhite-mobile d-inline-block align-text-top d-block d-md-none logo-mobile" />
            </Link>}



          <div className="divIcon">
            <a href="https://cursos.iwsservices.com/">

              {headerClassName > 200 ?
                // <SchoolIcon className="iconPersonBlue" color="blue"></SchoolIcon> : 
                // <SchoolIcon className="iconPerson"></SchoolIcon>
                <FontAwesomeIcon className="iconPersonBlue" icon={faUserGraduate} /> :
                <FontAwesomeIcon className="iconPerson" icon={faUserGraduate} />
              }
            </a>

          </div>
          <MainMenu scroll={headerClassName} className="colorMenu" />
        </MobileView>

        {/* <button className="navbar-toggler" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent" 
            aria-expanded="false" aria-label="Toggle navigation"
            >
            <SVG xmlns={iconBar.xmlns} ancho={iconBar.ancho} alto={iconBar.alto} currentColor={iconBar.currentColor} clase={iconBar.clase}
              viewCaja={iconBar.viewCaja} d={iconBar.d} />
          </button> */}


      </div>
      <div>
        <BrowserView>
          <div className="pleca-menu">
            <img src={plecaMenu} alt="Pleca menú" />
          </div>
        </BrowserView>
        <MobileView >
          <div className="pleca-menu-mobile">
            {headerClassName > 200 ? <img src={plecaMobile} alt="Pleca menú" className="show-pleca" /> : ""}
          </div>
        </MobileView>
      </div>


    </nav>
  )
}