import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../CSS/Design2023/OurServices.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import Card from 'react-bootstrap/Card';
import { CardContent } from '@mui/material';
import { Carousel as CarouselReact } from 'react-responsive-carousel';
import { useTranslation } from "react-i18next";
import {images} from "../../Images/images.json"

const servicesBoxImg ='https://cdn.iwsservices.com/NuestrosServicios/Recurso2.png';
const migracionesImg = 'https://cdn.iwsservices.com/NuestrosServicios/Recurso3.png';
const solucionesImg = 'https://cdn.iwsservices.com/NuestrosServicios/Recurso4.png';
const implementacionesImg = 'https://cdn.iwsservices.com/NuestrosServicios/Recurso5.png';



const OurServices = props => {
    const [classGiro, setClassGiro] = useState('anima-return');
    const [t, i18n] = useTranslation('common');
    const onChange = () => {

    }
    const onClickItem = () => {

    }

    const onClickThumb = () => {

    }

    const handleEnterGiro = (e)=>{
        
        if(e.target.children.length > 0){
            e.target.children[0].classList.add('anima');
            e.target.children[0].classList.remove('anima-return');
        }else{
            e.target.classList.add('anima');
            e.target.classList.remove('anima-return');

        }
    }
    const handleLeaveGiro = (e)=>{
        
        if(e.target.children.length > 0){
            e.target.children[0].classList.add('anima-return');
            e.target.children[0].classList.remove('anima');
        }else{
            e.target.classList.add('anima-return');
            e.target.classList.remove('anima');

        }
    }
    return (
        <>
            <BrowserView className="container-father card-service bg-white">
                <div className="bg-gray container-snake-left-rr_4 pb-6">
                    <div className="container text-center padding-">
                        <h2 className='title-a-section-home our-alianza'>{t('ourServices.nuestros')} {t('ourServices.servicios')}</h2>
                        <p className='card-service-desc'>{t('ourServices.especializamos')}</p>
                    </div>
                    <div className='container padding-'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <Card className='cardsHome card-service-'>
                                    <Card.Body>
                                        <Card.Title className="Titles-blue">{t('ourServices.servicesBox')}</Card.Title>
                                        <Card.Text>
                                            <div>
                                                {t('ourServices.servicesBoxCard')}
                                            </div>
                                            <div className='container text-center mt-3' onMouseEnter={(e)=>{handleEnterGiro(e)}} onMouseLeave={(e)=>{handleLeaveGiro(e)}}>
                                                <img src={servicesBoxImg} className={`img-giro img-50`}></img>
                                            </div>
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Link className='arrow-right-icon' to="/Services In a Box">{t('aboutUs.vermas')} <FontAwesomeIcon icon={faArrowRight} /></Link>
                                    </Card.Footer>
                                </Card>
                            </div>
                            <div className='col-lg-3'>
                                <Card className='cardsHome card-service-'>
                                    <Card.Body>
                                        <Card.Title className="Titles-blue">{t('ourServices.migraciones')}</Card.Title>
                                        <Card.Text>
                                            {t('ourServices.migracionesCard')}

                                            <div className='container text-center mt-3' onMouseEnter={(e)=>{handleEnterGiro(e)}} onMouseLeave={(e)=>{handleLeaveGiro(e)}}>
                                                <img src={migracionesImg} className='img-giro img-50'></img>
                                            </div>
                                        </Card.Text>

                                    </Card.Body>
                                    <Card.Footer>
                                        <Link className='arrow-right-icon' to="/Migrations">{t('aboutUs.vermas')} <FontAwesomeIcon icon={faArrowRight} /></Link>
                                    </Card.Footer>
                                </Card>
                            </div>
                            <div className='col-lg-3'>
                                <Card className='cardsHome card-service-'>
                                    <Card.Body>
                                        <Card.Title className="Titles-blue">{t('ourServices.soluciones')}</Card.Title>
                                        <Card.Text>
                                            {t('ourServices.solucionesCard')}
                                            <div className='container text-center mt-3' onMouseEnter={(e)=>{handleEnterGiro(e)}} onMouseLeave={(e)=>{handleLeaveGiro(e)}}>
                                                <img src={solucionesImg} className='img-giro img-50'></img>
                                            </div>
                                        </Card.Text>

                                        {/* <a className='arrow-right-icon'>Leer más <FontAwesomeIcon icon={faArrowRight} /></a> */}
                                    </Card.Body>
                                    <Card.Footer>
                                        <Link className='arrow-right-icon' to="/AWS">{t('aboutUs.vermas')} <FontAwesomeIcon icon={faArrowRight} /></Link>
                                    </Card.Footer>
                                </Card>
                            </div>
                            <div className='col-lg-3'>
                                <Card className='cardsHome card-service-'>
                                    <Card.Body>
                                        <Card.Title className="Titles-blue">{t('ourServices.implementaciones')}</Card.Title>
                                        <Card.Text>
                                            {t('ourServices.implementacionesCard')}
                                            <br />
                                            <br />
                                        </Card.Text>
                                        <div className='container text-center mt-3' onMouseEnter={(e)=>{handleEnterGiro(e)}} onMouseLeave={(e)=>{handleLeaveGiro(e)}}>
                                            <img src={implementacionesImg} className='img-giro img-50'></img>
                                        </div>
                                        <br />
                                    </Card.Body>
                                    <Card.Footer>
                                        <Link className='arrow-right-icon' to="/Implementations">{t('aboutUs.vermas')} <FontAwesomeIcon icon={faArrowRight} /></Link>
                                    </Card.Footer>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </BrowserView>
            <MobileView className="bg-white">
                <div className='bg-gray container-snake-left-rr_4 pb-6 pt-6'>
                    <div className='container'>
                        <div className='box-title pt-2'>
                            <h2 className='title-home-mb our-alianza'>{t('ourServices.nuestros')}</h2>
                            <span className='span-our-alianza color-blue-rr'>{t('ourServices.servicios')}</span>
                        </div>
                        <p className='card-service-desc'>{t('ourServices.especializamos')}
                        </p>
                        <CardContent>
                            <CarouselReact interval={7000} showStatus={false} showArrows={true} onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb} autoPlay={true} infiniteLoop={true} showThumbs={false}>
                                <div className='card no-border'>
                                    <div className='card-header no-border'>
                                        <h4 className="subtitle-service">{t('ourServices.servicesBox')}</h4>
                                    </div>
                                    <div className='card-container no-border bg-gray'>
                                        <div className='row'>
                                            <div className='col-8 mobile-services'>
                                                {t('ourServices.servicesBoxCard')}
                                            </div>
                                            <div className='col-4'>
                                                <div className='text-center image-center'>
                                                    <img className='image-mobile-nosotros' src={servicesBoxImg}></img>
                                                </div>
                                            </div>
                                        </div>
                                        <Link className='arrow-right-icon' to="/Services In a Box">{t('aboutUs.vermas')} <FontAwesomeIcon icon={faArrowRight} /></Link>
                                    </div>
                                    <div className='card-footer no-border bg-gray'>
                                        <br/>
                                     </div>
                                </div>

                                <div className='card no-border'>
                                    <div className='card-header no-border'>
                                        <h4 className="subtitle-service">{t('ourServices.migraciones')}</h4>
                                    </div>
                                    <div className='card-container no-border bg-gray'>
                                        <div className='row'>
                                            <div className='col-8 mobile-services'>
                                                {t('ourServices.migracionesCard')}
                                            </div>
                                            <div className='col-4'>
                                                <div className='text-center image-center'>
                                                    <img className='image-mobile-nosotros' src={migracionesImg}></img>
                                                </div>
                                            </div>
                                        </div>
                                        <Link className='arrow-right-icon' to="/Migrations">{t('aboutUs.vermas')} <FontAwesomeIcon icon={faArrowRight} /></Link>
                                   
                                    </div>
                                    <div className='card-footer no-border bg-gray'>
                                        <br/>
                                    </div>
                                </div>

                                <div className='card no-border'>
                                    <div className='card-header no-border'>
                                        <h4 className="subtitle-service">{t('ourServices.soluciones')}</h4>
                                    </div>
                                    <div className='card-container no-border bg-gray'>
                                        <div className='row'>
                                            <div className='col-8 mobile-services'>
                                                {t('ourServices.solucionesCard')}
                                            </div>
                                            <div className='col-4'>
                                                <div className='text-center image-center'>
                                                    <img className='image-mobile-nosotros' src={solucionesImg}></img>
                                                </div>
                                            </div>
                                        </div>
                                        <Link className='arrow-right-icon' to="/Assessment">{t('aboutUs.vermas')} <FontAwesomeIcon icon={faArrowRight} /></Link>
                                  
                                    </div>
                                    <div className='card-footer no-border bg-gray'>
                                        <br/>
                                    </div>
                                </div>

                                <div className='card no-border'>
                                    <div className='card-header no-border'>
                                        <h4 className="subtitle-service">{t('ourServices.implementaciones')}</h4>
                                    </div>
                                    <div className='card-container no-border bg-gray'>
                                        <div className='row'>
                                            <div className='col-8 mobile-services'>
                                                {t('ourServices.implementacionesCard')}
                                            </div>
                                            <div className='col-4'>
                                                <div className='text-center image-center'>
                                                    <img className='image-mobile-nosotros' src={implementacionesImg}></img>
                                                </div>
                                            </div>
                                        </div>
                                        <Link className='arrow-right-icon' to="/Implementations">{t('aboutUs.vermas')} <FontAwesomeIcon icon={faArrowRight} /></Link>
                                  
                                    </div>
                                    <div className='card-footer no-border bg-gray'>

                                        <br/>
                                    </div>
                                </div>                

                            </CarouselReact>
                        </CardContent>
                    </div>
                </div>
            </MobileView>
        </>
    )
}

export default OurServices