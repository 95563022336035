import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

import logoLatWhite from './../Images/Redinet_Lat-01.png'

import traductor from '../Images/WhatsApp.png'
import translate_us from '../Images/translate_us_a.png'
import translate_mx from '../Images/translate_mx_a.png'
import translate_pt from '../Images/Brasil.png'

import './../CSS/MainMenu.css'

import { useTranslation } from "react-i18next";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';



// import
const domainName = window.location.hostname;
//const subMenuSoluciones = ['AWS', 'Microsoft', 'Google', 'Kapersky', 'Veritas', 'Industria'];




export default function MainMenu(props) {
  const [t, i18n] = useTranslation('common');
  const [stateOpen, setStateOpen] = React.useState(false);
  const [expandmenu, setExpandmenu] = React.useState([false, false, false, false, false])
  const [expandSubMenu, setExpandSubmenu] = React.useState([false, false, false, false, false, false])

  const subMenuSoluciones = [{ text: 'AWS', link: '/AWS' },
  { text: 'Microsoft', link: '/Microsoft' },
  { text: 'Google', link: '/Google' },
  { text: 'Kaspersky', link: '/Kaspersky' },
  { text: 'Microsoft Teams', link: '/teams' },
  { text: 'Veritas', link: '/Veritas' }, { text: 'Veritas', link: '/Veritas' }]
  //const subMenuServicios = ['Services In a Box', 'Migraciones', 'Help Desk', 'Capacitaciones', 'Assessment', 'Bolsa de horas', 'Implementaciones'];
  const subMenuServicios = [{ text: t('header.services'), link: '/Services In a Box' },
  // { text: 'Migraciones', link: '/Migrations' },
  { text: t('header.migraciones'), link: '/Migrations' },
  // { text: 'Help Desk', link: '/Help Desk' },
  { text: t('header.helpdesk'), link: '/Help Desk' },
  // { text: 'Capacitaciones', link: '/Trainings' },
  { text: t('header.capacitaciones'), link: '/Trainings' },
  // { text: 'Assessment', link: '/Assessment' },
  { text: t('header.assessment'), link: '/Assessment' },
  // { text: 'Bolsa de horas', link: '/Bag of hours' },
  { text: t('header.bolsa'), link: '/Bag of hours' },
  // { text: 'Implementaciones', link: '/Implementations' }];
  { text: t('header.implementaciones'), link: '/Implementations' }];

  //const subMenuRecursos = ['Materiales', 'Noticias'];
  const subMenuRecursos = [{ text: t('header.materiales'), link: '/Recursos' },
  // { text: 'Noticias', link: '/News' }];
  { text: t('header.noticias'), link: '/News' }];

  //const subMenuIndustria = ['Educación', 'Financiero', 'Gobierno', 'Retail', 'Transporte']
  const subMenuIndustria = [{ text: t('header.industria.educacion'), link: '/Education' },
  // { text: 'Financiero', link: '/Financial' },
  { text: t('header.industria.financiero'), link: '/Financial' },
  // { text: 'Gobierno', link: '/Government' },
  { text: t('header.industria.gobierno'), link: '/Government' },
  // { text: 'Retail', link: '/Retail' },
  { text: t('header.industria.retail'), link: '/Retail' },
  // { text: 'Transporte', link: '/Transport' }]
  { text: t('header.industria.transporte'), link: '/Transport' }]

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setStateOpen(open);
  };

  const handleDropDown = (index) => {

    const newArr = [...expandmenu];
    newArr[index] = !newArr[index];

    setExpandmenu([...newArr]);

  }

  const handleDropDownSubMenu = (index) => {

    //debugger
    index--
    const newArr = [...expandSubMenu];
    newArr[index] = !newArr[index];

    setExpandSubmenu([...newArr]);
  }

  const renderSubMenu = () => {

    let arrayToRender = [];

    for (let i = 0; i < subMenuIndustria.length; i++) {

      arrayToRender.push(<ListItemButton sx={{ pl: 7 }} className='item-sub-submenu' key={subMenuIndustria[i].text} onClick={toggleDrawer(false)}>
        <Link to={subMenuIndustria[i].link}>
          <ListItemText primary={subMenuIndustria[i].text} className='text-whites item-submenu-text' />
        </Link>
      </ListItemButton>)
    }

    return arrayToRender;
  }
  const renderSwitch = (param) => {

    var arrayToRender = [];
    var arrayToReturn = [];
    let doubleMenu = false;

    switch (param) {
      case 1: arrayToRender = subMenuSoluciones;
        doubleMenu = true;
        break;

      case 2: arrayToRender = subMenuServicios;
        break;

      case 3: arrayToRender = subMenuRecursos;
        break;

    }

    for (var i = 0; i < arrayToRender.length; i++) {
      if (doubleMenu && i == 5) {
        arrayToReturn.push(
          <div key={i}>
            <ListItemButton sx={{ pl: 4 }} onClick={() => handleDropDownSubMenu(i)} className='item-submenu'>

              <ListItemText primary={t('header.indust')} className="text-whites item-submenu-text" />
              {expandSubMenu[i] ? <ExpandLess className='text-whites' /> : <ExpandMore className='text-whites' />}
            </ListItemButton>

            <Collapse in={expandSubMenu[i]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderSubMenu()}

              </List>
            </Collapse>

          </div>
        )

      } else {
        arrayToReturn.push(<ListItemButton sx={{ pl: 4 }} className='item-submenu' key={arrayToRender[i].text} onClick={toggleDrawer(false)}>
          <Link to={arrayToRender[i].link}>
            <ListItemText primary={arrayToRender[i].text} className='text-whites item-submenu-text' />
          </Link>
        </ListItemButton>)
      }

    }

    return arrayToReturn
  }

  const list = (anchor) => (

    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        height: '100%',

      }}
      role="presentation"
    // onClick={toggleDrawer( false)}
    // onKeyDown={toggleDrawer( false)}
    >
      <div className='container container-hamburguer'>
        <Link to={'/'} onClick={toggleDrawer(false)}>
          <img src={logoLatWhite} alt="LogoIWS" className="d-inline-block align-text-top d-md-block logo-mobile-menu-hamburguer" />
        </Link>
      </div>
      <List>
        {[t('header.nosotros'), t('header.soluciones'), t('header.servicios'), t('header.recursos'), t('header.contacto')].map((text, index) => (
          <div key={text}>
            {
              [1, 2, 3].some(el => el === index)
                ? <> <ListItemButton onClick={() => handleDropDown(index)} className='item-menu'>
                  <ListItemIcon>

                    {index === 1 && <EmojiObjectsIcon className='text-whites' />}
                    {index === 2 && <SettingsSuggestIcon className='text-whites' />}
                    {index === 3 && <CloudCircleIcon className='text-whites' />}


                  </ListItemIcon>
                  <ListItemText primary={text} className="item-menu-text" />

                  {expandmenu[index] ? <ExpandLess className='text-whites' /> : <ExpandMore className='text-whites' />}
                </ListItemButton>
                  <Collapse in={expandmenu[index]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {renderSwitch(index)}
                    </List>
                  </Collapse>
                </>
                : <>
                  <Link to={index === 0 ? '/Nosotros' : '/Contact'} >

                    <ListItemButton className='item-menu' onClick={toggleDrawer(false)}>

                      <ListItemIcon>
                        {index === 0 && <Diversity3Icon className='text-whites' />}
                        {index === 4 && <ContactPageIcon className='text-whites' />}
                      </ListItemIcon>
                      <ListItemText primary={text} className="item-menu-text" />

                      { /* [1,2,3].some( el => el === index) && <ArrowDropDownIcon sx={ {color: 'whitesmoke'} }/> */}


                    </ListItemButton>


                  </Link>
                </>
            }



          </div>
        ))}
      </List>
      <Divider />

      <div className='d-flex justify-content-center mt-5'>
        {
          domainName.includes('redinet') === false &&
          <>
            <div className={`nav-item unnamed-character-style-1 px-lg-2 px-xl-3 pt-md-4 pt-lg-0 icon-traducir ${t("clase.es")}`}>

              <a className="nav-link navLinkMobile" title='Traducir' href='https://iwsservices.us'>
                <div className='icons-tr icons-mt-2'>
                  <img src={translate_us} height="50" width="50" className='' />

                </div>
              </a>
            </div>

            <div className={`nav-item unnamed-character-style-1 px-lg-2 px-xl-3 pt-md-4 pt-lg-0 icon-traducir ${t("clase.es")}`}>
              <a className={domainName.includes('iwsservices.com.br') ? 'd-none' : 'nav-link'} title='Traducir' href='http://iwsservices.com.br'>
                <div className='divBrasil'>
                  <img src={translate_pt} className="iconBrasil" alt="" />
                </div>
              </a>
            </div>



            {/* <li className={`nav-item unnamed-character-style-1 px-lg-2 px-xl-3 pt-md-4 pt-lg-0 icon-traducir ${t("clase.en")}`} onClick={() => i18n.changeLanguage('es')}> */}
            <div className={`nav-item unnamed-character-style-1 px-lg-2 px-xl-3 pt-md-4 pt-lg-0 icon-traducir ${t("clase.en")}`}>

              <a className="nav-link navLinkMobile" title='Traducir' href='https://iwsservices.com'>
                <div className='icons-tr icons-mt-2'>
                  <img src={translate_mx} height="50" width="50" className='' />
                </div>
              </a>


            </div>
            <div className={`nav-item unnamed-character-style-1 px-lg-2 px-xl-3 pt-md-4 pt-lg-0 icon-traducir ${t("clase.en")}`}>
              <a className={domainName.includes('iwsservices.com.br') ? 'd-none' : 'nav-link navLinkMobile'} title='Traducir' href='http://iwsservices.com.br'>
                <div className='divBrasil'>
                  <img src={translate_pt} className="iconBrasil" alt="" />
                </div>
              </a>
            </div>
            <div className={`nav-item unnamed-character-style-1 px-lg-2 px-xl-3 pt-md-4 pt-lg-0 icon-traducir ${t("clase.pt")}`}>

              <a className="nav-link navLinkMobile" title='Traducir' href='https://iwsservices.com'>
                <div className='icons-tr icons-mt-2'>
                  <img src={translate_mx} height="50" width="50" className='' />
                </div>
              </a>
            </div>

            <div className={`nav-item unnamed-character-style-1 px-lg-2 px-xl-3 pt-md-4 pt-lg-0 icon-traducir ${t("clase.pt")}`}>
              <a className="nav-link navLinkMobile" title='Traducir' href='https://iwsservices.us'>
                <div className='icons-tr icons-mt-2'>
                  <img src={translate_us} height="50" width="50" className='' />

                </div>
              </a>
            </div>



          </>
        }
      </div>

      {/* <li className={`nav-item unnamed-character-style-1 px-lg-2 px-xl-3 pt-md-4 pt-lg-0 icon-traducir ${t("clase.es")}`} onClick={() => i18n.changeLanguage('en')}> */}

    </Box>

  );

  return (
    <div id='mainmenu' className={props.scroll > 200 ? 'main-menu-color mainmenuBurger' : 'main-menu-white mainmenuBurger'} >
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          {props.scroll > 200 ?
            <MenuIcon onClick={toggleDrawer(true)} sx={{ fontSize: "3rem", color: "#1F3E8D" }} /> :
            <MenuIcon onClick={toggleDrawer(true)} sx={{ fontSize: "3rem", color: "white" }} />}
          <Drawer
            anchor={anchor}
            open={stateOpen}
            onClose={toggleDrawer(false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}