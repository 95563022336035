import React, { Fragment, useEffect } from 'react'
import { Soluciones } from './Components/Soluciones'
import { SolutionsChild1 } from './Components/Solutions/SolutionsChild1'
import { SolutionsChild2 } from './Components/Solutions/SolutionsChild2'
import { useTranslation } from "react-i18next";
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";

const Kasper = 'https://cdn.iwsservices.com/AWS.png'
const Kasper2 = 'https://cdn.iwsservices.com/AWSMovil.png'
const Informatica = 'https://cdn.iwsservices.com/Soluciones/AWS/Informática.png'
const Análisis = 'https://cdn.iwsservices.com/Soluciones/AWS/Análisis.png'
const Contenedores = 'https://cdn.iwsservices.com/Soluciones/AWS/Contenedores.png'
const Base = 'https://cdn.iwsservices.com/Soluciones/AWS/Base de Datos.png'
const image01 = 'https://cdn.iwsservices.com/Soluciones/AWS/Amazon Workspaces.png'
const image02 = 'https://cdn.iwsservices.com/Soluciones/AWS/Amazon EC2.png'
const image03 = 'https://cdn.iwsservices.com/Soluciones/AWS/Amazon S3.png'
const lineas = 'https://cdn.iwsservices.com/Líneas diagonal.png'

export function AWS() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const [t, i18n] = useTranslation('common');
  const body = {
    seguridad: [
      { src: Informatica, text: `${t('AWS.informatica')}` },
      { src: Análisis, text: `${t('AWS.analisis')}` },
      { src: Contenedores, text: `${t('AWS.contenedores')}` },
      { src: Base, text: `${t('AWS.base')}` }
    ]
  }
  const child1 = {
    text1: `${t('AWS.child1-text1')}`,
    span: `${t('AWS.child1-span')}`,
    text2: `${t('AWS.child1-text2')}`,
    span2: `${t('AWS.child1-span2')}`,
    text3: `${t('AWS.child1-text3')}`
  }
  const child2 = [
    {
      src: image01, text1: `${t('AWS.img1-text1')}`, span: `${t('AWS.img1-span')}`,
      text2: `${t('AWS.img1-text2')}`, value: 1
    },
    {
      src: image02, text1: `${t('AWS.img2-text1')}`, span: `${t('AWS.img2-span')}`,
      text2: `${t('AWS.img2-text2')}`, value: 0
    },
    {
      src: image03, text1: `${t('AWS.img3-text1')}`, span: `${t('AWS.img3-span')}`,
      text2: `${t('AWS.img3-text2')}`, value: 1
    },
  ]
  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresKasFather">
          <Soluciones image={Kasper} image2={Kasper2} img2={lineas} />
        </div>
        <div id="KasperChild1" className='mb-4 soluciones'>
          <SolutionsChild1 images={body.seguridad} text1={child1.text1} span={child1.span} text2={child1.text2} span2={child1.span2} text3={child1.text3} />
        </div>
        <div id="KasperChild2" className='my-4 margin-top descriptionAWS'>
          <SolutionsChild2 image={child2} button={{ text: `${t('AWS.button')}`, rute: "/" }} />
        </div>
      </Fragment>
      <Footer />
      <ToggleComp />
    </div>
  )
}
