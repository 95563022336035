import React from 'react'
import {useTranslation} from "react-i18next";

export function ServicesChild1(props) {
  const [t, i18n] = useTranslation('common');
  const domainName = window.location.hostname;

  return (
    <div className='div-ServChild1 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-9 row justify-content-center'>
      <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex justify-content-center'>
        <img src={props.imga} alt="" height="334px" width="334px" className=''/>
      </div>
      <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8 row info-Services-father text-unnamed'>
        <div className='text-center info-Services text-azul-marino position-relative translate-middle start-50'>
          <p className='p-Services text-azul'>
            {t('services.soluciones')}
          </p>
          {t('services.lugar')}
        </div>
        <div className='pt-4 text-center p-services'>
          <p>
          {t('services.parte')}
          </p>
        </div>
        <p className=' text-center info-Services-2'>
        {domainName.includes('redinet.us') ? 'At Redinet, customer service is a priority.' : 
        domainName.includes('iwsservices.com.br') ? 'Na Redinet, o atendimento aos nossos clientes é prioridade.' : 
        domainName.includes('redinet.cr') || domainName.includes('redinet.pe')  || domainName.includes('redinet.com.mx') ? 'En Redinet, el servicio a nuestros clientes es una prioridad.' : 
        domainName.includes('iwsservices.com') ? 'En Redinet, el servicio a nuestros clientes es una prioridad.' :
        'At Redinet, customer service is a priority.' }

        </p>
      </div>
    </div>
  )
}
