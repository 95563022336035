import { useEffect } from 'react';

const useScript = (url, integrity) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.integrity = integrity;

    document.body.appendChild(script);

    //  const timer = setTimeout(() => {
    //   let element = document.querySelector('.fd-button');
    //   if(element){
    //     element.setAttribute('title',"Sent");
    //     element.setAttribute('onClick',"return gtag_report_conversion('')");
    //   }
    // }, 7000);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

export default useScript;