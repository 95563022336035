import React from 'react'
import { SVG } from '../SVGCreate'
import { useTranslation } from "react-i18next"; import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { BrowserView, isTablet, MobileView } from 'react-device-detect';

export const RecursosChild1 = () => {

  const [t, i18n] = useTranslation('common');

  const iconArrow = {
    xmlns: "http://www.w3.org/2000/svg",
    ancho: "59",
    alto: "42",
    currentColor: "white",
    clase: "bi bi-arrow-left",
    viewCaja: "-2 2 17 11",
    d: "M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"

  };
  return (

    <div>
      <div className='container'>
        <BrowserView>
          <div class="d-flex">
            <div class="p-2">
              <div className={`vr-2 align-self-center p-0 ${t("clase.es")}`}>
                <a href='https://cursos.iwsservices.com/' target="_blank" rel="noopener noreferrer">
                  <SVG xmlns={iconArrow.xmlns} ancho={iconArrow.ancho} alto={iconArrow.alto} currentColor={iconArrow.currentColor} clase={iconArrow.clase}
                    viewCaja={iconArrow.viewCaja} d={iconArrow.d} />
                </a>
              </div>
              <div className={`vr-2EN align-self-center p-0 ${t("clase.en")}`}>
                <a href='https://cursos.iwsservices.com/' target="_blank" rel="noopener noreferrer">
                  <SVG xmlns={iconArrow.xmlns} ancho={iconArrow.ancho} alto={iconArrow.alto} currentColor={iconArrow.currentColor} clase={iconArrow.clase}
                    viewCaja={iconArrow.viewCaja} d={iconArrow.d} />
                </a>
              </div>

              <p className='text-unnamed text-27-light p-recursos px-3'>
                {t('recursos.portal-cursos')}
                <span className='text-azul-marino s-recursos-Child1'> {t('recursos.lugar')} </span>
                {t('recursos.portal-cursos2')}
              </p>
            </div>
            <div class="p-2 flex-grow-1">
              <Card className='card-service-borders m-top-negatinve-10'>
                <Card.Body className="text-cent">
                  <div className='text-center'>
                    <Card.Title><span className='size-rm2 bg-blue'><FontAwesomeIcon icon={faGraduationCap} /></span></Card.Title>
                  </div>
                  <Card.Title>{t('recursos.portal')}</Card.Title>
                  {/* <a className='arrow-right-icon'>Leer más    <FontAwesomeIcon icon={faArrowRight} /></a> */}
                  <a className='arrow-right-icon' href='https://cursos.iwsservices.com/' target="_blank">{t('aboutUs.vermas')}  <FontAwesomeIcon icon={faArrowRight} /></a>
                </Card.Body>
              </Card>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div class="d-flex flex-column">
            <div class="p-2">
              <div className={`vr-2 align-self-center p-0 ${t("clase.es")}`}>
                <a href='https://cursos.iwsservices.com/' target="_blank" rel="noopener noreferrer">
                  <SVG xmlns={iconArrow.xmlns} ancho={iconArrow.ancho} alto={iconArrow.alto} currentColor={iconArrow.currentColor} clase={iconArrow.clase}
                    viewCaja={iconArrow.viewCaja} d={iconArrow.d} />
                </a>
              </div>
              <div className={`vr-2EN align-self-center p-0 ${t("clase.en")}`}>
                <a href='https://cursos.iwsservices.com/' target="_blank" rel="noopener noreferrer">
                  <SVG xmlns={iconArrow.xmlns} ancho={iconArrow.ancho} alto={iconArrow.alto} currentColor={iconArrow.currentColor} clase={iconArrow.clase}
                    viewCaja={iconArrow.viewCaja} d={iconArrow.d} />
                </a>
              </div>

              <p className='text-unnamed text-27-light p-recursos px-3'>
                {t('recursos.portal-cursos')}
                <span className='text-azul-marino s-recursos-Child1'> {t('recursos.lugar')} </span>
                {t('recursos.portal-cursos2')}
              </p>
            </div>
            <div class="p-2">
              <Card className='card-service-borders'>
                <Card.Body className="text-cent">
                  <div className='text-center'>
                    <Card.Title><span className='size-rm2 bg-blue'><FontAwesomeIcon icon={faGraduationCap} /></span></Card.Title>
                  </div>
                  <Card.Title>{t('recursos.portal')}</Card.Title>
                  {/* <a className='arrow-right-icon'>Leer más    <FontAwesomeIcon icon={faArrowRight} /></a> */}
                  <a className='arrow-right-icon' href='https://cursos.iwsservices.com/' target="_blank">{t('aboutUs.vermas')}  <FontAwesomeIcon icon={faArrowRight} /></a>
                </Card.Body>
              </Card>
            </div>
          </div>
        </MobileView>

        <div class="d-flex flex-row-reverse">
          <div class="p-2 fz-28">
            <p className='text-center'>{t('recursos.aprende')}</p>
            <div className='info-Recursos-Child1 text-sm-start text-md-end p-0 m-0 fz-28 text-center'>
              {t('recursos.productividad')} <span className='text-span-azul fz-28'>|</span> {t('recursos.microsoft')} <span className='text-span-azul fz-28'>|</span> {t('recursos.google')} <span className='text-span-azul fz-28'>|</span>  {t('recursos.ventas')} <span className='text-span-azul fz-28'>|</span>  {t('recursos.seguridad')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
