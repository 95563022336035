import React, { useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: '',
        },
    },
};

const labels = ['Soy Cliente nuevo y quiero comprar productos', 'Soy Cliente existente y solicito soporte de mesa de ayuda', 'Soy Cliente existente y solicito productos adicionales', 'Soy Cliente existente y tengo un problema administrativo', 'Quiero contactar un ejecutivo'];

const data = {
    labels,
    datasets: [
        {
            label: 'Servicios más buscados',
            data: labels.map(() => faker.number.int()),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }
    ],
};


function VerticalBarChart() {
    useEffect(() => {
        console.log(data);
    }, [])
    return (
        <div className='container'>
            <Bar options={options} data={data} />
        </div>

    );
}


export default VerticalBarChart;