import React from 'react'

export function SolutionsChild1(props) {
  const imagenes = props.images.map((images, index) =>
    <div className='col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-xxl-2 d-flex flex-column justify-content-start position-relative img-position' key={index} style={{ top: images.top }}>
      <img src={images.src} className="align-self-center img-fluid imgIcons" alt='' />
      <div className='text-azul-marino text-center solutions-img'>
        <p className='p-solutions'>
          {images.text}
        </p>
      </div>
    </div>
  );
  return (

    <div>
 
      <div className='row m-0 p-0'>
        <div className='row m-0 p-0'>
          <div className='col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8 d-flex justify-content-end position-relative top-50 start-50 translate-middle' id="ImagesPres">
            {imagenes}
          </div>
        </div>
        <div className='row col-12 m-0 p-0 justify-content-end text-unnamed'>
          <div className='row col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-9 p-0 m-0'>
            <div className='divSolucion-p m-0 p-0 row col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-4 text-azul text-center align-content-end'>
              <p className='p-Solu-child1 m-0 p-0'>
                {props.text1}
              </p>
              <span className='s-Solu-child1 text-unnamed'>{props.span}</span>
            </div>
            <div className='row col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-6 div-Solu-child1 text-center m-0 p-0'>
              <p className='p-Solutions'>
                {props.text2} <span className="fw-bold">{props.span2}</span>{props.text3}
              </p>
            </div>
            <hr></hr>
          </div>
        </div>
      </div>
    </div>
  )
}
