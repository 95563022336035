import React from 'react'
import { useEffect, useContext, useState, useRef } from 'react'
import { ContextDataChat } from "../index"
import { addUserConversation, addUSerSelected, ActivateConversation, SaveNameUser, Info } from "../redux/dataSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { ContextDataChatIWS } from "../index"
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import axios from 'axios'
import '../CSS/Historico.css'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChatNoHistory from './ChatBotInterfaz/ChatNoHistory';
import email from "../Images/correo.png";


const Historico = () => {
    const [Convesation, setConvesation] = useState([]);
    const [boolHistoricoSkeleton, setboolHistoricoSkeleton] = useState(true);
    const { ObjChatIWS, updateIWS } = useContext(ContextDataChat);
    const { ObjChat, UpdateObjHub } = useContext(ContextDataChatIWS);
    const [HistoryConversationUser, setHistoryConversationUser] = useState([]);
    const inputElement = useRef()
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        let _email = ObjChatIWS.UserSelected;
        const HistoryConversation = async () => {
            try {
                const GetConversationHistory = `${ObjChat.api}/api/Chat/GetConversationHistory?`;
                await axios.post(`${GetConversationHistory}emailUser=${_email}`)
                    .then((res) => {
                        let _history = res.data.data;
                        setHistoryConversationUser(_history)
                        dispatch(addUserConversation(_history))
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });

            }
            catch (err) {
                //console.log(err);

            }

            return null
        }

        HistoryConversation();
    }, [])



    const handleUsuarioClick = (usuario) => {
        let _email = usuario.email;
        let _ResEmail = ObjChatIWS.UserSelected
        let _resp;

        dispatch(SaveNameUser(usuario.name))
        const GetChat = `${ObjChat.api}/api/Chat/GetChat?`;
        axios.post(`${GetChat}emailUser=${_ResEmail}&emailClient=${_email}`)
            .then((res) => {
                if (res.data.code === 0) {
                    let History = res.data.data
                    dispatch(addUSerSelected(History))
                }
                else {
                    _resp = -1
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        dispatch(ActivateConversation(true))
        setboolHistoricoSkeleton(false)

    };

    const handleUsuarioClickM = (usuario) => {
        let _email = usuario.email;
        let _ResEmail = ObjChatIWS.UserSelected
        let _resp;
        dispatch(SaveNameUser(usuario.name))
        const GetChat = `${ObjChat.api}/api/Chat/GetChat?`;
        axios.post(`${GetChat}emailUser=${_ResEmail}&emailClient=${_email}`)
            .then((res) => {
                if (res.data.code === 0) {
                    let History = res.data.data
                    dispatch(addUSerSelected(History))
                }
                else {
                    _resp = -1
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        dispatch(ActivateConversation(true))
        setboolHistoricoSkeleton(false)


        dispatch(Info(false))



    };

    const handleClickReturn = () => {
        setboolHistoricoSkeleton(true)
        dispatch(Info(true))
    }

    const EnviarEmail = () => {
        console.log('se ah enviado mensaje')
    }

    return (
        <div className='container-historico'>
            <BrowserView>
                {
                    user.UserConversation.length > 0 ?
                        <ul className="users-chat-msg mt-1">
                            {Object.entries(Convesation).length !== 1 ?
                                <div>
                                    {
                                        user.UserConversation.map((item, index) => (
                                            <li key={index} onClick={() => handleUsuarioClick(item)} >
                                                <div className="Avatar-name">
                                                    <span>{item.letter.toUpperCase()}</span>
                                                </div>
                                                <div className="Name-Responsable">
                                                    <h1 className="leading-normal font-medium text-md">
                                                        {item.name} </h1>
                                                    <span className='leading-normal text-smll'>{item.email}</span>
                                                </div>
                                            </li>
                                        ))
                                    }

                                </div>
                                :
                                <div className='aqui esta!!!'>
                                    <span>aqui quitamos la clase</span>
                                </div>
                            }

                        </ul>
                        :
                        <ChatNoHistory />
                }

            </BrowserView>
            <MobileView>
                {
                    user.UserConversation.length > 0 ?
                        <div>
                            {boolHistoricoSkeleton ?
                                <ul className="users-chat-msg mt-1">
                                    <div>
                                        {
                                            user.UserConversation.map((item, index) => (
                                                <li key={index} onClick={() => handleUsuarioClickM(item)} >
                                                    <div className="Avatar-name">
                                                        <span>{item.letter.toUpperCase()}</span>
                                                    </div>
                                                    <div className="Name-Responsable">
                                                        <h1 className="leading-normal font-medium text-md">
                                                            {item.name} </h1>
                                                        <span className='leading-normal text-smll'>{item.email}</span>
                                                    </div>
                                                </li>
                                            ))
                                        }

                                    </div>


                                </ul> :
                                <div className='aqui esta!!!'>
                                    <div>
                                        <div className="msg-admin-chat mobile-chat-admin">
                                            <div className="header-msg-admin">
                                          
                                                <h2 className='fs-20 f-bold'> <span
                                                    onClick={() => handleClickReturn()}
                                                ><FontAwesomeIcon className="icon-faArrowCircleLeft" icon={faArrowCircleLeft} /> </span>{user.savenameuser}</h2>
                                                <br></br>
                                         
                                                {/* <div className='email' >
                                                    <button type="button" onClick={EnviarEmail}>
                                                        <img className='enviar_email' src={email} />
                                                    </button>
                                                </div> */}
                                            </div>
                                            <div id="body-msg-admin" className="body-msg-admin autoscroll-content">
                                                <div>
                                                    <ul className="users-chat-msgi">
                                                        <div className="d-flex flex-column">
                                                            {

                                                                user.UserSelected.map(
                                                                    (Chat, index) =>
                                                                        <div key={index}>
                                                                            <div className={Chat.className}>

                                                                                <div className="Name-User">
                                                                                    <span>{Chat.text}</span>
                                                                                </div>
                                                                            </div>
                                                                            <label className="black-letter-chat small-text-chat">{Chat.time}</label>
                                                                        </div>)
                                                            }
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <ChatNoHistory />
                }
            </MobileView>


        </div>
    )
}

export default Historico;