import React, { useState, useEffect,useRef } from "react";
import { SlickCarrusel } from "./Components/Home/SlickCarrusel";
import { Carrusel } from "./Components/Home/Carrusel";
import { Carrusel2 } from "./Components/Home/Carrusel";
import { Carrusel2En } from "./Components/Home/Carrusel";
import { CarruselEn } from "./Components/Home/Carrusel";
import { Info } from "./Components/Home/Info";
import { useTranslation } from "react-i18next";
import OurServices from './Components/Home/OurServices'
import Ebook from './Components/Home/Ebook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Banner from "./Components/Home/Banner";
import CardBanner from "./Components/Home/CardBanners";
import FlowChartComp from "./Components/Home/FlowChartComp";
import SolutionsComp from "./Components/Home/SolutionsComp";
import UsComp from "./Components/Home/UsComp";
import { Diagram } from "./Components/Diagram";
import { Dynamic } from './Components/Home/Dynamic'

import { Dynamic365 } from "./Components/Home/Dynamic365";

import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";

const Revolverimg = 'https://cdn.iwsservices.com/Home/Revolver-img.png'
const ImgHome0Pt = "https://cdn.iwsservices.com/Home/Home0_pt.png";
const ImgHome0 = "https://cdn.iwsservices.com/Home/New/HomeCarouse_Esp.png";
const ImgHome0En = "https://cdn.iwsservices.com/Home/Home0_en.png";
const ImgHome1 = "https://cdn.iwsservices.com/Home/New/HomeCarouse_Esp2.png";
const ImgMap = "https://cdn.iwsservices.com/map_es.png";
const ImgMapMobile = "https://cdn.iwsservices.com/map_mobile.png";
const ImgHome1En = "https://cdn.iwsservices.com/Home/Home En.png";
const ImgHome3 = "https://cdn.iwsservices.com/Home2Movil.png";
const ImgHome3m = "https://cdn.iwsservices.com/Home/Home_azul.png";
const ImgHome3mEn = "https://cdn.iwsservices.com/Home/Home0_pt.png";
const ImgHome3En = "https://cdn.iwsservices.com/Home2MovilEn.png";
const ImgHome2 = "https://cdn.iwsservices.com/Home/New/HomeCarouse_Esp3.png";
const ImgHome2En = "https://cdn.iwsservices.com/Home/Carrusel 2 En.png";
const ImgHome4 = "https://cdn.iwsservices.com/HomeMovil.png";
const ImgHome4En = "https://cdn.iwsservices.com/HomeMovilEn.png";
const Forcepoint = 'https://cdn.iwsservices.com/Home/Forcepoint color.png';
const Microsoft = 'https://cdn.iwsservices.com/Home/Microsoft color.png';
const Kaspersky = 'https://cdn.iwsservices.com/Home/Kaspersky.png';

const Aruba = 'https://cdn.iwsservices.com/Home/Aruba.png';
const DellEMC = 'https://cdn.iwsservices.com/Home/Dell.png';
const Fortinet = 'https://cdn.iwsservices.com/Home/Fortinet.png';
const Lenovo = 'https://cdn.iwsservices.com/Home/Lenovo.png';
const TecTime = 'https://cdn.iwsservices.com/Home/Tecdime.png';
const TrippLite = 'https://cdn.iwsservices.com/Home/Tripp-Lite.png';


const Google = 'https://cdn.iwsservices.com/Home/Google color.png';
const Azure = 'https://cdn.iwsservices.com/Home/Logos_multinubes-01.png';
const AWS = 'https://cdn.iwsservices.com/Home/AWS color.png'
const CISCO = 'https://cdn.iwsservices.com/Home/Cisco color.png'
const HP = 'https://cdn.iwsservices.com/Home/HP color.png'
const IBM = 'https://cdn.iwsservices.com/Home/IBM color.png'
const Veritas = 'https://cdn.iwsservices.com/Home/Veritas color.png'
const Trend = 'https://cdn.iwsservices.com/Home/Trend color.png'
const Promo = 'https://cdn.iwsservices.com/Home/Promo.png'



export function App() {
  const domainName = window.location.hostname;

  const [t, i18n] = useTranslation('common');

  const imga = [
    { img: Microsoft, alt: "Microsoft" },
    { img: Kaspersky, alt: "Kaspersky" },
    { img: Forcepoint, alt: "FORCEPOINT" },
    { img: CISCO, alt: "CISCO" },
    { img: HP, alt: "HP", clase: "img-HP" },
    { img: IBM, alt: "IBM" }
  ];

  const imga2 = [{
    img: Veritas, alt: "Veritas"
  },
  { img: Trend, alt: "Trend" },
  { img: Aruba, alt: "Aruba" },
  { img: DellEMC, alt: "DellEMC" },
  { img: Fortinet, alt: "Fortinet" },
  { img: Lenovo, alt: "Lenovo" },
  { img: TecTime, alt: "TecTime" },
  { img: TrippLite, alt: "TrippLite" }];

  const CarruselHome = [
    { src: ImgHome0, alt: "Home1", Id: 1, clase: "carousel-item active", btnText: `${t('home.btn')}`, btnSrc: "/Education" },
    { src: ImgHome1, alt: "Home2", Id: 2, clase: "carousel-item", btnText: `${t('home.btn')}`, btnSrc: "/Nosotros" },
    { src: ImgHome2, alt: "Home3", Id: 3, clase: "carousel-item", btnText: `${t('home.btn')}`, btnSrc: "/Services In a Box" }];

  const CarruselHomeEn = [
    { src: ImgHome0En, alt: "Home1", Id: 1, clase: "carousel-item active", btnText: `${t('home.btn')}`, btnSrc: "/Education" },
    { src: ImgHome1En, alt: "Home2", Id: 2, clase: "carousel-item active", btnText: `${t('home.btn')}`, btnSrc: "/Nosotros" },
    { src: ImgHome2En, alt: "Home3", Id: 3, clase: "carousel-item", btnText: `${t('home.btn')}`, btnSrc: "/Services In a Box" }];

  const CarruselHomePt = [
    { src: ImgHome0Pt, alt: "Home1", Id: 1, clase: "carousel-item active", btnText: `${t('home.btn')}`, btnSrc: "/Education" },
    { src: ImgHome1En, alt: "Home2", Id: 2, clase: "carousel-item active", btnText: `${t('home.btn')}`, btnSrc: "/Nosotros" },
    { src: ImgHome2En, alt: "Home3", Id: 3, clase: "carousel-item", btnText: `${t('home.btn')}`, btnSrc: "/Services In a Box" }];

  const CarruselHome2 = [
    { src: ImgHome3m, alt: "Home4", Id: 4, clase: "carousel-item active", btnText: `${t('home.btn')}`, btnSrc: "/Education" }];

  const CarruselHome2En = [
    { src: ImgHome3mEn, alt: "Home4", Id: 4, clase: "carousel-item active", btnText: `${t('home.btn')}`, btnSrc: "/Education" },
    { src: ImgHome3En, alt: "Home1", Id: 5, clase: "carousel-item active", btnText: `${t('home.btn')}`, btnSrc: "/Nosotros" },
    { src: ImgHome4En, alt: "Home2", Id: 6, clase: "carousel-item", btnText: `${t('home.btn')}`, btnSrc: "/Services In a Box" }];

  const CarruselHome2Pt = [
    { src: ImgHome3mEn, alt: "Home4", Id: 4, clase: "carousel-item active", btnText: `${t('home.btn')}`, btnSrc: "/Education" },
    { src: ImgHome3En, alt: "Home1", Id: 5, clase: "carousel-item active", btnText: `${t('home.btn')}`, btnSrc: "/Nosotros" },
    { src: ImgHome4En, alt: "Home2", Id: 6, clase: "carousel-item", btnText: `${t('home.btn')}`, btnSrc: "/Services In a Box" }];


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  return (
    <div>

      <Header />
      {/*"Section Banner"*/}
      <Banner />
      {/*"Section Card Banner"*/}
      <CardBanner />
      {/*"Section Imagen Flujo"*/}
      {/*<FlowChartComp /> */}
      <Diagram text="Hola">
        <img src={Revolverimg} alt="image" />
      </Diagram>
      {/*"Section Our Services"*/}
      <OurServices />
      <SolutionsComp />
      {/*"Section Solutions"*/}
      {/*<Dynamic/>*/}
      {/*"Section UsComp"*/}
      <UsComp />
      <Dynamic365 />


      {/*"Section Marcas"*/}
      <SlickCarrusel img={imga} imga2={imga2} />

      <ToggleComp />
      <Footer />
    </div>
  )
}