import React, { useCallback, useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { useTranslation } from "react-i18next";

const PartnerContainer = 'https://cdn.iwsservices.com/Nosotros/GoldPartnervacio.png'
const logo1 = 'https://cdn.iwsservices.com/Nosotros/Logos Partners-01.png'
const logo2 = 'https://cdn.iwsservices.com/Nosotros/Logos Partners-02.png'
const logo3 = 'https://cdn.iwsservices.com/Nosotros/LogosPartners-03_web.png'
const logo4 = 'https://cdn.iwsservices.com/Nosotros/LogosPartners-04_web.png'

const fondoPartnerts = 'https://cdn.iwsservices.com/Nosotros/Fondo_logos_partner.png';
const logo1web = 'https://cdn.iwsservices.com/Nosotros/Logos_Partners-01_web.png';
const logo2web = 'https://cdn.iwsservices.com/Nosotros/LogosPartners-02_web.png';
const logo3web = 'https://cdn.iwsservices.com/Nosotros/LogosPartners-03_web.png';
const logo4web = 'https://cdn.iwsservices.com/Nosotros/LogosPartners-04_web.png';
const iso = 'https://cdn.iwsservices.com/logoCav.png'

const Partners = props => {
    const [t, i18n] = useTranslation('common');
    const domainName = window.location.hostname;

    const [animateClass, setAnimateClass] = useState('');
    const [animateClass2, setAnimateClass2] = useState('');
    const [isMounted, setIsMounted] = useState(true);

    const handleScrollUs = useCallback(() => {
        if (isMounted) {

            if (window.pageYOffset >= 1700) {
                setAnimateClass('animate-slidein');

            } else if (window.pageYOffset < 1700) {
                setAnimateClass('');

            }
            if (window.pageYOffset >= 1600) {
                setAnimateClass2('animate-slidein');

            } else if (window.pageYOffset < 1600) {
                setAnimateClass2('');

            }
        }
    }, [isMounted]);

    React.useEffect(() => {
        //window.onscroll = () => handleScrollUs();

        window.addEventListener('scroll', handleScrollUs);

        return (
            () => {
                setIsMounted(false);
            }
        )
    }, [handleScrollUs]); // IMPORTANT, This will cause react to update depending on change of this value

    return (
        <>
            <BrowserView className='bg-gray'>
                <div className='bg-white container-snake-left-rr_'>
                    <div className='container-expertos-rr'>
                        <div className='text-center mg-0'>
                            <h2 className="span-our-alianza span-info-Aliado">{t('aboutUs.empresa')}</h2>
                            <h2 className='title-a-section-home our-alianza'>{t('aboutUs.expertos')}</h2>
                        </div>

                    </div>
                </div>
                <div className='bg-white row m-0 p-0 col-12 img-recortada-sm container-snake-left-rr position-relative'>
                    <img src={fondoPartnerts} alt="" className='img-fluid m-0 p-0' />
                    <img src={logo1web} className='logo1-partner logo-anime1'></img>
                    <img src={logo3web} className='logo1-partner logo-anime2'></img>
                    <img src={logo4web} className='logo2-partner logo-anime1'></img>
                    <img src={logo2web} className='logo2-partner logo-anime2'></img>

                    <div className='text-center container-expertos-rr  pb-4'>
                        <p className="p-expertors-rr">
                            <span className="span-expertos-rr">{domainName.includes('redinet.us') ? 'At Redinet ' : domainName.includes('redinet.pe') || domainName.includes('redinet.cr') || domainName.includes('redinet.com.mx') ? 'En Redinet ' : domainName.includes('iwsservices.com') ? 'En Redinet ' : 'At Redinet '}

                                {t('aboutUs.tecnologia')}</span>
                            <span> {t('aboutUs.colaborar')}</span>
                        </p>
                    </div>
                </div>
                <div className='bg-white'>
                    <div className='bg-gray container-snake-left-rr_5'>
                        <div className='container'>


                            <div className="row text-info-empresa-rr">
                                <div className='col-4'>
                                    <div className='d-flex align-items-center' style={{ height: '100%' }}>
                                        <img src={iso} alt="" className='img-fluid' />
                                    </div>
                                </div>
                                <div className='col-8'>
                                    <div className='text-left'>
                                        <p className='title-certifica-rr linea'>
                                            <span>{t('aboutUs.certificada')}</span>
                                        </p>
                                    </div>

                                    <div className='text-left border-left-rr'>
                                        <div className=''>
                                            <br />
                                            <ul>
                                                <li> <p className='p-certifica-rr first-bullet'>
                                                    {t('aboutUs.objetivo')}
                                                </p></li>
                                                <li>   <p className='p-certifica-rr-bold first-bullet'>
                                                    {t('aboutUs.objetivo2')}
                                                </p></li>
                                            </ul>

                                            <br />

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </BrowserView>
            <MobileView className='bg-gray'>
                <div className='bg-white container-snake-left-rr_'>
                    <div className='row'>
                        <div className='col-12 box-title-rr'>
                            <p className='title-rr'>{t('aboutUs.empresa')} </p>
                            <p className='subtitle-rr'>{t('aboutUs.expertos')}</p>
                        </div>
                    </div>
                </div>
                <div className='bg-white pb-4 container-snake-left-rr'>
                    <div className='row'>
                        <div className='col-12 p-0'>
                            <div className='box-img-f'>
                                <img src={PartnerContainer} alt="Partners de Redinet" className='partners-mobile-rr' />
                                <div className='logo-partner logo1 logo-anime1'>
                                    <img src={logo1} alt="Logo Partner 1" />
                                </div>
                                <div className='logo-partner logo2 logo-anime2'>
                                    <img src={logo2} alt="Logo Partner 2" />
                                </div>
                                <div className='logo-partner logo1 logo-anime2'>
                                    <img src={logo3} alt="Logo Partner 3" />
                                </div>
                                <div className='logo-partner logo2 logo-anime1'>
                                    <img src={logo4} alt="Logo Partner 4" />
                                </div>
                            </div>

                        </div>
                        
                    </div>

                    <div className='row mt-4'>
                        
                    <div className='col-12'>
                            <p className='text-info-partners fw-bold'>

                                {domainName.includes('redinet.us') ? 'At Redinet ' : domainName.includes('redinet.pe') || domainName.includes('redinet.cr') || domainName.includes('redinet.com.mx') ? 'En Redinet ' : domainName.includes('iwsservices.com') ? 'En Redinet ' : 'At Redinet '}

                                {t('aboutUs.tecnologia')}

                            </p>
                            <p className='text-info-partners'>
                                {t('aboutUs.colaborar')}

                            </p>
                        </div>

                    </div>
                </div>
                <div className='bg-white'>
                    <div className='bg-gray container-snake-left-rr_5 pt-4 pb-4'>
                        <div className='row'>

                        </div>
                        <div className='row box-container'>
                            <div className='col-12'>
                                <div className='d-flex justify-content-center'>
                                    <img src={iso} alt="" className='img-iso' />

                                </div>

                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='box-certificada'>
                                    <div className=''>
                                        <p className='certificada-title text-info-partners'>
                                            {t('aboutUs.certificada')}
                                        </p>
                                    </div>

                                    <ul className=''>
                                        <li className='first-bullet'>
                                            <div >
                                                <div className='icon-list'></div>
                                                <div>
                                                    <p className={`text-info-certificada ${animateClass2}`}>
                                                        {t('aboutUs.objetivo')}
                                                    </p>
                                                </div>

                                            </div>

                                        </li>
                                        <li className='first-bullet'>
                                            <div>
                                                <div className='icon-list'></div>
                                                <div>
                                                    <p className={`text-info-certificada ${animateClass}`}>
                                                        {t('aboutUs.objetivo2')}
                                                    </p>
                                                </div>
                                            </div>

                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </MobileView>
        </>
    )
}

export default Partners