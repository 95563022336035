import React, { useState, useContext } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import "./Components/ChatBotInterfaz/css/CharResponsable.css";
import InterfazChatBoot from "../src/InterfazChatBoot";
import {
    MsalProvider,
    useMsal,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../src/authConfig';


export function ChatAdmin() {

    //Estado donde se guarda el nombre del usuario
    const pca = new PublicClientApplication(msalConfig);
    const LoginButton = () => {
        const { instance } = useMsal();
        const handleLogin = () => {
            instance.loginRedirect();
        };
        return (
            handleLogin()
        );
    }
    function WelcomeUser() {
        const { accounts } = useMsal();
        return <InterfazChatBoot accounts={accounts} />
    }

    return (
        <div>
            <MsalProvider instance={pca}>
                <AuthenticatedTemplate>
                    <WelcomeUser />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <LoginButton />
                </UnauthenticatedTemplate>
            </MsalProvider>
        </div >
    );
};



