import React, { Fragment, useEffect } from 'react'
import { Soluciones } from './Components/Soluciones'
import { SolutionsChild1 } from './Components/Solutions/SolutionsChild1'
import { SolutionsChild2 } from './Components/Solutions/SolutionsChild2'
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";
import { useTranslation } from "react-i18next";

const Respaldo = 'https://cdn.iwsservices.com/Soluciones/Veritas/Respaldo.png'
const Cumplimiento = 'https://cdn.iwsservices.com/Soluciones/Veritas/Cumplimiento.png'
const proteccion = 'https://cdn.iwsservices.com/Soluciones/Veritas/Protección.png'
const Disponibilidad = 'https://cdn.iwsservices.com/Soluciones/Veritas/Disponibilidad.png'
const image01 = 'https://cdn.iwsservices.com/Soluciones/Veritas/Backup.png'
const image02 = 'https://cdn.iwsservices.com/Soluciones/Veritas/Desktop.png'
const Kasper = 'https://cdn.iwsservices.com/Veritas.png'
const Kasper2 = 'https://cdn.iwsservices.com/VeritasMovil.png'

export function Veritas() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const [t, i18n] = useTranslation('common');

  const body = {
    seguridad: [
      { src: Respaldo, height: "145px", width: "145px", text: `${t('veritas.respaldo')}` },
      { src: Cumplimiento, height: "145px", width: "145px", text: `${t('veritas.cumplimiento')}` },
      { src: proteccion, height: "145px", width: "145px", text: `${t('veritas.proteccion')}` },
      { src: Disponibilidad, height: "145px", width: "145px", text: `${t('veritas.disponibilidad')}` }
    ]
  }
  const child1 = {
    text1: `${t('veritas.child1-text1')}`,
    span: `${t('veritas.child1-span')}`,
    text2: `${t('veritas.child1-text2')}`,
    span2: `${t('veritas.child1-span2')}`,
    text3: `${t('veritas.child1-text3')}`,
  }
  const child2 = [
    {
      src: image01,
      text1: `${t('veritas.img1-text1')}`,
      span: `${t('veritas.img1-span')}`,
      text2: `${t('veritas.img1-text2')}`,
      value: 1
    },
    {
      src: image02,
      text1: `${t('veritas.img2-text1')}`,
      span: `${t('veritas.img2-span')}`,
      text2: `${t('veritas.img2-text2')}`,
      value: 0
    },
  ]
  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresKasFather">
          <Soluciones image={Kasper} image2={Kasper2} />
        </div>
        <div id="KasperChild1" className='mb-4 soluciones'>
          <SolutionsChild1 images={body.seguridad} text1={child1.text1} span={child1.span} text2={child1.text2} span2={child1.span2} text3={child1.text3} />
        </div>
        <div id="KasperChild2" className='my-4 margin-top'>
          <SolutionsChild2 image={child2} button={{ text: `${t('AWS.button')}`, rute: "/" }} />
        </div>
      </Fragment>
      <Footer />
      <ToggleComp />
    </div>
  )
}
