import React, { Fragment, useEffect } from 'react'
import { Presentation } from './Components/Presentation'
import { ProblemsContainer } from './Components/Industry/ProblemsContainer'
import { ProposalsContainer } from './Components/Industry/ProposalsContainer'
import { ProposalsContainerHorizontal } from './Components/Industry/ProposalsContainerHorizontal'
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from 'react-device-detect'
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";

const retail = 'https://cdn.iwsservices.com/Industrias/Retail/Retail.png'
const retail_mov = 'https://cdn.iwsservices.com/Industrias/Retail/Retail_Mobile.png'
const headerImg = 'https://cdn.iwsservices.com/Industrias/Retail/header_image.png'
const firstCard = 'https://cdn.iwsservices.com/Industrias/Retail/cards/1.4.png'
const secondCard = 'https://cdn.iwsservices.com/Industrias/Retail/cards/2.4.png'
const thirdCard = 'https://cdn.iwsservices.com/Industrias/Retail/cards/3.4.png'
const fourhCard = 'https://cdn.iwsservices.com/Industrias/Retail/cards/4.4.png'
const proposal1 = 'https://cdn.iwsservices.com/Industrias/Retail/proposals/Grupo 1.png'
const proposal2 = 'https://cdn.iwsservices.com/Industrias/Retail/proposals/Grupo 2.png'
const proposal3 = 'https://cdn.iwsservices.com/Industrias/Retail/proposals/Grupo 3.png'
const proposal1Pt = 'https://cdn.iwsservices.com/Industrias/Retail/proposals/Grupo 1 portugues.png'
const proposal2Pt = 'https://cdn.iwsservices.com/Industrias/Retail/proposals/Grupo 2 portugues.png'
const proposal3Pt = 'https://cdn.iwsservices.com/Industrias/Retail/proposals/Grupo 3 portugues.png'
const proposal1Hori = 'https://cdn.iwsservices.com/Industrias/Retail/proposals/Grupo 1 hori.png'
const proposal2Hori = 'https://cdn.iwsservices.com/Industrias/Retail/proposals/Grupo 2 hori.png'
const proposal3Hori = 'https://cdn.iwsservices.com/Industrias/Retail/proposals/Grupo 3 hori.png'

//inglés
const retailEN = 'https://cdn.iwsservices.com/Industrias/Educacion/ingles/Retail.png';
//portugués
const retailPT = 'https://cdn.iwsservices.com/Industrias/Educacion/portugues/Retail.png';
//inglés
const retailENMov = 'https://cdn.iwsservices.com/Industrias/Educacion/ingles/Retail_mov.png';
//portugués
const retailPTMov = 'https://cdn.iwsservices.com/Industrias/Educacion/portugues/Retail_mov.png';


export function Retail() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const domainName = window.location.hostname;
  const [t, i18n] = useTranslation("common");
  const proposalsImages = [proposal1, proposal2, proposal3];
  const proposalsImagesPt = [proposal1Pt, proposal2Pt, proposal3Pt];
  const proposalsImagesHorizontal = [proposal1Hori, proposal2Hori, proposal3Hori];

  const cardData = [
    {
      img: firstCard,
      title: "1. Confusión",
      body: "Los consumidores tienen problemas con la manera en que se presentan los distintos canales de venta y les cuesta trabajo realizar la compra."
    },
    {
      img: secondCard,
      title: "2. Incoherencia",
      body: "Los consumidores batallan al relacionar el producto que encuentran en línea con lo que encuentran en la tienda."
    },
    {
      img: thirdCard,
      title: "3. Frustración",
      body: "Nuestros consumidores, al no obtener lo que buscan, deciden irse con la competencia."
    },
    {
      img: fourhCard,
      title: "4. Insatisfacción",
      body: "Los clientes esperan demasiado para tener ayuda y no pueden completar la compra rápidamente o recoger la mercancía que esperan."
    },
  ]

  const cardDataPt = [
    {
      img: firstCard,
      title: "1. Confusão",
      body: "Os consumidores têm problemas com a forma como os diferentes canais de venda são apresentados e têm dificuldade em efetuar uma compra."
    },
    {
      img: secondCard,
      title: "2. Inconsistência",
      body: "Os consumidores lutam para combinar o produto que encontram online com o que encontram na loja."
    },
    {
      img: thirdCard,
      title: "3. Frustração",
      body: "Nossos consumidores, não conseguindo o que procuram, decidem ir com a concorrência."
    },
    {
      img: fourhCard,
      title: "4. Insatisfação",
      body: "Os clientes esperam muito por ajuda e não conseguem concluir a compra rapidamente ou pegar a mercadoria que esperam."
    },
  ]

  let imgBanner = retail;
  if (domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca')) {
    imgBanner = retailEN;
  } else if (domainName.includes('iwsservices.com.br')) {
    imgBanner = retailPT;
  }

  let imgBannerMov = retail_mov;
  if (domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca')) {
    imgBannerMov = retailENMov;
  } else if (domainName.includes('iwsservices.com.br')) {
    imgBannerMov = retailPTMov;
  }

  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresRecursosFather" className="recursosFather">
          <Presentation
            image={imgBanner}
            image2={imgBannerMov}
            text={t("retail.title")}
          />
          <div className="pleca-2"></div>
        </div>
        <div id="RecursosChild1" className="pb-0 control-header-industry">
          <BrowserView>
            <div className="box-problems-container">
              <ProblemsContainer widthImg={'w-60'} imagebotom={headerImg} cardsData={domainName.includes('iwsservices.com.br') ? cardDataPt : cardData} />

            </div>

          </BrowserView>

          <MobileView>
            <div className="box-problems-container-mb">
              <ProblemsContainer widthImg={'w-60'} imagebotom={headerImg} cardsData={domainName.includes('iwsservices.com.br') ? cardDataPt : cardData} />

            </div>

          </MobileView>
        </div>
        <div className='row justify-content-center'>
          <div className='col-sm-12 col-12 col-md-12 col-lg-8 col-xl-9 col-xxl-8 text-center'>
            <span className={domainName.includes('iwsservices.com.br') ? "d-none" : "span-our-alianza color-Retail"}>VISIÓN</span>
            <span className={domainName.includes('iwsservices.com.br') ? "span-our-alianza color-Retail" : "d-none"}>VISÃO</span>
            <p className={domainName.includes('iwsservices.com.br') ? "d-none" : "align-items-center justify-content-center"}>Empleados comprometidos, viaje centrado en el usuario, <strong>analítica avanzada, eficiencia operativa.</strong></p>
            <p className={domainName.includes('iwsservices.com.br') ? "align-items-center justify-content-center" : "d-none"}>Funcionários engajados, jornada centrada no usuário, <strong>análises avançadas, eficiência operacional.</strong></p>
          </div>
        </div>
        <div className='row justify-content-center mt-5 ProposalsContainer'>
          <div className='col-10 mt-5'>
            <ProposalsContainer images={domainName.includes('iwsservices.com.br') ? proposalsImagesPt : proposalsImages} classnameindustry="span-our-alianza color-Retail" />
          </div>
        </div>

        <div className='row justify-content-center mt-5 ProposalsContainerHorizontal'>
          <div className='col-10 mt-5'>
            <ProposalsContainerHorizontal images={domainName.includes('iwsservices.com.br') ? proposalsImagesHorizontal : proposalsImagesHorizontal} classnameindustry="span-our-alianza color-Retail" />
          </div>
        </div>

      </Fragment>
      <ToggleComp />
      <Footer />
    </div>
  )
}