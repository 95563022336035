import React, { Fragment } from 'react';
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from 'react-device-detect';
// import ImgMap from "../../Images/map_es.png";
// import ImgMapMobile from "../../Images/map_mobile.png";

const ImgMap = "https://cdn.iwsservices.com/map_es.png";
const ImgMapMobile = "https://cdn.iwsservices.com/map_mobile.png";



export function FlowChart(props) {

    const [t, i18n] = useTranslation('common');
    return (

        <Fragment>
            <BrowserView className="container-father bg-gray">

                

                <div className="container-father pb-sm-2 bg-white container-snake-left-rr">
                    <div className="container text-center padding-">
                        <p className="span-our-alianza span-info-Aliado">Dynamic 360</p>
                        <h2 className='title-a-section-home our-alianza'>{t('home.developapps')}</h2>
        
                    </div>
                    <div className="row justify-content-center dyanmics-srct">
                        <div className="col-10 d-none d-lg-block">
                            <img src={ImgMap} className='w-100' />
                        </div>
                        <div className="col-10 d-block d-lg-none">
                            <img src={ImgMapMobile} className="w-100" />
                        </div>
                    </div>

                    
                </div>

            </BrowserView>
            <MobileView className='bg-gray'>
                <div className="container-father pb-sm-2 bg-white container-snake-left-rr">
                    <div className="container text-center padding-">
                        <p className="span-our-alianza span-info-Aliado">Dynamic 360</p>
                        <div className='textC'>
                            <p>{t('home.developapps')}</p>
                        </div>   </div>
                    <div className="row justify-content-center dyanmics-srct">
                        <div className="col-10 d-none d-lg-block">
                            <img src={ImgMap} className='w-100' />
                        </div>
                        <div className="col-10 d-block d-lg-none">
                            <img src={ImgMapMobile} className="w-100 h-90" />
                        </div>
                    </div>
                </div>
            </MobileView>
        </Fragment>
    )
}

export default FlowChart