import React from 'react'
import {useTranslation} from "react-i18next";

export function ServicesChild2(props) {

  const [t, i18n] = useTranslation('common');

  const map = props.service.map((data, index) =>
    <div key={index} className="row p-0 m-0 col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11 py-4 align-items-center justify-content-center">
    
      <div className={`row col-10 col-sm-10 col-md-5 col-lg-5 col-xl-5 col-xxl-4 me-4 pb-4`}>
        <img src={data.src} alt=""/>
      </div>
    
      {/* <div className={`row col-10 col-sm-10 col-md-5 col-lg-5 col-xl-5 col-xxl-4 me-4 pb-4 ${t("clase.es")}`}>
        <img src={data.src} alt=""/>
      </div>

      <div className={`row col-10 col-sm-10 col-md-5 col-lg-5 col-xl-5 col-xxl-4 me-4 pb-4 ${t("clase.en")}`}>
        <img src={data.srcen} alt=""/>
      </div> */}


      <div className='row col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 align-items-center text-unnamed info-Serv-child2'>
        <p>
          {data.text1} <span className='s-Serv-child2'>{data.span1}</span>
          {data.text2} <span className='s-Serv-child2'>{data.span2}</span>
        </p>
      </div>
    </div>
  );
  return (
    <div className='row m-0 p-0 col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 justify-content-center'>
      <div className='row col-8 m-0 p-0 justify-content-end img-aBox'>
        <div className='col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-xxl-4'>
          <img src={props.image1} alt="" height="253px" width="323px" />
        </div>
      </div>
      <div className="row m-0 p-0 justify-content-end">
        {map}
      </div>
    </div>
  )
}