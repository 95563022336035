import React, { Fragment, useEffect } from 'react'
import { Presentation } from './Components/Presentation'
import { Child1 } from './Components/Services/Child1'
import { ButtonForm } from './Components/ButtonForm'
import styles from './CSS/Trainings.css';
import { useTranslation } from "react-i18next";
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";

const img1 = 'https://cdn.iwsservices.com/Capacitaciones.png'
const img2 = 'https://cdn.iwsservices.com/CapacitacionesMovil.png'
const img2En = 'https://cdn.iwsservices.com/CapacitacionesMovilEn.png'
const img01 = 'https://cdn.iwsservices.com/Beneficios.png'
const img01En = 'https://cdn.iwsservices.com/BeneficiosEn.png'
const img01Pt = 'https://cdn.iwsservices.com/Beneficios - Portugués.png'
const img02 = 'https://cdn.iwsservices.com/Beneficios2.png'
const img02En = 'https://cdn.iwsservices.com/Beneficios2En.png'
const img1DesktopEs = "https://cdn.iwsservices.com/Secciones/Desktop/Es/Capacitaciones.png";
const img1DesktopEn = "https://cdn.iwsservices.com/Secciones/Desktop/En/Trainings.png";
const img1DesktopPr = "https://cdn.iwsservices.com/Secciones/Desktop/Pr/Treinamentos.png";
const img1MobileEs = "https://cdn.iwsservices.com/Secciones/Mobile/Es/Capacitaciones.png";
const img1MobileEn = "https://cdn.iwsservices.com/Secciones/Mobile/En/Trainings.png";
const img1MobilePr = "https://cdn.iwsservices.com/Secciones/Mobile/Pr/Treinamentos.png";

export const Trainings = (props) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const domainName = window.location.hostname;
  const [t, i18n] = useTranslation('common');

  const child1 = {
    text1: `${t('trainings.child1-text1')}`,
    span: `${t('trainings.child1-span')}`,
    text2: `${t('trainings.child1-text2')}`,
    span2: `${t('trainings.child1-span2')}`,
    text3: `${t('trainings.child1-text3')}`,
  }
  const inputs = [
    { label: "Nombre", type: "text", placeholder: "Jhon Doe" },
    { label: "E-mail", type: "email", placeholder: "nuevo@gmail.com" },
    { label: "Comentarios", type: "textarea", placeholder: "Type something…" },
  ]
  return (
    <div><Header />
      <Fragment>
        <div id="PresMigrationsFather" className='trainingFather'>
          <Presentation
            image={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1DesktopEn : domainName.includes('iwsservices.com.br') ? img1DesktopPr : img1DesktopEs}
            image2={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1MobileEn : domainName.includes('iwsservices.com.br') ? img1MobilePr : img1MobileEs}
            text={t("trainings.text")}
          />

        </div>
        <div id="MigraChild1" className='row m-0 p-0 justify-content-center mb-0 pb-0 pb-sm-5 pt-7'>
          <div className='div-training'>
            <Child1 text1={child1.text1} span={child1.span} text2={child1.text2} span2={child1.span2} text3={child1.text3} />
          </div>
        </div>
        <div className='row m-0 p-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 justify-content-center div-benCapac'>
          <div className='m-0 p-0 col-12 col-sm-12 col-md-11 col-lg-10 col-xl-10 col-xxl-9 text-center text-47-azul div-benCapac2'>
            <p className='m-0 pb-5'>
              {t('trainings.beneficios')}
            </p>
          </div>
        </div>

        <div id="ServChild2" className='row m-0 p-0 pt-5 justify-content-center'>
          <div className={`col-12 col-sm-12 col-md-10 col-lg-9 col-xl-8 col-xxl-8 mt-0 pt-0 mt-sm-5 pt-sm-5 `}>
            <img src={domainName.includes('iwsservices.com.br') ? img01Pt : domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') || domainName.includes('redinet.us') ? img01En : img01} className="img-fluid d-none d-sm-block" alt='' />
            <img src={domainName.includes('iwsservices.com.br') ? img02 : domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') || domainName.includes('redinet.us') ? img02En : img02} className="img-fluid d-block d-sm-none" alt='' />
          </div>
          {/* <div className={`col-12 col-sm-12 col-md-10 col-lg-9 col-xl-8 col-xxl-8 mt-0 pt-0 mt-sm-5 pt-sm-5 ${t("clase.en")} `}>
          <img src={img01En} className="img-fluid d-none d-sm-block" alt=''/>
          <img src={img02En} className="img-fluid d-block d-sm-none" alt=''/>
        </div> */}
        </div>
        <ButtonForm inputs={inputs} />
      </Fragment> <Footer />
      <ToggleComp />
    </div>
  )
}
