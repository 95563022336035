import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import { useTranslation } from "react-i18next";
import Carousel from 'react-bootstrap/Carousel';

const BannerImage = 'https://cdn.iwsservices.com/Home/Home_Banner_1.png';
const BannerImage2 = 'https://cdn.iwsservices.com/Home/Home_Banner_2.png';
const BannerImage3 = 'https://cdn.iwsservices.com/Home/Home_Banner_3.png';
const BannerImageEn = 'https://cdn.iwsservices.com/Home/Home_Banner_1En.png';
const BannerImagePt = 'https://cdn.iwsservices.com/Home/Home_Banner_3Pt.png';
const logoISO = 'https://cdn.iwsservices.com/logoCavHome.png';

const Banner = props => {
    const [t, i18n] = useTranslation('common');

    const [classIso, setClassIso] = useState('logo-iso');

    const [isMounted, setIsMounted] = useState(true);

    const handleScrollISO = useCallback(() => {
        if (isMounted) {

            if (window.pageYOffset >= 100) {
                setClassIso('logo-iso hide-iso');
                window.removeEventListener('scroll', handleScrollISO);
            } 
        }
    }, [isMounted]);
    React.useEffect(() => {
        //window.onscroll = () => handleScrollUs();

        window.addEventListener('scroll', handleScrollISO);

        return (
            () => {
                setIsMounted(false);
            }
        )
    }, [handleScrollISO]); // IMPORTANT, This will cause react to update depending on change of this value

    return (
        <div>
            <BrowserView>
                <div className={`${t("clase.es")}`}>
                    <div className={classIso}>
                        <img src={logoISO} alt="" />
                    </div>
                    <Carousel indicators={false} interval={3000} controls={true}>
                        <Carousel.Item>
                        <Link to="/Education" rel="noreferrer">
                            <div className='banner-text'>
                                <h1 className='mobile-font font-home-desktop-1 color-white'>
                                    Los mejores
                                    <span className="span-bold"> servicios</span>
                                    <br />
                                    <span className="span-bold">administrados </span>
                                    <span className="span-bold">multinube</span>
                                </h1>
                            </div>
                            
                                <img
                                    className="d-block w-100"
                                    src={BannerImage}
                                    alt="First slide"
                                />
                            </Link>
                        </Carousel.Item>
                        <Carousel.Item>
                        <Link to="/Services%20In%20a%20Box"  rel="noreferrer">
                            <div className='banner-text'>
                                <h1 className='mobile-font font-home-desktop-1 color-white'>
                                    Tecnologías al
                                    <span className="span-bold"> alcance</span>
                                    <br />
                                    <span className="span-bold">de tu mano </span>
                                </h1>
                            </div>
                            
                                <img
                                    className="d-block w-100"
                                    src={BannerImage2}
                                    alt="Second slide"
                                />
                            </Link>
                        </Carousel.Item>
                        <Carousel.Item>
                        <Link to="/Nosotros"  rel="noreferrer">
                            <div className='banner-text'>
                                <h1 className='mobile-font font-home-desktop-1 color-white'>
                                    Tecnología en la nube
                                    <span className="span-bold"> para todas </span>
                                    <br />
                                    <span className="span-bold">las industrias</span>
                                </h1>
                            </div>
                            
                                <img
                                    className="d-block w-100"
                                    src={BannerImage3}
                                    alt="Third slide"
                                />
                            </Link>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className={`${t("clase.en")}`}>
                    <Carousel indicators={false} interval={3000}>
                        <Carousel.Item>
                        <Link to="/Education"  rel="noreferrer">
                            <div className='banner-text'>
                                <h1 className='mobile-font font-home-desktop-1 color-white'> The best <span className="span-bold">managed</span> <br /><span className="span-bold">cloud</span> <span className="span-bold">services</span></h1>
                            </div>
                            
                                <img
                                    className="d-block w-100"
                                    src={BannerImage}
                                    alt="First slide"
                                />
                            </Link>

                        </Carousel.Item>
                        <Carousel.Item>
                        <Link to="/Services%20In%20a%20Box"  rel="noreferrer">
                            <div className='banner-text'>
                                <h1 className='mobile-font font-home-desktop-1 color-white'> Technologies <span className="span-bold">at </span> <br /><span className="span-bold">your </span> <span className="span-bold">fingertips</span></h1>
                            </div>
                            
                                <img
                                    className="d-block w-100"
                                    src={BannerImage2}
                                    alt="Second slide"
                                />
                            </Link>
                        </Carousel.Item>
                        <Carousel.Item>
                        <Link to="/Nosotros"  rel="noreferrer">
                            <div className='banner-text'>
                                <h1 className='mobile-font font-home-desktop-1 color-white'> Cloud <span className="span-bold">tecnhology</span> <br /><span className="span-bold">for all</span> <span className="span-bold">industries</span></h1>

                            </div>
                            
                                <img
                                    className="d-block w-100"
                                    src={BannerImageEn}
                                    alt="Third slide"
                                />
                            </Link>
                        </Carousel.Item>
                    </Carousel>
                </div>
                {/*"Portu"*/}
                {<div className={`${t("clase.pt")}`}>
                    <Carousel indicators={false} interval={3000}>
                        <Carousel.Item>
                        <Link to="/Education"  rel="noreferrer">
                            <div className='banner-text'>
                                <h1 className='mobile-font font-home-desktop-1 color-white'> Os melhores <span className="span-bold">serviços</span> <br /><span className="span-bold"> de nuvem gerenciados </span> <span className="span-bold">services</span></h1>
                            </div>
                            
                                <img
                                    className="d-block w-100"
                                    src={BannerImage}
                                    alt="First slide"
                                />
                            </Link>
                        </Carousel.Item>
                        <Carousel.Item>
                        <Link to="/Services%20In%20a%20Box"  rel="noreferrer">
                            <div className='banner-text'>
                                <h1 className='mobile-font font-home-desktop-1 color-white'> Tecnologias  <span className="span-bold">ao seu</span> <br /><span className="span-bold"> ao seu</span> <span className="span-bold"> Da sua mão</span></h1>
                            </div>
                            
                                <img
                                    className="d-block w-100"
                                    src={BannerImage2}
                                    alt="Second slide"
                                />
                            </Link>
                        </Carousel.Item>
                        <Carousel.Item>
                        <Link to="/Nosotros"  rel="noreferrer">
                            <div className='banner-text'>
                                <h1 className='mobile-font font-home-desktop-1 color-white'> Tecnologia  <span className="span-bold">de nuvem</span> <br /><span className="span-bold"> para todos</span> <span className="span-bold">as indústrias</span></h1>

                            </div>
                            
                                <img
                                    className="d-block w-100"
                                    src={BannerImagePt}
                                    alt="Third slide"
                                />
                            </Link>
                        </Carousel.Item>
                    </Carousel>
                </div>}
            </BrowserView>

            <MobileView>
                {/*"Esp"*/}
                <div className={`container-home-banner-mobile image-home-esp-mobile ${t("clase.es")}`}>
                    <div className={classIso}>
                        <img src={logoISO} alt="" />
                    </div>
                    <Link to="/Education" target="_blank" rel="noreferrer">
                        <div className='container container-bannet-home'>
                            <div className='row'>
                                <div className='col'>
                                    <h1 className='mobile-font font-home-mobile-1'>Los mejores servicios administrados multinube</h1>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                {/*"Engish"*/}
                <div className={`container-home-banner-mobile image-home-esp-mobile ${t("clase.en")}`}>
                    <Link to="/Education" target="_blank" rel="noreferrer">
                        <div className='container container-bannet-home'>
                            <div className='row'>
                                <div className='col'>
                                    <h1 className='mobile-font font-home-mobile-1'>The best managed cloud services</h1>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                {/*"Portu"*/}
                {
                    <div className={`container-home-banner-mobile image-home-esp-mobile ${t("clase.pt")}`}>
                        <Link to="/Education" target="_blank" rel="noreferrer">
                            <div className='container container-bannet-home'>
                                <div className='row'>
                                    <div className='col'>
                                        <h1 className='mobile-font font-home-mobile-1'>Os melhores serviços de nuvem gerenciados</h1>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                }
            </MobileView>
        </div>
    )
}

export default Banner