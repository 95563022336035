import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useEffect } from 'react';

ChartJS.register(ArcElement, Tooltip, Legend);

//  const data = {
    // labels: ['Mexico', 'Costa Rica', 'Peru', 'Colombia', 'Costa Rica', 'Ecuador'],
    // datasets: [
        // {
            // label: '#Usuarios',
            // data: [12, 10, 3, 5, 2, 3],
            // backgroundColor: [
                // 'rgba(255, 99, 132, 0.2)',
                // 'rgba(54, 162, 235, 0.2)',
                // 'rgba(255, 206, 86, 0.2)',
                // 'rgba(75, 192, 192, 0.2)',
                // 'rgba(153, 102, 255, 0.2)',
                // 'rgba(255, 159, 64, 0.2)',
            // ],
            // borderColor: [
                // 'rgba(255, 99, 132, 1)',
                // 'rgba(54, 162, 235, 1)',
                // 'rgba(255, 206, 86, 1)',
                // 'rgba(75, 192, 192, 1)',
                // 'rgba(153, 102, 255, 1)',
                // 'rgba(255, 159, 64, 1)',
            // ],
            // borderWidth: 2,
        // },
    // ],
//  };

const chartStyle = {
    width: '375px', // Ajusta el ancho según tus necesidades
    height: '375px', // Ajusta la altura según tus necesidades
};


function PieComp({data, title}) {

    const options = {
        plugins: {
            title: {
                display: true,
                text: title,
                font: {
                    size: 20
                }
            }
        }
    }
    useEffect(() => {
        console.log(data);
    }, [])
    return (
        <div className='' style={chartStyle}>
            <Pie data={data} options={options} />
        </div>

    );
}


export default PieComp;