import React, { Fragment } from 'react';
import { SVG } from '../SVGCreate';
import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import { BrowserView, MobileView, isTablet } from 'react-device-detect';
import { Carousel as CarouselReact } from 'react-responsive-carousel';


export function Info(props) {

  const [t, i18n] = useTranslation('common');
  const iconArrow = {
    xmlns: "http://www.w3.org/2000/svg",
    ancho: "50",
    alto: "50",
    currentColor: "white",
    clase: "bi bi-arrow-right",
    viewCaja: "0 0 16 16",
    d: "M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"

  };
  return (

    <Fragment>
      <BrowserView className="container-father ">
        <div className='contianer' hidden>
          <div className='row col-12 position-relative justify-content-center m-0 p-0 div-HomeCursos'>
            <div className='row m-0 p-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 justify-content-between justify-content-md-between'>
              <div className='row m-0 p-0 col-6 justify-content-start Home-cursos'>
                <div className='text-cursos text-withe pe-2 d-flex align-items-center justify-content-start  justify-content-md-center'>
                  <a href="https://cursos.iwsservices.com/" target="_blank" rel="noopener noreferrer">
                    <div className='d-flex align-items-center justify-content-start  justify-content-xl-center text-center m-0 p-0'>
                      <span className='d-none d-xl-block m-0 p-0'>
                        <SVG xmlns={iconArrow.xmlns} ancho={iconArrow.ancho} alto={iconArrow.alto} currentColor={iconArrow.currentColor} clase={iconArrow.clase}
                          viewCaja={iconArrow.viewCaja} d={iconArrow.d} />

                      </span>
                      <p className='m-0 px-2 text-light d-none d-xl-block'>{t('home.clic')}</p>
                    </div>
                    <p className='d-block d-xl-none m-0 p-0'>{t('home.clic')}</p>
                    <p className='text-bold p-universidad m-0 p-0'> {t('home.universidad')} </p>
                  </a>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-4 d-flex justify-content-end align-items-center flex-column div-textSoluciones'>
                <div className='info-blue text-center'>
                  {t('home.descubre')}
                </div>
                <div className='info-blue2 text-center'>
                  {t('home.soluciones')}
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className='container container-services card-cloud-top'>
          <div className='container container-home-multinubes'>
            <h2 className='title-a-section-home our-alianza'>{t('home.aliado')} <span className='span-our-alianza span-info-Aliado'>{t('home.multinubes')}</span></h2>
            {/* <h3 className='our-alianza'>Somos representantes de las principales nubes del mercado.</h3> */}
            <h3 className='our-alianza'>{t('home.representantes')}</h3>
            <hr className='hr-info-Aliado' />

            <div className='container'>
              <div className='row'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <Card className='card-cloud  card-chicle'>
                    <div className='card card-cloud'>
                      <Card.Img variant="top" src={props.imgGoogle} />
                      <br />
                      <Card.Body>
                        <Card.Title>Google Cloud</Card.Title>
                        <Card.Text>
                          {t('home.google')}

                        </Card.Text>
                        <Button variant="primary">Read More</Button>
                      </Card.Body>
                    </div>
                  </Card>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <Card className='card-cloud card-chicle'>
                    <div className='card card-cloud amazon-card'>
                      <Card.Img variant="top" src={props.imgAWS} />
                      <br />
                      <Card.Body>
                        <Card.Title>Amazon Web Services</Card.Title>
                        <Card.Text>
                        {t('home.amazon')}
                        </Card.Text>
                        <Button variant="primary">Read More</Button>
                      </Card.Body>
                    </div>
                  </Card>
                </div>
                <div className='col-lg-4 col-md-6 co<l-sm-12'>
                  <Card className='card-cloud card-chicle'>
                    <div className='card card-cloud '>
                      <Card.Img variant="top" src={props.imgAzure} />
                      <br />
                      <Card.Body>
                        <Card.Title>Microsoft AZURE</Card.Title>
                        <Card.Text>
                        {t('home.azure')}
                        </Card.Text>
                        <Button variant="primary">Read More</Button>
                      </Card.Body>
                    </div>
                  </Card>
                </div>
              </div>
            </div>

          </div>

        </div>
      </BrowserView>
      <MobileView>
        <div className='bg-blue-light-mobile'>
          <div className='box-title pt-6'>
            <h2 className='title-home-mb our-alianza title-rr'>{t('home.aliado')}</h2>
            <span className='span-our-alianza span-info-Aliadotabl span-info-Aliado subtitle-rr'>{t('home.multinubes')}</span>
            {/* <h3 className='our-alianza'>Somos representantes de las principales nubes del mercado.</h3> */}
            <div className='textC'>
              <p>{t('home.representantes')}</p>
            </div>
          </div>
          <hr className='hr-info-Aliado hr-info-mb' />
          <CarouselReact className='carousel-mb-container' interval={5000} showThumbs={false} showArrows={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
            <div>
              <Card className='card-cloud card-chicle'>
                <div className='card card-cloud '>
                  <Card.Img variant="top" className={isTablet ? "img-ipad" : ""} src={props.imgGoogle} />
                  <br />
                  <Card.Body>
                    <Card.Title>Google Cloud</Card.Title>
                    <Card.Text>
                    {t('home.google')}

                    </Card.Text>
                    <Button variant="primary">Read More</Button>
                  </Card.Body>
                </div>
              </Card>
            </div>
            <div>
              <Card className='card-cloud card-chicle'>
                <div className='card card-cloud '>
                  <Card.Img variant="top" className={isTablet ? "img-ipad" : ""} src={props.imgAWS} />
                  <br />
                  <Card.Body>
                    <Card.Title>Amazon Web Services</Card.Title>
                    <Card.Text>
                    {t('home.amazon')}
                    </Card.Text>
                    <Button variant="primary">Read More</Button>
                  </Card.Body>
                </div>
              </Card>
            </div>
            <div>
              <Card className='card-cloud card-chicle'>
                <div className='card card-cloud '>
                  <Card.Img variant="top" className={isTablet ? "img-ipad" : ""} src={props.imgAzure} />
                  <br />
                  <Card.Body>
                    <Card.Title>Microsoft AZURE</Card.Title>
                    <Card.Text>
                    {t('home.azure')}
                    </Card.Text>
                    <Button variant="primary">Read More</Button>
                  </Card.Body>
                </div>
              </Card>
            </div>
          </CarouselReact>
        </div>
      </MobileView>
    </Fragment>
  )
}
