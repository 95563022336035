import React from 'react'

const divStyle = {
  width: '100vw'
};

export function Soluciones(props) {
  return (

    <div id="InfoKasp">
      {/* className='image-md-recortada' */}
      <div className=''>  
        {/* <img src={props.image} alt="" width={divStyle} className="img-fluid" /> */}
        <img src={props.image} alt="" width={divStyle}  className='img-fluid  d-none d-md-block'/>
        <img src={props.image2} alt=""  className='img-fluid d-block d-md-none'/>
      </div>
    </div>
  )
}
