import React from 'react'
import {useTranslation} from "react-i18next";

export function ServicesChild3(props) {
  const [t, i18n] = useTranslation('common');
  const domainName = window.location.hostname;
  
  return (
    
    <div className='row justify-content-center p-0 m-0'>
      <div className={`p-0 m-0 col-12 col-sm-12 col-md-12 col-lg-11 col-xl-10 col-xxl-8`} >
            <img src={props.image} alt=""  className='img-fluid d-none d-md-block'/>
            <img src={props.image2} alt=""  className='img-fluid d-block d-md-none'/>
      </div>
    </div>
  )
}
