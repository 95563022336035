import React, { Fragment, useEffect } from 'react'
import { Presentation } from './Components/Presentation'
import { ServicesChild1 } from './Components/ServicesInABox/ServicesChild1'
import { ServicesChild2 } from './Components/ServicesInABox/ServicesChild2'
import { ServicesChild3 } from './Components/ServicesInABox/ServicesChild3'
import styles from './CSS/Services In a Box.css'
import { useTranslation } from "react-i18next";
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";

const img1 = 'https://cdn.iwsservices.com/Services.png'
const img2 = 'https://cdn.iwsservices.com/ServicesMovil.png'
const satisfaction = 'https://cdn.iwsservices.com/Service In a Box/100.png'
const training = 'https://cdn.iwsservices.com/Service In a Box/Training Friday.png'
const desk = 'https://cdn.iwsservices.com/Service In a Box/Mesa de Ayuda.png'
const deskEn = 'https://cdn.iwsservices.com/Service In a Box/Mesa de Ayuda En.png'
const cursos = 'https://cdn.iwsservices.com/Service In a Box/Portal de Cursos.png'
const cursosEn = 'https://cdn.iwsservices.com/Service In a Box/Portal de Cursos En.png'
const aBox = 'https://cdn.iwsservices.com/Service In a Box/Services In a Box.png'
const mapa = 'https://cdn.iwsservices.com/Service In a Box/Mapa.png'
const mapaPt = 'https://cdn.iwsservices.com/Service In a Box/Mapa Portugués.png'
const mapaEn = 'https://cdn.iwsservices.com/Service In a Box/mapaEn.png'
const mapaEnRedinet = 'https://cdn.iwsservices.com/Service In a Box/MapaEnRedinet.png'
const mapaEn2Redinet = 'https://cdn.iwsservices.com/Service In a Box/Mapa2EnRedinet.png'
const mapaRedinet = 'https://cdn.iwsservices.com/Service In a Box/MapaRedinet.png'
const mapa2Redinet = 'https://cdn.iwsservices.com/Service In a Box/Mapa2Redinet.png'
const mapa2 = 'https://cdn.iwsservices.com/Service In a Box/Mapa2.png'
const mapa2En = 'https://cdn.iwsservices.com/Service In a Box/Mapa2En.png'
const imgPrueba = 'https://cdn.iwsservices.com/Service In a Box/Training Friday.png'
const img1DesktopEs = "https://cdn.iwsservices.com/Secciones/Desktop/Es/Servicesinabox.png";
const img1DesktopEn = "https://cdn.iwsservices.com/Secciones/Desktop/En/Servicesinabox.png";
const img1DesktopPr = "https://cdn.iwsservices.com/Secciones/Desktop/Pr/Servicesinabox.png";
const img1MobileEs = "https://cdn.iwsservices.com/Secciones/Mobile/Es/Servicesinabox.png";
const img1MobileEn = "https://cdn.iwsservices.com/Secciones/Mobile/En/Servicesinabox.png";
const img1MobilePr = "https://cdn.iwsservices.com/Secciones/Mobile/Pr/Servicesinabox.png";



export function Services() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const [t, i18n] = useTranslation('common');
  const domainName = window.location.hostname;


  const services = [
    {
      src: training,
      srcen: "",
      text1: `${t('services.training-text1')}`,
      span1: `${t('services.training-span1')}`,
      text2: `${t('services.training-text2')}`,
      span2: `${t('services.training-span2')}`,
    },
    {
      src: (domainName.includes('iwsservices.com') || domainName.includes('redinet.cr') || domainName.includes('redinet.pe')  || domainName.includes('redinet.com.mx')) ? desk : deskEn,
      srcen: "",
      text1: `${t('services.desk-text1')}`,
      span1: `${t('services.desk-span1')}`,
      text2: `${t('services.desk-text2')}`,
      span2: `${t('services.desk-span2')}`,
    },
    {
      src: (domainName.includes('iwsservices.com') || domainName.includes('redinet.cr') || domainName.includes('redinet.pe')  || domainName.includes('redinet.com.mx')) ? cursos : cursosEn,
      srcen: "",
      text1: `${t('services.cursos-text1')}`,
      span1: `${t('services.cursos-span1')}`,
      text2: `${t('services.cursos-text2')}`,
      span2: `${t('services.cursos-span2')}`,
    },
  ];
  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresServFather" className='ServicesFather'>
          <Presentation
            image={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1DesktopEn : domainName.includes('iwsservices.com.br') ? img1DesktopPr : img1DesktopEs}
            image2={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1MobileEn : domainName.includes('iwsservices.com.br') ? img1MobilePr : img1MobileEs}
            text={t("services.text")}
          />
        </div>
        <div id="ServChild1" className='row m-0 justify-content-center pt-7'>
          <ServicesChild1 imga={satisfaction} />
        </div>
        <div id="ServChild2" className=' pb-4 justify-content-center'>
          <ServicesChild2 service={services} image1={aBox} />
        </div>


        <div id="ServChild3" className='row m-0 pb-4 justify-content-center'>
          <div className='col-12 d-flex justify-content-center pt-5 mt-4'>
            <div className='title-Child3 text-center text-azul-marino p-2'>
              <p>{t('services.change')}</p>
            </div>
          </div>

          <div className='title-relative title-relative-rr position-relative text-azul-marino '>
            {t('services.costo')}
          </div>

          {domainName.includes('redinet.us') ?
            <ServicesChild3 image={mapaEnRedinet} image2={mapaEn2Redinet} /> :

            domainName.includes('redinet.pe') || domainName.includes('redinet.cr') || domainName.includes('redinet.com.mx') ?
              <ServicesChild3 image={mapaRedinet} image2={mapa2Redinet} /> :

              domainName.includes('iwsservices.com.br') ?
                <ServicesChild3 image={mapaPt} image2={mapa2} /> :

                domainName.includes('iwsservices.com') ?
                  <ServicesChild3 image={mapa} image2={mapa2} /> :

                  <ServicesChild3 image={mapaEn} image2={mapa2En} />}


        </div>
      </Fragment>
      <Footer />
      <ToggleComp />
    </div>
  )
}
