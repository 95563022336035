import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { Presentation } from './Components/Presentation'
import { Child1 } from './Components/Services/Child1'
import { ButtonForm } from './Components/ButtonForm'
import { AssessmentChild2 } from './Components/Services/AssessmentChild2'
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";
import styles from './CSS/assesment.css';
import { useTranslation } from "react-i18next";

const img1 = 'https://cdn.iwsservices.com/Assassement.png'
const img2 = 'https://cdn.iwsservices.com/AssessmentMovil.png'
const img2Pt = 'https://cdn.iwsservices.com/AssessmentMovil.png'
const estrellas = 'https://cdn.iwsservices.com/Assessment/Estrellas.png'
const img01 = 'https://cdn.iwsservices.com/Assessment/1.png'
const img01En = 'https://cdn.iwsservices.com/Assessment/1En.png'
const img01Pt = 'https://cdn.iwsservices.com/Assessment/Punto 1 portugues.png'
const img02 = 'https://cdn.iwsservices.com/Assessment/2.png'
const img02En = 'https://cdn.iwsservices.com/Assessment/2En.png'
const img02Pt = 'https://cdn.iwsservices.com/Assessment/Punto 2 portugues.png'
const img03 = 'https://cdn.iwsservices.com/Assessment/3.png'
const img03En = 'https://cdn.iwsservices.com/Assessment/3En.png'
const img03Pt = 'https://cdn.iwsservices.com/Assessment/Punto 3 portugues.png'


const img1DesktopEs = "https://cdn.iwsservices.com/Secciones/Desktop/Es/Assessment.png";
const img1DesktopEn = "https://cdn.iwsservices.com/Secciones/Desktop/En/Assessment.png";
const img1DesktopPr = "https://cdn.iwsservices.com/Secciones/Desktop/Pr/Avaliação.png";


const img1MobileEs = "https://cdn.iwsservices.com/Secciones/Mobile/Es/Assessment.png";
const img1MobileEn = "https://cdn.iwsservices.com/Secciones/Mobile/En/Assessment.png";
const img1MobilePr = "https://cdn.iwsservices.com/Secciones/Mobile/Pr/Avaliação.png";

export const Assessment = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const domainName = window.location.hostname;
  const [t, i18n] = useTranslation('common');

  const child1 = {
    text1: `${t('assessment.child1-text1')}`,
    span: `${t('assessment.child1-span')}`,
    text2: `${t('assessment.child1-text2')}`,
    span2: `${t('assessment.child1-span2')}`,
    text3: `${t('assessment.child1-text3')}`,
  }
  const inputs = [
    { label: "Nombre", type: "text", placeholder: "Jhon Doe" },
    { label: "E-mail", type: "email", placeholder: "nuevo@gmail.com" },
    { label: "Comentarios", type: "textarea", placeholder: "Type something…" },
  ]
  const AssessmentImages = [img01, img02, img03]
  const AssessmentImagesEn = [img01En, img02En, img03En]
  const AssessmentImagesPt = [img01Pt, img02Pt, img03Pt]
  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresMigrationsFather" className='assessmetFather'>
          <Presentation
            image={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') || domainName.includes('redinet.ca') ? img1DesktopEn : domainName.includes('iwsservices.com.br') ? img1DesktopPr : img1DesktopEs}
            image2={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') ? img1MobileEn : domainName.includes('iwsservices.com.br') ? img1MobilePr : img1MobileEs}
            text={t("assessment.text")}
          />
        </div>
        <div id="MigraChild1" className='row m-0 p-0 justify-content-center mb-2 pt-7'>
          <div className='mb-5 div-Migra-child1'>
            <Child1 text1={child1.text1} span={child1.span} text2={child1.text2} span2={child1.span2} text3={child1.text3} />
          </div>
          <div className='d-flex p-0 justify-content-center div-estrellas'>
            <div className='d-flex m-0 p-0 col-8 col-sm-7 col-md-7 col-lg-4 col-xl-4 col-xxl-4'>
              <img src={estrellas} alt="" className='img-fluid' />

            </div>
          </div>
        </div>
        <div id="ContactChild2" className='row m-0 p-0 py-4 justify-content-center'>
          <div className='col-12 d-flex justify-content-center pt-5'>
            <div className='title-Child3 text-center text-azul-marino p-2 textAsse-container'>
              <p className='nuestras text-puntos'> {t('assessment.puntos')} </p>
            </div>
          </div>

          <div className='title-relative position-relative text-azul-marino text-import'>
            <span className={`span-relleno ${t("clase.en")}`}>**</span>
            {t('assessment.importantes')}
            <span className={`span-relleno ${t("clase.en")}`}>**</span>
          </div>
          <div className={`row m-0 p-0 col-10 justify-content-center`}>
            <AssessmentChild2 images={domainName.includes('iwsservices.com.br') ? AssessmentImagesPt : domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') || domainName.includes('redinet.us') ? AssessmentImagesEn : AssessmentImages} />
          </div>
          {/* <div className={`row m-0 p-0 col-10 justify-content-center ${t("clase.en")}`}>
          <AssessmentChild2 images={AssessmentImagesEn} />
        </div> */}
        </div>
        <ButtonForm inputs={inputs} />
      </Fragment>
      <Footer />
      <ToggleComp />
    </div>
  )
}
