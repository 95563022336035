import React from 'react'
import { Fragment } from 'react'
import { Presentation } from './Components/Presentation'
import styles from './CSS/news.css';
import axios from "axios"
import Moment from 'moment';
import { animateScroll as scroll } from 'react-scroll';

import { useTranslation } from "react-i18next";
import { useEffect } from 'react'
import { Markup } from 'interweave';
import { ThreeDots } from 'react-loader-spinner'
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router';
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";
// variable uri BCIntegrations

const img1 = 'https://cdn.iwsservices.com/Noticias/Portada centro noticias.png'
const icon_return = 'https://cdn.iwsservices.com/Noticias/icon_return.png'
const cursos = 'https://cdn.iwsservices.com/Noticias/Banner cursos 2.png'

const clientBcInte = axios.create({
  baseURL: "https://bcintegrationiws.azurewebsites.net/"
});

// const clientBcInte = axios.create({
//   baseURL: "https://localhost:44311/" 
// });

var qs = require('qs');


export const News = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const [t, i18n] = useTranslation('common');
  const domainName = window.location.hostname;

  const [latestNews, setLatestNews] = React.useState(null);
  const [showlatestNews, toggleShowlatestNews] = React.useState(true);

  const [categories, setCategories] = React.useState(null);
  const [showCategories, toggleShowCate] = React.useState(true);

  const [showReturnNews, toggleReturnNews] = React.useState(false);
  const [returnButtonNews, setReturnNews] = React.useState(null);

  const [showReturnCateg, toggleReturnCateg] = React.useState(false);
  const [returnButtonCateg, setReturnCateg] = React.useState(null);

  const [showLoader, toggleLoader] = React.useState(true);

  const [showCabecera, toggleShowCabecera] = React.useState(true);

  let navigate = useNavigate();



  var htmlContent = <div className='h-100 w-100 div_return' >
    <div className='row justify-content-center'>
      <div className='col-5'>
        <button onClick={() => (toggleReturnNews(showReturnNews))} className="btn btn-outline-primary returnBtn">
          <img className='img_return' src={icon_return}></img>
        </button>
      </div>
    </div>
  </div>;

  function getNewsContent(id) {
    setReturnNews(
      htmlContent =
      <div className='w-100 h-100 div_return p-3 m-3'>
        <div className='row justify-content-center'>
          <div className='col-5'>
            <button onClick={() => (toggleReturnNews(showReturnNews), toggleReturnCateg(showCategories), toggleShowCate(showCategories), toggleShowlatestNews(showlatestNews))} className="btn btn-outline-primary returnBtn">
              <img className='img_return' src={icon_return}></img>
            </button>
          </div>
        </div>
        <div className="col-12 h-100 w-100">
          <Markup content={""}></Markup>
        </div>
      </div>
    );
    clientBcInte.post("/api/NewsApi/News", { Id: id }).then(res => {//content of new by id
      var newsList = res.data.data;
      console.log(newsList);
      toggleLoader(!showLoader);
      setReturnNews(
        htmlContent =
        <div className='px-3 pb-3 mx-3 mb-3 w-100 h-100'>
          <div className="col-12 h-100 w-100 newsContent">
            <div className='New_Poster'>
              <img src={newsList.portada} className='New_Poster-Img'></img>
            </div>

            <h3 className='noti_descrip'>{newsList.description}</h3>
            <br></br>
            <br></br>
            <div className='noti_metadata m-4 p-4'>
              <div className='row'>
                <div className='col-6'>
                  <h2 className='noti_center'>Centro de noticias</h2>
                </div>
                <div className='col-6 noti_date'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                  </svg>
                  <h3 className='noti_fecha'>{Moment(newsList.createdOn).format('DD/MM/YYYY')}</h3>
                </div>
              </div>
              <Markup content={newsList.html}></Markup>
            </div>

          </div>
          <div className='w-100 h-100 div_return' >
            <div className='row justify-content-center'>
              <div className='col-5'>
                <button onClick={() => (toggleReturnNews(showReturnNews), toggleReturnCateg(showCategories), toggleShowCate(showCategories), toggleShowlatestNews(showlatestNews), toggleShowCabecera(showCabecera), scroll.scrollToTop())} className="btn btn-outline-primary returnBtn">
                  <img className='img_return' src={icon_return}></img>
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    })
    clientBcInte.post("/api/NewsApi/NewsCount", { Id: id }).then(res => {
      var response = res.status;
      if (response == 200) {
        console.log('NewsCoun Succesfull');
      }
    })
  }



  useEffect(() => {
    Moment.locale('en');
    toggleLoader(showLoader);
    clientBcInte.get("/api/NewsApi/LatestNews").then(res => {
      var newsList = res.data.data;
      console.log(newsList);
      setLatestNews(
        <div key={newsList.id} className="carousel-item">
          <a onClick={() => (toggleReturnCateg(!showCategories), toggleReturnNews(!showReturnNews), getNewsContent(newsList.id), toggleLoader(showLoader), toggleShowCate(!showCategories), toggleShowlatestNews(!showlatestNews), toggleShowCabecera(!showCabecera))} className="select_carousel">
            <div className="row g-0">
              <div className="carousel-container col-md-6">
                <img src={newsList.preview} className=" img-carousel img-fluid rounded-start"></img>
              </div>
              <div className="col-md-6 p-2">
                <div className="card-body ">
                  <h2 className="card-title latest-title mt-3">{newsList.title}</h2>
                  <p className="card-text mt-3">{newsList.description}</p>
                </div>
              </div>
            </div>
          </a>
        </div>

      )
    });

    clientBcInte.get("/api/CategoriesNewsApi/Categories").then(res => {
      var newsList = res.data.data;
      console.log(newsList);
      setCategories(newsList.map(
        obj =>
          <div key={obj.id} className="col-12">
            <div className="categ h-100 w-100 ">
              <h1 className="card-title">{obj.name}</h1>
              <div className='row w-100 h-100 grid-news' >
                {obj.news.map(
                  obj =>
                    <div key={obj.id} className="col-md-4 col-sm-6">
                      <div className="card News h-100 w-100 ">
                        <a onClick={() => (toggleReturnCateg(!showCategories), toggleReturnNews(!showReturnNews), getNewsContent(obj.id), toggleLoader(showLoader), toggleShowCate(!showCategories), toggleShowlatestNews(!showlatestNews), toggleShowCabecera(!showCabecera))} className="">
                          <img src={obj.preview} className="card-img-top p-2 news-img" alt={obj.photo}></img>
                        </a>
                        <div className="card-body">
                          <h5 className="card-title">{obj.title}</h5>
                          <p className="card-text">{obj.description}</p>
                        </div>
                      </div>
                    </div>
                )
                }
              </div>
            </div>
            <br></br>
          </div>
      ));

      toggleLoader(!showLoader);
    });
    setReturnNews(
      htmlContent
    );
  }, []);

  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresMigrationsFather" className='newsFather'>
          {showCabecera && <Presentation image={img1} image2={domainName.includes('iwsservices.com.br') ? img1 : img1} text={t('assessment.text')} />}
        </div>

        {showlatestNews && <div><div className='h-100 w-100 title_news mt-5'>
          <h1 className='latest-title'>¡LO MÁS<strong> NUEVO!</strong></h1>
        </div><Carousel className='h-75 w-75' slide={false}>
            {latestNews}
          </Carousel></div>}


        <div className='mx-3 mb-3'>
          <div className='loader h-100 w-100'>
            {showLoader && <ThreeDots height="80" width="80" radius="9" color="#003061" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />}
          </div>
          <div className='row div-notis'>

            {showCategories && categories}
            {showReturnNews && returnButtonNews}
            {showReturnCateg && returnButtonCateg}
          </div>
        </div>
        <a href='https://cursos.iwsservices.com/'>
          <img src={cursos} alt="Cursos" className='img-fluid img-cursos' />
        </a>
        <div className=''>
          <div className=''>
          </div>
        </div>
      </Fragment> <ToggleComp />
      <Footer />
    </div>
  )

}

