import React, { Fragment, useEffect } from 'react';
import { Presentation } from './Components/Presentation';
import { useTranslation } from "react-i18next";

import styles from './CSS/about.css'
import './CSS/Design2023/AboutUs.css';
import Commitment from './Components/About Us/Commitment';
import MissionVision from './Components/About Us/MissionVision';
import Partners from './Components/About Us/Partners';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import pdfPresentacion from "./Icons/IWS Presentacion ejecutiva 2023.pdf";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import OurTeam from './Components/About Us/OurTeam';
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";

const conjunto_1_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo Comercial.png';
const conjunto_1_2 = 'https://cdn.iwsservices.com/Nosotros/Ventas.png';
const conjunto_2_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo de Preventa.png';
const conjunto_2_2 = 'https://cdn.iwsservices.com/Nosotros/Preventa.png';
const conjunto_3_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo de Ingeniería.png';
const conjunto_3_2 = 'https://cdn.iwsservices.com/Nosotros/Ingeniería.png';
const conjunto_4_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo Help Desk.png';
const conjunto_4_2 = 'https://cdn.iwsservices.com/Nosotros/Help desk.png';
const conjunto_5_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo Diseño y Marketing.png'
const conjunto_5_2 = 'https://cdn.iwsservices.com/Nosotros/Diseño.png'
const conjunto_6_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo Administración.png'
const conjunto_6_2 = 'https://cdn.iwsservices.com/Nosotros/Administración.png'
const conjunto_7_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo RRHH.png'
const conjunto_7_2 = 'https://cdn.iwsservices.com/Nosotros/RRHH.png'
const conjunto_8_1 = 'https://cdn.iwsservices.com/Nosotros/Equipo Legal.png'
const conjunto_8_2 = 'https://cdn.iwsservices.com/Nosotros/Legal.png'

const conjunto_1_1En = 'https://cdn.iwsservices.com/Nosotros/ComercialEn.png';
const conjunto_2_1En = 'https://cdn.iwsservices.com/Nosotros/PreventaEn.png';
const conjunto_3_1En = 'https://cdn.iwsservices.com/Nosotros/IngenieríaEn.png';
const conjunto_4_1En = 'https://cdn.iwsservices.com/Nosotros/HelpEn.png';
const conjunto_5_1En = 'https://cdn.iwsservices.com/Nosotros/DiseñoEn.png';
const conjunto_6_1En = 'https://cdn.iwsservices.com/Nosotros/AdmEn.png';
const conjunto_7_1En = 'https://cdn.iwsservices.com/Nosotros/HREn.png';
const conjunto_8_1En = 'https://cdn.iwsservices.com/Nosotros/LegalEn.png';

const conjunto_1_1Pt = 'https://cdn.iwsservices.com/Nosotros/Equipe Comercial.png';
const conjunto_2_1Pt = 'https://cdn.iwsservices.com/Nosotros/Equipe de Pré-venda.png';
const conjunto_3_1Pt = 'https://cdn.iwsservices.com/Nosotros/Equipe de Engenharia.png';
const conjunto_4_1Pt = 'https://cdn.iwsservices.com/Nosotros/Equipe de Help Desk.png';
const conjunto_5_1Pt = 'https://cdn.iwsservices.com/Nosotros/Equipe de Desenho e Marketing.png';
const conjunto_6_1Pt = 'https://cdn.iwsservices.com/Nosotros/Equipe de AdminisTtraÇÃo.png';
const conjunto_7_1Pt = 'https://cdn.iwsservices.com/Nosotros/Equipe de RH.png';
const conjunto_8_1Pt = 'https://cdn.iwsservices.com/Nosotros/Equipe Legal.png';

const img1DesktopEs = 'https://cdn.iwsservices.com/Nosotros/Nosotros_desktop_.png';
const img1DesktopEn = "https://cdn.iwsservices.com/Secciones/Desktop/En/Aboutus-02.png";
const img1DesktopPr = "https://cdn.iwsservices.com/Secciones/Desktop/Pr/Sobrenós-02.png";


const img1MobileEs = 'https://cdn.iwsservices.com/Nosotros/Nosotros_mobile_.png';
const img1MobileEn = "https://cdn.iwsservices.com/Secciones/Mobile/En/Aboutus.png";
const img1MobilePr = "https://cdn.iwsservices.com/Secciones/Mobile/Pr/Sobrenós.png";



export function About_US() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);

  const [t, i18n] = useTranslation('common');
  const domainName = window.location.hostname;
  let textHelp = "";
  let textIng = "";

  if (domainName.includes('redinet.us')) {
    textHelp = "This centralized team within Redinet employees or customers in a personalized and efficient manner. It all comes down to the core principle of helping and providing excellent care to serve as a place where they can seek help.";
    textIng = "At Redinet, we have a deft certified engineering team with broad experience in technology tools, infrastructure, process design, among other things. Our team knows how to optimize your performance.";
  }
  else if (domainName.includes('redinet.pe') || domainName.includes('redinet.cr') || domainName.includes('redinet.com.mx')) {
    textHelp = "Este equipo centralizado dentro de Redinet, atiende a empleados y clientes de forma personalizada y eficaz. Todo se reduce al principio central de ayudar y brindar una excelente atención para servir como lugar en el que puedan buscar ayuda.";
    textIng = "En Redinet tenemos un increíble equipo conformado por ingenieros certificados y con amplia experiencia en herramientas tecnológicas, infraestructura, diseño de procesos, entre otras cosas. Contamos con un equipo especializado y que sabe optimizar su rendimiento.";
  }
  else if (domainName.includes('iwsservices.com')) {
    textHelp = "Este equipo centralizado dentro de Redinet, atiende a empleados y clientes de forma personalizada y eficaz. Todo se reduce al principio central de ayudar y brindar una excelente atención para servir como lugar en el que puedan buscar ayuda.";
    textIng = "En Redinet tenemos un increíble equipo conformado por ingenieros certificados y con amplia experiencia en herramientas tecnológicas, infraestructura, diseño de procesos, entre otras cosas. Contamos con un equipo especializado y que sabe optimizar su rendimiento.";
  }
  else if (domainName.includes('iwsservices.com.br')) {
    textHelp = "Esta equipe centralizada dentro da Redinet, atende aos funcionários e aos clientes de maneira especializada e eficaz. Tudo se reduz ao principio central de ajudar e oferecer uma excelente atenção para que seja considerado o lugar ideal para pedir ajuda.";
    textIng = "Na Redinet nós temos uma equipe incrível formada por engenheiros certificados e com ampla experiencia em ferramentas tecnológicas, infraestrutura, desenho de processos, entre outras coisas. Contamos com uma equipe especializada e que sabe otimizar os resultados.";
  }
  else {
    textHelp = "This centralized team within Redinet employees or customers in a personalized and efficient manner. It all comes down to the core principle of helping and providing excellent care to serve as a place where they can seek help.";
    textIng = "At Redinet, we have a deft certified engineering team with broad experience in technology tools, infrastructure, process design, among other things. Our team knows how to optimize your performance.";
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Presentación Ejecutiva
    </Tooltip>
  );

  const slider = {
    settings: {
      className: "",
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 2,
      speed: 500,
      rows: 2,
      slidesPerRow: 1,
      // autoplay:true,
      // autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 993,
          settings: {
            // centerPadding: "10px",
            slidesToShow: 1,
            speed: 500,
            rows: 2,
            slidesPerRow: 1,
            // autoplay:true,
            // autoplaySpeed: 3000,
          }
        },
        {
          breakpoint: 769,
          settings: {
            // centerPadding: "10px",
            slidesToShow: 1,
            speed: 500,
            rows: 1,
            slidesPerRow: 1,
            // autoplay:true,
            // autoplaySpeed: 3000,
          }
        },
      ]
    },
    body: [
      {
        src1: conjunto_1_1, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.comercial')}`, height: "230px", widht: "100%", classimg1: "imagen-slick",
        src2: conjunto_1_2, classFather: "father-slick m-0 p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.comercial-text')}`
      },
      {
        src1: conjunto_3_1, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.ingenieria')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_3_2, classFather: "father-slick d-flex  m-0 p-0 justify-content-end", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: textIng
      },
      {
        src1: conjunto_2_1, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.preventa')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_2_2, classFather: "father-slick m-0 p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.preventa-text')}`
      },
      {
        src1: conjunto_4_1, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.help')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_4_2, classFather: "father-slick d-flex m-0 p-0 justify-content-end", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: textHelp
      },
      {
        src1: conjunto_5_1, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.diseño')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_5_2, classFather: "father-slick m-0 p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.diseño-text')}`
      },
      {
        src1: conjunto_6_1, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.administracion')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_6_2, classFather: "father-slick d-flex m-0 p-0 justify-content-end", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: `${t('aboutUsEquipos.administracion-text')}`
      },

      {
        src1: conjunto_7_1, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.RRHH')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_7_2, classFather: "father-slick m-0 p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.RRHH-text')}`
      },
      {
        src1: conjunto_8_1, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.legal')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_8_2, classFather: "father-slick d-flex m-0 p-0 justify-content-end", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: `${t('aboutUsEquipos.legal-text')}`
      }
    ],
    body2: [
      {
        src1: conjunto_1_1En, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.comercial')}`, height: "230px", widht: "100%", classimg1: "imagen-slick",
        src2: conjunto_1_2, classFather: "father-slick m-0 p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.comercial-text')}`
      },
      {
        src1: conjunto_3_1En, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.ingenieria')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_3_2, classFather: "father-slick d-flex  m-0 p-0 justify-content-end", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: textIng
      },
      {
        src1: conjunto_2_1En, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.preventa')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_2_2, classFather: "father-slick m-0 p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.preventa-text')}`
      },
      {
        src1: conjunto_4_1En, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.help')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_4_2, classFather: "father-slick d-flex m-0 p-0 justify-content-end", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: textHelp
      },
      {
        src1: conjunto_5_1En, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.diseño')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_5_2, classFather: "father-slick m-0 p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.diseño-text')}`
      },
      {
        src1: conjunto_6_1En, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.administracion')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_6_2, classFather: "father-slick d-flex m-0 p-0 justify-content-end", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: `${t('aboutUsEquipos.administracion-text')}`
      },

      {
        src1: conjunto_7_1En, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.RRHH')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_7_2, classFather: "father-slick m-0 p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.RRHH-text')}`
      },
      {
        src1: conjunto_8_1En, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.legal')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_8_2, classFather: "father-slick d-flex m-0 p-0 justify-content-end", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: `${t('aboutUsEquipos.legal-text')}`
      }
    ],
    body3: [
      {
        src1: conjunto_1_1Pt, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.comercial')}`, height: "230px", widht: "100%", classimg1: "imagen-slick",
        src2: conjunto_1_2, classFather: "father-slick m-0 p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.comercial-text')}`
      },
      {
        src1: conjunto_3_1Pt, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.ingenieria')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_3_2, classFather: "father-slick d-flex  m-0 p-0 justify-content-end", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: textIng
      },
      {
        src1: conjunto_2_1Pt, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.preventa')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_2_2, classFather: "father-slick m-0 p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.preventa-text')}`
      },
      {
        src1: conjunto_4_1Pt, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.help')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_4_2, classFather: "father-slick d-flex m-0 p-0 justify-content-end", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: textHelp
      },
      {
        src1: conjunto_5_1Pt, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.diseño')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_5_2, classFather: "father-slick m-0 p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.diseño-text')}`
      },
      {
        src1: conjunto_6_1Pt, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.administracion')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_6_2, classFather: "father-slick d-flex m-0 p-0 justify-content-end", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: `${t('aboutUsEquipos.administracion-text')}`
      },

      {
        src1: conjunto_7_1Pt, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.RRHH')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_7_2, classFather: "father-slick m-0 p-0", class2: "overlay-slick widht-slick-img", classimg2: "imagen-slick", value: 1,
        text: `${t('aboutUsEquipos.RRHH-text')}`
      },
      {
        src1: conjunto_8_1Pt, class1: "row col-6 m-0 p-0 d-flex ", alt1: `${t('aboutUsEquipos.legal')}`, height: "230px", widht: "230px", classimg1: "imagen-slick",
        src2: conjunto_8_2, classFather: "father-slick d-flex m-0 p-0 justify-content-end", class2: "overlay-slick-2 widht-slick-img", classimg2: "imagen-slick", value: 0,
        text: `${t('aboutUsEquipos.legal-text')}`
      }
    ]
  }



  return (
    <div>
      <Header />
      <Fragment>
        <div>
          <Presentation
            image={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') || domainName.includes('redinet.ca') ? img1DesktopEn : domainName.includes('iwsservices.com.br') ? img1DesktopPr : img1DesktopEs}
            image2={domainName.includes('redinet.us') || domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca') || domainName.includes('redinet.ca') ? img1MobileEn : domainName.includes('iwsservices.com.br') ? img1MobilePr : img1MobileEs}
            text={t("aboutUs.text")}
          />
          <Commitment />
          <MissionVision />
          <Partners />
          <OurTeam />
        </div>
      </Fragment >

      <Footer />
      <ToggleComp />
    </div>

  )
}