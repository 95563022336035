import React, { useCallback, useEffect, useRef, useState } from 'react'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import { useTranslation } from "react-i18next"
import { Link } from 'react-scroll';
import '../../CSS/Dynamic365.css'

const Dynamicwhite = 'https://cdn.iwsservices.com/Dynamics365/Dynamics_new.png';
const CustomerService = 'https://cdn.iwsservices.com/Dynamics365/SinEnvolvente/Customer_Service.png';
const Field_Service = 'https://cdn.iwsservices.com/Dynamics365/SinEnvolvente/Field_Service.png';
const Customer_Insights = 'https://cdn.iwsservices.com/Dynamics365/SinEnvolvente/Customer_Insights.png';
const Commerce = 'https://cdn.iwsservices.com/Dynamics365/SinEnvolvente/Commerce.png';
const Human_Resources = 'https://cdn.iwsservices.com/Dynamics365/SinEnvolvente/Human_Resources.png';
const Remote_assist = 'https://cdn.iwsservices.com/Dynamics365/SinEnvolvente/Remote_Assist.png';
const Marketing = 'https://cdn.iwsservices.com/Dynamics365/SinEnvolvente/Marketing.png';
const Sales = 'https://cdn.iwsservices.com/Dynamics365/SinEnvolvente/Sales.png';
const Project_operations = 'https://cdn.iwsservices.com/Dynamics365/SinEnvolvente/Project_Operations.png';
const dynamics = 'https://cdn.iwsservices.com/Dynamics.png'
const flecha_curva = 'https://cdn.iwsservices.com/Dynamics365/flecha_curva_b.png'
const flecha_curva_mb = 'https://cdn.iwsservices.com/Dynamics365/flecha_curva_mb.png'

export const Dynamic365 = () => {
    const [t, i18n] = useTranslation('common');
    const [ObjDynamic, setObj] = useState({ src: Customer_Insights, descrp: t('dynamic.sales'), tittle : t('dynamic.salesTi') });
    const [iconSelected, setIconSelected] = useState({src: Human_Resources, titulo: 'Human Resources', desc: t('dynamic.rh')});
    const [imgMb, setImgMB] = useState(Human_Resources);
    const [tituloMb, setTituloMb] = useState('Human Resources');
    const [descMb, setDescMb] = useState(t('dynamic.rh'));

    const [enMovimiento, setEnMovimiento] = useState(false);
    const anguloGeneral = useRef(0);
    const anguloActualMb = useRef(0);
    const imgDyanamic = useRef(Human_Resources);
    const tituloDynamic = useRef('Human Resources');
    const descDynamic = useRef(t('dynamic.rh'));
    //const [anguloGeneralAux, setAnguloGeneralAux] = useState(0);
    const anguloGeneralAux = useRef(0);
    const refCircleBox = useRef(null);
    const anguloInicial = useRef(0);
    const cuadrante = useRef(0);
    const miDivRef = useRef(null);

    const item1 = useRef(null);
    const item2 = useRef(null);
    const item3 = useRef(null);
    const item4 = useRef(null);
    const item5 = useRef(null);
    const item6 = useRef(null);
    const item7 = useRef(null);
    const item8 = useRef(null);
    const item9 = useRef(null);

    const dynamiclogo = useRef(null);
    let center = 0;
    let h = 0;
    let radianes = 0;
    let grados = 0;
    let hipo = 0;
    let initialAngle = 0;
    let angulo = 0;
    let x = 0;
    let y = 0;
    // let cuadrante = 0;
    let lastQuadrant = 0;
    let percentX = 0;
    let percentY = 0;
    // let anguloInicial = 0;
    let anguloAvance = 0;
    let anguloActual = 0;
    let cuadranteInicio = 0;
    let widthArea = 0;
    let pasoPorC1 = false;
    let pasoPorC2 = false;
    let lastQuadrant1 = 0;
    let anguloAux = 0;
    let percentCenter1 = 0;
    let percentCenter2 = 0;
    let percentCenter3 = 0;
    let percentCenter4 = 0;
    let percentCenter5 = 0;
    let percentCenter6 = 0;
    let percentCenter7 = 0;
    let percentCenter8 = 0;
    let percentCenter9 = 0;

    let distanceX1 = 0;
    let distanceX2 = 0;
    let distanceX3 = 0;
    let distanceX4 = 0;
    let distanceX5 = 0;
    let distanceX6 = 0;
    let distanceX7 = 0;
    let distanceX8 = 0;
    let distanceX9 = 0;
    //let anguloGeneralAux = 0;
    let listPercentY = [];
    let maxNumber = 0;
    let indexFound = 0;
   
    useEffect(() => {

        setTimeout(() => {
            let bounds = refCircleBox.current.getBoundingClientRect();

            let boundslogo = dynamiclogo.current.getBoundingClientRect();
            let centerX = (bounds.width / 2) - (boundslogo.width / 2);

            dynamiclogo.current.style.left = `${centerX}px`;

            if(isMobile){
                item5.current.classList.add("circle-item-hover");
            }else{
                item3.current.classList.add("circle-item-hover");

            }
        }, 2000);

    }, [])

    const startMove = () => {


        let bounds = refCircleBox.current.getBoundingClientRect();
        widthArea = bounds.width;
        hipo = bounds.height / 2;
        center = bounds.top + hipo;
        console.log(enMovimiento);
        // if (enMovimiento) {
        //     endMove();

        // }
        // else {
        //     notifyPaintMoveMent();
        // }
        notifyPaintMoveMent();
    }
    const endMove = () => {

        notifyStopPaintMoveMent();
        anguloGeneral.current = anguloGeneralAux.current;

        anguloInicial.current = 0;
        cuadranteInicio = 0;
        pasoPorC1 = false;
        pasoPorC2 = false;
        lastQuadrant = 0;
        lastQuadrant1 = 0;

    }
    const notifyPaintMoveMent = () => {

        refCircleBox.current.addEventListener('mousemove', paint);
        setEnMovimiento(true);
    }
    const notifyStopPaintMoveMent = () => {

        setEnMovimiento(false);
        refCircleBox.current.removeEventListener('mousemove', paint);
    }

    const paint = useCallback(
        (event) => {
            x = event.clientX;
            y = event.clientY;
            percentX = 100 / (widthArea / x);
            percentY = 100 / (window.innerHeight / y);


            //console.log(`X: ${x} - Y: ${y}`);
            h = center - y;
            radianes = Math.asin(h / hipo);
            grados = radianes * (180 / Math.PI);


            if (!isNaN(grados)) {

                //CUADRANTE 1
                if (percentX <= 50) {
                    // console.log('**********************Cuadrante ' + cuadrante);
                    if (anguloInicial.current === 0 || cuadrante.current === 2) {
                        anguloInicial.current = grados;
                        if (cuadrante.current === 2)
                            anguloGeneral.current = anguloGeneralAux.current;
                    }

                    anguloActual = grados;

                    cuadrante.current = 1;
                    pasoPorC1 = true;

                    if (percentY <= 50) {
                        lastQuadrant1 = 1;
                        // console.log('*********************** last q1' + lastQuadrant1);
                    } else {
                        lastQuadrant1 = 4;
                        // console.log('*********************** last q1' + lastQuadrant1);
                    }

                }
                //CUADRANTE 2
                else if (percentX > 50) {
                    // console.log('*************************Cuadrante ' + cuadrante);
                    if (anguloInicial.current === 0 || cuadrante.current === 1) {
                        anguloInicial.current = 87 + (87 - grados);
                        if (cuadrante.current === 1)
                            anguloGeneral.current = anguloGeneralAux.current;
                    }

                    anguloActual = 87 + (87 - grados);

                    cuadrante.current = 2;
                    //pasoPorC2 = true;

                    if (percentY <= 50) {
                        lastQuadrant = 2;
                        // console.log('*********************** last q' + lastQuadrant);
                    } else {
                        lastQuadrant = 3;
                        // console.log('*********************** last q' + lastQuadrant);
                    }
                }
                anguloGeneralAux.current = anguloGeneral.current;

                anguloAvance = anguloActual - anguloInicial.current;

                anguloGeneralAux.current += anguloAvance;

                // console.log(`h: ${h} - hipo: ${hipo} - center: ${center} - radianes: ${radianes} - grados: ${grados}`);
                // console.log('ai: ' + anguloInicial.current);
                // console.log('aa: ' + anguloActual);
                // console.log('grados: ' + grados);
                // console.log('avance: ' + anguloAvance);
                // console.log('agaux: ' + anguloGeneralAux.current);

                const circle = document.querySelector('#container-circle');
                circle.style.transform = `rotate(${anguloGeneralAux.current}deg)`

                item1.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
                item2.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
                item3.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
                item4.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
                item5.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
                item6.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
                item7.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
                item8.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
                item9.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`

                percentCenter1 = item1.current.getBoundingClientRect().y / center;
                percentCenter2 = item2.current.getBoundingClientRect().y / center;
                percentCenter3 = item3.current.getBoundingClientRect().y / center;
                percentCenter4 = item4.current.getBoundingClientRect().y / center;
                percentCenter5 = item5.current.getBoundingClientRect().y / center;
                percentCenter6 = item6.current.getBoundingClientRect().y / center;
                percentCenter7 = item7.current.getBoundingClientRect().y / center;
                percentCenter8 = item8.current.getBoundingClientRect().y / center;
                percentCenter9 = item9.current.getBoundingClientRect().y / center;

                distanceX1 = 100 / (widthArea / item1.current.getBoundingClientRect().x);
                distanceX2 = 100 / (widthArea / item2.current.getBoundingClientRect().x);
                distanceX3 = 100 / (widthArea / item3.current.getBoundingClientRect().x);
                distanceX4 = 100 / (widthArea / item4.current.getBoundingClientRect().x);
                distanceX5 = 100 / (widthArea / item5.current.getBoundingClientRect().x);
                distanceX6 = 100 / (widthArea / item6.current.getBoundingClientRect().x);
                distanceX7 = 100 / (widthArea / item7.current.getBoundingClientRect().x);
                distanceX8 = 100 / (widthArea / item8.current.getBoundingClientRect().x);
                distanceX9 = 100 / (widthArea / item9.current.getBoundingClientRect().x);

                listPercentY = [distanceX1, distanceX2, distanceX3, distanceX4, distanceX5, distanceX6, distanceX7, distanceX8, distanceX9];
                maxNumber = Math.max(...listPercentY);
                indexFound = listPercentY.findIndex(el => el === maxNumber);

                if (indexFound === 0) {

                    setObj({ 
                        src: CustomerService, 
                        descrp: t('dynamic.customerServ'),
                        tittle: t('dynamic.customerServTi') 
                    
                    });
                    item2.current.classList.remove('circle-item-hover');
                    item3.current.classList.remove('circle-item-hover');
                    item4.current.classList.remove('circle-item-hover');
                    item5.current.classList.remove('circle-item-hover');
                    item6.current.classList.remove('circle-item-hover');
                    item7.current.classList.remove('circle-item-hover');
                    item8.current.classList.remove('circle-item-hover');
                    item9.current.classList.remove('circle-item-hover');

                    item1.current.classList.add("circle-item-hover");
                } else if (indexFound === 1) {

                    setObj({
                        src: Field_Service,
                        descrp: t('dynamic.fieldServ'),
                        tittle : t('dynamic.fieldServTi')

                    });
                    item1.current.classList.remove('circle-item-hover');
                    item3.current.classList.remove('circle-item-hover');
                    item4.current.classList.remove('circle-item-hover');
                    item5.current.classList.remove('circle-item-hover');
                    item6.current.classList.remove('circle-item-hover');
                    item7.current.classList.remove('circle-item-hover');
                    item8.current.classList.remove('circle-item-hover');
                    item9.current.classList.remove('circle-item-hover');

                    item2.current.classList.add("circle-item-hover");
                } else if (indexFound === 2) {
                    setObj({
                        src: Customer_Insights,
                        descrp: t('dynamic.customerInsights'),
                        tittle : t('dynamic.customerInsightsTi')

                    });
                    item2.current.classList.remove('circle-item-hover');
                    item1.current.classList.remove('circle-item-hover');
                    item4.current.classList.remove('circle-item-hover');
                    item5.current.classList.remove('circle-item-hover');
                    item6.current.classList.remove('circle-item-hover');
                    item7.current.classList.remove('circle-item-hover');
                    item8.current.classList.remove('circle-item-hover');
                    item9.current.classList.remove('circle-item-hover');

                    item3.current.classList.add("circle-item-hover");
                } else if (indexFound === 3) {
                    setObj({
                        src: Commerce,
                        descrp: t('dynamic.commerce'),
                        tittle : t('dynamic.commerceTi')

                    });
                    item2.current.classList.remove('circle-item-hover');
                    item3.current.classList.remove('circle-item-hover');
                    item1.current.classList.remove('circle-item-hover');
                    item5.current.classList.remove('circle-item-hover');
                    item6.current.classList.remove('circle-item-hover');
                    item7.current.classList.remove('circle-item-hover');
                    item8.current.classList.remove('circle-item-hover');
                    item9.current.classList.remove('circle-item-hover');

                    item4.current.classList.add("circle-item-hover");
                } else if (indexFound === 4) {
                    setObj({
                        src: Human_Resources,
                        descrp: t('dynamic.rh'),
                        tittle: t('dynamic.rhTi')
                        

                    });
                    item2.current.classList.remove('circle-item-hover');
                    item3.current.classList.remove('circle-item-hover');
                    item4.current.classList.remove('circle-item-hover');
                    item1.current.classList.remove('circle-item-hover');
                    item6.current.classList.remove('circle-item-hover');
                    item7.current.classList.remove('circle-item-hover');
                    item8.current.classList.remove('circle-item-hover');
                    item9.current.classList.remove('circle-item-hover');

                    item5.current.classList.add("circle-item-hover");
                } else if (indexFound === 5) {
                    setObj({
                        src: Remote_assist,
                        descrp: t('dynamic.remoteAssist'),
                        tittle: t('dynamic.remoteAssistTi')

                    });
                    item2.current.classList.remove('circle-item-hover');
                    item3.current.classList.remove('circle-item-hover');
                    item4.current.classList.remove('circle-item-hover');
                    item5.current.classList.remove('circle-item-hover');
                    item1.current.classList.remove('circle-item-hover');
                    item7.current.classList.remove('circle-item-hover');
                    item8.current.classList.remove('circle-item-hover');
                    item9.current.classList.remove('circle-item-hover');

                    item6.current.classList.add("circle-item-hover");
                } else if (indexFound === 6) {
                    setObj({
                        src: Marketing,
                        descrp: t('dynamic.marketing'),
                        tittle: t('dynamic.marketingTi')

                    });
                    item2.current.classList.remove('circle-item-hover');
                    item3.current.classList.remove('circle-item-hover');
                    item4.current.classList.remove('circle-item-hover');
                    item5.current.classList.remove('circle-item-hover');
                    item6.current.classList.remove('circle-item-hover');
                    item1.current.classList.remove('circle-item-hover');
                    item8.current.classList.remove('circle-item-hover');
                    item9.current.classList.remove('circle-item-hover');

                    item7.current.classList.add("circle-item-hover");
                } else if (indexFound === 7) {
                    setObj({
                        src: Sales,
                        descrp: t('dynamic.sales'),
                        tittle: t('dynamic.salesTi')

                    });
                    item2.current.classList.remove('circle-item-hover');
                    item3.current.classList.remove('circle-item-hover');
                    item4.current.classList.remove('circle-item-hover');
                    item5.current.classList.remove('circle-item-hover');
                    item6.current.classList.remove('circle-item-hover');
                    item7.current.classList.remove('circle-item-hover');
                    item1.current.classList.remove('circle-item-hover');
                    item9.current.classList.remove('circle-item-hover');

                    item8.current.classList.add("circle-item-hover");
                } else if (indexFound === 8) {
                    setObj({
                        src: Project_operations,
                        descrp: t('dynamic.projectOps'),
                        tittle: t('dynamic.projectOpsTi'),

                    });
                    item2.current.classList.remove('circle-item-hover');
                    item3.current.classList.remove('circle-item-hover');
                    item4.current.classList.remove('circle-item-hover');
                    item5.current.classList.remove('circle-item-hover');
                    item6.current.classList.remove('circle-item-hover');
                    item7.current.classList.remove('circle-item-hover');
                    item8.current.classList.remove('circle-item-hover');
                    item1.current.classList.remove('circle-item-hover');

                    item9.current.classList.add("circle-item-hover");
                }

                // item1.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg) scale(${distanceX1 * 2 / 100})`;
                // item2.current.style.transform = `scale(${distanceX2 *2 / 100 })`;
                // item3.current.style.transform = `scale(${distanceX3 * 2/ 100 })`;
                // item4.current.style.transform = `scale(${distanceX4 *2 / 100 })`;
                // item5.current.style.transform = `scale(${distanceX5 *2 / 100 })`;
                // item6.current.style.transform = `scale(${distanceX6 *2 / 100 })`;
                // item7.current.style.transform = `scale(${distanceX7 *2 / 100 })`;
                // item8.current.style.transform = `scale(${distanceX8 *2 / 100 })`;
                // item9.current.style.transform = `scale(${distanceX9 *2 / 100 })`;
            }


        },
        [],
    )

    const onTouchStart = () => {
        console.log('Empezaste a tocar la pantalla');
        document.querySelector('body').style.overflowY = 'hidden';
        document.querySelector('body').style.overflowX = 'hidden';
        document.querySelector('.main-container-circle').style.overflow = 'hidden';
        // const box = document.querySelector('#circle-box');
        let bounds = refCircleBox.current.getBoundingClientRect();
        widthArea = bounds.width;
        hipo = bounds.height / 2;
        center = bounds.top + hipo;
    }
    const onTouchEnd = () => {
        console.log('Terminaste de tocar la pantalla');
        document.querySelector('body').style.overflowY = 'auto';
        document.querySelector('body').style.overflowX = 'auto';
        document.querySelector('.main-container-circle').style.overflow = 'auto';

        // setAnguloGeneral(anguloGeneralAux);
        anguloGeneral.current = anguloGeneralAux.current;
        anguloInicial.current = 0;
        cuadranteInicio = 0;
        pasoPorC1 = false;
        pasoPorC2 = false;
        lastQuadrant = 0;
        lastQuadrant1 = 0;

        percentCenter1 = item1.current.getBoundingClientRect().y - center;
        percentCenter2 = item2.current.getBoundingClientRect().y - center;
        percentCenter3 = item3.current.getBoundingClientRect().y - center;
        percentCenter4 = item4.current.getBoundingClientRect().y - center;
        percentCenter5 = item5.current.getBoundingClientRect().y - center;
        percentCenter6 = item6.current.getBoundingClientRect().y - center;
        percentCenter7 = item7.current.getBoundingClientRect().y - center;
        percentCenter8 = item8.current.getBoundingClientRect().y - center;
        percentCenter9 = item9.current.getBoundingClientRect().y - center;

        listPercentY = [percentCenter1,percentCenter2,percentCenter3,percentCenter4,percentCenter5, percentCenter6, percentCenter7, percentCenter8, percentCenter9];
        maxNumber = Math.max(...listPercentY);
        indexFound = listPercentY.findIndex(el => el === maxNumber);

        item1.current.classList.remove('circle-item-hover');
        item2.current.classList.remove('circle-item-hover');
        item3.current.classList.remove('circle-item-hover');
        item4.current.classList.remove('circle-item-hover');
        item5.current.classList.remove('circle-item-hover');
        item6.current.classList.remove('circle-item-hover');
        item7.current.classList.remove('circle-item-hover');
        item8.current.classList.remove('circle-item-hover');
        item9.current.classList.remove('circle-item-hover');

        if(indexFound === 0){
              
            setImgMB(CustomerService);
            setTituloMb('Customer Service');
            setDescMb(t('dynamic.customerServ'));

            item1.current.classList.add("circle-item-hover");
        }else if(indexFound === 1){
            
           
            setImgMB(Field_Service);
            setTituloMb('Field Service');
            setDescMb(t('dynamic.fieldServ'));
           
            item2.current.classList.add("circle-item-hover");
        }else if(indexFound === 2){
            
            setImgMB(Customer_Insights);
            setTituloMb('Customer Insights');
            setDescMb(t('dynamic.customerInsights'));
            
            item3.current.classList.add("circle-item-hover");
        }else if(indexFound === 3){
            
            setImgMB(Commerce);
            setTituloMb('Commerce');
            setDescMb(t('dynamic.commerce'));
        
            item4.current.classList.add("circle-item-hover");
        }else if(indexFound === 4){
         
            setImgMB(Human_Resources);
            setTituloMb('Human Resources');
            setDescMb(t('dynamic.rh'));
            
            item5.current.classList.add("circle-item-hover");
        }else if(indexFound === 5){
           
            setImgMB(Remote_assist);
            setTituloMb('Remote Assist');
            setDescMb(t('dynamic.remoteAssist'));
            

            item6.current.classList.add("circle-item-hover");
        }else if(indexFound === 6){
            
            setImgMB(Marketing);
            setTituloMb('Marketing');
            setDescMb(t('dynamic.marketing'));
           
            item7.current.classList.add("circle-item-hover");
        }else if(indexFound === 7){
           
            setImgMB(Sales);
            setTituloMb('Sales');
            setDescMb(t('dynamic.sales'));
            
            item8.current.classList.add("circle-item-hover");
        }else if(indexFound === 8){
           
            setImgMB(Project_operations);
            setTituloMb('Project Operations');
            setDescMb(t('dynamic.projectOps'));
            
            item9.current.classList.add("circle-item-hover");
        }


        const btnScroll = document.getElementById('btnScroll');
        btnScroll.click();

        setTimeout(() => {
            const btnScrollUp = document.getElementById('btnScrollUp');
            btnScrollUp.click();
        }, 5000);

    }
    const onTouchCancel = () => {
        console.log('Se canceló el evento touch');
        document.querySelector('body').style.overflowY = 'auto';
        document.querySelector('.main-container-circle').style.overflow = 'auto';

        // setAnguloGeneral(anguloGeneralAux);
        anguloGeneral.current = anguloGeneralAux.current;
        anguloInicial.current = 0;
        cuadranteInicio = 0;
        pasoPorC1 = false;
        pasoPorC2 = false;
        lastQuadrant = 0;
        lastQuadrant1 = 0;
    }
    const onTouchMove = (event) => {

        x = event.touches[0].clientX;
        y = event.touches[0].clientY;
        percentX = 100 / (widthArea / x);
        percentY = 100 / (window.innerHeight / y);

        h = center - y;
        radianes = Math.asin(h / hipo);
        grados = radianes * (180 / Math.PI);

        // console.log(`h: ${h} - hipo: ${hipo} - center: ${center} - radianes: ${radianes} - grados: ${grados}`);

        if (!isNaN(grados)) {

            //CUADRANTE 1
            if (percentX <= 50) {
                // console.log('**********************Cuadrante ' + cuadrante);
                if (anguloInicial.current === 0 || cuadrante.current === 2) {
                    anguloInicial.current = grados;
                    if (cuadrante.current === 2)
                        anguloGeneral.current = anguloGeneralAux.current;
                }

                anguloActualMb.current = grados;

                cuadrante.current = 1;
                pasoPorC1 = true;

                if (percentY <= 50) {
                    lastQuadrant1 = 1;
                    // console.log('*********************** last q1' + lastQuadrant1);
                } else {
                    lastQuadrant1 = 4;
                    // console.log('*********************** last q1' + lastQuadrant1);
                }

            }
            //CUADRANTE 2
            else if (percentX > 50) {
                // console.log('*************************Cuadrante ' + cuadrante);
                if (anguloInicial.current === 0 || cuadrante.current === 1) {
                    anguloInicial.current = 87 + (87 - grados);
                    if (cuadrante.current === 1)
                        anguloGeneral.current = anguloGeneralAux.current;
                }

                anguloActualMb.current = 87 + (87 - grados);

                cuadrante.current = 2;
                //pasoPorC2 = true;

                if (percentY <= 50) {
                    lastQuadrant = 2;
                    // console.log('*********************** last q' + lastQuadrant);
                } else {
                    lastQuadrant = 3;
                    // console.log('*********************** last q' + lastQuadrant);
                }
            }
            anguloGeneralAux.current = anguloGeneral.current;

            anguloAvance = anguloActualMb.current - anguloInicial.current;

            anguloGeneralAux.current += anguloAvance;

            // console.log(`h: ${h} - hipo: ${hipo} - center: ${center} - radianes: ${radianes} - grados: ${grados}`);
            // console.log('ai: ' + anguloInicial.current);
            // console.log('aa: ' + anguloActual);
            // console.log('grados: ' + grados);
            // console.log('avance: ' + anguloAvance);
            // console.log('agaux: ' + anguloGeneralAux.current);

            const circle = document.querySelector('#container-circle-mobile');
            circle.style.transform = `rotate(${anguloGeneralAux.current}deg)`

            item1.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
            item2.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
            item3.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
            item4.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
            item5.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
            item6.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
            item7.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
            item8.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`
            item9.current.style.transform = `rotate(${anguloGeneralAux.current * -1}deg)`

            percentCenter1 = item1.current.getBoundingClientRect().y - center;
            percentCenter2 = item2.current.getBoundingClientRect().y - center;
            percentCenter3 = item3.current.getBoundingClientRect().y - center;
            percentCenter4 = item4.current.getBoundingClientRect().y - center;
            percentCenter5 = item5.current.getBoundingClientRect().y - center;
            percentCenter6 = item6.current.getBoundingClientRect().y - center;
            percentCenter7 = item7.current.getBoundingClientRect().y - center;
            percentCenter8 = item8.current.getBoundingClientRect().y - center;
            percentCenter9 = item9.current.getBoundingClientRect().y - center;

            listPercentY = [percentCenter1,percentCenter2,percentCenter3,percentCenter4,percentCenter5, percentCenter6, percentCenter7, percentCenter8, percentCenter9];
            maxNumber = Math.max(...listPercentY);
            indexFound = listPercentY.findIndex(el => el === maxNumber);
            
            item1.current.classList.remove('circle-item-hover');
            item2.current.classList.remove('circle-item-hover');
            item3.current.classList.remove('circle-item-hover');
            item4.current.classList.remove('circle-item-hover');
            item5.current.classList.remove('circle-item-hover');
            item6.current.classList.remove('circle-item-hover');
            item7.current.classList.remove('circle-item-hover');
            item8.current.classList.remove('circle-item-hover');
            item9.current.classList.remove('circle-item-hover');

            if(indexFound === 0){
            
                item1.current.classList.add("circle-item-hover");
            }else if(indexFound === 1){
               
                item2.current.classList.add("circle-item-hover");
            }else if(indexFound === 2){
               
                item3.current.classList.add("circle-item-hover");
            }else if(indexFound === 3){
               
                item4.current.classList.add("circle-item-hover");
            }else if(indexFound === 4){
                
                item5.current.classList.add("circle-item-hover");
            }else if(indexFound === 5){
               
                item6.current.classList.add("circle-item-hover");
            }else if(indexFound === 6){
               
                item7.current.classList.add("circle-item-hover");
            }else if(indexFound === 7){
        
                item8.current.classList.add("circle-item-hover");
            }else if(indexFound === 8){
               
                item9.current.classList.add("circle-item-hover");
            }
        }
    }

    return (
        <>
            <BrowserView>
                <div className="container text-center padding-" >
                    <h2 className='title-a-section-home our-alianza'>{t('365.tittle')}</h2>
                    <p className='card-service-desc'>{t('365.desc')}</p>
                </div>

                <div>
                    <div className='row'>
                        <div className='col-lg-6 container-circle-ruleta p-2'>
                            <section id="circle-box" className='my-5' ref={refCircleBox}>
                                <div id="container-circle" onMouseDown={startMove} onMouseUp={endMove}>
                                    <div class="circle-item figcaption item1" ref={item1}>
                                    </div>
                                    <div class="circle-item figcaption item2" ref={item2}>
                                    </div>
                                    <div class="circle-item figcaption item3" ref={item3}>
                                    </div>
                                    <div class="circle-item figcaption item4" ref={item4}>
                                    </div>
                                    <div class="circle-item figcaption item5" ref={item5}>
                                    </div>
                                    <div class="circle-item figcaption item6" ref={item6}>
                                    </div>
                                    <div class="circle-item figcaption item7" ref={item7}>
                                    </div>
                                    <div class="circle-item figcaption item8" ref={item8}>
                                    </div>
                                    <div class="circle-item figcaption item9" ref={item9}>
                                    </div>
                                </div>
                                <div id='background-circle' onClick={startMove} ref={dynamiclogo}>
                                    <img src={dynamics} alt="" />
                                </div>
                                <div className='flecha'>
                                    <label htmlFor="" >{t('dynamic.instruccion')}</label>
                                    <img src={flecha_curva} alt="" />
                                </div>
                            </section>
                        </div>
                        <div className='col-6 image-cover-container p-2' style={{ backgroundImage: `url(${ObjDynamic.src})` }}>
                            <div className='card no-border bg-not'>
                                <div className='card-header bg-not'>
                                    <div className='container'
                                    >
                                        <h2 className='title-a-section-home our-alianza c-whihte text-center'> {ObjDynamic.tittle}</h2>

                                        <div className='container text-center mt-3'>
                                            <img src={Dynamicwhite} className='img-center'></img>
                                        </div>

                                        <p className='card-service-desc c-whihte'>
                                            {ObjDynamic.descrp} </p>
                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </BrowserView>

            <MobileView className='main-container-circle'>
                <div className="container text-center padding-" id='dynamic365'>
                    <h2 className='title-a-section-home our-alianza'>{t('365.tittle')}</h2>
                    <p className='card-service-desc'>{t('365.desc')}</p>
                    {/* <button id='btnScroll' onClick={scrollDown}>click</button> */}
                    <Link to="circle-box" id='btnScroll' smooth={true} duration={350} hidden>
                        Ir al elemento
                    </Link>
                    <Link to="dynamic365" id='btnScrollUp' smooth={true} duration={550} hidden>
                        Ir al elemento
                    </Link>
                </div>

                <div>
                    <div className='row'>
                        <div className='col-12 p-0 container-circle-ruleta-mb'>

                            <section id="circle-box" ref={refCircleBox} className='my-5' onTouchMove={onTouchMove} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} onTouchCancel={onTouchCancel}>
                                <div id="container-circle-mobile">
                                    <div class="circle-item-mobile item1-mb" ref={item1}>
                                    </div>
                                    <div class="circle-item-mobile item2-mb" ref={item2}>
                                    </div>
                                    <div class="circle-item-mobile item3-mb" ref={item3}>
                                    </div>
                                    <div class="circle-item-mobile item4-mb" ref={item4}>
                                    </div>
                                    <div class="circle-item-mobile item5-mb" ref={item5}>
                                    </div>
                                    <div class="circle-item-mobile item6-mb" ref={item6}>
                                    </div>
                                    <div class="circle-item-mobile item7-mb" ref={item7}>
                                    </div>
                                    <div class="circle-item-mobile item8-mb" ref={item8}>
                                    </div>
                                    <div class="circle-item-mobile item9-mb" ref={item9}>
                                    </div>
                                </div>
                                <div id='background-circle-mb' onClick={startMove} ref={dynamiclogo}>
                                    <img src={dynamics} alt="" />
                                </div>
                                <div className='flecha'>
                                    <label htmlFor="">{t('dynamic.instruccion')}</label>
                                    <img src={flecha_curva_mb} alt="" />
                                </div>
                            </section>
                        </div>
                        <div ref={miDivRef} id="miDiv" className='col-12 image-cover-container pt-3' style={{backgroundImage: `url(${imgMb})`}}>
                            <div className='card no-border bg-not'>
                                <div className='card-header bg-not'>
                                    <div className='container mt-5'
                                    >
                                        <h2 className='our-alianza c-whihte text-center mt-2'>{tituloMb}</h2>

                                        <div className='container text-center mt-3'>
                                            <img src={Dynamicwhite} className='img-center'></img>
                                        </div>

                                        <p className='card-service-desc c-whihte'>
                                            {descMb}
                                        </p>

                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </MobileView>
        </>
    )
}
