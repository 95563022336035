
import React, { Fragment } from 'react';
import { Outlet, Link } from 'react-router-dom';
import traductor from '../Images/WhatsApp.png'
import translate_us from '../Images/translate_us_a.png'
import translate_mx from '../Images/translate_mx_a.png'
// import translate_pt from '../Images/translate_br_a.png'
import translate_pt from '../Images/Brasil.png'
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEarth, faUserGraduate } from '@fortawesome/free-solid-svg-icons'
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
export function ItemHeader(props) {
  const [t, i18n] = useTranslation('common');
  const domainName = window.location.hostname;
  return (
    <Fragment>
      <li className="nav-item unnamed-character-style-1 px-lg-2 px-xl-3 navText">
        <Link className="nav-link active unnamed-character-style-1" aria-current="page" to="/Nosotros" onClick={props.action}><span>{t('header.nosotros')}</span></Link>
      </li>
      <li className="nav-item dropdown unnamed-character-style-1 px--2">
        <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <span>{t('header.soluciones')}</span>
        </a>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><Link className="dropdown-item unnamed-character-style-1 textNav" to="/AWS" onClick={props.action}>AWS</Link></li>
          <li><Link className="dropdown-item unnamed-character-style-1" to="/Microsoft" onClick={props.action}>Microsoft</Link></li>
          <li><Link className="dropdown-item unnamed-character-style-1" to="/Google" onClick={props.action}>Google</Link></li>
          <li><Link className="dropdown-item unnamed-character-style-1" to="/Kaspersky" onClick={props.action}>Kaspersky</Link></li>
          <li><Link className="dropdown-item unnamed-character-style-1" to="/Veritas" onClick={props.action}>Veritas</Link></li>
          <li><Link className="dropdown-item unnamed-character-style-1" to="/Teams" onClick={props.action}>Microsoft Teams</Link></li>
          <li className="nav-item dropdown unnamed-character-style-1 px--2">
            <a className="dropdown-item  dropdown-toggle" href="#">
              {t('header.indust')}
            </a>
            <ul className="dropdown-menu dropdown-submenu" id="submenu">
              <li><Link className="dropdown-item unnamed-character-style-1" to="/Education" onClick={props.action}>{t('header.industria.educacion')}</Link></li>
              <li><Link className="dropdown-item unnamed-character-style-1" to="/Financial" onClick={props.action}>{t('header.industria.financiero')}</Link></li>
              <li><Link className="dropdown-item unnamed-character-style-1" to="/Government" onClick={props.action}>{t('header.industria.gobierno')}</Link></li>
              <li><Link className="dropdown-item unnamed-character-style-1" to="/Retail" onClick={props.action}>{t('header.industria.retail')}</Link></li>
              <li><Link className="dropdown-item unnamed-character-style-1" to="/Transport" onClick={props.action}>{t('header.industria.transporte')}</Link></li>
            </ul>
          </li>

        </ul>
      </li>
      <li className="nav-item dropdown unnamed-character-style-1 px--2">
        <a className="nav-link dropdown-toggle" id="Dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <span>{t('header.servicios')}</span>
        </a>
        <ul className="dropdown-menu" aria-labelledby="Dropdown">
          <li><Link className="dropdown-item unnamed-character-style-1" to="/Services In a Box" onClick={props.action}>{t('header.services')}</Link></li>
          <li><Link className="dropdown-item unnamed-character-style-1" to="/Migrations" onClick={props.action}>{t('header.migraciones')}</Link></li>
          <li><Link className="dropdown-item unnamed-character-style-1" to="/Help Desk" onClick={props.action}>{t('header.helpdesk')}</Link></li>
          <li><Link className="dropdown-item unnamed-character-style-1" to="/Trainings" onClick={props.action}>{t('header.capacitaciones')}</Link></li>
          <li><Link className="dropdown-item unnamed-character-style-1" to="/Assessment" onClick={props.action}>{t('header.assessment')}</Link></li>
          <li><Link className="dropdown-item unnamed-character-style-1" to="/Bag of hours" onClick={props.action}>{t('header.bolsa')}</Link></li>
          <li><Link className="dropdown-item unnamed-character-style-1" to="/Implementations" onClick={props.action}>{t('header.implementaciones')}</Link></li>


        </ul>
      </li>
      {domainName.includes('redinet.cr') === false &&
        <>
          {/* <li className="nav-item unnamed-character-style-1 px-lg-2 px-xl-3">
            <Link className="nav-link" to="/Recursos" onClick={props.action}><span>{t('header.recursos')}</span></Link>
          </li> */}
          <li className="nav-item dropdown unnamed-character-style-1 px--2">
            <a className="nav-link dropdown-toggle" id="Dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span>{t('header.recursos')}</span>
            </a>
            <ul className="dropdown-menu" aria-labelledby="Dropdown">
              <li><Link className="dropdown-item unnamed-character-style-1" to="/Recursos" onClick={props.action}>{t('header.materiales')}</Link></li>
              <li><Link className="dropdown-item unnamed-character-style-1" to="/News" onClick={props.action}>{t('header.noticias')}</Link></li>
            </ul>
          </li>
        </>
      }
      <li className="nav-item unnamed-character-style-1 px-lg-2 px-xl-3">
        <Link className="nav-link" to="/Contact" onClick={props.action}><span>{t('header.contacto')}</span></Link>
      </li>
      {/* <li className={`nav-item unnamed-character-style-1 px-lg-2 px-xl-3 pt-md-4 pt-lg-0 icon-traducir ${t("clase.es")}`} onClick={() => i18n.changeLanguage('en')}> */}
          <li className={`nav-item unnamed-character-style-1 px-lg-2 px-xl-3 pt-md-4 pt-lg-0 icon-traducir ${t("clase.es")}`}>
            <div className='d-flex align-items-center' style={{ marginTop: '0.3rem' }}>
              <li className="nav-item dropdown unnamed-character-style-1 px--2">
                <a className="nav-link dropdown-toggle" id="Dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span><FontAwesomeIcon className="fz-23" icon={faEarth} /></span>
                </a>
                <ul className="dropdown-menu" aria-labelledby="Dropdown">
                  <li>
                    <a className="nav-link" title='Traducir' href={domainName.includes('redinet') ? 'https://www.redinet.us' : 'https://www.iwsservices.us'}>
                      <div className='d-flex justify-content-start'>
                        <div className='icons-tr icons-mt-2'>
                          <img src={translate_us} height="50" width="50" className='' />
                        </div>
                        <span className='ms-1'>English</span>
                      </div>

                    </a>
                  </li>
                  <li>
                    <a className={domainName.includes('iwsservices.com.br') ? 'd-none' : 'nav-link'} title='Traducir' href='http://www.iwsservices.com.br'>
                      <div className='d-flex justify-content-start'>
                        <div className='divBrasil'>
                          <img src={translate_pt} className="iconBrasil" alt="" />
                        </div>
                        <span className='ms-1'>Portuguese</span>
                      </div>

                    </a>
                  </li>
                </ul>
              </li>
            </div>
          </li>
          {/* <li className={`nav-item unnamed-character-style-1 px-lg-2 px-xl-3 pt-md-4 pt-lg-0 icon-traducir ${t("clase.en")}`} onClick={() => i18n.changeLanguage('es')}> */}
          <li className={`nav-item unnamed-character-style-1 px-lg-2 px-xl-3 pt-md-4 pt-lg-0 icon-traducir ${t("clase.en")}`}>
            <div className='d-flex align-items-center' style={{ marginTop: '0.3rem' }}>
              <li className="nav-item dropdown unnamed-character-style-1 px--2">
                <a className="nav-link dropdown-toggle" id="Dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span><FontAwesomeIcon className="fz-23" icon={faEarth} /></span>
                </a>
                <ul className="dropdown-menu" aria-labelledby="Dropdown">
                  <li>
                    <a className="nav-link" title='Traducir' href={domainName.includes('redinet') ? 'https://www.redinet.com.mx' : 'https://www.iwsservices.com'} >
                      <div className='d-flex justify-content-start'>
                        <div className='icons-tr icons-mt-2'>
                          <img src={translate_mx} height="50" width="50" className='' />
                        </div>
                        <span className='ms-1'>Español</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className={domainName.includes('iwsservices.com.br') ? 'd-none' : 'nav-link'} title='Traducir' href='http://www.iwsservices.com.br'>
                      <div className='d-flex justify-content-start'>
                        <div className='divBrasil'>
                          <img src={translate_pt} className="iconBrasil" alt="" />
                        </div>
                        <span className='ms-1'>Portuguese</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
            </div>
          </li>


          <li className={`nav-item unnamed-character-style-1 px-lg-2 px-xl-3 pt-md-4 pt-lg-0 icon-traducir ${t("clase.pt")}`}>
            <div className='d-flex align-items-center' style={{ marginTop: '0.3rem' }}>
              <li className="nav-item dropdown unnamed-character-style-1 px--2">
                <a className="nav-link dropdown-toggle" id="Dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span><FontAwesomeIcon className="fz-23" icon={faEarth} /></span>
                </a>
                <ul className="dropdown-menu" aria-labelledby="Dropdown">
                  <li>
                    <a className="nav-link" title='Traducir' href={domainName.includes('redinet') ? 'https://www.redinet.com.mx' : 'https://www.iwsservices.com'}>
                      <div className='d-flex justify-content-start'>
                        <div className='icons-tr icons-mt-2'>
                          <img src={translate_mx} height="50" width="50" className='' />
                        </div>
                        <span className='ms-1'>Español</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="nav-link" title='Traducir' href={domainName.includes('redinet') ? 'https://www.redinet.us' : 'https://www.iwsservices.us'}>
                      <div className='d-flex justify-content-start'>
                        <div className='icons-tr icons-mt-2'>
                          <img src={translate_us} height="50" width="50" className='' />
                        </div>
                        <span className='ms-1'>English</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
            </div>
          </li>

          <li>
            <div>
              <a href="https://cursos.iwsservices.com/">
                {/* <PersonIcon className="iconPersonDesktop"></PersonIcon> */}
                {/* <SchoolIcon className="iconPersonDesktop"></SchoolIcon> */}
                <FontAwesomeIcon className="iconPersonDesktop" icon={faUserGraduate} />
                {/* <LocalLibraryIcon className="iconPersonDesktop"></LocalLibraryIcon> */}
              </a>
            </div>
          </li>
      <Outlet />
    </Fragment>
  )
}
