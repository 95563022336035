import React from 'react'
import { Carousel as CarouselReact } from 'react-responsive-carousel';

const CarouselOurTeam = ({body}) => {

    const carousel = body.map((imagenes, index)=>
    imagenes.value === 1 ?
    <div key={index} className="d-block d-sm-flex col-12 pb-2">
      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-center justify-content-sm-end relative-US-Child3-1 margin-left-15">
        <img src={imagenes.src1} alt={imagenes.alt} height={imagenes.height} width={imagenes.width} className={imagenes.classimg1} />
      </div>
      <div className={imagenes.classFather}>
        <img src={imagenes.src2} alt={imagenes.alt} height={imagenes.height} width={imagenes.width} className={imagenes.classimg2} />
        <div className={imagenes.class2} style={{ width: imagenes.width }}>
          <div className="text-slick-carrousel text-unnamed p-2">
            {imagenes.text}
          </div>
        </div>
      </div>
    </div> 
    :
    <div key={index} className="d-block d-sm-flex col-12 pb-2">
      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-center justify-content-sm-end margin-left-15">
        <div className={imagenes.classFather}>
        <img src={imagenes.src2} alt={imagenes.alt} height={imagenes.height} width={imagenes.width} className={imagenes.classimg2} />
        <div className={imagenes.class2} style={{ width: imagenes.width }}>
          <div className="text-slick-carrousel-2 text-unnamed p-2">
            {imagenes.text}
          </div>
        </div>
      </div>
      </div>
      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 m-0 p-0 d-flex justify-content-center justify-content-sm-end relative-US-Child3-2">
        <img src={imagenes.src1} alt={imagenes.alt} height={imagenes.height} width={imagenes.width} className={imagenes.classimg1} />
      </div>
    </div> 
    );
    return (
    <div>
        <CarouselReact className='carousel-mb-container' interval={5000} showThumbs={false} showArrows={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
            
               <div className='row'>
                    <div className='d-flex justify-content-center'>
                        <div className=''>
                            {carousel[0]}
                            {carousel[1]}
                        </div>

                        <div className=''>
                            {carousel[2]}
                            {carousel[3]}
                        </div>
                    </div>
                
               </div>

               <div className='row'>
                    <div className='d-flex justify-content-center'>
                        <div className=''>
                            {carousel[4]}
                            {carousel[5]}
                        </div>

                        <div className=''>
                            {carousel[6]}
                            {carousel[7]}
                        </div>
                    </div>
                
               </div>
            
        </CarouselReact>
    </div>
  )
}


export default CarouselOurTeam