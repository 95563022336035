import React, { useEffect, useState, useContext } from 'react';
import ChatBot from "react-simple-chatbot";
import Axios from "axios";
import { ThemeProvider } from "styled-components";
import img_bot from "./Image/AvatarChatbot.png";
import img_user from "./Image/AvatarUser.svg";
import img_header from "./Image/Header.png";
import BotTakename from "./APIChat/BotTakename";
import SaveUser from "./APIChat/SaveUser";
import HubChat from "./HubChat";
import "./css/Chatbot.css"
import { ContextDataChatIWS } from "../../index"

const theme = {
    background: '#f5f8fb',
    headerBgColor: '#003061',
    headerHeight: '100px',
    headerFontColor: '#fff',
    headerFontSize: '20px',
    botBubbleColor: '#003061',
    botFontColor: '#fff',
    userBubbleColor: '#0e70d5',
    avatarStyle: 'none',
    userBubble: {


        masxWidth: "67%",

    }
    ,
    userFontColor: '#fff',

}

function Chatbot() {

    const { ObjChat, UpdateObjHub } = useContext(ContextDataChatIWS);
    const [chatConversation, setchatConversation] = useState([]);
    const [loading, setLoading] = useState(true);
    //const [countries, setcountries] = useState([]);
    //const [services, setservices] = useState([]);
    const [booluser, setbooluser] = useState(ObjChat.IsConectedResponsable);

    Axios.defaults.headers.post['Api_Key'] = `J7rTm2LpH9qA6wZ8xN3cE1bD4`;
    Axios.defaults.headers.common['Api_Key'] = 'J7rTm2LpH9qA6wZ8xN3cE1bD4';




    //Conexion a H
    useEffect(async () => {

        async function fetchWelcome() {
            async function Botcountry() {

                const BotCountry = `${ObjChat.api}/api/Services/BotCountries?`;
                let resp;
                await Axios
                    .get(`${BotCountry}`)
                    .then((res) => {
                        UpdateObjHub("paises", res.data.data.data)
                        //console.log(res.data.data.data)
                        resp = res.data.data.data
                        //setcountries(res.data.data.data)
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });

                return resp
            }
            async function BotServices() {
                //////saveServices
                let resp;
                const BotServices = `${ObjChat.api}/api/Services/BotServices?`;
                await Axios
                    .get(`${BotServices}`)
                    .then((res) => {
                        UpdateObjHub("services", res.data.data.data)
                        //console.log(res.data.data.data)
                        resp = res.data.data.data
                        //setservices(res.data.data.data)
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });

                return resp
            }
            async function HorarioLaboral() {
                const currentDate = new Date();
                let resp;
                if (currentDate.getHours() >= 8 && currentDate.getHours() < 19) {
                    resp = true;
                }
                else {
                    resp = false;
                }
                UpdateObjHub("HorarioLaboral", resp)
                return resp;
            }
            async function Conversationstart() {
                return ObjChat.ConversacionIniciada;
            }
            async function conversationbot(_CurrentHour, countries, services) {
                //console.log("CurrenHour que recibe la funcion", _CurrentHour)
                try {
                    const { data } = await Axios.get(
                        `${ObjChat.api}/api/Services/BotWelcome?`,
                    ).finally(() => {
                    })
                    let Obj = {
                        id: '1',
                        message: () => {
                            const fecha = new Date();
                            const hora = fecha.getHours();
                            if (hora >= 0 && hora < 12) {
                                return data.data[0].pregunta1;
                            }
                            if (hora >= 12 && hora < 18) {
                                return data.data[1].pregunta1;
                            }
                            if (hora >= 18 && hora < 24) {
                                return data.data[2].pregunta1;
                            }
                        },
                        trigger: "2",
                    }
                    let Obj2 = {
                        id: '2',
                        message: "¿Cúal es tu nombre?", message: () => {
                            if (_CurrentHour) {
                                return '¿Cual es tu nombre?';
                            } else {
                                return "Hola! Lamento informarte que nuestro horario de servicio es de 8 a 7, por lo que en este momento no es posible que un asesor se contacte contigo, que tengas una linda noche!!";
                            }
                        },
                        trigger: () => {
                            if (_CurrentHour) {
                                return 'names';
                            } else {
                                return 'end2';
                            }
                        },
                    }

                    let Obj3 = {
                        id: "names",
                        user: true,
                        validator: (value) => {
                            const pattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+$/;
                            if (/^[a-zA-ZáéíóúÁÉÍÓÚ\s]*$/.test(value)) {
                                return true;
                            }
                            else {
                                return 'Ingresa un nombre valido';
                            }
                        },
                        trigger: "q-email",
                    }
                    let Obj4 = {
                        id: "q-email",
                        component: <BotTakename />,
                        asMessage: true,
                        trigger: "email",
                    }
                    let Obj5 = {
                        id: "email",
                        user: true,
                        validator: (value) => {

                            if (/^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(value)) {
                                UpdateObjHub("emailUser", value)
                                return true;
                            }
                            else {
                                return 'Ingresa un correo valido';
                            }
                        },

                        trigger: 'phone',

                    }
                    let Obj5_phone = {
                        id: "phone",
                        message: "¿Cúal es tu número de celular?",
                        trigger: 'phoneinput',

                    }
                    let Obj5_phoneinput = {
                        id: "phoneinput",
                        user: true,
                        validator: (value) => {

                            if (/[\(]?[\+]?(\d{2}|\d{3})[\)]?[\s]?((\d{6}|\d{8})|(\d{3}[\*\.\-\s]){3}|(\d{2}[\*\.\-\s]){4}|(\d{4}[\*\.\-\s]){2})|\d{8}|\d{10}|\d{12}/i.test(value)) {
                                UpdateObjHub("phoneUser", value)
                                return true;
                            }
                            else {
                                return 'Ingresa un celular valido';
                            }
                        },
                        user: true,
                        trigger: '7',

                    }
                    let Obj6 = {
                        id: "7",
                        message: "¿De que pais te conectas?",
                        trigger: "pais",
                    }
                    let Obj7 = {
                        id: "pais",
                        //options: ObjChat.paises,
                        options: countries,
                    }
                    let Obj8 = {
                        id: "8",
                        message: "¿Cómo te puedo ayudar?",
                        trigger: "services",
                    }
                    let Obj9 = {
                        id: "services",
                        //options: ObjChat.services,
                        options: services,
                    }
                    let Obj10 = {
                        id: "end",
                        component: <SaveUser setbooluser={setbooluser} />,
                        asMessage: true,
                        end: true,
                    }
                    let Obj11 = {
                        id: "end2",
                        message: 'Adios!',
                        end: true,
                    }

                    let ArrayObj = []

                    if (_CurrentHour) {

                        ArrayObj.push(Obj)
                        ArrayObj.push(Obj2)
                        ArrayObj.push(Obj3)
                        ArrayObj.push(Obj4)
                        ArrayObj.push(Obj5)
                        ArrayObj.push(Obj5_phone)
                        ArrayObj.push(Obj5_phoneinput)
                        ArrayObj.push(Obj6)
                        ArrayObj.push(Obj7)
                        ArrayObj.push(Obj8)
                        ArrayObj.push(Obj9)
                        ArrayObj.push(Obj10)
                        ArrayObj.push(Obj11)
                        setchatConversation(ArrayObj)
                        UpdateObjHub("Convesation", ArrayObj)
                    } else {
                        ArrayObj.push(Obj2)
                        setchatConversation(ArrayObj)
                        UpdateObjHub("Convesation", ArrayObj)
                    }



                }

                catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            }


            //console.log("5590")
            //console.log("Primero obtenemos paises")
            let countries = await Botcountry();
            //console.log(countries)
            //console.log("Luego obtenemos servicios")
            let services = await BotServices();
            //console.log(services)


            //console.log("¿Dentro de Horario laboral?")
            const _CurrentHour = await HorarioLaboral();
            //console.log(_CurrentHour)
            if (_CurrentHour) {
                //console.log("¿Ya se tiene una conversacion inicial?")
                const _Conversationstart = await Conversationstart();
                //console.log(_Conversationstart)
                if (!_Conversationstart) {
                    //console.log("Mostramos conversacion")
                    await conversationbot(_CurrentHour, countries, services);
                }
                else {
                    //console.log("Ya tiene conversacion iniciada")
                }
            }
            else {
                await conversationbot(_CurrentHour);
                //console.log("Fuera de zona de horario laboral")
            }
        }

        fetchWelcome();

    }, []);

    return (

        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <ThemeProvider theme={theme}>
                    {
                        booluser ?
                            <HubChat />
                            :
                            <ChatBot

                                headerTitle={
                                    <img className="encabezado" src={img_header} />
                                }
                                botAvatar={img_bot}
                                userAvatar={img_user}
                                steps={chatConversation}
                            />


                    }

                </ThemeProvider>
            )}
        </div>
    );

}

export default Chatbot;