import React from 'react'
import { Form } from './Form'
import {useTranslation} from "react-i18next";

const width1 = {
  width: "80%"
}

export const ButtonForm = (props) => {
  const [t, i18n] = useTranslation('common');
  return (
    <div className='row p-0 m-0 justify-content-center justify-content-md-end col-11 col-sm-11 col-md-10 pt-3'>
      <div className='col-8 col-sm-8 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex justify-content-center'>
        <button type="button" className="btn mb-4 btn-primary btn-solutions" data-bs-toggle="modal" data-bs-target="#form">
          {t('AWS.button')}
        </button>
        <div className="modal fade" id="form" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered justify-content-center">
            <div className="modal-content" style={width1}>
              <div className="modal-body">
                <div className="row m-0 p-0 pb-2 justify-content-end">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <Form campos={props.inputs} text="Enviar" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
