import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect'
import { useTranslation } from 'react-i18next';
import { Carousel as CarouselReact } from 'react-responsive-carousel'

const MissionVision = props => {
    const [t, i18n] = useTranslation('common');

    const [readmore, setReadmore] = useState(true);
    const [readmoreClass, setReadmoreClass] = useState('card-vision');
    const [readmoreClassMobile, setReadmoreClassMobile] = useState('card-vision-mb');
    const [readmoreText, setReadmoreText] = useState(t('aboutUs.vermas'));
    const handleReadMore = () => {

        
        if (readmore) {
            setReadmoreClass('');
            setReadmoreClassMobile('');
            setReadmoreText(t('aboutUs.vermenos'));
        } else {
            setReadmoreClass('card-vision');
            setReadmoreClassMobile('card-vision-mb');
            setReadmoreText(t('aboutUs.vermas'));
        }
        setReadmore(!readmore);
    }
    return (
        <>
            <BrowserView className='bg-white'>
                <div className='bg-gray pb-4 continaer-mision-valores-rr container-snake-left-rr_4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col'>
                                <Card className='card-cloud  card-chicle card-readmore'>
                                    <div className='card card-cloud'>
                                        <Card.Body>
                                            <Card.Title className='text-center'>
                                                <h2 className='title-a-section-home our-alianza"'>{t('aboutUs.mision')}</h2></Card.Title>
                                            <Card.Text className='p-compromisos'>

                                                {t('aboutUs.mision-text')}

                                            </Card.Text>
                                        </Card.Body>
                                    </div>
                                </Card>
                            </div>
                            <div className='col'>
                                <Card className={`card-cloud card-chicle ${readmoreClass}`}>
                                    <div className={`card card-cloud`}>
                                        <Card.Body>
                                            <Card.Title className='text-center'>   <h2 className='title-a-section-home our-alianza"'>{t('aboutUs.vision')}</h2>
                                            </Card.Title>
                                            <Card.Text className='p-compromisos'>

                                                {t('aboutUs.vision-text')}

                                            </Card.Text>
                                        </Card.Body>
                                    </div>
                                    <button className='btn-readmore' onClick={handleReadMore}>{readmoreText}</button>
                                </Card>

                            </div>
                        </div>
                    </div>
                </div>
            </BrowserView>

            <MobileView className='bg-white'>
                <div className='bg-gray pb-4 container-snake-left-rr_4'>
                    <div className='container'>
                        <CarouselReact className='' interval={7000} showStatus={false} showThumbs={false} showArrows={false} autoPlay={false} infiniteLoop={true}>
                            <div>
                                <Card className='card-cloud  card-chicle card-readmore-mb'>
                                    <div className='card card-cloud'>
                                        <Card.Body>
                                            <Card.Title className='text-center'> {t('aboutUs.mision')}</Card.Title>
                                            <Card.Text className='font-poppins-regular'>
                                                {t('aboutUs.mision-text')}
                                            </Card.Text>
                                        </Card.Body>
                                    </div>
                                </Card>
                            </div>

                            <div>
                                <Card className={`card-cloud  card-chicle ${readmoreClassMobile}`}>
                                    <div className={`card card-cloud`}>
                                        <Card.Body>
                                            <Card.Title className='text-center'>  {t('aboutUs.vision')}</Card.Title>
                                            <Card.Text className='font-poppins-regular'>
                                                {t('aboutUs.vision-text')}
                                            </Card.Text>

                                        </Card.Body>
                                    </div>
                                    <button className='btn-readmore' onClick={handleReadMore}>{readmoreText}</button>
                                </Card>
                            </div>
                        </CarouselReact>
                    </div>
                </div>
            </MobileView>
        </>
    )
}

export default MissionVision