import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import { BrowserView, MobileView } from 'react-device-detect';
import Accordion from 'react-bootstrap/Accordion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'

export function SlickCarrusel(props) {

  const [t, i18n] = useTranslation('common');
  const settings = {
    dots: true,
    infinite: true,
    // centerMode: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };
  const Images = props.img.map((imagenes, index) =>

    <div key={index} className="style-section-b col-2">
      <Card className="card-section-b">

        <Card.Body>
          <Card.Text>
            <Image className="image-brands" src={imagenes.img} />
          </Card.Text>
        </Card.Body>
      </Card>

    </div>
  );
  const Images2 = props.imga2.map((imagenes, index) =>

    <div key={index} className="style-section-b col-2">
      <Card className="card-section-b">
        <Card.Body>
          <Card.Text>
            <Image className="image-brands" src={imagenes.img} />
          </Card.Text>
        </Card.Body>
      </Card>

    </div>
  );
  const ImagesMobile = props.img.map((imagenes, index) =>

    <div key={index} className="style-section-b col-6">
      <Card className="card-section-b">
        <Card.Body>
          <Card.Text>
            <Image className="image-brands-mobile" src={imagenes.img} />
          </Card.Text>
        </Card.Body>
      </Card>

    </div>
  );

  const Images2Mobile = props.imga2.map((imagenes, index) =>

    <div key={index} className="style-section-b col-6">
      <Card className="card-section-b">
        <Card.Body>
          <Card.Text>
            <Image className="image-brands" src={imagenes.img} />
          </Card.Text>
        </Card.Body>
      </Card>

    </div>
  );
  return (
    <div>

      <BrowserView>
        <div className="text-center bg-gray">
          <div className="bg-white container-snake-left-rr_">
            <div className="d-flex flex-column justify-content-center align-items-center pt-4">
              <div className="father-our-alianza m-0 align-items-center">
                <h3 className="d-flex flex-row m-0 align-items-center p-line justify-content-between our-alinza title-a-section-home our-alianza">
                  {t('home.marcas')}
                </h3>
              </div>
              <p className="span-our-alianza span-info-Aliado">{t('home.comercializamos')}</p>
            </div>
            <div className="row">
              {Images}
            </div>
            <Accordion className="mb-4">
              <Accordion.Item eventKey="0" data-bs-toggle="collapse" aria-expanded="false">
                <Accordion.Header><FontAwesomeIcon className="icon-plus" icon={faCirclePlus} /></Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    {Images2}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

          </div>
        </div>
      </BrowserView>


      <MobileView>
        <div className="text-center bg-gray">
          <div className="bg-white">
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <div className="father-our-alianza m-0 align-items-center pt-6">
                <h2 className="d-flex flex-row m-0 align-items-center p-line justify-content-between our-alinza title-a-section-home our-alianza title-home-mb our-alianza">
                  {t('home.marcas')}
                </h2>
                <p className="span-our-alianza span-info-Aliado">{t('home.comercializamos')}</p>
              </div>
            </div>
            <div className="row">
              {ImagesMobile}
            </div>
            <Accordion className="mb-4">
              <Accordion.Item eventKey="0">
                <Accordion.Header><FontAwesomeIcon className="icon-plus" icon={faCirclePlus} /></Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    {Images2Mobile}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </MobileView>
    </div>
  )
}

