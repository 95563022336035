import React, { Fragment, useEffect } from 'react'
import { Presentation } from './Components/Presentation'
import { ProblemsContainer } from './Components/Industry/ProblemsContainer'
import { ProposalsContainer } from './Components/Industry/ProposalsContainer'
import { ProposalsContainerHorizontal } from './Components/Industry/ProposalsContainerHorizontal'
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from 'react-device-detect'

const education = 'https://cdn.iwsservices.com/Industrias/Educacion/Educacion.png'
const education_mov = 'https://cdn.iwsservices.com/Industrias/Educacion/Educación_mobile.png'
const headerImg = 'https://cdn.iwsservices.com/Industrias/Educacion/header_image.png'
const firstCard = 'https://cdn.iwsservices.com/Industrias/Educacion/cards/1.1.png'
const secondCard = 'https://cdn.iwsservices.com/Industrias/Educacion/cards/2.1.png'
const thirdCard = 'https://cdn.iwsservices.com/Industrias/Educacion/cards/3.1.png'
const fourhCard = 'https://cdn.iwsservices.com/Industrias/Educacion/cards/4.1.png'
const proposal1 = 'https://cdn.iwsservices.com/Industrias/Educacion/proposals/Grupo 1.png'
const proposal2 = 'https://cdn.iwsservices.com/Industrias/Educacion/proposals/Grupo 2.png'
const proposal3 = 'https://cdn.iwsservices.com/Industrias/Educacion/proposals/Grupo 3.png'
const proposal4 = 'https://cdn.iwsservices.com/Industrias/Educacion/proposals/Grupo 4.png'
const proposal1Pt = 'https://cdn.iwsservices.com/Industrias/Educacion/proposals/Grupo 1 portugues.png'
const proposal2Pt = 'https://cdn.iwsservices.com/Industrias/Educacion/proposals/Grupo 2 portugues.png'
const proposal3Pt = 'https://cdn.iwsservices.com/Industrias/Educacion/proposals/Grupo 3 portugues.png'
const proposal4Pt = 'https://cdn.iwsservices.com/Industrias/Educacion/proposals/Grupo 4 portugues.png'
const proposal1Hori = 'https://cdn.iwsservices.com/Industrias/Educacion/proposals/Grupo 1 hori.png'
const proposal2Hori = 'https://cdn.iwsservices.com/Industrias/Educacion/proposals/Grupo 2 hori.png'
const proposal3Hori = 'https://cdn.iwsservices.com/Industrias/Educacion/proposals/Grupo 3 hori.png'
const proposal4Hori = 'https://cdn.iwsservices.com/Industrias/Educacion/proposals/Grupo 4 hori.png'
//inglés
const educationEN = 'https://cdn.iwsservices.com/Industrias/Educacion/ingles/Education.png';
//portugués
const educationPT = 'https://cdn.iwsservices.com/Industrias/Educacion/portugues/Education.png';
//inglés
const educationENmov = 'https://cdn.iwsservices.com/Industrias/Educacion/ingles/Education_mov.png';
//portugués
const educationPTmov = 'https://cdn.iwsservices.com/Industrias/Educacion/portugues/Education_mov.png';

export function Education() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const domainName = window.location.hostname;
  const [t, i18n] = useTranslation("common");
  const proposalsImages = [proposal1, proposal2, proposal3, proposal4];
  const proposalsImagesPt = [proposal1Pt, proposal2Pt, proposal3Pt, proposal4Pt];
  const proposalsImagesHorizontal = [proposal1Hori, proposal2Hori, proposal3Hori, proposal4Hori];
  const cardData = [
    {
      img: firstCard,
      title: "1. Pocas oportunidades",
      body: "Los recursos son desviados debido a la poca productividad y procesos ineficientes, impactando en el número de oportunidades que podemos ofrecer a los jóvenes."
    },
    {
      img: secondCard,
      title: "2. Falta de innovación",
      body: "Al tener diversas áreas con deficiencias, es imposible pensar en innovar para mejorar la manera de enseñar y revolucionar la educación."
    },
    {
      img: thirdCard,
      title: "3. Falta de automatización",
      body: "Los sistemas de administración de alumnos, biblioteca, alta de cursos y calificaciones, son complejos y tardados ya que no cuentan con procesos automatizados."
    },
    {
      img: fourhCard,
      title: "4. Aprendizaje atrasado",
      body: "La falta de herramientas, atraso tecnológico y la falta de innovación no suman al aprendizaje. Los alumnos no están siendo preparados para los desafíos del futuro."
    },
  ]
  const cardDataPt = [
    {
      img: firstCard,
      title: "1. Poucas oportunidades",
      body: "Os recursos são desviados devido à baixa produtividade e processos ineficientes, impactando no número de oportunidades que podemos oferecer aos jovens."
    },
    {
      img: secondCard,
      title: "2. Falta de inovação",
      body: "Tendo diversas áreas com deficiências, é impossível pensar em inovar para melhorar a forma de ensinar e revolucionar a educação."
    },
    {
      img: thirdCard,
      title: "3. Falta de automação",
      body: "Os sistemas de administração de alunos, biblioteca, cadastro de cursos e notas são complexos e demorados, pois não possuem processos automatizados."
    },
    {
      img: fourhCard,
      title: "4. Aprendizagem atrasada",
      body: "A falta de ferramentas de aprendizagem, o atraso tecnológico e a falta de inovação não contribuem para a aprendizagem. Os alunos não estão sendo preparados para os desafios do futuro."
    },
  ]

  let imgBanner = education;
  if (domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca')) {
    imgBanner = educationEN;
  } else if (domainName.includes('iwsservices.com.br')) {
    imgBanner = educationPT;
  }

  let imgBannerMov = education_mov;
  if (domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca')) {
    imgBannerMov = educationENmov;
  } else if (domainName.includes('iwsservices.com.br')) {
    imgBannerMov = educationPTmov;
  }

  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresRecursosFather" className="recursosFather">
          <Presentation
            image={imgBanner}
            image2={imgBannerMov}
            text={t("education.title")}
          />
          <div className="pleca-2"></div>
        </div>

        <div id="RecursosChild1" className="pb-0 pt-5 control-header-industry">
          <BrowserView>
            <div className='box-problems-container'>
              <ProblemsContainer widthImg={'w-75'} imagebotom={headerImg} cardsData={domainName.includes('iwsservices.com.br') ? cardDataPt : cardData} />

            </div>
          </BrowserView>

          <MobileView>
            <div className='box-problems-container-mb top-education-mb'>
              <ProblemsContainer widthImg={'w-75'} imagebotom={headerImg} cardsData={domainName.includes('iwsservices.com.br') ? cardDataPt : cardData} />

            </div>
          </MobileView>
        </div>

        <div className='row justify-content-center'>
          <div className='col-sm-12 col-12 col-md-12 col-lg-8 col-xl-9 col-xxl-8 text-center'>
            <span className={domainName.includes('iwsservices.com.br') ? "d-none" : "s-Solu-child1 vision-title-education"}>VISIÓN</span>
            <span className={domainName.includes('iwsservices.com.br') ? "s-Solu-child1 vision-title-education" : "d-none"}>VISÃO</span>
            <p className={domainName.includes('iwsservices.com.br') ? "d-none" : "align-items-center justify-content-center"}>Dele el poder a cada maestro, estudiante, investigador, administrador y padre de familia para lograr más. <strong>Plataformas inteligentes para empoderar a los estudiantes y a la institución.</strong></p>
            <p className={domainName.includes('iwsservices.com.br') ? "align-items-center justify-content-center" : "d-none"}>Capacite cada professor, aluno, pesquisador, administrador e pai para alcançar mais.  <strong>Plataformas inteligentes para capacitar os alunos e a instituição.</strong></p>
          </div>
        </div>
        <div className='row justify-content-center mt-5 ProposalsContainer'>
          <div className='col-10 mt-5'>
            <ProposalsContainer images={domainName.includes('iwsservices.com.br') ? proposalsImagesPt : proposalsImages} classnameindustry="span-our-alianza vision-title-education" />
          </div>
        </div>

        <div className='row justify-content-center mt-5 ProposalsContainerHorizontal'>
          <div className='col-10 mt-5'>
            <ProposalsContainerHorizontal images={domainName.includes('iwsservices.com.br') ? proposalsImagesHorizontal : proposalsImagesHorizontal} classnameindustry="span-our-alianza vision-title-education" />
          </div>
        </div>


      </Fragment>
      <ToggleComp />
      <Footer />
    </div>
  )
}