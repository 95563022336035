import React, { useContext, useState} from "react";

import whats_img from '../Image/WhatsApp.png';

export const WhatsApp = ({linkWA})=> {

    
    return ( 
        <>
            <a href={linkWA}>
                <img src={whats_img} />                
            </a>
        </>

     );
}
