import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  countries: [],
  services: [],
  UserConversation: [],
  UserSelected:[],
  activateConversation: false,
  savenameuser:'',
  alertmobile: true,
  OneService: "",
  UserSelectEmail: "",
  UserServiceGroup: [],
  HistoryNoread: [],
  totalNoread: 0,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action) => {
      const { email } = action.payload;
      state.email = email;
    },
    addUserConversation(state,action){
      const conversationhistory = action.payload
      state.UserConversation = conversationhistory
    },
    addUSerSelected(state,action){
      const selected = action.payload
      state.UserSelected = selected
    },
    ActivateConversation(state,action){
      const selected = action.payload
      state.activateConversation = selected
    },
    SaveNameUser(state,action){
      const selected = action.payload
      state.savenameuser = selected
    },
    changeEmail: (state, action) => {
      state.email = action.payload;
    },
    addServices: (state, action) => {
      const services = action.payload;
      state.services = services;
    },
    Info: (state,action) =>{
      const alert = action.payload;
      state.alertmobile = alert;
    },
    UserService: (state,action) =>{
      const service = action.payload;
      state.OneService = service;
    },
    UserSelectedE: (state,action) =>{
      const _emailSelected = action.payload;
      state.UserSelectEmail = _emailSelected;
    },
    UserServices: (state,action) =>{
      const users = action.payload;
      state.UserServiceGroup = users;
    },
    HistoryRead: (state,action) =>{
      const listaUser = action.payload;
      state.HistoryNoread = listaUser;
    },
    UserNoRead: (state,action) =>{
      const noread = action.payload;
      state.totalNoread = noread;
    }
  },
});

export const { addUser,
   changeEmail, 
   addServices, 
   addUserConversation,
   addUSerSelected,
   ActivateConversation,
   SaveNameUser,
   Info,
   UserService,
   UserSelectedE,
   UserServices,
   HistoryRead,
   UserNoRead,
} = userSlice.actions;

export default userSlice.reducer;
 