import React, { Fragment, useEffect } from 'react'
import { Presentation } from './Components/Presentation'
import { ProblemsContainer } from './Components/Industry/ProblemsContainer'
import { ProposalsContainer } from './Components/Industry/ProposalsContainer'
import { ProposalsContainerHorizontal } from './Components/Industry/ProposalsContainerHorizontal'
import { Header } from './Header';
import { Footer } from "./Components/Footer";
import { ToggleComp } from "./Components/ToggleComp";
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from 'react-device-detect'

const transport = 'https://cdn.iwsservices.com/Industrias/Transporte/Transporte.png'
const transport_mov = 'https://cdn.iwsservices.com/Industrias/Transporte/Transporte_Mobile.png'
const headerImg = 'https://cdn.iwsservices.com/Industrias/Transporte/header_image.png'
const firstCard = 'https://cdn.iwsservices.com/Industrias/Transporte/cards/1.5.png'
const secondCard = 'https://cdn.iwsservices.com/Industrias/Transporte/cards/2.5.png'
const thirdCard = 'https://cdn.iwsservices.com/Industrias/Transporte/cards/3.5.png'
const fourhCard = 'https://cdn.iwsservices.com/Industrias/Transporte/cards/4.5.png'
const proposal1 = 'https://cdn.iwsservices.com/Industrias/Transporte/proposals/Grupo 1.png'
const proposal2 = 'https://cdn.iwsservices.com/Industrias/Transporte/proposals/Grupo 2.png'
const proposal3 = 'https://cdn.iwsservices.com/Industrias/Transporte/proposals/Grupo 3.png'
const proposal4 = 'https://cdn.iwsservices.com/Industrias/Transporte/proposals/Grupo 4.png'
const proposal1Pt = 'https://cdn.iwsservices.com/Industrias/Transporte/proposals/Grupo 1 portugues.png'
const proposal2Pt = 'https://cdn.iwsservices.com/Industrias/Transporte/proposals/Grupo 2 portugues.png'
const proposal3Pt = 'https://cdn.iwsservices.com/Industrias/Transporte/proposals/Grupo 3 portugues.png'
const proposal4Pt = 'https://cdn.iwsservices.com/Industrias/Transporte/proposals/Grupo 4 portugues.png'
const proposal1Hori = 'https://cdn.iwsservices.com/Industrias/Transporte/proposals/Grupo 1 hori.png'
const proposal2Hori = 'https://cdn.iwsservices.com/Industrias/Transporte/proposals/Grupo 2 hori.png'
const proposal3Hori = 'https://cdn.iwsservices.com/Industrias/Transporte/proposals/Grupo 3 hori.png'
const proposal4Hori = 'https://cdn.iwsservices.com/Industrias/Transporte/proposals/Grupo 4 hori.png'


//inglés
const transportEN = 'https://cdn.iwsservices.com/Industrias/Educacion/ingles/Transport.png';
//portugués
const transportPT = 'https://cdn.iwsservices.com/Industrias/Educacion/portugues/Transport.png';
//inglés
const transportENMov = 'https://cdn.iwsservices.com/Industrias/Educacion/ingles/Transport_mov.png';
//portugués
const transportPTMov = 'https://cdn.iwsservices.com/Industrias/Educacion/portugues/Transport_mov.png';

export function Transport() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    return () => {

    }
  }, []);
  const domainName = window.location.hostname;
  const [t, i18n] = useTranslation("common");
  const proposalsImages = [proposal1, proposal2, proposal3, proposal4];
  const proposalsImagesPt = [proposal1Pt, proposal2Pt, proposal3Pt, proposal4Pt];
  const proposalsImagesHorizontal = [proposal1Hori, proposal2Hori, proposal3Hori, proposal4Hori];
  const cardData = [
    {
      img: firstCard,
      title: "1. Demoras",
      body: "Clientes insatisfechos debido a las demoras de sus vuelos que los hacen perder conexiones y tiempo valioso."
    },
    {
      img: secondCard,
      title: "2. Mantenimiento y reparación",
      body: "El mantenimiento imprevisto ocasiona demoras que impactan el desempeño de la aerolínea."
    },
    {
      img: thirdCard,
      title: "3. Perdida de equipaje",
      body: "La complicada logística de los aeropuertos propicia la pérdida de equipaje y la consiguiente inconformidad de los pasajeros."
    },
    {
      img: fourhCard,
      title: "4. Ineficiencia",
      body: "Se desperdicia tiempo en procedimientos manuales y llenos de errores ocasionados por operadores."
    },
  ]

  const cardDataPt = [
    {
      img: firstCard,
      title: "1. Atrasos",
      body: "Clientes insatisfeitos devido a atrasos nos voos que os fazem perder conexões e tempo valioso."
    },
    {
      img: secondCard,
      title: "2. Manutenção e reparo",
      body: "A manutenção não planejada causa atrasos que afetam o desempenho da companhia aérea."
    },
    {
      img: thirdCard,
      title: "3. Bagagem extraviada",
      body: "A complicada logística dos aeroportos favorece o extravio de bagagens e a consequente insatisfação dos passageiros."
    },
    {
      img: fourhCard,
      title: "4. Ineficiência",
      body: "Perde-se tempo em procedimentos manuais e cheio de erros causados pelos operadores."
    },
  ]
  let imgBanner = transport;
  if (domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca')) {
    imgBanner = transportEN;
  } else if (domainName.includes('iwsservices.com.br')) {
    imgBanner = transportPT;
  }

  let imgBannerMov = transport_mov;
  if (domainName.includes('iwsservices.us') || domainName.includes('iwsservices.ca')) {
    imgBannerMov = transportENMov;
  } else if (domainName.includes('iwsservices.com.br')) {
    imgBannerMov = transportPTMov;
  }

  return (
    <div>
      <Header />
      <Fragment>
        <div id="PresRecursosFather" className="recursosFather">
          <Presentation
            image={imgBanner}
            image2={imgBannerMov}
            text={t("transport.title")}
          />
          <div className="pleca-2"></div>
        </div>
        <div id="RecursosChild1" className="pb-0 control-header-industry pt-5">

          <BrowserView>
            <div className='box-problems-container'>
              <ProblemsContainer widthImg={'w-70'} imagebotom={headerImg} cardsData={domainName.includes('iwsservices.com.br') ? cardDataPt : cardData} />

            </div>
          </BrowserView>

          <MobileView>
            <div className='box-problems-container-mb top-transport-mb'>
              <ProblemsContainer widthImg={'w-70'} imagebotom={headerImg} cardsData={domainName.includes('iwsservices.com.br') ? cardDataPt : cardData} />

            </div>

          </MobileView>
        </div>
        <div className='row justify-content-center'>
          <div className='col-sm-12 col-12 col-md-12 col-lg-8 col-xl-9 col-xxl-8 text-center'>
            <span className={domainName.includes('iwsservices.com.br') ? "d-none" : "span-our-alianza color-Transporte"}>VISIÓN</span>
            <span className={domainName.includes('iwsservices.com.br') ? "span-our-alianza color-Transporte" : "d-none"}>VISÃO</span>
            <p className={domainName.includes('iwsservices.com.br') ? "d-none" : "align-items-center justify-content-center"}>Experiencia del pasajero, productividad y colaboración empresarial, <strong>analítica avanzada e I.A., comercio conversacional,</strong> ioT y big data, realidad virtual y blockchain.</p>
            <p className={domainName.includes('iwsservices.com.br') ? "align-items-center justify-content-center" : "d-none"}>Experiência do passageiro, produtividade e colaboração nos negócios, <strong>análises avançadas e IA, comércio conversacional, </strong>IoT e big data, realidade virtual e blockchain.</p>
          </div>
        </div>
        <div className='row justify-content-center mt-5 ProposalsContainer'>
          <div className='col-10 mt-5'>
            <ProposalsContainer images={domainName.includes('iwsservices.com.br') ? proposalsImagesPt : proposalsImages} classnameindustry="span-our-alianza color-Transporte" />
          </div>
        </div>

        <div className='row justify-content-center mt-5 ProposalsContainerHorizontal'>
          <div className='col-10 mt-5'>
            <ProposalsContainerHorizontal images={domainName.includes('iwsservices.com.br') ? proposalsImagesHorizontal : proposalsImagesHorizontal} classnameindustry="span-our-alianza color-Transporte" />
          </div>
        </div>

      </Fragment>

      <ToggleComp />
      <Footer />
    </div>
  )
}