import React, { useContext, useRef } from "react";
import img_avatar from "./Image/AvatarChatbot.png";
import img_user from "./Image/AvatarUser.svg";
import icon_enviar from "./Image/icon-enviar.png";
import img_header from "./Image/Header.png";
import { useEffect } from "react";
import * as signalR from '@microsoft/signalr'
import { useState } from "react";
import { ContextDataChatIWS } from "../../index"
import axios from "axios";
import "./css/Chatbot.css"
import { WhatsApp } from "./APIChat/WhatsApp";


function HubChat() {

    const { ObjChat, UpdateObjHub } = useContext(ContextDataChatIWS);
    const chatContainerRef = useRef(null);
    const [inputValue, setinputValue] = useState('');
    const [correoresponsable, setcorreoresponsable] = useState('');
    const [conexionidcliente, setconexionidcliente] = useState('');
    const [nombreresponsable, setnombreresponsable] = useState('');
    const [EmailsResponsables, setEmailsResponsables] = useState([]);
    const [conexionidresponsable, setconexionidresponsable] = useState('');
    const [arrayChat, setarrayChat] = useState([]);
    const [boolpadlock, setboolpadlock] = useState(false);
    const [tiempo, setTiempo] = useState(0);
    const [corriendo, setCorriendo] = useState(false);


    async function FechaActual() {

        // Obtener la fecha y hora actual
        const fechaHoraActual = new Date();

        // Obtener las partes individuales de la fecha y hora
        const dia = String(fechaHoraActual.getDate()).padStart(2, '0');
        const mes = String(fechaHoraActual.getMonth() + 1).padStart(2, '0');
        const anio = fechaHoraActual.getFullYear();
        const horas = String(fechaHoraActual.getHours() % 12).padStart(2, '0'); // Ajuste para mostrar en formato 12 horas
        const minutos = String(fechaHoraActual.getMinutes()).padStart(2, '0');
        const segundos = String(fechaHoraActual.getSeconds()).padStart(2, '0');
        const ampm = fechaHoraActual.getHours() >= 12 ? 'PM' : 'AM'; // Obtener AM o PM


        // Crear la cadena en el formato deseado
        const fechaHoraPersonalizada = `${dia}/${mes}/${anio} ${horas}:${minutos}:${segundos} ${ampm}`;

        return fechaHoraPersonalizada;
    }
    async function HorarioLaboral() {
        const currentDate = new Date();
        let resp;
        if (currentDate.getHours() >= 8 && currentDate.getHours() < 19) {
            resp = true;
        }
        else {
            resp = false;
        }
        UpdateObjHub("HorarioLaboral", resp)
        return resp;
    }
    async function GetInfoResponsable() {
        //////SendNotification
        let resp;
        const url = `${ObjChat.api}/api/Chat/GetInfoResponsable?`;
        await axios
            .get(`${url}serviceId=${ObjChat.serviceId}&countryId=${ObjChat.countryId}`)
            .then(async (res) => {

                setEmailsResponsables(res.data.data)
                if (res.data.code === 1) {
                    resp = 1
                }
                else {
                    resp = res.data.data.length > 0 ? res.data.data[0].email : "";
                }
            })
            .catch(function (error) {
                //console.log.log(error);
                resp = -1;
            });
        return resp;
    }
    async function GetInfoAllResponsables() {
        //////SendNotification
        let resp;
        const url = `${ObjChat.api}/api/Chat/GetInfoResponsable?`;
        await axios
            .get(`${url}serviceId=${ObjChat.serviceId}&countryId=${ObjChat.countryId}`)
            .then(async (res) => {
                //console.log.log("GetInfoResponsable: ", res);
                if (res.data.code === 1) {
                    resp = 1
                }
                else {
                    resp = res.data.data;
                }
            })
            .catch(function (error) {
                //console.log.log(error);
                resp = -1;
            });
        return resp;
    }
    async function GetConnResponsable(emailR) {
        //////SendNotification

        let resp;
        //console.log.log(emailR);
        const url = `${ObjChat.api}/api/Chat/GetConnResponsable?`;
        await axios
            .get(`${url}emailRes=${emailR}`)
            .then((res) => {
                //console.log.log("GetConnResponsable: ", res);
                resp = res;
            })
            .catch(function (error) {
                //console.log.log(error);
            });

        return resp;
    }
    async function SendWelcomeMessage(de, para, countryId, serviceId, date) {
        //////SendNotification
        //console.log.log("SendWelcomeMessage RECIBE", de, para, countryId, serviceId, date)
        let resp;
        const url = `${ObjChat.api}/api/Services/SendWelcomeMessage?`;
        await axios
            .post(`${url}de=${de}&para=${para}&countryId=${countryId}&serviceId=${serviceId}&date=${date}`)
            .then((res) => {
                //console.log.log("SendWelcomeMessage: ", res.status);
                resp = true;
            })
            .catch(function (error) {
                //console.log.log(error);
                resp = false;
            });

        return resp;
    }
    async function Lastmessage(message) {

        // Obtener la fecha y hora actual
        const fechaHoraPersonalizada = await FechaActual();
        //console.log.log('Fecha y hora actual en formato personalizado:', fechaHoraPersonalizada);
        const arrChat = arrayChat;
        let item = {
            "Time": fechaHoraPersonalizada,
            "Name": "IWO",
            "Text": message,
            "From": true,
            "classname": "Chat-Resposable",
            "clasname2": "avatar-chat",
            "src": img_avatar,
            "clasname3": "message",
            "clasname4": "msg-chat",
            "timeclass": ""
        }
        arrChat.push(item);

        setarrayChat(arrChat);
        UpdateObjHub("ConvesationHub", arrayChat)
        scrollToBottom();
    }
    async function sendNotification(emailResponsable) {

        //////SendNotification
        //emailResponsable
        //ObjChat.phoneUser
        const fechaHoraPersonalizada = await FechaActual();
        let resp;
        const SendNotification = `${ObjChat.api}/api/Services/SendNotificationWithInfo?`;
        await axios
            .post(`${SendNotification}clientEmail=${ObjChat.emailUser}&serviceId=${ObjChat.serviceId}&countryId=${ObjChat.countryId}&date=${fechaHoraPersonalizada}&responsableEmail=${emailResponsable}`)
            .then((res) => {
                //console.log.log("sendNotification: ", res.status);
                resp = true;
            })
            .catch(function (error) {
                //console.log.log(error);
                resp = false;
            });
    }

    async function sendNotificationNoResponse(emailResponsable) {

        //////SendNotification
        //emailResponsable
        //ObjChat.phoneUser
        const fechaHoraPersonalizada = await FechaActual();
        let resp;
        const SendNotification = `${ObjChat.api}/api/Services/SendNotificationNoResponse?`;
        await axios
            .post(`${SendNotification}clientEmail=${ObjChat.emailUser}&serviceId=${ObjChat.serviceId}&countryId=${ObjChat.countryId}&date=${fechaHoraPersonalizada}&responsableEmail=${emailResponsable}`)
            .then((res) => {
                //console.log.log("sendNotification: ", res.status);
                resp = true;
            })
            .catch(function (error) {
                //console.log.log(error);
                resp = false;
            });
    }
    async function EvaluateResponsable(obje) {

        //console.log.log("Recibe", obje)
        return true;
    }
    
    async function PostCorreoIwo() {
        const correosResposables = EmailsResponsables.map((item) => item.email);

        const requestData = {
            emailClient: ObjChat.emailUser,
            emailsResponsable: correosResposables
          };
        const SendMailNoResponse = `${ObjChat.api}/api/Services/SendMailNoResponse?`;
        await axios
            .post(`${SendMailNoResponse}`, requestData)
            .then((res) => {
                console.log.log("Mensaje enviado con exito: ", res);
              
            })
            .catch(function (error) {
                //console.log.log(error);
            });
    }

    useEffect(async () => {
        //console.log.log("Contexto al momento", ObjChat)
        const connectToSignalR = async () => {
            let conexionID;
            try {
                const newConnection = new signalR.HubConnectionBuilder()
                    .withUrl(`${ObjChat.api}/message`)
                    .build();
                UpdateObjHub("conexion", newConnection)
                await newConnection.on("NewUserConected", async (obje) => {
                    //console.log.log("Un usuario acaba de conectarse", obje);
                    let _EvaluateResponsable = await EvaluateResponsable(obje)
                    //console.log.log("Resp", _EvaluateResponsable)

                });
                await newConnection.on("sendMessage", async (obj) => {
                    //console.log.log("Un usuario acaba de mandar mensaje", obj);
                    var item = {
                        "Time": obj.fecha,
                        "Name": obj.de,
                        "Text": obj.mensaje,
                        "From": true,
                        "classname": "Chat-Resposable",
                        "clasname2": "avatar-chat",
                        "src": img_avatar,
                        "clasname3": "message",
                        "clasname4": "msg-chat",
                        "timeclass": ""
                    }
                    setarrayChat(prevMessages => [...prevMessages, item])
                    UpdateObjHub('ConvesationHub', arrayChat)
                    scrollToBottom();
                    //UpdateObjHub('conexionIDClienteTest', obj.deConnId)

                });
                await newConnection.start()
                    .then(() => {
                        conexionID = newConnection.connection.connectionId
                        //Aqui conectamos a la sala
                    })
                    .catch(error => {
                        //console.log.error(error);
                        conexionID = false
                    });
            } catch (error) {
                //console.log.log(error)
            }
            scrollToBottom();
            return conexionID;

        };
        async function fetchConnectUser(conId, email, idUser) {
            //console.log.log(conId)
            //console.log.log(email)
            //console.log.log(idUser)
            let isClient = true;
            let resp;
            const ConnectUser = `${ObjChat.api}/api/Chat/ConnectUser?`;
            await axios
                .post(`${ConnectUser}conId=${conId}&email=${email}&idUser=${idUser}&isClient=${isClient}`)
                .then((res) => {
                    //console.log.log("ConnectUser: ", res);
                    resp = res;
                })
                .catch(function (error) {
                    //console.log.log(error);
                });

            return resp;

        }
        async function ConversationstartHub() {
            return ObjChat.ConversationstartHub;
        }
        async function Firstmessage(message) {
            //console.log.log('ObjChat', ObjChat);

            let array = []
            setinputValue("")
            setarrayChat("");
            const fechaHoraPersonalizada = await FechaActual();
            //console.log.log('Fecha y hora actual en formato personalizado:', fechaHoraPersonalizada);
            let firtconversation = {
                "Time": fechaHoraPersonalizada,
                "Name": "IWO",
                "Text": message,
                "From": true,
                "classname": "Chat-Resposable",
                "clasname2": "avatar-chat",
                "src": img_avatar,
                "clasname3": "message",
                "clasname4": "msg-chat",
                "timeclass": ""
            }


            array.push(firtconversation)

            if (!message.includes('Ya te conectamos con un asesor')) {

                //console.log.log("ObjChar", ObjChat)
                let arra = ObjChat.listRespbuscadorporTeams;
                if (arra.length !== 0) {
                    let correo = arra[0].email
                    //console.log.log("Enviamos el primer mensaje de bienvenida")
                    let SendWelcomeMessage_ = await SendWelcomeMessage(ObjChat.emailUser, correo, ObjChat.countryId, ObjChat.serviceId, fechaHoraPersonalizada)
                    //console.log.log("SendWelcomeMessage Respuesta:", SendWelcomeMessage_)
                }

                // const pais = ObjChat.paises.find(e => e.value === ObjChat.countryId)?.label;
                // const servicio = ObjChat.services.find(e => e.value === ObjChat.serviceId)?.label;
                //const _dataResponsables = await GetInfoAllResponsables();
                // const numero = "525551389393";//_dataResponsables[0].telefono;
                // const message = `Buen%20día,%20quiero%20comunicarme%20con%20IWS%20Services,%20soy%20de%20${pais}%20y%20estoy%20interesado%20en%20el%20servicio%20${servicio}`;
                // const linkWA = `https://wa.me/${numero}?text=${message}`

                // let whatsApp = {
                //     "Time": fechaHoraPersonalizada,
                //     "Name": "IWO",
                //     "Text": "WhatsApp",
                //     "From": true,
                //     "classname": "Chat-Resposable",
                //     "clasname2": "avatar-chat",
                //     "src": img_avatar,
                //     "clasname3": "message",
                //     "clasname4": "msg-chat",
                //     "timeclass": "",
                //     "image": true,
                //     "linkWA": linkWA
                // }
                // array.push(whatsApp);
            }

            setarrayChat(array);
            UpdateObjHub("IsConectedResponsable", true)
            scrollToBottom();

        }
        const fetchData = async () => {
            let message = "Estamos buscando a un asesor disponible, espere un momento por favor...."
            await Firstmessage(message);
            const _emailresponsable = await GetInfoResponsable();
            UpdateObjHub("emailResponsable", _emailresponsable);
            UpdateObjHub("phoneResponsable", _emailresponsable);
            let _sendNotification;

            //console.log.log("Correo responsable", _emailresponsable)
            switch (_emailresponsable) {
                case -1:
                    //console.log.log("Hubo un error al obtener el correo del responsable")
                    _sendNotification = await sendNotificationNoResponse(_emailresponsable);
                    console.log.log(_sendNotification)
                    setboolpadlock(true)
                    break;
                case 1:
                    //console.log.log("Responsable no se encuentra en base de datos")
                    let message = "Una disculpa, nuestro servicio no esta disponible por el momento. Pero ya tenemos tus datos un asesor se contactara contigo"
                    _sendNotification = await sendNotificationNoResponse(_emailresponsable);
                    console.log.log(_sendNotification)
                    await Firstmessage(message);
                    setboolpadlock(true)
                    break;
                default:
                    //connectReponsable(1);
                    connectResponsables();
                    break;
            }
        }


        const connectResponsables = async (times = 1) => {

            async function sendmessage_(conexiondidr_, correoresponsable) {

                const emailFrom = ObjChat.emailUser;
                const emailTo = correoresponsable//await GetInfoResponsable();
                const ConnectionId = conexiondidr_;
                const Mesage = `¡Hola! , el cliente ${ObjChat.nombreUser}, esta intentando contactarte.`
                const Name = ObjChat.nombreUser;
                const UserType = 'C';

                const fechaHoraPersonalizada = await FechaActual();
                var Message = { ConnectionId, emailFrom, emailTo, Mesage, Date: fechaHoraPersonalizada, UserType };
                var itemJson = JSON.stringify(Message);
                //console.log.log(itemJson)

                try {
                    await ObjChat.conexion.invoke("SendAllMessages", itemJson);

                } catch (err) {
                    //console.log.error(err);
                }

            }

            let _sendNotification;
            let ResponsableInicial;
            //console.log.log("Obtenemos Listado de Responsables",)
            const _emailresponsable = await GetInfoAllResponsables();
            ResponsableInicial = _emailresponsable[0].email;
            //console.log.log("GetInfoAllR_emailresponsableesponsables", _emailresponsable)
            const _item = _emailresponsable.map(async (item) => {

                let _GetConnResponsable = await GetConnResponsable(item.email)
                let _Conexion = _GetConnResponsable.data
                //console.log.log(_GetConnResponsable)
                return {
                    ...item,
                    _Conexion,
                }
            });
            Promise.all(_item)
                .then(async (resultados) => {
                    // resultados es un array con los valores resueltos de las promesas
                    //console.log.log(resultados);
                    //console.log.log("BUSCAMOS SI ALGUN RESPONSABLE ESTA CONECTADO")
                    const _resultados = resultados.map((item) => {
                        if (item._Conexion && item._Conexion.code === 0) {
                            // Hacer algo si el code es igual a 0
                            return item;
                        }
                    });
                    //console.log.log("resultados", _resultados);
                    const _responsablesFiltrados = _resultados.filter((_resultados) => _resultados !== undefined);
                    //console.log.log("_responsablesFiltrados", _responsablesFiltrados);
                    if (_responsablesFiltrados.length > 0) {
                        //console.log.log('Hay datos en el array.');
                        UpdateObjHub("ConversationstartHub", true)
                        const fechaHoraPersonalizada = await FechaActual();
                        const _conexionID = await connectToSignalR();

                        //console.log.log("Generar conexionID")
                        setconexionidcliente(_conexionID)
                        //console.log.log("Conexion ID Asignado", _conexionID)
                        if (_conexionID !== false) {
                            //console.log.log("Se genero de manera correcta el conexionID")
                            //console.log.log("Conectamos a HUB")
                            const _ConnectUser = await fetchConnectUser(_conexionID, ObjChat.emailUser, ObjChat.idUSER);
                            //console.log.log("HUB", _ConnectUser)
                            if (_ConnectUser.data.code === 0) {
                                //console.log.log("Enviamos el primer mensaje de bienvenida")
                                let SendWelcomeMessage_ = await SendWelcomeMessage(ObjChat.emailUser, _responsablesFiltrados[0].email, ObjChat.countryId, ObjChat.serviceId, fechaHoraPersonalizada, _responsablesFiltrados[0]._Conexion.data.data.connectionID[0])
                                //console.log.log("SendWelcomeMessage Respuesta:", SendWelcomeMessage_)
                                UpdateObjHub("Conected", true)
                                //console.log.log("Conectamos a HUB de manera exitosa")
                                //console.log.log("Responsable conectado")
                                setnombreresponsable(_responsablesFiltrados[0]._Conexion.data.data.name)
                                setcorreoresponsable(_responsablesFiltrados[0]._Conexion.data.data.email)
                                setconexionidresponsable(_responsablesFiltrados[0]._Conexion.data.data.connectionID[0])
                                const _Conversationstart = await ConversationstartHub();
                                if (!_Conversationstart) {
                                    //console.log.log("¿Dentro de Horario laboral?")
                                    const _CurrentHour = await HorarioLaboral();
                                    //console.log.log(_CurrentHour)
                                    if (_CurrentHour) {
                                        let message = `¡Hola! ${ObjChat.nombreUser}, Ya te conectamos con ${_responsablesFiltrados[0]._Conexion.data.data.name}`
                                        let _sendNotification = await sendNotification(_responsablesFiltrados[0].email);
                                        //console.log.log(_sendNotification)
                                        await Firstmessage(message);
                                        sendmessage_(_responsablesFiltrados[0]._Conexion.data.data.connectionID[0], _responsablesFiltrados[0]._Conexion.data.data.email)
                                        setboolpadlock(false)
                                    }
                                    else {
                                        let message = "!Hola! Lamento informarte que nuestro horario de servicio es de 8 a 7, por lo que en este momento no es posible que un asesor se contacte contigo, que tengas una linda noche!!"
                                        await Firstmessage(message);
                                        setboolpadlock(true)
                                    }
                                }
                                else {
                                    //console.log.log("Ya tenia una conversacion activa")
                                    const _CurrentHour = await HorarioLaboral();
                                    if (_CurrentHour) {
                                        let message = `¡Hola de nuevo! ${ObjChat.nombreUser}, Ya te conectamos con ${_responsablesFiltrados[0]._Conexion.data.data.name}`
                                        let _sendNotification = await sendNotification(_responsablesFiltrados[0].email);
                                        //console.log.log(_sendNotification)
                                        await Firstmessage(message);
                                        sendmessage_(_responsablesFiltrados[0]._Conexion.data.data.connectionID[0], _responsablesFiltrados[0]._Conexion.data.data.email)
                                        setboolpadlock(false)
                                    }

                                }


                            }
                            else {
                                UpdateObjHub("Conected", false)
                                //console.log.log("Hubo un error al conectarse al HUB")
                                setboolpadlock(true)
                            }


                        }
                        else {
                            //console.log.log("Hubo un error al generar conexionID")
                        }

                    } else {
                        //console.log.log('El array está vacío o no contiene datos.');
                        times += 1;
                        if (times <= 60) {
                            setTimeout(() => {
                                connectResponsables(times)

                            }, 1000);
                        }
                        else {
                            //console.log.log("Responsable No conectado")
                            let message = "Una disculpa, tu asesor tiene problemas con su conexion, pero no te preocupes tenemos tus datos, muy pronto se contactarán contigo"
                            await PostCorreoIwo();
                            await Firstmessage(message);
                            const fechaHoraPersonalizada = await FechaActual();
                            let SendWelcomeMessage_ = await SendWelcomeMessage(ObjChat.emailUser, ResponsableInicial, ObjChat.countryId, ObjChat.serviceId, fechaHoraPersonalizada)
                            _sendNotification = await sendNotificationNoResponse(ResponsableInicial);
                            console.log(_sendNotification)
                            setboolpadlock(true)
                        }
                    }
                })
                .catch((error) => {
                    // Manejar errores si alguna de las promesas falla
                    //console.log.error(error);
                });
        }

        const connectReponsable = async (times = 1, indice = 0) => {


            async function findemail(array, email_) {
                //console.log.log("findemail")
                //console.log.log(array)
                //console.log.log(email_)
                let his_ = array;
                const position_ = his_.findIndex(item => item.email === email_)
                return position_
            }

            let x;
            //console.log.log("Obtenemos correo de responsable")
            const _emailresponsable = await GetInfoAllResponsables();
            //console.log.log("Correo responsable", _emailresponsable)

            let numResponsables = _emailresponsable.length;
            if (times <= 12) {
                //console.log.log("Correo obtenido exitosamente")
                //console.log.log("Buscamos conexionID del resónsable")

                //Timer de 30segundos

                const _ConnResponsable = await GetConnResponsable(_emailresponsable[indice].email);
                //console.log.log("_ConnResponsable", _ConnResponsable)
                if (_ConnResponsable.data.code === 0) {

                    UpdateObjHub("ConversationstartHub", true)
                    const fechaHoraPersonalizada = await FechaActual();
                    const _conexionID = await connectToSignalR();

                    //console.log.log("Generar conexionID")
                    setconexionidcliente(_conexionID)
                    //console.log.log("Conexion ID Asignado", _conexionID)
                    if (_conexionID !== false) {
                        //console.log.log("Se genero de manera correcta el conexionID")
                        //console.log.log("Conectamos a HUB")
                        const _ConnectUser = await fetchConnectUser(_conexionID, ObjChat.emailUser, ObjChat.idUSER);
                        //console.log.log("HUB", _ConnectUser)
                        if (_ConnectUser.data.code === 0) {
                            //console.log.log("Enviamos el primer mensaje de bienvenida")
                            let SendWelcomeMessage_ = await SendWelcomeMessage(ObjChat.emailUser, _emailresponsable[indice].email, ObjChat.countryId, ObjChat.serviceId, fechaHoraPersonalizada, _ConnResponsable.data.data.name)
                            //console.log.log("SendWelcomeMessage Respuesta:", SendWelcomeMessage_)
                            UpdateObjHub("Conected", true)
                            //console.log.log("Conectamos a HUB de manera exitosa")
                            //console.log.log("Responsable conectado")
                            setnombreresponsable(_ConnResponsable.data.data.data.name)
                            setcorreoresponsable(_ConnResponsable.data.data.data.email)
                            setconexionidresponsable(_ConnResponsable.data.data.data.connectionID[0])
                            const _Conversationstart = await ConversationstartHub();
                            if (!_Conversationstart) {
                                //console.log.log("¿Dentro de Horario laboral?")
                                const _CurrentHour = await HorarioLaboral();
                                //console.log.log(_CurrentHour)
                                if (_CurrentHour) {


                                    /*const emailFrom = ObjChat.emailUser;
                                    const emailTo = _emailresponsable[indice].email;
                                    const ConnectionId = _ConnResponsable.data.data.data.connectionID;
                                    const Mesage = "Hola";
                                    const Name = _ConnResponsable.data.data.data.name;
                                    const UserType = 'C';
                                    var Message = { ConnectionId, emailFrom, emailTo, Mesage, Date: fechaHoraPersonalizada, UserType };
                                    var itemJson = JSON.stringify(Message);
                                    //console.log.log(itemJson)
                                    await ObjChat.conexion.invoke("SendAllMessages", itemJson);*/


                                    let message = `¡Hola! ${ObjChat.nombreUser}, Ya te conectamos con ${_ConnResponsable.data.data.data.name}`
                                    await Firstmessage(message);
                                    setboolpadlock(false)

                                }
                                else {
                                    let message = "!Hola! Lamento informarte que nuestro horario de servicio es de 8 a 7, por lo que en este momento no es posible que un asesor se contacte contigo, que tengas una linda noche!!"
                                    await Firstmessage(message);
                                    setboolpadlock(true)
                                }
                            }
                            else {
                                //console.log.log("Ya tenia una conversacion activa")
                                const _CurrentHour = await HorarioLaboral();
                                if (_CurrentHour) {
                                    let message = `¡Hola de nuevo! ${ObjChat.nombreUser}, Ya te conectamos con ${_ConnResponsable.data.data.data.name}`
                                    await Firstmessage(message);
                                    setboolpadlock(false)
                                }

                            }


                        }
                        else {
                            UpdateObjHub("Conected", false)
                            //console.log.log("Hubo un error al conectarse al HUB")
                            setboolpadlock(true)
                        }


                    }
                    else {
                        //console.log.log("Hubo un error al generar conexionID")
                    }

                } else {
                    times += 1;
                    setTimeout(() => {
                        connectReponsable(times, indice)
                    }, 30000);
                }

            } else {
                if (indice >= (numResponsables - 1)) {
                    //console.log.log("Responsable No conectado")
                    let message = "Una disculpa, tu asesor tiene problemas con su conexion, pero no te preocupes tenemos tus datos, muy pronto se contactarán contigo"
                    PostCorreoIwo()
                    await Firstmessage(message);
                    setboolpadlock(true)
                } else {
                    indice++;
                    connectReponsable(1, indice);
                }

            }

        }
        await fetchData();
    }, []);


    const handleClickSend = async () => {

        const emailTo = correoresponsable//await GetInfoResponsable();
        async function sendmessage_(conexiondidr_) {

            const emailFrom = ObjChat.emailUser;
            const emailTo = correoresponsable//await GetInfoResponsable();
            const ConnectionId = conexiondidr_;
            const Mesage = inputValue;
            const Name = ObjChat.nombreUser;
            const UserType = 'C';

            const fechaHoraPersonalizada = await FechaActual();
            var Message = { ConnectionId, emailFrom, emailTo, Mesage, Date: fechaHoraPersonalizada, UserType };
            var itemJson = JSON.stringify(Message);
            //console.log.log(itemJson)

            try {
                await ObjChat.conexion.invoke("SendAllMessages", itemJson);
                let obj = {
                    "Time": fechaHoraPersonalizada,
                    "Name": Name,
                    "Text": Mesage,
                    "From": true,
                    "classname": "container-user",
                    "clasname2": "avatar-user",
                    "src": img_user,
                    "clasname3": "message-user",
                    "clasname4": "",
                    "timeclass": ""
                };
                setarrayChat(prevArray => [...prevArray, obj])
                UpdateObjHub("ConvesationHub", arrayChat)
                scrollToBottom();

            } catch (err) {
                //console.log.error(err);
            }

            setinputValue('')
            scrollToBottom();
        }
        //console.log.log("¿Dentro de Horario laboral?")
        const _CurrentHour = await HorarioLaboral();
        //console.log.log(_CurrentHour)
        if (_CurrentHour) {
            const _ConnResponsable = await GetConnResponsable(emailTo);
            //console.log.log("_ConnResponsable", _ConnResponsable)
            if (_ConnResponsable.data.code === 0) {
                //console.log.log("Responsable conectado")
                await sendmessage_(_ConnResponsable.data.data.data.connectionID[0]);
            }
            else {
                //console.log.log("Responsable No conectado")
                await Lastmessage("Una disculpa, tu asesor tiene problemas con su conexion, pero no te preocupes tenemos tus datos, muy pronto se contactaran contigo")
                PostCorreoIwo()

            }
        }
        else {
            //console.log.log("Fuera de horario laboral")
            await Lastmessage("Lamento informarte que nuestro horario de servicio es de 8 a 7, por lo que en este momento no es posible que un asesor se contacte contigo, que tengas una linda noche!!")
        }
    }
    const handleInput = (event) => {

        setinputValue(event.target.value)
        scrollToBottom();

    }
    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            if (inputValue.length == 0) {

            } else {

                handleClickSend();
            }
        }
    }
    const valueMessage = () => {
        if (inputValue.length == 0) {

        } else {

            handleClickSend();

        }

    }
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            const chatContainer = chatContainerRef.current;
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    };
    return (
        <div>
            <div className="chat">
                <div className="header-chat">
                    <img src={img_header} class="logo-image" />
                </div>
                <div className="container-chat" ref={chatContainerRef}>
                    {arrayChat.length > 0 ? arrayChat.map((
                        chat, index
                    ) =>
                        <div key={index}>
                            <div className={chat.classname}>
                                <div className={chat.clasname2}>
                                    <img className="img-avatar-chat" src={`${chat.src}`} />
                                </div>
                                <div className={chat.clasname3}>
                                    <span className={chat.clasname4}>

                                        {
                                            chat.image === undefined ? chat.Text : <WhatsApp linkWA={chat.linkWA} />
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : ""}
                </div>
                {
                    !boolpadlock ?
                        <div className="enviar-chat">
                            <input type="textarea" value={inputValue} onChange={handleInput} onKeyPress={handleEnter} className="chat-text" placeholder="Type the message ..." />
                            <button className="input-enviar" id="animado" onClick={() => valueMessage()}>
                                <img src={icon_enviar} className="icon-enviar" />
                            </button>
                        </div>
                        : ""
                }

            </div>
        </div>
    );
}


export default HubChat;